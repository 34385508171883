import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
export const configJSONBase = require("../../../framework/src/config");
import {Chart} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import React from 'react'
const chartRef = React.createRef()
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  history: any;
  location: any;
  // Customizable Area End
}

interface S {
  arrayHolder: any;
  token: string;
  // Customizable Area Start
    analyticsData: any;
    optionspie: any;
    bardataclientwise: any;
    selecteddate: any;
    dateRange: any;
    from: any;
    to: any;
    plugins: any;
    customDatalabels: any;
    baroptionclientwise: any;
    baroptionclientwiseNodata: any;
    TLChartRolewise: any;
    TLOptionRolewise: any;
    TLOptionRolewiseNodata: any;
    loader: any;
    totalNoOfProjectsTypewise: any;
    totalProjectsTypewise: any;
    totalProjectsClientWise: any;
    totalProjectsStatusWise: any;
    noOfErrorsClientWise: any;
    overdueClientWise: any;
    backgroundColor: any;
    analytics_workspace_id :any;
    fullSizeData: any;
    fullSizeClientWise: any;
    fullSizeNoofErrors: any;
    fullSizeOverdue: any;
    drilldownData: any;
    myDataCount: any;
    myDataLabels: any;

    drilldownoption: any;
    drilldownanalyticsdata: any;
    drilldowntotalNoOfProjectsTypewise: any;
    drilldowntotalProjectsTypewise: any;
    indexValue: any;
    passedData: any;
    drilldownconsolidatedData: any;
    drilldowntype_wise: any;
    selectedData: any;
    analyticsDatastatuswise: any;
    totalNoOfProjectsStatusWise: any;
    totalProjectsStatuswiseanalytics: any;
    TLDataErrorDesigneerWise: any;
    clientValue: any;
    chartError : any;
    errorStatement: any;
    myDatacountBarteamwise: any;
    myDatacountOverdue: any;
    activeBarIndex: any;
    buttonkeycode: any;
    tokenError: any;
    isSuccessModal: any;
    OtherErrorStatement: any;
    drilldownErrorStat: any;
    overdueDataArray: any;

    buttondisableLeftoverdue: any;
    buttondisableLeftError: any;
    buttondisableRightOverdue: any;
    buttondisableRightError: any;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class DesignerAnalyticsController extends BlockComponent<Props, S, SS> {

  // Customizable Area Start
  userGetApiCallId: any;
  analyticsWorkspaceApiCallId :any;
  analyticsDetailsApiCallId: any;
  analyticsDetailsfullsizeApiCallId: any;
  _isMounted: any;
    chartRef: any;
    chartReference: any;
    chartReferenceTeamwise: any;
    chartReferenceOverdue: any;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    Chart.register(ChartDataLabels);
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      arrayHolder: [],
      token: "",
      selecteddate: "",
      dateRange: "",
      from: JSON.parse(sessionStorage.getItem("analyticsData") || '{}').from || "",
      to: JSON.parse(sessionStorage.getItem("analyticsData") || '{}').to || "",
      loader:true,
      chartError :false,
      tokenError: false,
      isSuccessModal: false,
      OtherErrorStatement: "",
      errorStatement: "",
      activeBarIndex: 0,
      buttonkeycode: "",
      totalNoOfProjectsTypewise: "",
      totalNoOfProjectsStatusWise: "",
      totalProjectsTypewise: [],
      totalProjectsStatuswiseanalytics: [],
      TLDataErrorDesigneerWise: [],
      totalProjectsClientWise: [],
      totalProjectsStatusWise: [],
      noOfErrorsClientWise: [],
      overdueClientWise: [],
      analytics_workspace_id: "",
      fullSizeData: {},
      fullSizeClientWise: {},
      fullSizeNoofErrors: {},
      fullSizeOverdue: {},
      drilldownData: {},
      myDataCount :[],
      myDatacountBarteamwise: [],
      myDatacountOverdue: [],
      clientValue: "",
      myDataLabels: [],
      drilldownErrorStat: "",
      overdueDataArray: [],
      buttondisableLeftoverdue: true,
      buttondisableLeftError: true,
      buttondisableRightOverdue: false,
      buttondisableRightError: false,
      customDatalabels: {
        id: 'customDatalabels',
        afterDatasetsDraw(chart: any, args: any, pluginOptions: any) {
          const { ctx, chartArea: {top, bottom, left, right}} = chart;
          ctx.save();
          ctx.beginPath();
          ctx.lineWidth = 3;
          ctx.strokeStyle = "red";

          ctx.moveTo(left - 1, top + 3);
          ctx.lineTo(left + 5, top + 10);
          ctx.moveTo(left + 1, top + 3);
          ctx.lineTo(left - 5, top + 10);
          ctx.moveTo(left, top + 5);
          ctx.lineTo(left, bottom);
          ctx.lineTo(right - 5, bottom);
          ctx.moveTo(right - 3, bottom + 1)
          ctx.lineTo(right - 10, bottom - 5);
          ctx.moveTo(right - 3, bottom - 1);
          ctx.lineTo(right - 10, bottom + 5);
          ctx.stroke();
          ctx.closePath();
        }
      },
      plugins: [],
      analyticsData : {},
      analyticsDatastatuswise: {},
      backgroundColor: [
                '#00FFFF', '#EADDCA', '#EE4B2B', '#AAFF00', '#F2D2BD', '#89CFF0', 
                '#E1C16E', '#800020', '#E4D00A', '#FFB6C1', '#7393B3', '#FFFF8F', 
                '#E97451', '#50C878', '#DA70D6', '#0047AB', '#FAD5A5', '#D70040',
                '#228B22', '#F8C8DC', '#00008B', '#FFC000', '#954535', '#90EE90', 
                '#673147', '#40e0d0'
              ],

      optionspie: {
        elements: {
          arc: {
            offset: 10
          }
        },
        responsive: true, 
        maintainAspectRatio: false, 
        plugins: {
          labels:{
            display: false,
          },
          legend: {
            display: false,
          },
          datalabels: {
            display: true,
            color: "black",
            font: {
              weight: "bold"
            },
            formatter: (value: any, context: any) => {
              return value;
            },
          },
        },
      },
      TLChartRolewise: {
        labels: [
          'a12345', 'Client 1001', 'client 1601', 'dsd', 'Hi', 'HONDA', 'humtum', 
          'J Client', 'jony', 'new_client', 'neww', 'one', 'Rajdeep', 'ROCK', 
          'Solar 1 client', 'Solar 1 client 1', 'Solar client 28 dec', 'son', 
          'Temp tEst', 'Testing email value', 'Test Solar 23', 'UFO', 'uu', 
          'VG', 'Z Email validation front end', 'zemmm mmmmmm mmm mmmmmmm'
        ],
        datasets: [
          {
            grouped: true,
            label: "Designer",
            data: [
              22, 31, 38, 20, 82, 40, 68, 
              79, 50, 86, 35, 82, 65, 22, 
              41, 84, 25, 97, 20, 63, 55, 
              20, 40, 75, 51, 44 
            ],
            backgroundColor: "#1F51FF",
            datalabels: {
              anchor: 'end',
              align: 'start',
              rotation: 270,
            },
            barPercentage: 0.7,
            // borderWidth: 1,
            borderRadius: 5
          },

          {
            grouped: true,
            label: "QC",
            data: [
              72, 36, 122, 31, 82, 100, 60, 
              50, 20, 33, 91, 96, 37, 44, 
              51, 28, 126, 84, 99, 40, 38, 
              43, 73, 58, 68, 69 
            ],
            backgroundColor: "#FFBF00",
            datalabels: {
              anchor: 'end',
              align: 'start',
              rotation: 270,
            },
            barPercentage: 0.7,
            // borderRadius: 5,
            // borderWidth: 1,
            borderRadius: 5
          },

          {
            grouped: true,
            label: "QA",
            data: [
              12, 26, 32, 13, 24, 10, 9,
              51, 28, 126, 84, 99, 40, 38, 
              50, 20, 33, 91, 96, 37, 44, 
              34, 37, 85, 28, 63 
            ],
            backgroundColor: "#660066",
            datalabels: {
              anchor: 'end',
              align: 'start',
              rotation: 270,
            },
            barPercentage: 0.7,
            // borderRadius: 5,
            // borderWidth: 0.8,
            borderRadius: 5
          },    
        ],
      },

      TLOptionRolewise: {
        onClick: this.drillDownFunction,
        borderWidth: 1,
        barThickness: 24,
        categoryPercentage: 0.2,
        barPercentage: 0.5,
        plugins: {
          legend: {
            display: false,
          },
          datalabels: {
            display: true,
            color: "black",
            font: {
              weight: "bold",
              color: "black"
            }
          }
        },
        scales: {
          y: {
            min: 0,
            max: this.maxValueOverduewise,
            stepSize: 50,
            beginAtZero: true,
            title: {
              display: true,
              text: "No of projects   ⎯⎯⎯⎯⎯⎯⎯›",
              anchor: "start",
              align: "start",
              padding: 10,
              
            },
            grid: {
              display: true,
              lineWidth: 1,
              borderDash: [5]
            },
            border: {
              dash: 10,
            },
            ticks:{
              font: {
                weight: "600"
              }
            }
          },
          x: {
            min: 0,
            max: 2,
            beginAtZero: true,
            title: {
              display: true,
              text: "Clients   ⎯⎯⎯⎯⎯⎯⎯›",
              anchor: "start",
              align: "start",
            },
            grid: {
              display: false
            },
            ticks:{
              minRotation: 20,
              maxRotation: 20,
              font: {
                weight: "600"
              },
              callback: function(this: any, value :any) {
                return (this.getLabelForValue(value).substr(0, 4).concat('...'))
              }
            }
          }
        },
        layout: {
          padding: {
            top: 15,
          }
        }
      },

      TLOptionRolewiseNodata: {
        onClick: this.drillDownFunction,
        borderWidth: 1,
        barThickness: 38,
        plugins: {
          legend: {
            display: false,
          },
          datalabels: {
            display: true,
            color: "black",
            font: {
              weight: "bold",
              color: "black"
            }
          }
        },
        scales: {
          y: {
            beginAtZero: true,
            title: {
              display: true,
              text: "No of projects   ⎯⎯⎯⎯⎯⎯⎯›",
              anchor: "start",
              align: "start",
              padding: 10,
              
            },
            grid: {
              display: true,
              lineWidth: 1,
              borderDash: [5]
            },
            border: {
              dash: 10,
            },
            ticks:{
              font: {
                weight: "600"
              }
            }
          },
          x: {
            min: 0,
            max: 4,
            beginAtZero: true,
            title: {
              display: true,
              text: "Clients   ⎯⎯⎯⎯⎯⎯⎯›",
              anchor: "start",
              align: "start",
            },
            grid: {
              display: false
            },
            ticks:{
              minRotation: 20,
              maxRotation: 20,
              font: {
                weight: "600"
              }
            }
          }
        },
      },
      
      baroptionclientwise: {
        // onClick: this.drillDownFunction,
        borderWidth: 1,
        barThickness: 38, 
        plugins: {
        legend: {
          display: false
        },
        datalabels: {
          display: true,
          color: "black",
          font: {
            weight: "bold",
            color: "black"
          }
      }
      },
        scales: {
          y: {
            min: 0,
            max: this.maxValueErrorwise,
            stepSize: 50,
            beginAtZero: true,
            title: {
              display: true,
              text: "No of errors   ⎯⎯⎯⎯⎯⎯⎯›",
              anchor: "start",
              align: "start",
              padding: 10,
              
            },
            grid: {
              display: true,
              lineWidth: 1,
              borderDash: [5]
            },
            border: {
              dash: 10,
            },
            ticks:{
              font: {
                weight: "600"
              }
            }
          },
          x: {
            min: 0,
            max: 2,
            beginAtZero: true,
            title: {
              display: true,
              text: "Client folder/ subfolder - Template   ⎯⎯⎯⎯⎯⎯⎯›",
              anchor: "start",
              align: "start",
            },
            grid: {
              display: false
            },
            ticks:{
              minRotation: 20,
              maxRotation: 20,
              font: {
                weight: "600"
              },
              callback: function(this: any, value :any) {
                return (this.getLabelForValue(value).substr(0, 4).concat('...'))
              }
            }
          }
        },
        layout: {
          padding: {
            top: 15,
          }
        }
      },

      baroptionclientwiseNodata: {
        // onClick: this.drillDownFunction,
        borderWidth: 1,
        barThickness: 38, 
        plugins: {
        legend: {
          display: false
        },
        datalabels: {
          display: true,
          color: "black",
          font: {
            weight: "bold",
            color: "black"
          }
      }
      },
        scales: {
          y: {
            stepSize: 5,
            beginAtZero: true,
            title: {
              display: true,
              text: "No of errors   ⎯⎯⎯⎯⎯⎯⎯›",
              anchor: "start",
              align: "start",
              padding: 10,
              
            },
            grid: {
              display: true,
              lineWidth: 1,
              borderDash: [5]
            },
            border: {
              dash: 10,
            },
            ticks:{
              font: {
                weight: "600"
              }
            }
          },
          x: {
            min: 0,
            max: 2,
            beginAtZero: true,
            title: {
              display: true,
              text: "Client folder/ subfolder - Template   ⎯⎯⎯⎯⎯⎯⎯›",
              anchor: "start",
              align: "start",
            },
            grid: {
              display: false
            },
            ticks:{
              minRotation: 20,
              maxRotation: 20,
              font: {
                weight: "600"
              }
            }
          }
        },
      },

      bardataclientwise: {},

      drilldownoption: {
        responsive: true, 
        maintainAspectRatio: false,
        elements: {
            arc: {
            offset: 10
            }
        }, 
        plugins: {
            labels:{
                display: false,
            },
            datalabels: {
                display: true,
                color: "black",
                font: {
                    weight: "bold"
                },
                formatter: (value: any, context: any) => {
                    return value;
                },
            },
        },
      },
      drilldownanalyticsdata: {},
      drilldowntotalNoOfProjectsTypewise: "",
      drilldowntotalProjectsTypewise: [],
      indexValue: "",
      passedData: {},

      drilldownconsolidatedData: {},
      drilldowntype_wise: {},
      selectedData: false
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
}


handleDatePicker = (a: any) => {
  if (a == null){
    this.setState({
      dateRange: null,
      from: "",
      to: "",
    })
    return
  }
  
  this.setState({
    dateRange:a,
    from: new Date(a[0]).toLocaleDateString("en-GB"),
    to: new Date(a[1]).toLocaleDateString("en-GB"),
  })
}


  analyticsGraphDetails = () => {
    const header = {
      'token': window.localStorage.getItem('token'),
      "Content-Type" : "application/json",
    };
  
    const attrs = {
      "start_date": this.state.from,
      "end_date": this.state.to,
    };
  
    const analytics = {
      filter: attrs,
    };
  
    const httpBody = {
      analytics: analytics,
    };
  
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.analyticsDetailsApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),"bx_block_dashboard/analytics/report");
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(header));  
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage),JSON.stringify(httpBody)); 
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),"POST");  
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  capitalizeFirstLetter(str: any){
    return str.charAt(0).toUpperCase() + str.slice(1);
    }
  
    maxValueErrorwise = () => {
      let maxLimit = Math.max(...this.state.myDataCount)
        return ((Math.round(maxLimit / 100)) * 100 + 100);
    }

    maxValueOverduewise = () => {
      let maxLimit = Math.max(...this.state.overdueDataArray)
        return ((Math.round(maxLimit / 100)) * 100 + 100);
    }

  handleDataForChart = (responseJson: any) => {

    let rolewiseLabels = responseJson.result.client_projects_role_wise.map((item: any) => {return(this.capitalizeFirstLetter(item.client_name))})
    this.setState({totalProjectsClientWise: responseJson.result.client_projects_role_wise})
    this.setState({myDatacountOverdue: rolewiseLabels.length})
    let rolwiseTL = responseJson.result.client_projects_role_wise
    let designer = rolwiseTL.map((item: any) => {if(item.designer > 0) {return(item.designer)}})
    let qc = rolwiseTL.map((item: any) => { if(item.qc > 0) {return(item.qc)}})
    let qa = rolwiseTL.map((item: any) => { if(item.qa > 0) {return (item.qa)}})
    let bardatadesmax = rolwiseTL.map((item: any) => {return(item.designer)})
    let bardataqcmax = rolwiseTL.map((item: any) => {return(item.qc)})
    let bardataqamax = rolwiseTL.map((item: any) => {return (item.qa)})
    let bardataoverduepdqc = bardatadesmax.concat(bardataqcmax, bardataqamax)
    this.setState({overdueDataArray: bardataoverduepdqc})

    let rolewisedatalabels =  {
        anchor: 'end',
        padding: -2,
        align: 'end',
      }

    let rolewisedatasets = [
      {
        grouped: true,
        label: "Designer",
        data: designer,
        backgroundColor: "#1F51FF",
        datalabels: rolewisedatalabels,
        barPercentage: 0.5,
      },
      {
        grouped: true,
        label: "QC",
        data: qc,
        backgroundColor: "#FFBF00",
        datalabels: rolewisedatalabels,
        barPercentage: 0.5,
      },
      {
        grouped: true,
        label: "QA",
        data: qa,
        backgroundColor: "#660066",
        datalabels: rolewisedatalabels,
        barPercentage: 0.5,
      },
    ]

    let rolewise = {
      labels: rolewiseLabels,
      datasets: rolewisedatasets
    }

    this.setState({TLChartRolewise: rolewise})
    
    let clientwiseLabels = responseJson.result.no_of_errors_client_wise.map((item: any) => {return(this.capitalizeFirstLetter(item.client_name))})
    let barclientWisedata = responseJson.result.no_of_errors_client_wise.map((item: any) => {return(item.count)})
    this.setState({myDataCount: barclientWisedata})
    let bardataLabels =  {
      anchor: 'end',
      padding: -2,
      align: 'end',
    }
    let backgroundColor = [
      '#00FFFF', '#EADDCA', '#EE4B2B', '#AAFF00', '#F2D2BD', '#89CFF0', 
      '#E1C16E', '#800020', '#E4D00A', '#FFB6C1', '#7393B3', '#FFFF8F', 
      '#E97451', '#50C878', '#DA70D6', '#0047AB', '#FAD5A5', '#D70040',
      '#228B22', '#F8C8DC', '#00008B', '#FFC000', '#954535', '#90EE90', 
      '#673147', '#40e0d0']

    let bardatasetclientwise = [
      {
        data: this.state.myDataCount,
        datalabels: bardataLabels,
        backgroundColor: backgroundColor,
        barPercentage: 0.5,
      }
    ] 

    let bardataclientwise = {
      labels: clientwiseLabels,
      datasets: bardatasetclientwise,
    }
    this.setState({bardataclientwise: bardataclientwise})
    this.setState({loader: false})
  }

  drillDownFunction = (click: any, myDataCount: any) => {
    if(myDataCount.length >0){
    if(myDataCount[0]) {
      let x = myDataCount[0].index
      let dataValue = this.state.totalProjectsClientWise[x]
      let clientnameValue = this.state.totalProjectsClientWise[x].client_name
      this.setState({clientValue: clientnameValue})
      this.setState({drilldowntotalNoOfProjectsTypewise: dataValue.type_wise_all_clients})
      let drilldowntypewisedata = dataValue.type_wise  
      this.setState({totalProjectsTypewise: dataValue.type_wise}) 
      let piedatatype_wise = drilldowntypewisedata.map((item: any) => {
        if(item.count > 0 ) {return(item.count)}
      })
      let piecolortype_wise = drilldowntypewisedata.map((item: any) => {return(item.color_code)})
      let piedataValuetype_wise = drilldowntypewisedata.map((item: any) => {return(item.type)})
      let datasettype_wise = [
        {
          data: piedatatype_wise,
          backgroundColor: piecolortype_wise,
          radius: 120,
          innerWidth: 120,
          innerHeight: 120,
        }
      ]
      let analyticsDatatype_wise = {
        labels: piedataValuetype_wise,
        datasets: datasettype_wise
      }

      this.setState({analyticsData: analyticsDatatype_wise})

      let drilldownstatuswisedata = dataValue.status_wise 
      this.setState({totalProjectsStatuswiseanalytics: dataValue.status_wise})
      let piedatastatus_wise = drilldownstatuswisedata.map((item: any) => {
        if(item.count > 0 ) {return(item.count)}
      })
      let piecolorstatus_wise = drilldownstatuswisedata.map((item: any) => {return(item.color_code)})
      let piedatavaluestatus_wise = drilldownstatuswisedata.map((item: any) => {return(item.status)})
      let datasetstatus_wise = [
        {
          data: piedatastatus_wise,
          backgroundColor: piecolorstatus_wise,
          radius: 120,
          innerWidth: 120,
          innerHeight: 120,
        }
      ]
      let analyticsDatastatus_wise = {
        labels: piedatavaluestatus_wise,
        datasets: datasetstatus_wise
      }
      this.setState({analyticsDatastatuswise: analyticsDatastatus_wise})
    }
    this.setState({selectedData: true})
  }
  else {
    this.setState({drilldownErrorStat: "Drilldown functionality error "})
  }
  }

  analyticsGraphDetailsDate = (reqType: any, data1: any, data2: any) => {
    if(reqType == "date"){
      this.setState({from: data1})
      this.setState({to: data2})
    }
    const header = {
      'token': window.localStorage.getItem('token'),
      "Content-Type" : "application/json",
    };
  
    const attrs = {
      "start_date": data1,
      "end_date": data2,
    };
  
    const analytics = {
      filter: attrs,
    };
  
    const httpBody = {
      analytics: analytics,
    };
  
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
  
    this.analyticsDetailsApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),"bx_block_dashboard/analytics/report");
  
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(header));
  
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage),JSON.stringify(httpBody));
  
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),"POST");
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
  
    return true;
  }


  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<S>,
    snapshot?: SS | undefined
  ): void {
    if (this.state.dateRange && this.state.dateRange !== prevState.dateRange) {
      this.setState({ loader: true });
      const fromDate = new Date(this.state.dateRange[0]).toLocaleDateString("en-GB", {year: 'numeric', month: 'numeric', day: 'numeric'}).split('/').reverse().join('-');
      const toDate = new Date(this.state.dateRange[1]).toLocaleDateString("en-GB", {year: 'numeric', month: 'numeric', day: 'numeric'}).split('/').reverse().join('-');
      this.analyticsGraphDetailsDate("date",fromDate,toDate);
    }else if(!this.state.dateRange && this.state.dateRange !== prevState.dateRange){
      this.setState({ loader: true });
      this.analyticsGraphDetailsDate("date",null,null);
    }
  }


  handleErrorchart = (responseJson: any) => {

    if(responseJson.errors) {
      if(responseJson.errors[0].token){
        console.clear()
        console.log("Calling error functionality")
        this.setState({OtherErrorStatement: responseJson?.errors[0]?.token})
        this.setState({tokenError: true})
        this.setState({isSuccessModal: true})
        this.setState({loader: false})
      }
      else{
      this.setState({chartError: true})
      this.setState({errorStatement: responseJson?.errors[0]})
      this.setState({loader: false})
      }
    }
  }

  bardata = (start: any, end: any) => {
    const chart = this.chartReference;
    const startScale = chart.config.options.scales.x.min + start;
    const endScale = chart.config.options.scales.x.max + end;
    chart.config.options.scales.x.min = startScale;
    chart.config.options.scales.x.max = endScale;
    if (startScale <= 0) {
      chart.config.options.scales.x.min = 0;
      chart.config.options.scales.x.max = 2;
      this.setState({buttondisableLeftError: true})
    }
    else {
      this.setState({buttondisableLeftError: false})
    }
    if(endScale >= this.state.myDataCount.length) {
      chart.config.options.scales.x.min = this.state.myDataCount.length - 2;
      chart.config.options.scales.x.max  = this.state.myDataCount.length;
      this.setState({buttondisableRightError: true})
    }
    else {
      this.setState({buttondisableRightError: false})
    }
    chart.update()
    
  }
  
  bardatateamwise = (start: any, end: any) => {
    const chart = this.chartReferenceTeamwise;
    const startScale = chart.config.options.scales.x.min + start;
    const endScale = chart.config.options.scales.x.max + end;
    chart.config.options.scales.x.min = startScale;
    chart.config.options.scales.x.max = endScale;
    if (startScale < 0) {
      chart.config.options.scales.x.min = 0;
      chart.config.options.scales.x.max = 3;
    }
    if(endScale > this.state.myDatacountBarteamwise.length) {
      chart.config.options.scales.x.min = this.state.myDatacountBarteamwise.length - 3;
      chart.config.options.scales.x.max  = this.state.myDatacountBarteamwise.length - 1;
    }
    chart.update()
    
  }
  
  bardataoverdue = (start: any, end: any) => {
    const chart = this.chartReferenceOverdue;
    const startScale = chart.config.options.scales.x.min + start;
    const endScale = chart.config.options.scales.x.max + end;
    chart.config.options.scales.x.min = startScale;
    chart.config.options.scales.x.max = endScale;
    if (startScale <= 0) {
      chart.config.options.scales.x.min = 0;
      chart.config.options.scales.x.max = 2;
      this.setState({buttondisableLeftoverdue: true})
    }
    else {
      this.setState({buttondisableRightOverdue: false})
    }
    if(endScale >= this.state.myDatacountOverdue) {
      chart.config.options.scales.x.min = this.state.myDatacountOverdue - 2;
      chart.config.options.scales.x.max  = this.state.myDatacountOverdue - 1;
      this.setState({buttondisableRightOverdue: true})
      this.setState({buttondisableLeftoverdue: false})
    }
    else {
      this.setState({buttondisableRightOverdue: false})
      
    }
    chart.update()
    
  }


  // Customizable Area End


  async receive(from: string, message: Message) {
    // Customizable Area Start  
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if(apiRequestCallId === this.analyticsDetailsApiCallId) {
       if(responseJson && responseJson.result) {
          this.handleDataForChart(responseJson)
        }
        else {
          this.handleErrorchart(responseJson)
        }
        }

    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End
 }
}
