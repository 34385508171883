import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import moment from "moment";

import actionCable from 'actioncable'
const configJSONBase = require("../../../framework/src/config");
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  key: any;
  navigation: any;
  id: string;
  // Customizable Area Start
  history: any;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  projects: any;
  noDataFound: any;
  history: any;
  projectStatusColors: any;
  loader: boolean;
  clientDropDown: any;
  selectedClient: any;
  selectedProjectData: any;
  // Customizable Area End
  filterOpen: any;
  leaveFromDate: Date | null;
  draftData: any;
  workspaceData: any;
  workspace_id: any;
  filterApplied: any;
  modalFromDate: string;
  modalToDate: string;
  selectedClientsName: any;
  clientsData: any;
  filterToDateError : any;
  isFilterApplied : any;
  searchInvoiceValue : any;
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class InvoicesDashboardController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  searchFunction: any;
  allProjectApiCallId: string = "";
  allColorApiCallId: string = "";
  allClientDropDownId: string = "";
  selectedProjectApiId: string = "";
  getAllprojectsApiCallId: string = "";
  getAllWorkspaceApiCallId: string = "";
  getAllClientsApiCallId:string="";
  getProjectsWithFilterApiCallId:string="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      filterToDateError : false,
      filterApplied : false,
      isFilterApplied : false,
      loader: true,
      projects: {},
      noDataFound: false,
      projectStatusColors: {},
      history: this.props.history,
      clientDropDown: [],
      selectedClient: "All",
      selectedProjectData: {},
      filterOpen: null,
      leaveFromDate: null,
      draftData: [],
      workspaceData: [],
      workspace_id: "",
      modalFromDate: "",
      modalToDate: "",
      selectedClientsName: [],
      searchInvoiceValue : "",
      clientsData: [
        {
          id: "169",
          type: "client_list",
          attributes: {
            client_id: "FS169",
            client_name: "ss",
          },
        },
        {
          id: "263",
          type: "client_list",
          attributes: {
            client_id: "SL263",
            client_name: "son",
          },
        },
      ],

      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start

    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      //istanbul ignore next
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      //istanbul ignore next
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
     //istanbul ignore next
      if (responseJson && !responseJson.errors) {
        switch (apiRequestCallId) {
          case this.allProjectApiCallId:
            return true;
          case this.allColorApiCallId:
            this.setState({ projectStatusColors: { ...responseJson } });
            this.setState({ loader: false });
            break;

          case this.allClientDropDownId:
            return this.setState({ clientDropDown: [...responseJson] });

          case this.selectedProjectApiId:
            this.setState({ projects: { ...responseJson } });
            this.setState({ loader: false });
            break;
          case this.getAllprojectsApiCallId:       
            this.getAllprojectsApiCall(responseJson) 
            return true;
            case this.getProjectsWithFilterApiCallId:
            this.setState({ loader:false,})
              this.getProjectsWithFilterApiCall(responseJson)
              return true;  
          case this.getAllWorkspaceApiCallId:
            console.log("RESPONSE", responseJson);
            this.setState({ workspaceData: responseJson.data });
            return true;
            case this.getAllClientsApiCallId:
              console.log("RESPONSE", responseJson);
              this.setState({ clientsData: responseJson.data });
              return true;  
          default:
            break;
        }
      } else {
        this.setState({ loader: false });
      }
    }
    // Customizable Area End

    // Customizable Area Start

    // Customizable Area End
  }

  getAllprojectsApiCall=(responseJson:any)=>{
        if(responseJson[0]){
              this.setState({
                projects: responseJson[0],
                noDataFound: false,
                loader: false
              });
            }else{
              this.setState({
                projects: {},
                noDataFound: true,
                loader: false
              });
            }
  }

  getProjectsWithFilterApiCall=(responseJson:any)=>{
if(responseJson[0]){
                this.setState({
                  projects: responseJson[0],
                  noDataFound: false,
                });
              }else{
                this.setState({
                  projects: {},
                  noDataFound: true,
                });
              }
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start

  getAllProjectData = () => {
    const header = {
      token: window?.localStorage?.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.allColorApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.allProjectColorApi
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.typeForGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
    return true;
  };

  getAllStatusColor = () => {
    const header = {
      token: window?.localStorage?.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.allProjectApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.allProjectApi
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.typeForGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
    return true;
  };

  getAllClientDropDown = () => {
    const header = {
      token: window?.localStorage?.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.allClientDropDownId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.allClientDropDownApi
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.typeForGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
    return true;
  };

  async componentDidMount() {
    this.getAllClientDropDown();
    this.getAllInvoices();
    this.getAllWorkspace();
    this.getAllClientsData()
    this.updateInvoiceWebSocket()
  }

  getAllInvoices = () => {
    const header = {
      token: window?.localStorage?.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAllprojectsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_invoicebilling/invoice_dashboard/invoice_list_dashborad"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
    return true;
  };
  getAllWorkspace = () => {
    const header = {
      token: window?.localStorage?.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAllWorkspaceApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_workspace_management/workspaces"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
    return true;
  };
  getApply = () => {
    // Customizable Area Start
    let selectedClients = "";
    for (const iterator of this.state.selectedClientsName) {
      if(iterator?.id){
        selectedClients = selectedClients+ "&client_id[]="+iterator.id
      }
    }    
    
    let URL = `bx_block_invoicebilling/invoice_dashboard/invoice_list_dashborad?`;
    if (this.state.workspace_id) {
      URL = URL + `workspace_id=${this.state.workspace_id}`;
    }

    if(this?.state?.searchInvoiceValue?.length > 0){
      URL = URL+`&search=${this?.state?.searchInvoiceValue?.trim()}`
    }

    if (this.state.modalFromDate) {
      URL =
        URL +
        `&from_date=${moment(this.state.modalFromDate).format("YYYY-MM-DD")}`;
    }

    if (this.state.modalToDate) {
      URL =
        URL + `&to_date=${moment(this.state.modalToDate).format("YYYY-MM-DD")}`;
    }
    if(this.state.selectedClientsName?.length > 0){
      URL = URL + selectedClients
    }

    const header = {
      token: window.window?.localStorage?.getItem("token"),
      "Content-Type": "application/json",
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getProjectsWithFilterApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      URL
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
    return true;
  };
  handleChangeWorkspaceandClientSearch = (event: any, type: any) => {
    // console.log("VVVVAl",event?.target?.value)
    type == "dropDown" && this.handleChangeWorkspace(event);
  };
  handleChangeWorkspace = (event: any) => {
    console.log("EVE",event)
    const {
      target: { value },
    } = event;
    console.log("VVVV",value)
    if (value[value.length - 1] === "all") {
      this.setState({
        selectedClientsName:
          this.state.selectedClientsName.length ===
          this.state.clientsData.length
            ? []
            : this.state.clientsData,
      });
    }
    else{
      this.setState({ selectedClientsName: value});

    }

    
  };
  getAllClientsData=(workspaceID?:any)=>{

    let URL = "bx_block_invoicebilling/invoice_dashboard/list_client"

    if(workspaceID){
      URL = URL+"?workspace_id="+workspaceID
    }

    const header = {
      token: window?.localStorage?.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAllClientsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      URL
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
    return true;
  }
  handleReset=()=>{
    this.getAllInvoices();
    this.getAllClientsData();
    this.setState({modalFromDate:"",modalToDate:"",selectedClientsName:[],workspace_id:"",filterToDateError:false, isFilterApplied:false, filterApplied:false})
  }


  updatecable:any = {}
  updateInvoiceWebSocket = () => {
    
    this.updatecable = actionCable.createConsumer(
      `${configJSONBase.baseURL}/cable`
    );
    this.updatecable.subscriptions.create(
      {
        channel: "UpdateInvoiceDashboardChannel"
      },
      {
        connected: () => {
          console.log('connected updateInvoiceWebSocket.........');
          
        },
        disconnected: () => {
          console.log('disconnected updateInvoiceWebSocket.........');

        },
        received: (data: any) => {
          let projects = this.state.projects;
          let updatedInvoice:any = {};

          if(data){
            console.log('data for updateInvoiceWebSocket >>>>>>> ', data);

            Object.keys(projects).forEach((type:any) => {
              if (type == data.invoice_type) {
                updatedInvoice[type] = projects[type].map((invoice:any) => {
                  if (invoice.id == data.invoice_list_id) {
                    return Object.assign({}, invoice, {
                      pdf: data.pdf
                    })
                  } else {
                    return invoice;
                  }
                })
              } else {
                updatedInvoice[type] = projects[type];
              }

            });
            this.setState({projects:updatedInvoice})
          }
        }
      }
    );
  }

  async componentWillUnmount() {
    this.updatecable.disconnect();
  }


  // Customizable Area End
}
