import React from 'react';
// Customizable Area Start
import {Paper, Divider, Grid, Box, Link, CircularProgress, Modal, Typography,Button} from '@material-ui/core';
import { Chart as ChartJS, ArcElement, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, } from 'chart.js';
import { Doughnut, Bar,} from 'react-chartjs-2';
ChartJS.register(ArcElement, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend,);
import {ZoomOutMap, ArrowLeft, ArrowRight, ArrowLeftRounded} from '@material-ui/icons';

import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import { CalendarIcon } from "./assets";
import './Graphs.css';


import SuperadminAnalyticsController, { Props} from "./SuperadminAnalyticsController";
// Customizable Area End

export default class Graphs extends SuperadminAnalyticsController {
// Customizable Area Start
    constructor(props: Props) {
        super(props);
        this.chartReference = React.createRef()
        this.chartReferenceTeamwise = React.createRef()
        this.chartReferenceOverdue = React.createRef()
    }

    componentDidMount(): any {
        let token = localStorage.getItem("token")
        this.handleDateChange = this.handleDateChange.bind(this)
        this.setState({selectedData: false})
        if(token) {
            let workspace_id = this.props.location.state;
            let workspace_name = localStorage.getItem('analytics_workspace_name')
            this.setState({workspace_name: workspace_name})
            this.setState({workspace_id_ana: workspace_id.state})
            this.analyticsGraphDetails(workspace_id.state)
    }
    }

    handleBarclient = () => {
        document.addEventListener("keydown", this.handleArrowKeys)
        document.removeEventListener("keydown", this.handleArrowKeysError)
        document.removeEventListener("keydown", this.handleArrowKeysOverdue)
    }

    handleBarteam = () => {
        document.addEventListener("keydown", this.handleArrowKeysError)
        document.removeEventListener("keydown", this.handleArrowKeys)
        document.removeEventListener("keydown", this.handleArrowKeysOverdue)
    }
    handleBaroverdue = () => {
        document.addEventListener("keydown", this.handleArrowKeysOverdue)
        document.removeEventListener("keydown", this.handleArrowKeysError)
        document.removeEventListener("keydown", this.handleArrowKeys)
    }
    handleArrowKeys = (event: any) => {
        const numbars = this.state.bardataclientwise.labels.length
        if(event.keyCode === 37 && this.state.activeBarIndex > 0) {
            this.setState({activeBarIndex: this.state.activeBarIndex - 1})
            this.bardata(-3, -3)
        }
        if(event.keyCode === 39 && this.state.activeBarIndex < numbars - 1) {
            this.setState({activeBarIndex: this.state.activeBarIndex + 1})
            this.bardata(3, 3)
        }
    }

    handleArrowKeysError = (event: any) => {
        const numbars = this.state.bardatateamwise.labels.length
        if(event.keyCode === 37 && this.state.activeBarIndex > 0) {
            this.setState({activeBarIndex: this.state.activeBarIndex - 1})
            this.bardatateamwise(-3, -3)
        }
        if(event.keyCode === 39 && this.state.activeBarIndex < numbars - 1) {
            this.setState({activeBarIndex: this.state.activeBarIndex + 1})
            this.bardatateamwise(3, 3)
        }
    }

    handleArrowKeysOverdue = (event: any) => {
        const numbars = this.state.bardataoverdue.labels.length
        if(event.keyCode === 37 && this.state.activeBarIndex > 0) {
            this.setState({activeBarIndex: this.state.activeBarIndex - 1})
            this.bardataoverdue(-3, -3)
        }
        if(event.keyCode === 39 && this.state.activeBarIndex < numbars - 1) {
            this.setState({activeBarIndex: this.state.activeBarIndex + 1})
            this.bardataoverdue(3, 3)
        }
    }
    
    handleDateChange(date: any) {
        this.setState({ selecteddate: date });
      }
        handleBack = () => {
            this.setState({selectedData: false})
        }

        handleRefresh = () => {
            this.setState({loader: true})
            this.setState({from: null})
            this.setState({to: null})
            this.handleDatePicker(null)
            let workspace_id_value = localStorage.getItem('analytics_workspace')
            this.props.history.push("/analytics_graph", {state: workspace_id_value})
            this.analyticsGraphDetails(workspace_id_value)
        }

        handleClose = () => {
            this.setState({isSuccessModal: false})
            this.setState({tokenError: false})
            this.props.history.push("/EmailAccountLoginsBlock")
        }

        renderSuperadmindrilldown = () => {
            return (
                <div>
                                <Paper style={{minHeight: "400", maxHeight: "auto", flexGrow: 1, display: "flex", flexDirection: "column", textAlign: "center", marginBottom: 10,}}>
                                    <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", height: 60}}>
                                        <h4 style={{paddingLeft: 20,}}>Total Projects (Typewise)</h4>
                                    </div>
                                    <Divider />
                                    <Grid container>
                                        <Grid item lg={4} sm={6} style={{height: 300, marginLeft: -10}}>
                                            <Doughnut 
                                            options={this.state.TLDoughnutChartOptions} 
                                            data = {this.state.analyticsDatatypewise}
                                            width={'100px'} height={'100px'}
                                            />
                                        </Grid>
                                        <Grid item lg={8} sm={6}>
                                            <div style={{maxWidth: 850, minHeight: "230", maxHeight: "auto", marginTop: 40, marginLeft: -40, border: '2px solid #e8e8e8', backgroundColor: "#f9f9f9",
                                            display: 'flex', flexDirection: 'row', flexGrow:1}}>
                                                <div style={{display: "flex", flexDirection: "column", width: '250px', justifyContent: "center", 
                                                alignItems: "center", borderRight: '2px solid #e8e8e8', marginTop: 20, marginBottom: 20}}>
                                                    <h4 style={{fontSize: 24, marginLeft: -50, marginTop: -10,}}>{this.state.drilldowntotalNoOfProjectsTypewise}</h4>
                                                    <div>
                                                        <p style={{marginTop: -25, color: "#9fa0a2"}}>Total projects</p>
                                                    </div>
                                                </div> 
                                                <Grid container>
                                                    <div style={{marginTop: 40, marginLeft: 40}}>
                                                        <Grid container>
                                                            {this.state.totalProjectsTypewiseanalytics.map((item: any) => {
                                                                if(item.count > 0) {
                                                                    return (
                                                                        <Grid item lg={6} sm={12}>
                                                                            <div style={{display: "flex",}}>
                                                                                <Grid container spacing={1} style={{marginRight: 20}}>
                                                                                    <Grid item sm={1}>
                                                                                        <Box><p style={{display: "inline-block", marginTop: 14, marginBottom: 10,  height: "10px", width: "10px", borderRadius: "50%", backgroundColor:`${item.color_code}`,marginLeft: -10,}}></p></Box>
                                                                                    </Grid>
                                                                                    <Grid item sm={5}>
                                                                                        <Box style={{ marginTop: 10, marginBottom: 10, marginLeft: 4, }}><p style={{color: "#9fa0a2", marginTop: 10, marginBottom: 10, marginLeft: 4, textAlign: "start"}}>{item.type}</p></Box>
                                                                                    </Grid>
                                                                                    <Grid item sm={5}>
                                                                                        <Box style={{marginLeft: 50,marginTop: 10, marginBottom: 10,}}><p style={{marginLeft: 20, color: "#000000", fontWeight: 600, marginTop: 10, marginBottom: 10,textAlign: "start"}}>{item.count}</p></Box>
                                                                                    </Grid>
                                                                                    <Grid item sm={1}></Grid>                        
                                                                                </Grid>
                                                                            </div> 
                                                                        </Grid>
                                                                    )
                                                                }
                                                            })}
                                                        </Grid>
                                                    </div>
                                                </Grid>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Paper>

                            <br/>
                            <br/>
                            <Paper style={{minHeight: "400", maxHeight: "auto", flexGrow: 1, display: "flex", flexDirection: "column", textAlign: "center", marginBottom: 10,}}>
                                <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", height: 60}}>
                                    <h4 style={{paddingLeft: 20,}}>Client Projects (Statuswise)</h4>
                                </div>
                                <Divider />
                                <Grid container>
                                    <Grid item lg={4} sm={6} style={{height: 300, marginLeft: -10}}>
                                        <Doughnut 
                                        options={this.state.TLDoughnutChartOptions} 
                                        data = {this.state.analyticsDatastatuswise}
                                        width={'100px'} height={'100px'}
                                        />
                                    </Grid>
                                    <Grid item lg={8} sm={6}>
                                        <Grid container>
                                            <div style={{width: 850, minHeight: "235", maxHeight: "auto", marginTop: 40, marginLeft: -40, border: '2px solid #e8e8e8', backgroundColor: "#f9f9f9",
                                            display: 'flex', flexDirection: 'row'}}>
                                                <Grid container>
                                                    {this.state.totalProjectsStatuswiseanalytics.map((item: any) => {
                                                        if(item.count > 0) {
                                                        return (
                                                            <Grid item lg={3} md={6} sm={12}>
                                                                <div style={{display: "flex",}}>
                                                                    <Grid container>
                                                                        <Grid item sm={1}></Grid>
                                                                        <Grid item sm={1}>
                                                                            <p style={{display: "inline-block", marginTop: 14, marginBottom: 10,  height: "10px", width: "10px", borderRadius: "50%", backgroundColor:`${item.color_code}`,marginLeft: -10,}}></p>
                                                                        </Grid>
                                                                        <Grid item sm={5}>
                                                                            <p style={{color: "#9fa0a2", marginTop: 10, marginBottom: 10, marginLeft: 4, textAlign: "start"}}>{item.status}</p>
                                                                        </Grid>
                                                                        <Grid item sm={5}>
                                                                            <p style={{marginLeft: 20, color: "#000000", fontWeight: 600, marginTop: 10, marginBottom: 10,textAlign: "start"}}>{item.count}</p>
                                                                        </Grid>
                                                                        {/* <Grid item sm={1}></Grid> */}
                                                                    </Grid>
                                                                </div>
                                                            </Grid>
                                                        )
                                                        }
                                                    })}
                                                </Grid>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Paper>
                            </div>
            )
        }

        renderSuperadminData = () => {
            return (
                <>
                {!this.state.tokenError && 
                <div>
                <Paper style={{minHeight: "400", maxHeight: "auto", flexGrow: 1, display: "flex", flexDirection: "column", textAlign: "center",  marginBottom: 10,}}>
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", height: 60}}>
                        <h4 style={{paddingLeft: 20,}}>Total Projects (Typewise)</h4>
                    </div>
                    <Divider />
                    <Grid container>
                        <Grid item lg={4} sm={6} style={{height: 300, marginLeft: -10}}>
                            <Doughnut 
                            options={this.state.optionspie} 
                            data = {this.state.analyticsData}
                            width={'100px'} height={'100px'}
                            />
                        </Grid>
                        <Grid item lg={8} sm={6}>
                            <div style={{maxWidth: 850, minHeight: "230", maxHeight: "auto", marginTop: 40, marginLeft: -40, border: '2px solid #e8e8e8', backgroundColor: "#f9f9f9",
                            display: 'flex', flexDirection: 'row', flexGrow: 1}}>
                                <div style={{display: "flex", flexDirection: "column", width: '250px', justifyContent: "center", 
                                alignItems: "center", borderRight: '2px solid #e8e8e8', marginTop: 20, marginBottom: 20}}>
                                    <h4 style={{fontSize: 24, marginLeft: -50, marginTop: -10,}}>{this.state.totalNoOfProjectsTypewise}</h4>
                                    <div>
                                        <p style={{marginTop: -25, color: "#9fa0a2"}}>Total projects</p>
                                        <p style={{marginTop: -10,color: "#9fa0a2"}}>(All Clients)</p>
                                    </div>
                                </div> 
                                <Grid container>
                                    <div style={{marginTop: 40, marginLeft: 40}}>
                                        <Grid container>
                                            {this.state.totalProjectsTypewise.map((item: any) => {
                                                if(item.count > 0) {
                                                 return (
                                                    <Grid item lg={6} sm={12}>
                                                        <div style={{display: "flex",}}>
                                                            <Grid container spacing={1} style={{marginRight: 20}}>
                                                                <Grid item sm={1}>
                                                                    <Box><p style={{display: "inline-block", marginTop: 14, marginBottom: 10,  height: "10px", width: "10px", borderRadius: "50%", backgroundColor:`${item.color_code}`,marginLeft: -10,}}></p></Box>
                                                                </Grid>
                                                                <Grid item sm={5}>
                                                                    <Box style={{ marginTop: 10, marginBottom: 10, marginLeft: 4, }}><p style={{color: "#9fa0a2", marginTop: 10, marginBottom: 10, marginLeft: 4, textAlign: "start"}}>{item.type}</p></Box>
                                                                </Grid>
                                                                <Grid item sm={5}>
                                                                    <Box style={{marginLeft: 30,marginTop: 10, marginBottom: 10,}}><p style={{marginLeft: 20, color: "#000000", fontWeight: 600, marginTop: 10, marginBottom: 10,textAlign: "start"}}>{item.count}</p></Box>
                                                                </Grid>
                                                                <Grid item sm={1}></Grid>                        
                                                            </Grid>
                                                        </div> 
                                                    </Grid>
                                                )
                                                 }
                                            })}
                                        </Grid>
                                    </div>
                                </Grid>
                            </div>
                        </Grid>
                    </Grid>
                </Paper>

            <br/>
            <br/>
            <Grid container spacing={3}>

                <Grid item lg={6} sm={12}>
                    <Paper  style={{height: 430,  flexGrow: 1, display: "flex", flexDirection: "column", textAlign: "center", marginBottom: 10,}} onClick={this.handleBarclient}>
                        <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", height: 60}}>
                            <h4 style={{paddingLeft: 20,}}>Total Projects(Clientwise)</h4>
                        </div>
                        <Divider />
                        <div style={{height : 340}}>
                            <Grid container>
                                <Grid item sm={10} style={{marginTop: 30, marginLeft: 20}}>
                                    <div style={{width: 545}}>
                                    <Bar data={this.state.bardataclientwise} id="barclientwise"
                                    options={this.state.bardataclientwise.labels.length > 0 ?this.state.baroptionclientwise: this.state.baroptionclientwiseNodata}
                                    height={'180px'} style={webStyle.barchartstyle}
                                    ref={(reference: any) => (this.chartReference = reference)}
                                    />
                                    </div>
                                </Grid>
                                <Grid item sm={1}></Grid>
                            </Grid>
                        </div>
                        <div style={{display: 'flex', justifyContent: 'flex-end', padding: 10,height: 10}}>
                            <Button className="leftbutton" style={{backgroundColor: "white", height: 25, minWidth: 25, border: '1px solid #e8e8e8', display: 'flex', justifyContent: "center", alignItems: "center", marginLeft: 5, marginTop: -30,}}  disabled={this.state.buttondisableLeft}>
                                <ArrowLeft onClick={() => this.bardata(-3, -3)} style={{cursor: "pointer"}} tabIndex={0}/>
                            </Button>
                            <Button className='rightbutton' style={{backgroundColor: "white", height: 25, minWidth: 25, border: '1px solid #e8e8e8', display: 'flex', justifyContent: "center", alignItems: "center", marginLeft: 5, marginTop: -30}}  disabled={this.state.buttondisableRight}>
                                <ArrowRight onClick={() => this.bardata(3, 3)} style={{cursor: "pointer"}} tabIndex={0} />
                            </Button>
                            <Box style={{backgroundColor: "#e8e8e8", height: 30, width: 30, border: '1px solid #e8e8e8', display: 'flex', justifyContent: "center", alignItems: "center", marginLeft: 10, marginTop: 1, marginRight: -20, borderRadius: "50%"}}>
                                <Link onClick={() => {this.props.history.push("/detailedgraph", {state: this.state.bardataclientwise, title: "Total Projects(Clientwise)", drilldowndata: this.state.totalProjectsClientWise, dataCount: this.state.myDataCount})}} underline='none' style={{color: 'black'}}>
                                    <ZoomOutMap style={{cursor: "pointer"}} />
                                </Link>
                            </Box>
                        </div>
                    </Paper>
                </Grid>

                <Grid item lg={6} sm={12}>
                    <Paper style={{height: 430}} onClick={this.handleBarteam}>
                        <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", height: 60}}>
                            <h4 style={{paddingLeft: 20,}}>No of Errors(Teamwise)</h4>
                        </div>
                        <Divider />
                        <div style={{height: 340}} id={`${this.props.id}`}>
                            <Grid container>
                                <Grid item sm={10} style={{marginTop: 30, marginLeft: 30}}>
                                   <div style={{width: 545}}>
                                    <Bar data={this.state.bardatateamwise} 
                                    options={this.state.bardatateamwise.labels.length > 0 ? this.state.baroptionteamwise : this.state.baroptionteamwiseNodata} 
                                    height={'180px'} style={webStyle.barchartstyle}
                                    ref={(reference: any) => (this.chartReferenceTeamwise = reference)}
                                    />
                                    </div>
                                </Grid>
                                <Grid item sm={1}></Grid>
                            </Grid>
                        </div>
                        <div style={{display: 'flex', justifyContent: 'flex-end', padding: 10, height: 20}}>
                            <Button className="leftbutton" style={{backgroundColor: "white", height: 25, minWidth: 25, border: '1px solid #e8e8e8', display: 'flex', justifyContent: "center", alignItems: "center", marginLeft: 5, marginTop: -30}}  disabled={this.state.buttondisableLeftError}>
                                <ArrowLeft onClick={() => this.bardatateamwise(-3, -3)} style={{cursor: "pointer"}} />
                            </Button>
                            <Button className="rightbutton" style={{backgroundColor: "white", height: 25, minWidth: 25, border: '1px solid #e8e8e8', display: 'flex', justifyContent: "center", alignItems: "center", marginLeft: 5, marginTop: -30}}  disabled={this.state.buttondisableRightError}>
                                <ArrowRight onClick={() => this.bardatateamwise(3, 3)} style={{cursor: "pointer"}} />
                            </Button>
                            <Box style={{backgroundColor: "#e8e8e8", height: 30, width: 30, border: '1px solid #e8e8e8', display: 'flex', justifyContent: "center", alignItems: "center", marginLeft: 10, marginTop: 1, marginRight: -20, borderRadius: "50%"}}>
                                <Link onClick={() => {this.props.history.push("/detailedgraph", {state: this.state.bardatateamwise, title: "No of Errors(Teamwise)", dataCount: this.state.myDatacountBarteamwise})}} underline='none' style={{color: 'black'}}>
                                    <ZoomOutMap style={{cursor: "pointer"}} />
                                </Link>
                            </Box>
                        </div>
                    </Paper>
                </Grid>

                <Grid item lg={6} sm={12}>
                <Paper style={{height: 430}} onClick={this.handleBaroverdue}>
                        <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", height: 60}}>
                            <h4 style={{paddingLeft: 20,}}>Overdue Files(Clientwise)</h4>
                        </div>
                        <Divider />
                        <div style={{height: 340}} id="overdue">
                        <Grid container>
                            <Grid item sm={10} style={{marginTop: 30, marginLeft: 20}}>
                                <Bar data={this.state.bardataoverdue} 
                                options={this.state.bardataoverdue.labels.length > 0 ?this.state.baroptionoverdue : this.state.baroptionoverdueNodata} 
                                height={'180px'} style={webStyle.barchartstyle}
                                ref={(reference: any) => (this.chartReferenceOverdue = reference)}
                                />
                            </Grid>
                            <Grid item sm={1}>
                                <div>
                                    <div style={{display: "flex", flexDirection: "row", rotate: "270deg", width: 126, height: 200, marginLeft: 53, marginRight: 20, marginTop: -16}}>
                                    <p style={{ height: "10px", width: "10px", borderRadius: "50%", backgroundColor: "#FFBF00",margin: '5px 3px 7px 14px',}}></p>
                                    <p style={{height: 50, marginLeft: 10, marginTop: 0, fontSize: 13}}>overdue files for QC</p>
                                    </div>
                                    <div style={{display: "flex", flexDirection: "row", rotate: "270deg", width: 126, height: 50, marginLeft: -12}}>
                                    <p style={{margin: "-6px 6px 7px -13px", height: "10px", width: "10px", borderRadius: "50%", backgroundColor: "#1F51FF",}}></p>
                                    <p style={{marginLeft: 3, marginTop: -11, height: 50, fontSize: 13}}>overdue files for production</p>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                        </div>
                        <div style={{display: 'flex', justifyContent: 'flex-end', padding: 10, height: 20}}>
                            <Button className="leftbutton" style={{backgroundColor: "white", height: 25, minWidth: 25, border: '1px solid #e8e8e8', display: 'flex', justifyContent: "center", alignItems: "center", marginLeft: 5, marginTop: -30}}  disabled={this.state.buttondisableLeftOverdue}>
                                <ArrowLeft onClick={() => this.bardataoverdue(-5, -5)} style={{cursor: "pointer"}} />
                            </Button>
                            <Button className="rightbutton" style={{backgroundColor: "white", height: 25, minWidth: 25, border: '1px solid #e8e8e8', display: 'flex', justifyContent: "center", alignItems: "center", marginLeft: 5, marginTop: -30}}  disabled={this.state.buttondisableRightOverdue}>
                                <ArrowRight onClick={() => this.bardataoverdue(5, 5)} style={{cursor: "pointer"}} />
                            </Button>
                            <Box style={{backgroundColor: "#e8e8e8", height: 30, width: 30, border: '1px solid #e8e8e8', display: 'flex', justifyContent: "center", alignItems: "center", marginLeft: 10, marginTop: 1, marginRight: -20, borderRadius: "50%"}}>
                                <Link onClick={() => {this.props.history.push("/detailedgraph", {state: this.state.bardataoverdue, title: "Overdue Files(Clientwise)", dataCount: this.state.overdueDataArray})}}underline='none' style={{color: 'black'}}>
                                    <ZoomOutMap style={{cursor: "pointer"}} />
                                </Link>
                            </Box>
                        </div>
                    </Paper>
                </Grid>
            </Grid>
            </div>
            }
            </>
            )
        }

        renderErrorModal = () => {
            return (
                <>
                {this.state.tokenError && 
                <Modal className="modalbackdrop"
                style={webStyle.modalbackdropAnalytics}
                open={this.state.isSuccessModal}
                onClose={this.handleClose}
              >
                <div className="modalcontentsuccess"
                style={webStyle.modalcontentsuccessAnalytics}
                >
                  <div className="modalbox"
                  style={webStyle.modalboxAnalytics}
                  >
                  </div>

                  <Typography
                  className="modalcontent"
                  style={webStyle.modalcontentAnalytics}
                  >
                    <b>{this.state.OtherErrorStatement}</b>
                  </Typography>
                  <div style={webStyle.buttonAnalytics}>
                  <Button
                  className="modalbutton"
                  style={(localStorage.getItem('role_id') === "Superadmin") ? (webStyle.loginbtnotherAnalytics) :  (webStyle.modalbuttonAnalytics)}
                    onClick={this.handleClose}
                  >
                    <Link
                          href="/EmailAccountLoginsBlock"
                        style={(localStorage.getItem('role_id') === "Superadmin") ? (webStyle.okbtnsuperAnalytics) :(webStyle.loginbtnAnalytics)}
                        >
                          ok
                        </Link>
                  </Button>
                  </div>
                </div>
              </Modal>
                }
              </>
            )
        }
// Customizable Area End
    render() {  
        // Customizable Area Start
        // Customizable Area End
        return (
            // Customizable Area Start
            <>
            <div style={webStyle.container} className="boxcontainer">
                {this.state.loader ? <Grid item sm={12}><Box style={webStyle.circularprogress}><CircularProgress size={50} /></Box></Grid>
                :
                    <div className="content"style={webStyle.contents}>
                        <Grid container>
                            <Grid item lg={6} sm={6}>
                                {this.state.selectedData ? <div style={{display: "flex", flexDirection: "row"}}><ArrowLeftRounded  style={webStyle.arrow}
                                onClick={this.handleBack}/><h4  style={{color: "#6a6f7a", marginLeft: 20}}><Link onClick={this.handleBack} style={{color: "#6a6f7a", cursor: "pointer"}}>Analytics {'>'} {this.state.workspace_name}</Link> {'>'} {this.state.clientValue}</h4></div>: 
                                <h4>Analytics {'>'} {this.state.workspace_name}</h4>
                                }
                            </Grid>
                            <Grid item lg={6} sm={6}>
                                {!this.state.selectedData && 
                                    <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-end", height: 60}}>
                                        <DateRangePicker
                                            value={this.state.dateRange}
                                            className={"calender"}
                                            onChange = {(a)=>{this.handleDatePicker(a)}}
                                            calendarIcon={
                                            <img src={CalendarIcon} style={{height: '19', paddingBottom: '1'}} />
                                            }
                                            format={"dd/MM/y"}
                                            dayPlaceholder={"DD"} monthPlaceholder={"MM"} yearPlaceholder={"YYYY"}
                                            />
                                        <p style={{display: "flex", justifyContent: 'flex-end', marginRight: 10, textDecoration: "underline", cursor: "pointer", marginTop: 24}} onClick={this.handleRefresh}>Refresh</p>
                                    </div>
                                }
                            </Grid>
                        </Grid>
                        <p>{this.state.errorStatement}</p>
                        {this.state.selectedData ? this.renderSuperadmindrilldown() : this.renderSuperadminData()}
                        {this.renderErrorModal()}
                </div>
            }
            </div>
            </>
        )
    }
}
 // Customizable Area End
 // Customizable Area Start
const webStyle = {

    container: {
        backgroundColor: "#eeeeee",
        height: "100%",
        color: "#5f5f5f",
        fontFamily: "sans-serif",
        width: "85vw",
        top: "50px",
        right:0,
        padding:"30px 20px 10px 0px",
        marginLeft: '15vw',
        boxSizing: "border-box",
        position: "fixed",
        overflow: "scroll"
    }as React.CSSProperties,

    innerGridcontainer: {
        // marginLeft: 0,
    },

    content: {
        margin: 0
    },
    
    sign: {
        display: "flex",
        justifyContent: "flex-start",
        paddingLeft: "20px",
        fontFamily: "sans-serif",
        zIndex: 0,
    },

    
    headnav: {
        marginLeft: 10,
        marginTop: "-55px",
        zIndex: 1300,
    },

    contents: {
        marginLeft: 30
    },

    twocards: {
        display: "flex",
        height: 100,
        width: 350,
        marginRight: 30,
        marginTop: 20,
        padding: 20,
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
    },

    iconimages: {
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
    },

    icons: {
        width: 120,
        height: 120,
    },

    iconname: {
        marginLeft: 28,
        marginRight: 20,
    },

    iconnamecheck: {
        marginLeft: 32,
        marginRight: 20,
    },

    text: {
        backgroundColor: "white", 
        color: "#000000", 
        margin: 10,
        marginRight: 20,
    },

    circularprogress: {
        height: "100%",
        // margin: "50",
        marginTop: -50,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        alignContent: "center",
        color: "blue"
    } as React.CSSProperties,
  
    barchartstyle: {
        maxHeight: "300px",
        maxWidth: "650px"
    },

    arrow: {
        backgroundColor: "white",
        width: 30,
        height: 30,
        marginTop: 12,
        cursor: "pointer"
    }as React.CSSProperties,

    
    modalbackdropAnalytics: {
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: "rgba(0, 0, 0, 0.4)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 2,

    },

    modalcontentsuccessAnalytics: {
        width: "20%",
        height: "35%",
        fontFamily: "sans-serif",
        backgroundColor: "white",
        alignItems: "center",
        justifyContent: "center",
    },

    modalboxAnalytics: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: -62,
    },

    modalcheckAnalytics: {
        width: "200px",
        height: "100px",
        marginBottom: "25px"
    },

    modalcontentAnalytics: {
        fontSize: "16px",
        fontFamily: "sans-serif",
        fontWeight: 500,
        alignItems: "center",
        justifyContent: "center",
        alignContent: "center",
        alignSelf: "center",
        display: "flex"
    },

    buttonAnalytics: {
        alignItems: "center",
        display: "flex",
        justifyContent: "center"

    },

    modalbuttonAnalytics: {
        paddingLeft: "25%",
        paddingRight: "25%",
        paddingTop: "2%",
        paddingBottom: "2%",
        fontWeight: 600,
        borderRadius: "6px",
        width: "140px",
        height: "auto",
        marginTop: "13px",
        display: "flex",
        backgroundColor: "#e9e9e9",
        color: "536c7c",

    },

    loginbtnotherAnalytics: {
        backgroundColor:"rgb(100, 177, 243)",
        paddingLeft: "25%",
        paddingRight: "25%",
        paddingTop: "2%",
        paddingBottom: "2%",
        fontWeight: 600,
        borderRadius: "6px",
        width: "140px",
        height: "auto",
        marginTop: "13px",
        display: "flex",
        color: "536c7c",
    },

    loginbtnAnalytics: {
        color: "black",
        fontWeight: 600,
        fontSize: "14px",
        textDecoration: "none",
    }as React.CSSProperties,

    okbtnsuperAnalytics :{
        color: "white",
        fontWeight: 600,
        fontSize: "14px",
        textDecoraton: "none"
    }as React.CSSProperties,

  

}

// Customizable Area End