import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
export const configJSONBase = require("../../../framework/src/config");
// Customizable Area End
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

toast.configure();

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  that?: any;
  history?: any;

  // Customizable Area End
}

interface S {
  // Customizable Area Start
  editTemplate: boolean;
  editTemplateName: string;
  getTemplatesDetail: any;
  editTemplateDesc: string;
  editTemplateId: string;
  editImg: any;
  sequenceData: any,
  sequenceDataCopy: any,
  editViewImg:any,
  isImageChanged: boolean,
  editTemplateKey: string,
  loading: boolean,
  error: string
  // Customizable Area End
}
interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class EditTemplateDesignController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  ApiGetTemplateId: string = "";
  ApiUpdateTemplateId: string = "";
  ApiUpdateTemplateImgId: string = "";
  query: any = new URLSearchParams(this.props.history?.location?.search);
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.getTemplates = this.getTemplates.bind(this);
    this.updateTemplate = this.updateTemplate.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area Start
      // Customizable Area End
    ];
    this.state = {
      // Customizable Area Start
      editTemplate: false,
      editTemplateName: "",
      getTemplatesDetail: [],
      editTemplateDesc: "",
      editTemplateId: "",
      editImg: {},
      sequenceData: [],
      sequenceDataCopy: [],
      editViewImg: "",
      isImageChanged: false,
      editTemplateKey: '',
      loading: true,
      error: ""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }
  async componentDidMount() {
    this.getTemplates();
  }
  // Customizable Area Start

  handleClose = () => {
    this.setState({ editTemplate: false, editImg: {}, editViewImg: "", isImageChanged: false })
  }
  // Customizable Area End
  getTemplates = ()=>{
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.ApiGetTemplateId = requestMessage.messageId;
    const workspaceId = localStorage.getItem('user_type') == 'Admin' ? `?workspace_id=${localStorage.getItem('workspace_id')}` : "";
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_dashboard/templates${workspaceId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  updateTemplate = () =>{
    this.setState({editTemplate:false})
      let formData = new FormData();
      formData.append('title',this.state.editTemplateName);
      formData.append('description',this.state.editTemplateDesc);
    if(this.state?.editViewImg?.length > 0 && this.state.isImageChanged)  formData.append('image', this.state.editImg);

    const header = {
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
        this.ApiUpdateTemplateId = requestMessage.messageId;
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `bx_block_dashboard/templates/${this.state.editTemplateId}`
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );

        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),formData
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),"PUT"
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);

        return true;
  }

  handleGetTemplatesSuccess = (responseJson: any) => {
    this.setState({ getTemplatesDetail: responseJson.data, loading: false });
  }

  handleGetTemplatesFail = (responseJson: any) => {
    this.setState({ loading: false });
    if (responseJson?.errors?.length) {
      this.setState({ error: responseJson?.errors?.join(". ") });
    }
    else if (responseJson?.error) {
      this.setState({ error: responseJson?.error });
    }
  }

  handleUpdateTemplatesSuccess = () => {
    this.getTemplates();
    this.handleClose();
    toast.success('Template Edited Successfully', { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 2000 });
  }
  async receive(from: string, message: Message) {
        // Customizable Area Start
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson?.errors && !responseJson?.error) {
        if (this.ApiGetTemplateId === apiRequestCallId) {
          this.handleGetTemplatesSuccess(responseJson);
        } else if (this.ApiUpdateTemplateId === apiRequestCallId) {
          this.handleUpdateTemplatesSuccess();
        }
      } else {
        if (this.ApiGetTemplateId === apiRequestCallId) {
          this.handleGetTemplatesFail(responseJson);
        }
      }
    }
        // Customizable Area End
  }
}
