import React from 'react';
// Customizable Area Start
import {Paper, Divider, Grid, Box, Link, CircularProgress, Button} from '@material-ui/core';
import { Chart as ChartJS, ArcElement, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, } from 'chart.js';
import { Doughnut, Bar,} from 'react-chartjs-2';
ChartJS.register(ArcElement, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend,);
import {ZoomOutMap, ArrowLeft, ArrowRight, ArrowBackIos, Loop} from '@material-ui/icons';

import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import { CalendarIcon } from "./assets";
import './Graphs.css';


import DesignerAnalyticsController, { Props} from "./DesignerAnalyticsController";
// Customizable Area End

export default class DesignerAnalyticsGraph extends DesignerAnalyticsController {
// Customizable Area Start
    constructor(props: Props) {
        super(props);
        this.handleDateChange = this.handleDateChange.bind(this)
        this.chartReference = React.createRef()
        this.chartReferenceOverdue = React.createRef()
    }

    componentDidMount(): any {
        let token = localStorage.getItem("token")
        this.handleDateChange = this.handleDateChange.bind(this)
        if(token) {
            this.setState({selectedData: false})
            this.analyticsGraphDetails()
    }
    }

    handleBarclient = () => {
        document.addEventListener("keydown", this.handleArrowKeys)
        document.removeEventListener("keydown", this.handleArrowKeysOverdue)
    }

    handleBaroverdue = () => {
        document.addEventListener("keydown", this.handleArrowKeysOverdue)
        document.removeEventListener("keydown", this.handleArrowKeys)
    }
    handleArrowKeys = (event: any) => {
        const numbars = this.state.bardataclientwise.labels.length
        if(event.keyCode === 37 && this.state.activeBarIndex > 0) {
            this.setState({activeBarIndex: this.state.activeBarIndex - 1})
            this.bardata(-3, -3)
        }
        if(event.keyCode === 39 && this.state.activeBarIndex < numbars - 1) {
            this.setState({activeBarIndex: this.state.activeBarIndex + 1})
            this.bardata(3, 3)
        }
    }

    handleArrowKeysOverdue = (event: any) => {
        const numbars = this.state.TLChartRolewise.labels.length
        if(event.keyCode === 37 && this.state.activeBarIndex > 0) {
            this.setState({activeBarIndex: this.state.activeBarIndex - 1})
            this.bardataoverdue(-3, -3)
        }
        if(event.keyCode === 39 && this.state.activeBarIndex < numbars - 1) {
            this.setState({activeBarIndex: this.state.activeBarIndex + 1})
            this.bardataoverdue(3, 3)
        }
    }
   

    handleDateChange(date: any) {
        this.setState({ selecteddate: date });
      }

      handleBack = () => {
        this.setState({selectedData: false})

    }

    handleRefresh = () => {
        this.setState({loader: true})
        this.setState({from: null})
        this.setState({to: null})
        this.handleDatePicker(null)
        this.analyticsGraphDetails()
    }


    // renderHeaderValues = () => {
    //     return (
    //         <>
    //             {this.state.selectedData ?
    //                 <Box style={webStyle.sign} className="headnavbar">
    //                     <Button variant="contained" style={webStyle.backbutton} onClick={this.handleBack}>
    //                         <ArrowBackIos fontSize="small" style={webStyle.arrowback} />
    //                         <span style={webStyle.back} className="buttontext">Back</span>
    //                     </Button>
    //                     <h3 style={webStyle.headnav}>
    //                         {this.state.clientValue}
    //                     </h3>
    //                 </Box>
    //             :
    //                 <Box style={webStyle.sign} className="headnavbar">
    //                     <h3 style={webStyle.headnavAnalytics}>
    //                         Analytics
    //                     </h3>
    //                 </Box>
    //             }
    //         </>
    //     )
    // }
    renderHeaderValues = () => {
        return (
            <>
                {this.state.selectedData ?
                    <div style={webStyle.divnav}>  
                     <Box style={webStyle.sign} className="headnavbar">
                        <Button variant="contained" style={webStyle.backbutton} onClick={this.handleBack}>
                            <ArrowBackIos fontSize="small" style={webStyle.arrowback} />
                            <span style={webStyle.back} className="buttontext">Back</span>
                        </Button>
                        <h3 style={webStyle.headnav}>
                            {this.state.clientValue}
                        </h3>
                        
                     </Box>
                    </div>
                :
                    <div style={webStyle.divnav}>
                        <h3 style={webStyle.headnavAna}  className="headnavbaranalytics">
                            Analytics
                        </h3>
                    </div>
                }
            </>
        )
    }
    renderDrillDownDesigner = () => {
        return (
            <div> 
            <Paper style={{minHeight: 400, marginLeft: 20, flexGrow: 1, display: "flex", flexDirection: "column", textAlign: "center", maxHeight: "auto",}}>
                <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", height: 60}}>
                    <h4 style={{paddingLeft: 20,}}>Total Projects (Typewise)</h4>
                </div>
                <Divider />
                    <Grid container>
                        <Grid item lg={4} sm={6} style={{height: 300, marginLeft: -10}}>
                            <Doughnut 
                            options={this.state.optionspie} 
                            data = {this.state.analyticsData}
                            width={'100px'} height={'100px'}
                            />
                        </Grid>
                        <Grid item lg={8} sm={6}>
                            <div style={{maxWidth: 850, minHeight: 230, maxHeight: "auto", marginTop: 40, marginLeft: -40, border: '2px solid #e8e8e8', backgroundColor: "#f9f9f9", 
                            display: 'flex', flexDirection: 'row', flexGrow: 1}}>
                                <div style={{display: "flex", flexDirection: "column", width: '250px', justifyContent: "center", 
                                alignItems: "center", borderRight: '2px solid #e8e8e8', marginTop: 20, marginBottom: 20}}>
                                    <h4 style={{fontSize: 24, marginLeft: -50, marginTop: -10,}}>{this.state.drilldowntotalNoOfProjectsTypewise}</h4>
                                    <div>
                                        <p style={{marginTop: -25, color: "#9fa0a2"}}>Total projects</p>
                                    </div>
                                </div> 
                                <Grid container>
                                        <div style={{marginTop: 40, marginLeft: 40}}>
                                            <Grid container>
                                                {this.state.totalProjectsTypewise.map((item: any) => {
                                                    if(item.count > 0) {
                                                        return (
                                                            <Grid item lg={6} sm={12}>
                                                                <div style={{display: "flex",}}>
                                                                    <Grid container spacing={1} style={{marginRight: 20}}>
                                                                        <Grid item sm={1}>
                                                                            <Box><p style={{display: "inline-block", marginTop: 14, marginBottom: 10,  height: "10px", width: "10px", borderRadius: "50%", backgroundColor:`${item.color_code}`,marginLeft: -10,}}></p></Box>
                                                                        </Grid>
                                                                        <Grid item sm={5}>
                                                                            <Box style={{ marginTop: 10, marginBottom: 10, marginLeft: 4, }}><p style={{color: "#9fa0a2", marginTop: 10, marginBottom: 10, marginLeft: 4, textAlign: "start"}}>{item.type}</p></Box>
                                                                        </Grid>
                                                                        <Grid item sm={5}>
                                                                            <Box style={{marginLeft: 50,marginTop: 11, marginBottom: 10,}}><p style={{marginLeft: 20, color: "#000000", fontWeight: 600, marginTop: 10, marginBottom: 10,textAlign: "start"}}>{item.count}</p></Box>
                                                                        </Grid>
                                                                        <Grid item sm={1}></Grid>                        
                                                                    </Grid>
                                                                </div> 
                                                            </Grid>
                                                        )
                                                    }
                                                })}
                                            </Grid>
                                        </div>
                                </Grid>
                            </div>
                        </Grid>
                    </Grid>
            </Paper>
            <br/>
            <br/>
            <Paper style={{minHeight: 400, marginLeft: 20, flexGrow: 1, display: "flex", flexDirection: "column", textAlign: "center", maxHeight: "auto",}}>
                <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", height: 60}}>
                    <h4 style={{paddingLeft: 20,}}>Total Projects (Statuswise)</h4>
                </div>
                <Divider />
                <Grid container>
                    <Grid item lg={4} sm={6} style={{height: 300, marginLeft: -10}}>
                        <Doughnut 
                        options={this.state.optionspie} 
                        data = {this.state.analyticsDatastatuswise}
                        width={'100px'} height={'100px'}
                        />
                    </Grid>
                    <Grid item lg={8} sm={6}>
                            <Grid container>
                                    <div style={{maxWidth: 850, minHeight: 255, maxHeight: "auto", marginTop: 40, marginLeft: -40, border: '2px solid #e8e8e8', backgroundColor: "#f9f9f9",
                                    display: 'flex', flexDirection: 'row', flexGrow: 1}}>
                                        <Grid container>
                                            {this.state.totalProjectsStatuswiseanalytics.map((item: any) => {
                                                if(item.count > 0) {
                                                return (
                                                    <Grid item lg={3} md={6} sm={12}>
                                                        <div style={{display: "flex"}}>
                                                            <Grid container>
                                                                <Grid item sm={1}></Grid>
                                                                <Grid item sm={1}>
                                                                    <p style={{display: "inline-block", marginTop: 14, marginBottom: 10,  height: "10px", width: "10px", borderRadius: "50%", backgroundColor:`${item.color_code}`,marginLeft: -10,}}></p>
                                                                </Grid>
                                                                <Grid item sm={5}>
                                                                    <p style={{color: "#9fa0a2", marginTop: 10, marginBottom: 10, marginLeft: 4,textAlign: "start"}}>{item.status}</p>
                                                                </Grid>
                                                                <Grid item sm={5}>
                                                                    <p style={{marginLeft: 15, color: "#000000", fontWeight: 600, marginTop: 10, marginBottom: 10,textAlign: "start"}}>{item.count}</p>
                                                                </Grid>
                                                                <Grid item sm={1}></Grid>
                                                            </Grid>
                                                        </div>
                                                    </Grid>
                                                )
                                                }
                                            })}
                                        </Grid>
                                    </div>
                            </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </div>
        )
    }

    renderDesignerBarGraph = () => {
        if(!this.state.chartError){
        return (
            <>
            {!this.state.tokenError && 
            <Grid container spacing={3}>
            <Grid item lg={6} sm={12}>
                <Paper style={{height: 430, margin: 5, marginBottom: 40,  border: '1px solid #e8e8e8', flexGrow: 1, display: "flex", flexDirection: "column", textAlign: "center",}} onClick={this.handleBaroverdue}>
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", height: 60}}>
                        <Grid container>
                            <Grid item sm={6}>
                                <div style={{display: "flex", flexDirection: "row",}}>
                                    <h4 style={{paddingLeft: 20}}>Clients' Projects (Rolewise)</h4>
                                </div>
                            </Grid>
                            <Grid item sm={6}>
                                <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-end", alignContent: "flex-end", marginTop: -4}}>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                    <Divider />
                    <div style={{height: 340}}>
                    <Grid container>
                        <Grid item sm={10} style={{marginTop: 30, marginLeft: 20}}>
                            <Bar data={this.state.TLChartRolewise} 
                            options={this.state.TLChartRolewise.labels.length > 0 ? this.state.TLOptionRolewise : this.state.TLOptionRolewiseNodata} 
                            height={'180px'} style={webStyle.barchartstyle}
                            ref={(reference: any) => (this.chartReferenceOverdue = reference)}
                            />
                        </Grid>
                        <Grid item sm={1}>
                            <div>
                                <div style={{display: "flex", flexDirection: "row", rotate: "270deg", width: 75, height: 154, marginLeft: 68, marginRight: 20, marginTop: -16}}>
                                    <p style={{ height: "10px", width: "10px", borderRadius: "50%", backgroundColor: "#660066",margin: '3px 3px 7px 14px',}}></p>
                                    <p style={{height: 50, marginLeft: 10, marginTop: 0, fontSize: 13}}>QA</p>
                                </div>
                                <div style={{display: "flex", flexDirection: "row", rotate: "270deg", width: 75, height: 62, marginLeft: 23, marginRight: 20, marginTop: -16}}>
                                    <p style={{ height: "10px", width: "10px", borderRadius: "50%", backgroundColor: "#FFBF00",margin: '3px 3px 7px 14px',}}></p>
                                    <p style={{height: 50, marginLeft: 10, marginTop: 0, fontSize: 13}}>QC</p>
                                </div>
                                <div style={{display: "flex", flexDirection: "row", rotate: "270deg", width: 126, height: 50, marginLeft: 1}}>
                                    <p style={{margin: "-8px 6px 7px -13px", height: "10px", width: "10px", borderRadius: "50%", backgroundColor: "#1F51FF",}}></p>
                                    <p style={{marginLeft: 3, marginTop: -11, height: 50, fontSize: 13}}>Designer</p>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                    </div>
                    <div style={{display: 'flex', justifyContent: 'flex-end', padding: 10, height: 10}}>
                        <Button className='leftbutton' style={{backgroundColor: "white", height: 25, minWidth: 25, border: '1px solid #e8e8e8', display: 'flex', justifyContent: "center", alignItems: "center", marginLeft: 5, marginTop: -30}} disabled={this.state.buttondisableLeftoverdue}>
                            <ArrowLeft onClick={() => this.bardataoverdue(-3, -3)} style={{cursor: "pointer"}} />
                        </Button>
                        <Button className='rightbutton' style={{backgroundColor: "white", height: 25, minWidth: 25, border: '1px solid #e8e8e8', display: 'flex', justifyContent: "center", alignItems: "center", marginLeft: 5, marginTop: -30}} disabled={this.state.buttondisableRightOverdue}>
                            <ArrowRight onClick={() => this.bardataoverdue(3, 3)} style={{cursor: "pointer"}} />
                        </Button>
                        <Box style={{backgroundColor: "#e8e8e8", height: 30, width: 30, border: '1px solid #e8e8e8', display: 'flex', justifyContent: "center", alignItems: "center", marginLeft: 10, marginTop: 1, marginRight: -20, borderRadius: "50%"}}>
                            <Link onClick={() => {this.props.history.push("/detailedgraph", {state: this.state.TLChartRolewise, title: "Clients' Projects (Rolewise)", drilldowndata: this.state.totalProjectsClientWise, dataCount: this.state.overdueDataArray})}}  underline='none' style={{color: 'black'}}>
                                <ZoomOutMap style={{cursor: "pointer"}} />
                            </Link>
                        </Box>
                    </div>
                </Paper>
            </Grid>

            <Grid item lg={6} sm={12}>
                <Paper  style={{height: 430, margin: 5, marginBottom: 40,  border: '1px solid #e8e8e8',  flexGrow: 1, display: "flex", flexDirection: "column", textAlign: "center",}} onClick={this.handleBarclient}>
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", height: 60}}>
                        <h4 style={{paddingLeft: 20,}}>No. of Errors(Clientwise)</h4>
                    </div>
                    <Divider />
                    <div style={{height: 340}}>
                    <Grid container>
                        <Grid item sm={10} style={{marginTop: 30, marginLeft: 20}}>
                            <div style={{width: 545}}>
                            <Bar data={this.state.bardataclientwise} 
                            options={this.state.bardataclientwise.labels.length > 0 ? this.state.baroptionclientwise : this.state.baroptionclientwiseNodata}
                            height={'180px'} style={webStyle.barchartstyle}
                            ref={(reference: any) => (this.chartReference = reference)}
                            />
                            </div>
                        </Grid>
                        <Grid item sm={1}></Grid>
                    </Grid>
                    </div>
                    <div style={{display: 'flex', justifyContent: 'flex-end', padding: 10,height: 10}}>
                        <Button className='leftbutton' style={{backgroundColor: "white", height: 25, minWidth: 25, border: '1px solid #e8e8e8', display: 'flex', justifyContent: "center", alignItems: "center", marginLeft: 5, marginTop: -30}} disabled={this.state.buttondisableLeftError}>
                            <ArrowLeft onClick={() => this.bardata(-3, -3)} style={{cursor: "pointer"}} />
                        </Button>
                        <Button className='rightbutton' style={{backgroundColor: "white", height: 25, minWidth: 25, border: '1px solid #e8e8e8', display: 'flex', justifyContent: "center", alignItems: "center", marginLeft: 5, marginTop: -30}} disabled={this.state.buttondisableRightError}>
                            <ArrowRight onClick={() => this.bardata(3, 3)} style={{cursor: "pointer"}} />
                        </Button>
                        <Box style={{backgroundColor: "#e8e8e8", height: 30, width: 30, border: '1px solid #e8e8e8', display: 'flex', justifyContent: "center", alignItems: "center", marginLeft: 10, marginTop: 1, marginRight: -20, borderRadius: "50%"}}>
                            <Link onClick={() => {this.props.history.push("/detailedgraph", {state: this.state.bardataclientwise, title: "Total Projects(Clientwise)", dataCount: this.state.myDataCount})}} underline='none' style={{color: 'black'}}>
                                <ZoomOutMap style={{cursor: "pointer"}} />
                            </Link>
                        </Box>
                    </div>
                </Paper>
            </Grid>

        </Grid>
        }
        </>
        )
    }
    }

    handleClose = () => {
        this.setState({isSuccessModal: false})
        this.setState({tokenError: false})
        this.props.history.push("/EmailAccountLoginsBlock")
    }

// Customizable Area End
    render() {
        // Customizable Area Start
        // Customizable Area End
        return (
            // Customizable Area Start
            <>
            <div style={webStyle.container} className="boxcontainer">
            {this.renderHeaderValues()}
                {this.state.loader ? <Grid item sm={12}><Box style={webStyle.circularprogress}><CircularProgress size={50} /></Box></Grid>
                  :
                  <div>
                    {this.state.chartError ?  
                        <div style={webStyle.errormessageContent}><p>{this.state.errorStatement}</p></div> 
                    :
                  <div  className="content"style={webStyle.contents}>
                    <Grid container>
                        <Grid item lg={6} sm={6}></Grid>
                        <Grid item lg={6} sm={6}>
                            {!this.state.selectedData && 
                            <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-end", height: 60}}>
                                <DateRangePicker
                                        value={this.state.dateRange}
                                        className={"calender"}
                                        onChange = {(a)=>{this.handleDatePicker(a)}}
                                        calendarIcon={
                                        <img src={CalendarIcon} style={{height: '19', paddingBottom: '1'}} />
                                        }
                                        format={"dd/MM/y"}
                                        dayPlaceholder={"DD"} monthPlaceholder={"MM"} yearPlaceholder={"YYYY"}
                                        />
                                <p style={{display: "flex", justifyContent: 'flex-end', marginRight: 10, textDecoration: "underline", marginTop: 24, cursor: "pointer"}} onClick={this.handleRefresh}><Loop style={{marginTop: -4}} /> Refresh</p>
                            </div>
                            }
                        </Grid>
                    </Grid>
                {this.state.selectedData ?  this.renderDrillDownDesigner() : this.renderDesignerBarGraph()}
                </div>
                }
                </div>
             } 
            </div>
            </>
        )
    }
}
 // Customizable Area End
 // Customizable Area Start
const webStyle = {

    container: {
        backgroundColor: "#eeeeee",
        height: "100%",
        color: "#5f5f5f",
        fontFamily: "sans-serif",
        width: "85vw",
        top: "50px",
        right:0,
        padding:"30px 20px 10px 0px",
        marginLeft: '15vw',
        boxSizing: "border-box",
        // position: "fixed",
        overflow: "scroll"
    }as React.CSSProperties,

    innerGridcontainer: {
        // marginLeft: 0,
    },

    content: {
        margin: 0
    },
    
    sign: {
        display: "flex",
        justifyContent: "flex-start",
        paddingLeft: "20px",
        fontFamily: "sans-serif",
        zIndex: 1300,
        marginTop: -80,
        marginLeft: -220
    },

    
    headnav: {
        marginLeft: 10,
        marginTop: "22px",
        color: "#6a6f7a",
        fontWeight: "normal",
        fontFamily: "sans-serif",
        zIndex: 1300,
    }as React.CSSProperties,

    headnavAnalytics: {
        marginLeft: 240,
        marginTop: "22px",
        color: "#6a6f7a",
        fontWeight: "bold",
        fontFamily: "sans-serif",
        zIndex: 1300,
    }as React.CSSProperties,


    contents: {
        marginLeft: 20
    },

    twocards: {
        display: "flex",
        height: 100,
        width: 350,
        marginRight: 30,
        marginTop: 20,
        padding: 20,
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
    },

    iconimages: {
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
    },

    icons: {
        width: 120,
        height: 120,
    },

    iconname: {
        marginLeft: 28,
        marginRight: 20,
    },

    iconnamecheck: {
        marginLeft: 32,
        marginRight: 20,
    },

    text: {
        backgroundColor: "white", 
        color: "#000000", 
        margin: 10,
        marginRight: 20,
    },

    circularprogress: {
        height: "100%",
        // margin: "100",
        marginTop: -50,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        alignContent: "center",
        // color: "#6e6e6e"
    } as React.CSSProperties,

    backbutton: {
        paddingLeft: "1%",
        paddingRight: "1%",
        backgroundColor: "#efefef",
        color: "#9a9ea1",
        width: "100px",
        height: '32px',
        marginTop: "15",
        marginLeft: "235"
        // zIndex: 1300,

    },

    arrowback: {
        color: 'gray',
        fontSize: "14px",
    },

    back: {
        marginLeft: 8,
        fontSize: "14px",
        textTransform: "none",
    }as React.CSSProperties,

    headnavTop: {
        marginLeft: 10,
        marginTop: "-55px",
        zIndex: 1300,
    },

    barchartstyle: {
        maxHeight: "300px",
        maxWidth: "650px"
    },

    errormessageContent: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        fontSize: 16,
        fontWeight: 600,
        color: "#6a6f7a",
        fontFamily: "sans-serif",
        margin: 300
    }as React.CSSProperties,

    
    divnav: {
        display: "flex",
        justifyContent: "flex-start",
    },

    headnavAna: {
        marginLeft: 35,
        marginTop: -55,
        zIndex: 1300
    },


  


}

// Customizable Area End