import React from 'react';
// Customizable Area Start
import {
    Box,
    Grid,
    Button,
    Link,
    InputLabel,
    TextField,
    Avatar,
    Modal,
    Typography
} from "@material-ui/core";

import MomentUtils from "@date-io/moment";
import PhoneInput from "react-phone-input-2";
import { modalCheck } from "./assets";
import { tickmark } from './assets';
import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import moment from "moment";
import {
    MuiPickersUtilsProvider,
    TimePicker,
    DatePicker,
  } from "material-ui-pickers";
import "./style.css";

const materialTheme = createMuiTheme({
    overrides: {
    //   @ts-ignore
      MuiPickersToolbar: {
        toolbar: {
            color: "black",
            backgroundColor: "#e8e8e8"
        },
      },
  
      MuiPickersDay: {
        day: {
        color: "black"
        },
        daySelected: {
            backgroundColor: "#e8e8e8"
        },
        dayDisabled: {
            color: "#e8e8e8"
        },
        current: {
            color: "#e8e8e8"
        },
        isSelected: {
          color: "white",
          backgroundColor: "#e8e8e8"
        },
      },

     MuiPickersToolbarButton: {
        toolbarBtn: {
            color: "black",
        },
        toolbarBtnSelected: {
            color: "black"
        },
     },

      palette: {
        primary: "red",
      },
      MuiButton: {
        textPrimary: {
            color: "black"
        },
      },
      MuiIconButton: {
        root: {
          padding: "12px 12px 12px 12px",
        },
      },
  
      MuiPickersModal: {
        dialogAction: {
            color: "#e8e8e8"
        },
      },
      myComponent: {
        "& .MuiPickersDay-isSelected": {
          backgroundColor: "red",
        },
      },
  
      MuiPickersCalendarHeader: {
        switchHeader: {
          backgroundColor: "white",
          color: "black"
        },
        dayLabel: {
            color: "black"
        },
      },
    },
  });

import UserProfileBasicController, { Props} from "./UserProfileBasicController";
// Customizable Area End

// Customizable Area Start
export default class UserProfileEditBlock extends UserProfileBasicController {

    constructor(props: Props) {
        super(props);
        this.handleDateChange = this.handleDateChange.bind(this);
    }
    componentDidMount(): any {
        let token = localStorage.getItem("token")
        if(token) {
            this.getUser()
        }
    }

    handleCancel = () => {
        this.props.history.push("/userprofile")
    }

    handleImage = (e: any) => {
        this.setState({pimage: URL.createObjectURL(e.target.files[0])})
        this.setState({images: e.target.files[0]})
        this.setState({imagechanged: true})
    }

    handleDateChange(date: any) {
        this.setState({ btdate: date });
      }

      handleChangePasswordPage = () => {
        this.props.history.push("/changepassword", {state: "profileupdate"})
      }

      handleRoleSuperAdmin = (style1:any,style2:any) =>{
        return (localStorage.getItem('role_id') === "Superadmin") ? (style1) :(style2)
      }
    

    render() {

        return (
            <>
            <div style={webStyle.container} className="boxcontainer">

                <div style={webStyle.divnav}>
                    {(localStorage.getItem("role_id") != "Superadmin") && 
                    (
                        <h3 style={webStyle.headnav} className="headnavbar">My Profile</h3>  
                    )}
                </div>
                
                <div className="content1" style={webStyle.contentBody}>

                    <Grid container spacing={2}>

                        <Grid item sm={6} style={webStyle.griditem}>
                        <div style={{width: 196}}>
                        <label className="input-label" htmlFor="input-label" style={webStyle.label}>
                            {this.state.imagep === null ? <Avatar style={webStyle.image} /> :
                            <Avatar style={webStyle.image} src={this.state.pimage}>
                            {this.state.imagep ?
                                (<img 
                                    src={this.state.imagep}
                                    style={webStyle.images}
                                />) : 
                                (<img src={this.state.pimage} style={webStyle.images}/>)
                                
                             } 
                            </Avatar>
                            }
                            <input
                                id="input-label"
                                type="file"
                                accept="image/*"
                                hidden
                                onChange={this.handleImage}
                            />
                                    <br/>
                                    <span style={(localStorage.getItem('role_id') === "Superadmin") ?(webStyle.profilepic) : (webStyle.profilepicother)}>Update Profile picture</span>
                        </label>
                        </div>
                        </Grid>

                        <Grid item sm={6} style={webStyle.buttoncontainer}>
                            <Button className={'cancelBtnHandle'} style={webStyle.cancelbutton}  onClick={this.handleCancel}>
                                <span className="buttontext">Cancel</span>
                            </Button>
                            
                            <Button className={'saveAndUpdate'} style={(localStorage.getItem('role_id') === "Superadmin") ?(webStyle.savebutton) : (webStyle.savebuttonother)} onClick={this.handleUpdate}>
                            <span className="buttontext">Save</span>
                            </Button>
                        </Grid>

                    </Grid>

                </div>

                <div className="content"style={webStyle.body}>

                    <Grid container spacing={2}>
                        <Grid item sm={4}>

                            <Box style={webStyle.box}>
                                <InputLabel style={webStyle.input}>First Name</InputLabel>
                                <TextField variant="outlined" style={(localStorage.getItem('role_id') === "Superadmin") ?(webStyle.text) : (webStyle.textfilled)} fullWidth
                                type="text"  size="small" required value={this.state.ftname}
                                placeholder={this.state.ftname} disabled />   
                            </Box>

                        </Grid>

                        <Grid item sm={1}></Grid>

                        <Grid item sm={5}>

                            <Box style={webStyle.box}>                               
                                <InputLabel style={webStyle.input}>Last Name</InputLabel>
                                <TextField variant="outlined" style={this.handleRoleSuperAdmin(webStyle.text,webStyle.textfilled)} fullWidth
                                type="text" placeholder={this.state.ltname} size="small" required value={this.state.ltname} disabled />
                            </Box>

                        </Grid>

                        <Grid item sm={2}></Grid>

                    </Grid>
                    
                    <Grid container spacing={2}>

                        <Grid item sm={4}>

                            <Box style={webStyle.box}>
                                <InputLabel style={webStyle.input}>BirthDate</InputLabel>
                                <MuiPickersUtilsProvider
                        utils={MomentUtils}
                      >
                        <ThemeProvider theme={materialTheme}>
                          <DatePicker
                            keyboard
                            variant="outlined"
                            size="small"
                            fullWidth
                            style={webStyle.text}
                            maxDate={new Date().setDate(
                              new Date().getDate() - 1
                            )}
                            placeholder="DD/MM/YYYY"
                            format={"DD/MM/YYYY"}
                            mask={(value) =>
                              value
                                ? [
                                    /\d/,
                                    /\d/,
                                    "/",
                                    /\d/,
                                    /\d/,
                                    "/",
                                    /\d/,
                                    /\d/,
                                    /\d/,
                                    /\d/,
                                  ]
                                : []
                            }
                            value={this.state.btdate}
                            onChange={this.handleDateChange}
                            disableOpenOnEnter
                            animateYearScrolling={false}
                            autoOk={true}
                            clearable
                          />
                        </ThemeProvider>
                      </MuiPickersUtilsProvider>
                            </Box>

                        </Grid>

                        <Grid item sm={1}></Grid>

                        <Grid item sm={5}>

                            <Box style={webStyle.box}>
                                <InputLabel style={webStyle.input}>Contact Number</InputLabel>
                                <div style={webStyle.textbox}>
                                <PhoneInput
                                    country={"in"}
                                    enableSearch={true}
                                    disableSearchIcon
                                    inputStyle={webStyle.phoneinput}
                                    value={this.state.cellno}
                                    onChange={(phone) =>
                                        this.setState({ cellno: phone })
                                      }
                                />
                                </div>
                            </Box>

                        </Grid>

                        <Grid item sm={2}></Grid>

                    </Grid>

                    <Grid container spacing={2}>

                        <Grid item sm={4}>

                            <Box style={webStyle.box}>
                                <InputLabel style={webStyle.input}>Designation</InputLabel>
                                <TextField variant="outlined" style={this.handleRoleSuperAdmin(webStyle.text,webStyle.textfilled)} fullWidth
                                type="text" placeholder={this.state.design} size="small" required value={(this.state.design === "not found") ? "N/A" :(this.state.design)} disabled /> 
                            </Box>

                        </Grid>

                        <Grid item sm={1}></Grid>

                        <Grid item sm={5}>

                            <Box style={webStyle.box}>
                                <InputLabel style={webStyle.input}>Role</InputLabel>
                                <TextField variant="outlined" style={this.handleRoleSuperAdmin(webStyle.text,webStyle.textfilled)} fullWidth value={this.state.roleid}
                                type="text" placeholder={this.state.roleid} size="small" required disabled /> 
                            </Box>

                        </Grid>

                        <Grid item sm={2}></Grid>
                    </Grid>


                    <Grid container spacing={2}>

                        <Grid item sm={4}>

                            <Box style={webStyle.box}>
                                <InputLabel style={webStyle.input}>Official Email id</InputLabel>
                                <TextField variant="outlined" style={this.handleRoleSuperAdmin(webStyle.text,webStyle.textfilled)} fullWidth
                                type="email" placeholder={this.state.oemail} size="small" required value={this.state.oemail} disabled /> 
                            </Box>

                        </Grid>

                        <Grid item sm={1}></Grid>

                        <Grid item sm={5}>

                            <Box style={webStyle.box}>
                                <InputLabel style={webStyle.input}>Workspace</InputLabel>
                                <TextField variant="outlined" style={this.handleRoleSuperAdmin(webStyle.text,webStyle.textfilled)} fullWidth
                                type="text" placeholder={this.state.workspace?.name} size="small" required disabled value={(this.state.workspace === "not found") ? "N/A" : (this.state.workspace?.name)} /> 
                            </Box>

                        </Grid>

                        <Grid item sm={2}></Grid>
                    </Grid>

                    <Grid container spacing={2}>

                        <Grid item sm={4}>

                        <Box style={webStyle.box}>
                                <InputLabel style={webStyle.input}>Joining Date</InputLabel>
                                <TextField variant="outlined" style={this.handleRoleSuperAdmin(webStyle.text,webStyle.textfilled)} fullWidth
                                type="text" placeholder={moment(this.state.jtdate).format("DD/MM/YYYY")} size="small" value={(this.state.jtdate === null || this.state.jtdate === "") ? "N/A" : (moment(this.state.jtdate).format("DD/MM/YYYY"))} required disabled /> 
                            </Box>

                        </Grid>

                        <Grid item sm={1}></Grid>

                        <Grid item sm={5}>
                        <Box style={webStyle.box}>
                                <h4 style={webStyle.heading}>Password</h4>
                                {/* <InputLabel>
                                <Link style={(localStorage.getItem('role_id') === "Superadmin") ?(webStyle.changepasswordlink) : (webStyle.changepasswordlinkother)}
                                href="changepassword"
                                onClick={this.handleChangePasswordPage}>
                                    Change Password</Link>
                                </InputLabel> */}
                                    <Button style={this.handleRoleSuperAdmin(webStyle.changepasswordlink,webStyle.changepasswordlinkother)}
                                // href="changepassword"
                                onClick={this.handleChangePasswordPage}>
                                    Change Password</Button>
                            </Box>
                        </Grid>

                        <Grid item sm={2}></Grid>

                    </Grid>

                    <Grid container spacing={2}>
                        <Grid item sm={10}>

                            <Box style={webStyle.box}>
                                <InputLabel style={webStyle.input}>Description</InputLabel>
                                <TextField variant="outlined" style={webStyle.text} fullWidth
                                type="text" placeholder={this.state.descrp} 
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    this.setState({ descrp: e.target.value });
                                  }}
                                size="small" required multiline rows={5} value={this.state.descrp} /> 
                            </Box>

                        </Grid>
                
                    </Grid>

                    

                        {this.state.modalOpen && (
                  <Modal className="modalbackdrop"
                  style={webStyle.modalbackdrop}
                  open={this.state.isSuccessModal}
                  onClose={this.handleClose}
                >
                  <div className="modalcontentsuccess"
                  style={webStyle.modalcontentsuccess}
                  >
                    <div className="modalbox"
                    style={webStyle.modalbox}
                    >
                      {(localStorage.getItem('role_id') === "Superadmin") ?
                      (<img
                        src={tickmark}
                        className="modalcheck"
                        style={webStyle.modaltickcheck}
                      />) : (<img
                        src={modalCheck}
                        className="modalcheck"
                        style={webStyle.modalcheck}
                      />)}
                    </div>
      
                    <Typography
                    className="modalcontent"
                    style={webStyle.modalcontent}
                    >
                      Profile updated
                    </Typography>
                    <Typography
                    className="modalcontent"
                    style={webStyle.modalcontent}
                    >
                      Successfully!
                    </Typography>
                    <div style={webStyle.button}>
                    <Button
                    className="modalbutton"
                    style={this.handleRoleSuperAdmin(webStyle.loginbtnother,webStyle.modalbutton)}
                      onClick={this.handleClose}
                    >
                      <Link
                          href="userprofile"
                        style={this.handleRoleSuperAdmin(webStyle.okbtnsuper,webStyle.loginbtn)}
                        >
                          ok
                        </Link>
                    </Button>
                    </div>
                  </div>
                </Modal>
            )}

                    
                    
                </div>
            </div>
            </>
        )
    }
}
// Customizable Area End

// Customizable Area Start
const webStyle = {

    container: {
        height: "fit-content", 
        backgroundColor: "#eeeeee", 
        color: "#5f5f5f", 
        fontFamily: "sans-serif",
        width: "85vw",
        top: "50px",
        right:0,
        padding:"30px 20px 10px 0px",
    },

    label: {
        display: "flex",
        justifyContent: "flex-start",
        alignContent: "start",
        alignItems: "start",
        marginLeft: 40,
    },

    contentBody: {
        marginLeft: 0
    },
    
    griditem: {
        color: "#64b1f3",
    },

    image: {
        width: 120, 
        height: 110,
    },

    images: {
        width: 120, 
        height: 110,
    },

    profilepic: {
    marginTop: 10,
    fontWeight: 600,
    },

    profilepicother: {
        marginTop: 10,
        fontWeight: 600,
        color: "rgb(243 180 21)",
        },

    buttoncontainer: {
        display: "flex", 
        alignContent: "end", 
        justifyContent: "end"
    },

    cancelbutton: {
        display: "flex", 
        width: "100px", 
        border: "1px solid #cecece",
        height: "40px", 
        backgroundColor: "#e8e8e8", 
        marginRight: 10,
        fontWeight: 600,
    },

    savebutton: {
        display: "flex", 
        width: "100px", 
        border: "1px solid #cecece",
        height: "40px", 
        backgroundColor: "#64b1f3", 
        marginLeft: 10, 
        color: "white",
        fontWeight: 600,
    },

    savebuttonother: {
        display: "flex", 
        width: "100px", 
        border: "1px solid #cecece",
        height: "40px", 
        backgroundImage: "radial-gradient(rgb(246 168 34), rgb(171 114 24))",
        marginLeft: 10, 
        color: "black",
        fontWeight: 600,
    },

    textfilled: {
        backgroundColor: "#eeeeee", 
        color: "black", 
        margin: 10,
    },

    body: {
        marginLeft: 30
    },

    box: {
        marginTop: 10
    },

    input: {
        margin: "10 10 0 10", 
        fontWeight: 600
    },

    text: {
        backgroundColor: "white", 
        color: "#000000", 
        margin: 10
    },

    heading: {
        margin: 10
    },

    changepasswordlink: {
        margin: 0, 
        paddingLeft: 12, 
        color: "#64b1f3", 
        fontWeight: 600, 
        textDecoration: "none",
        textTransform: "none",
        backgroundColor: "transparent",
    }as React.CSSProperties,

    changepasswordlinkother: {
        margin: 0, 
        paddingLeft: 12,  
        color: "rgb(243 180 21)",
        fontWeight: 600, 
        textDecoration: "none",
        textTransform: "none",
        backgroundColor: "transparent",
    }as React.CSSProperties,


    phoneinput: {
        padding: "10px 14px 10px 60px",
        width: "100%",
        backgroundColor: "white",
    },

    inputContact: {
        margin: "10 10 10 10", 
        fontWeight: 600
    },

    textbox: {
        backgroundColor: "white", 
        color: "black", 
        marginTop: 10,
        marginLeft: 10,
        marginBottom: 10,
        marginRight: -10,
    },

    modalbackdrop: {
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: "rgba(0, 0, 0, 0.4)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 2,

    },

    modalcontentsuccess: {
        width: "20%",
        height: "35%",
        fontFamily: "sans-serif",
        backgroundColor: "white",
        alignItems: "center",
        justifyContent: "center",
    },

    modalbox: {
        display: "flex", 
        justifyContent: "center", 
        alignItems: "center",
        marginTop: -62,
    },

    modalcheck: {
        width: "200px", 
        height: "100px", 
        marginBottom: "25px"
    },

    modalcontent: {
        fontSize: "16px",
        fontFamily: "sans-serif",
        fontWeight: 500,
        alignItems: "center",
        justifyContent: "center",
        alignContent: "center",
        alignSelf: "center",
        display: "flex"
    },

    button: {
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
        width: 200,

    },

    modalbutton: {  
        paddingLeft: "25%",
        paddingRight: "25%",
        paddingTop: "2%",
        paddingBottom: "2%",
        fontWeight: 600,
        borderRadius: "6px",
        width: "140px",
        height: "auto",
        marginTop: "13px",
        display: "flex",
        backgroundColor: "#e9e9e9",
        color: "536c7c",

    },

    loginbtn: {
        color: "black",
        fontWeight: 600,
        fontSize: "14px",
        textDecoration: "none",
    }as React.CSSProperties,

    okbtnsuper :{
        color: "white",
        fontWeight: 600,
        fontSize: "14px",
        textDecoration: "none",
    }as React.CSSProperties,

    divnav: {
        display: "flex",
        justifyContent: "flex-start"
    },

    headnav: {
        marginLeft: 20,
        marginTop: -55,
        zIndex: 1300
    },

    loginbtnother: {
        backgroundColor:"rgb(100, 177, 243)",
        paddingLeft: "25%",
        paddingRight: "25%",
        paddingTop: "2%",
        paddingBottom: "2%",
        fontWeight: 600,
        borderRadius: "6px",
        width: "140px",
        height: "auto",
        marginTop: "13px",
        display: "flex",
        color: "white"
    },

    modaltickcheck: {
        width: "120px", 
        height: "120px", 
        marginBottom: "8px",
        marginTop: "13px",
    },

}
// Customizable Area End