import React from 'react';
// Customizable Area Start
import {Paper, Divider, Grid, Box, Link, CircularProgress, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Typography,} from '@material-ui/core';
import { Chart as ChartJS, ArcElement, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, } from 'chart.js';
ChartJS.register(ArcElement, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend,);
import {FullscreenExit,Loop} from '@material-ui/icons';
import './Graphs.css';


import FullSizeGraphController, { Props} from "./FullSizeGraphController";
// Customizable Area End

export default class FullSizeTableGraph extends FullSizeGraphController {
// Customizable Area Start
    constructor(props: Props) {
        super(props);
    }

    componentDidMount(): any {
        let token = localStorage.getItem("token")
        if(token) {
            this.getData()
    }
    }

    getData = () => {
        let completeData = this.props?.location?.state?.state;
        let headerdata = this.props?.location?.state.title;
        this.setState({headerValue: headerdata})
        this.setState({tableData: completeData})
        this.setState({analytics_workspace_id: localStorage.getItem("analytics_workspace")})
        this.setState({loader: false})
    }

    // handleDateChange(date: any) {
    //     this.setState({ selecteddate: date });
    //   }

    //   handleRefresh = () => {
    //     this.setState({loader:true})
    //     this.analyticsGraphDetails()
    // }

// Customizable Area End
    render() {
        // Customizable Area Start
        // Customizable Area End
        return (
            // Customizable Area Start
            <>
            <div style={webStyle.container} className="boxcontainer">
                {this.state.loader ? <Grid item sm={12}><Box style={webStyle.circularprogress}><CircularProgress size={50} /></Box></Grid>
                  :
                <div className="content"style={webStyle.contents}>
                    <Grid container>
                        <Grid item lg={6} sm={6}>
                            <h4>Analytics</h4>
                        </Grid>
                        <Grid item lg={6} sm={6}>
                            <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-end", height: 60}}>
                                {/* <p style={{display: "flex", justifyContent: 'flex-end', marginRight: 10, textDecoration: "underline", marginTop: 24, cursor: "pointer"}} onClick={this.handleRefresh}> Refresh</p> */}
                            </div>
                        </Grid>
                    </Grid>

                    <Grid container spacing={3}>
                        <Grid item lg={12} sm={12}>
                        <Paper  style={{height: 550}}>
                                <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", height: 60}}>
                                {this.state.refreshing ? <h4 style={{paddingLeft: 20,}}>{this.state.refreshedHEaderValue}</h4>:<h4 style={{paddingLeft: 20,}}>{this.state.headerValue}</h4>}
                                </div>
                                <Divider />
                                <div style={{height: 430}}>
                                    <div style={{height: 430}}>
                                    <Paper>
                                                        <TableContainer style={{maxHeight: 370}}  className='scrollbar'>
                                                            <Table>
                                                                <TableHead style={{height: 5}}>
                                                                    <TableRow style={{height: 5}}>
                                                                        <TableCell style={{height: 5, backgroundColor: "#f5a823", textAlign: "center",}}>
                                                                            <Typography style={{fontWeight: 600}}>S no.</Typography>
                                                                        </TableCell>
                                                                        <TableCell style={{height: 5, backgroundColor: "#f5a823", textAlign: "center",}}>
                                                                            <Typography style={{fontWeight: 600}}>Designer ID</Typography>
                                                                        </TableCell>
                                                                        <TableCell style={{height: 5, backgroundColor: "#f5a823", textAlign: "center",}}>
                                                                            <Typography style={{fontWeight: 600}}>Designer Name</Typography>
                                                                        </TableCell>
                                                                        <TableCell style={{height: 5, backgroundColor: "#f5a823", textAlign: "center",}}>
                                                                            <Typography style={{fontWeight: 600}}>No of errors</Typography>
                                                                        </TableCell>
                                                                    </TableRow>
                                                                </TableHead>
                                                                {this.state.tableData?.map((userdata: any) => {
                                                                    return (
                                                                        <TableBody>
                                                                    <TableRow>
                                                                        <TableCell align='center' style={{borderRight: "1px solid #d0d0d0", borderTop: "1px solid #d0d0d0", backgroundColor: "white",}}>{userdata.s_no}</TableCell>
                                                                        <TableCell align='center' style={{borderRight: "1px solid #d0d0d0", borderTop: "1px solid #d0d0d0", backgroundColor: "white",}}>{userdata.email_id}</TableCell>
                                                                        <TableCell align='center' style={{borderRight: "1px solid #d0d0d0", borderTop: "1px solid #d0d0d0", backgroundColor: "white",}}>{userdata.name} </TableCell>
                                                                        <TableCell align='center' style={{borderTop: "1px solid #d0d0d0", backgroundColor: "white",}}>{userdata.errors}</TableCell>
                                                                    </TableRow>
                                                                    </TableBody>
                                                                    )
                                                                }) }
                                                            </Table>
                                                        </TableContainer>
                                                        </Paper>
                                    </div>
                                    <div style={{display: 'flex', justifyContent: 'flex-end', padding: 10,}}>
                                        <Box style={{backgroundColor: "#e8e8e8", height: 30, width: 30, border: '1px solid #e8e8e8', display: 'flex', justifyContent: "center", alignItems: "center", marginLeft: 10, marginTop: 25, marginRight: -20, borderRadius: "50%"}}>
                                            {/* {localStorage.getItem('role_id') === "TL/Manager" &&  */}
                                                <Link onClick={() => this.props.history.push("/graph", {state: this.state.analytics_workspace_id})} underline='none'  style={{color: 'black'}} className="graphtable">
                                                    <FullscreenExit  style={{cursor: "pointer"}} />
                                                </Link>
                                            {/* // } */}
                                        </Box>
                                    </div>
                                </div>
                            </Paper>
                        </Grid>
                    </Grid>
                </div>
                }
            </div>
            </>
        )
    }
}
 // Customizable Area End
 // Customizable Area Start
const webStyle = {

    container: {
        backgroundColor: "#eeeeee",
        height: "100%",
        color: "#5f5f5f",
        fontFamily: "sans-serif",
        width: "85vw",
        top: "50px",
        right:0,
        padding:"30px 20px 10px 0px",
        marginLeft: '15vw',
        boxSizing: "border-box",
        position: "fixed",
        overflow: "scroll"
    }as React.CSSProperties,

    innerGridcontainer: {
        // marginLeft: 0,
    },

    content: {
        margin: 0
    },
    
    sign: {
        display: "flex",
        justifyContent: "flex-start",
        paddingLeft: "20px",
        fontFamily: "sans-serif",
        zIndex: 0,
    },

    
    headnav: {
        marginLeft: 10,
        marginTop: "-55px",
        zIndex: 1300,
    },

    contents: {
        marginLeft: 30
    },

    twocards: {
        display: "flex",
        height: 100,
        width: 350,
        marginRight: 30,
        marginTop: 20,
        padding: 20,
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
    },

    iconimages: {
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
    },

    icons: {
        width: 120,
        height: 120,
    },

    iconname: {
        marginLeft: 28,
        marginRight: 20,
    },

    iconnamecheck: {
        marginLeft: 32,
        marginRight: 20,
    },

    text: {
        backgroundColor: "white", 
        color: "#000000", 
        margin: 10,
        marginRight: 20,
    },

    circularprogress: {
        height: "100%",
        // margin: "100",
        marginTop: -50,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        alignContent: "center",
        // color: "#6e6e6e"
      } as any,

      barchartstyle: {
        maxHeight: "450px",
        // maxWidth: "650px"
    }

}


function getDataClear() {
    throw new Error('Function not implemented.');
}
// Customizable Area End