// Customizable Area Start
import React, { PureComponent } from "react";
import Avatar from "@material-ui/core/Avatar";
const configJSONBase = require("../../../framework/src/config");
import "./notification.css";
import {
  Box,
  Button,
  Typography,
  CircularProgress 
} from "@material-ui/core";

import InAppNotificationController, { Props } from "./InAppNotificationController";
import { MdKeyboardArrowUp, MdKeyboardArrowDown } from "react-icons/md";
import moment from "moment";


export class AppNotification extends InAppNotificationController {

  constructor(props: Props) {
    super(props);
  }


  render() {
    const { allNotificationData } = this.state;
    const isUnreadNotification = ()=>{
      const unreadNotfication = allNotificationData.filter((el:any)=>!el.attributes.is_read);
      const value = unreadNotfication.length == 0 ? true : false;
      return value;
   }
    return (
      <>

        <Box
          sx={{
            width: "85vw",
            top: "50px",
            right: 0,
            boxSizing: "border-box",
            padding: "50px 20px 10px 20px",
            position: "absolute",
          }}
          style={{ backgroundColor: "#e8e8e8", height: "95%", overflowY: "scroll" }}
        >
          <Box sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between"
          }}>

            <Typography
              className="userrequest-heading"
              style={{
                fontFamily: "sans-serif",
                fontWeight: "bold",
                fontSize: 16,
              }}
            >
              Notifications
            </Typography>
          {!isUnreadNotification() &&  <Box onClick={this.markAllAsReadNotfication} className={"markAllasRead"}>
              Mark all as read
            </Box>}

          </Box>

          {!this.state.isLoading ? allNotificationData.length != 0 && <Box marginTop={"30px"}>
            <Box color={"darkgrey"} paddingLeft={"20px"}>New</Box>
            {allNotificationData.map((el: any, index: number) => {
              return !el.attributes.is_read && <NotficationCard key={index} notificationData={el} markAsRead = {this.markAsReadNotfications}/>
            })}


            <Box marginTop={"25px"}>
              <Box color={"darkgrey"} paddingLeft={"20px"}>Old</Box>
              {allNotificationData.sort((a:any, b:any) => new Date(b.attributes.created_at).valueOf() - new Date(a.attributes.created_at).valueOf()).map((el: any, index: number) => {
                return el.attributes.is_read && <NotficationCard key={index} notificationData={el} markAsRead = {this.markAsReadNotfications}/>
              })}
            </Box>
          </Box> :  <Box className="loaderStyle"><CircularProgress size={"45px"}/></Box>}

        </Box>
      </>
    );
  }
}

type NotficationCardProp = {
  notificationData:any;
  markAsRead:any;
}



export class NotficationCard extends PureComponent<NotficationCardProp  , { showMore: boolean,setHeight:any }>{
  textref= React.createRef();
  constructor(props: NotficationCardProp) {
    super(props);
    this.state = {
      showMore: true,
      setHeight:false
    }
  }

  showMoreButtonToggler = (event:any) => {
    event?.stopPropagation();
    this.setState({ showMore: !this.state.showMore });
  }

   handleMarkAsRead=()=>{
      const {id} = this.props.notificationData;
      const { is_read} = this.props.notificationData.attributes;
      !is_read && this.props.markAsRead(id);
  }

  renderColor=(role:any)=>{
    const value = role == "Superadmin" ? "skyblue" : "orange";
    return value;
  }

  commentRegex=(body:any)=>{
    const regex = /@\[([^\]]+)\]\(\d+\)/g;
    return body.replace(regex ,`<span class = "taggedPerson">$1</span>`)
  }


  componentDidMount(){
    if (this.textref.current) {
      // @ts-expect-error
      const { height, fontSize } = window.getComputedStyle(this.textref.current);
      this.setState({setHeight:Math.floor(parseFloat(height) / parseFloat(fontSize)),showMore:false});

    } 
  }




  render() {
    const { is_read, title, remarks, body, created_at, perfom_by } = this.props.notificationData.attributes;
    
    const { showMore } = this.state;
    const role = localStorage.getItem("role_id");
    const unReadColor = role === "Superadmin" ? "#DFE9F4" : "#F9F0EB"
    return <>
      <Box className={"notificationCard"} onClick={this.handleMarkAsRead} padding={"15px 20px"} bgcolor={!is_read ? unReadColor : "#DFDFDF"} borderRadius={"5px"} margin={"10px 0px"}>
        <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
          <Box display={"flex"} alignItems={"center"} >
            <Box><Avatar style={{ height: "22px", width: "22px" }} src={configJSONBase.baseURL + perfom_by?.attributes.image} /></Box>
            <Box marginLeft={"15px"} fontWeight={"bold"}>{perfom_by?.attributes.first_name || "John"}  {perfom_by?.attributes.last_name || "Doe"}  </Box>
          </Box>
          <Box color={"darkgrey"}>{moment(created_at).format("DD MMM YY , hh:mm A")}</Box>
        </Box>
        <Box fontSize={"13.5px"} marginLeft={"35px"} marginTop={"10px"}>{remarks}</Box>

        {title && <Box>      
          {/*@ts-expect-error*/}
          <Box dangerouslySetInnerHTML={{ __html: this.commentRegex(body) }} fontSize={"13px"} ref={this.textref} marginTop={"5px"} marginLeft={"35px"} color={"#A7A6A6"} overflow={"hidden"} textOverflow={"ellipsis"} whiteSpace={!showMore ? "nowrap" : ""}>
            
          </Box>

        {this.state.setHeight > 1 &&  <Box  marginLeft={"35px"}><Button onClick={this.showMoreButtonToggler} style={{ fontWeight: "bold", color: role == "Superadmin" ? "skyblue" : "orange", textTransform: "none" }}>{showMore ? "see less " : "see more "}{showMore ? <MdKeyboardArrowUp color={this.renderColor(role)} fontSize={"25px"} /> : <MdKeyboardArrowDown color={this.renderColor(role)} fontSize={"25px"} />} </Button> </Box>}
        </Box>
        
        }
      </Box>
    </>
  }
}
// Customizable Area End