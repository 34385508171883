// Customizable Area Start
import React, { Component, PureComponent } from "react";
import {
  Box,
  Avatar

} from "@material-ui/core";
import "./ProjectCard.css";
import Carousel from "react-multi-carousel";
import {
  AiOutlineLeft,
  AiOutlineRight,
  AiOutlineMessage
} from "react-icons/ai";
import "react-multi-carousel/lib/styles.css";
import { ImAttachment } from "react-icons/im";
import { projectCardData, projectCarouselData, status, projectDataType } from "../../../components/src/types";
import { RouteComponentProps } from 'react-router-dom';
import TeamPlayerController from "../../../components/src/TeamPlayerController";
import KanbanBoardController, {PCProps, responsive}  from "./KanbanBoardController";
const configJSONBase = require("../../../framework/src/config");


export interface Props {
  navigation: unknown;
  id: string;
  history: RouteComponentProps;

}

interface SomeData {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  projects: object;
  history: object;
  projectStatusColors: object;
  loader: boolean;
  clientDropDown: {client_type: string, id: string, name:string}[];
  selectedClient: string;
  selectedProjectData: object;
  dateRange: string | null | string[] | Date[];
  client_type: string;
  client_id: number | string;
  from: string | null | string[] | Date[];
  to: string | null | string[] | Date[];
  statusFlow: string[]
  // Customizable Area End
}


export default class KanbanBoard extends PureComponent<{projects:projectDataType, statusFlow: string[],data:SomeData}>{
  constructor(props: {projects:projectDataType, statusFlow: string[], data: SomeData}) {
    super(props);
  
  }


  render() {
    return (
  
      <>
      {Object.keys(this.props.projects).length != 0 ? this.props.statusFlow.map((element,index)=>{
          return <>
              <ProjectCarousel 
              key={index}
              data={this.props.data as unknown as projectCarouselData}
              projectStatus={element as keyof status}
          />
          </>
        }) : 
        
        <Box style={{"height": "80vh",
        "margin": "auto",
        "display": "flex",
        "fontSize": "25px",
        "alignItems": "center",
        "fontWeight": "bold",
        "flexDirection": "column",
        "justifyContent": "center"}} >
        No Projects found
        </Box>}   
       
      </>
    
    );
  }
}

 export class ProjectCarousel extends KanbanBoardController{
  carouselRef:React.RefObject<Carousel>
  constructor(props: PCProps) {
    super(props);

      this.carouselRef = React.createRef();
  
  }


  render() {
    const {projectStatus} = this.props;
    const {projects,history,projectStatusColors} = this.props.data;

  

    return (
  
      <>
        <Box bgcolor={"white"} marginTop={"20"} paddingTop={"1.2vh"}>
          <Box
            marginTop={"10px"}
            display={"flex"}
            justifyContent={"space-between"}
          >
            <Box>
              <Box
                padding={"5px"}
                borderRadius={"0px 30px 30px 0px;"}
                component={"span"}
                color={"white"}
                bgcolor={ projectStatusColors[projectStatus] ? `#${projectStatusColors[projectStatus]}` :  "#F6C03D"}
              >
                {projectStatus}
              </Box>
              <Box
              component={"span"}
              marginLeft={"0.5rem"}
              >
                ( {projects[projectStatus].length} )
              </Box>
            </Box>
            <Box
              display={"flex"}
              alignItems={"center"}
              className={"leftRightDir"}
            >
              <Box component={"div"} padding={"5"} bgcolor={"#F3F4F6"}>
              
                <AiOutlineLeft onClick={()=>{
                  // @ts-expect-error
                  this.carouselRef.current?.previous()}
                  }
                  style={this.state.currentSlide == 0 ? {cursor: 'default', fill: 'gray'} : {cursor: 'pointer'}}
                  />
              </Box>
              <Box
                marginRight={"2vw"}
                component={"div"}
                padding={"5"}
                bgcolor={"#F3F4F6"}
              >
                <AiOutlineRight onClick={()=>{
                  // @ts-expect-error
                  this.carouselRef.current?.next()
                  }}
                  style={(this.state.currentSlide == projects[projectStatus].length - responsive[this.state.deviceType].items as number) || projects[projectStatus].length - responsive[this.state.deviceType].items as number < 0  ? {cursor: 'default', fill: 'gray'} : {cursor: 'pointer'} }
                  />
              </Box>
            </Box>
          </Box>


          <Box
          onFocus={this.activeKeyBoardControlToggleTrue}
          onMouseEnter={this.activeKeyBoardControlToggleTrue}
          onBlur={this.activeKeyBoardControlToggleFalse}
          onMouseLeave={this.activeKeyBoardControlToggleFalse}
          className={"newBoxx"}
          >
          <Carousel
            ref = {this.carouselRef}
            arrows={false}
            slidesToSlide={1}
            renderButtonGroupOutside={true}
            responsive={responsive}
            containerClass="makeItScrollable"
            ssr
            keyBoardControl={this.state.ActiveKeyBoardControl}
            beforeChange={(nextSlide,element) => this.beforeChangeHandler(nextSlide, element.deviceType)}
            afterChange={prevSlide => this.afterChangeHandler(prevSlide)}
          >
           
            {projects[projectStatus].map((element ,index:number)=>{
              return (
                <ProjectCard key={index}  data={{"projectData":element as unknown as projectCardData['projectData'],"history":history ,"dotColor":projectStatusColors[projectStatus] ? `#${projectStatusColors[projectStatus]}` : "red", projectStatusColors: projectStatusColors }}  priority={index == 2}/>
              )
            })}


          </Carousel>
          </Box>
        </Box>
      </>
    
    );
  }
}
interface ProjectCardProps {
  data:projectCardData
  priority:boolean
}

export class ProjectCard extends PureComponent<ProjectCardProps >{
  
  render() {
    const {history,projectData,dotColor, projectStatusColors} = this.props.data;
    const {client_name,attachment_count, priority, is_overdue} = projectData;
    let newDate: string | null | string [] = projectData.Project_created ? projectData.Project_created.split("/") : null;
    newDate = newDate ? [newDate[1],newDate[0],newDate[2]].join("/") : null;
    const projectDate = new Date(newDate as unknown as string).toDateString();
    let stringProjectDate = projectDate.split(" ");
    const [year,month,days] = [stringProjectDate[3],stringProjectDate[1],stringProjectDate[2]];
    
    let tags: string[] | unknown[] = []
    let output = ""

    const handleTags = () => {
      
      if(is_overdue){
        tags.push("Overdue")
      }
      if(priority == "Yes"){
        tags.push("Priority")
      }
      
      let tempArr: unknown[] = []
      
      if(tags.length > 0){
        tags.forEach((element,index)=>{
          if(index != 0){
            tempArr.push(<Box component={"span"} key={index} marginX={'5px'} style={{borderLeft: '1px solid red', height: '50px', opacity: 0.8}}></Box>)
          }
         tempArr.push(element)
        })
      }

      tags = tempArr
      return <Box  display={"flex"} justifyContent= {"flex-end"}>
              <Box fontSize= {"0.8rem"} paddingX={"2px"} style={{borderRadius: '0 3px 0 3px'}} bgcolor={"#FFD3D2"} border= {"1px solid red"}>{tags.map((item, index)=>{
                if(index > 0){
                  output += " | " 
                }
                return item
              })}</Box>
            </Box>
    }
  

    return (
  
      <>
        <Box
          onClick={()=>{history.push("/projectStatus",{id:this.props.data.projectData.id, data:projectData, projectStatusColors: projectStatusColors, prevPath: window.location.pathname})}}
          border={(priority == "Yes" || is_overdue) ? "1px solid red" : "1px solid darkgrey"}
          bgcolor= {(priority == "Yes" || is_overdue) ? "#FEF4F3" : ""}  
          display={"flex"}
          justifyContent={"space-between"}
          flexDirection={"column"}
          className={"projectCard"}
        >
            {(priority == "Yes" || is_overdue) && handleTags()}
          <Box
            className={"projectCardSection"}
            height={"14%"}
            component={"div"}
            display={"flex"}
            marginTop={(is_overdue || priority == "Yes") ? "-14" : "0.8vh"}
            >
            <DotOnCard color={dotColor} />
            <Box className="lightText" margin={"1.2vh"}>
              Project Id
            </Box>
            <Box className="darkText"
            style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              maxWidth: '68%'
            }}
            >{projectData?.project_id }</Box>
          </Box>
          <Box
            className={"projectCardSection"}
            display={"flex"}
            alignItems={"center"}
            height={"14%"}
            component={"div"}
          >
            <Box className="darkText" 
            title={projectData["project_name"]}
            style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: 'block'
            }}>{projectData["project_name"]}</Box>
          </Box>
          
          <Box
            display={"flex"}
            alignItems={"center"}
            className={"projectCardSection"}
            height={"14%"}
            component={"div"}
          >
            <Box className="lightText">Client</Box>
            <Box className="darkText" margin={"1.2vh"}
            style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}
            >
               {client_name}
            </Box>
          </Box>
          <Box
            display={"flex"}
            alignItems={"center"}
            className={"projectCardSection"}
            height={"10%"}
            component={"div"}
          >
            <Box className="lightText">Sub Folder Name</Box>
            <Box className="darkText" margin={"1.2vh"}
            style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}
            >
               {projectData?.team_title || "NA"}
            </Box>
          </Box>
          <Box
            className={"projectCardSection"}
            height={"14%"}
            component={"div"}
            display={"flex"}
            alignItems={"center"}
          >
            <Box className="lightText">Type </Box>
            <Box className="darkText" margin={"1.2vh"}>
               {projectData?.type?.project_type || "NA"}
            </Box>
          </Box>
          <Box
            bgcolor={priority == "Yes" || is_overdue ? "#f5e4e5" : '#F3F7FA'}
            className={"projectCardSection"}
            component={"div"}
            style={{ padding: '10 0' }}
            display={"flex"}
            marginLeft={0}
          >
            <TeamPlayer data={
              {
                designer:{fname: this.props.data?.projectData?.designer?.first_name || this.props.data?.projectData?.designer as unknown as string, lname: this.props.data?.projectData?.designer?.last_name, image: this.props.data?.projectData?.designer_image || undefined },
                IQA:{fname: this.props.data?.projectData["internal_qa"]?.first_name || this.props.data?.projectData["internal_qa"] as unknown as string, lname: this.props.data?.projectData["internal_qa"]?.last_name, image: this.props.data?.projectData?.internal_qa_image || undefined },
                IQC:{fname: this.props.data?.projectData["internal_qc"]?.first_name || this.props.data?.projectData["internal_qc"] as unknown as string, lname: this.props.data?.projectData["internal_qc"]?.last_name, image: this.props.data?.projectData?.internal_qc_image || undefined }
            }} 
            forWhom="designer"
            fullData={this.props.data}
            />
            <div style={{borderLeft: '0.5px solid grey', height: '100%', opacity: 0.5}}></div>
            <TeamPlayer data={
              {
                designer:{fname: this.props.data?.projectData?.designer?.first_name || this.props.data?.projectData?.designer as unknown as string, lname: this.props.data?.projectData?.designer?.last_name, image: this.props.data?.projectData?.designer_image || undefined },
                IQA:{fname: this.props.data?.projectData["internal_qa"]?.first_name || this.props.data?.projectData["internal_qa"] as unknown as string, lname: this.props.data?.projectData["internal_qa"]?.last_name, image: this.props.data?.projectData?.internal_qa_image || undefined },
                IQC:{fname: this.props.data?.projectData["internal_qc"]?.first_name || this.props.data?.projectData["internal_qc"] as unknown as string, lname: this.props.data?.projectData["internal_qc"]?.last_name, image: this.props.data?.projectData?.internal_qc_image || undefined }
            }}
            forWhom="IQC"
            fullData={this.props.data}
            />
            <div style={{borderLeft: '0.5px solid grey', height: '100%', opacity: 0.5}}></div>
            <TeamPlayer data={
              {
                designer:{fname: this.props.data?.projectData?.designer?.first_name || this.props.data?.projectData?.designer as unknown as string, lname: this.props.data?.projectData?.designer?.last_name, image: this.props.data?.projectData?.designer_image || undefined },
                IQA:{fname: this.props.data?.projectData["internal_qa"]?.first_name || this.props.data?.projectData["internal_qa"] as unknown as string, lname: this.props.data?.projectData["internal_qa"]?.last_name, image: this.props.data?.projectData?.internal_qa_image || undefined },
                IQC:{fname: this.props.data?.projectData["internal_qc"]?.first_name || this.props.data?.projectData["internal_qc"] as unknown as string, lname: this.props.data?.projectData["internal_qc"]?.last_name, image: this.props.data?.projectData?.internal_qc_image || undefined }
            }} 
            forWhom="IQA" 
            fullData={this.props.data}
            />

          </Box>
          <Box
            className={"projectCardSection"}
            height={"14%"}
            component={"div"}
            display={"flex"}
            alignItems={"center"}
            padding={"10 0"}
          >
            <Box
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              className="lightText"
              height={"100%"}
              width={"17%"}      
              gridGap={5}
            >
              <AiOutlineMessage /> {projectData.project_comments_count}
            </Box>
            <Box
              
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              className="lightText"
              height={"100%"}
              width={"17%"}
              gridGap={5}
            >
              <ImAttachment /> {attachment_count}
            </Box>
            <Box width={"64%"} display={"flex"} justifyContent={"center"}>
              <Box className="lightText" fontSize={"0.8rem"}>
                Project Created
              </Box>
              <Box className="darkText" fontSize={"0.8rem"} marginLeft={"5px"}>
                {days+" "+month+" "+year}
              </Box>
            </Box>
          </Box>
        </Box>
      </>
    
    );
  }
}
export class TeamPlayer extends TeamPlayerController{

  forPropValue = () => {
    if(this.props.forWhom == "IQA")
      return "Internal QA"
    else if(this.props.forWhom == "IQC")
      return "Internal QC"
    else
      return "Designer"
  }
  render(): React.ReactNode {
    return <Box
            height={"100%"}
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"space-evenly"}
          width={"32.8%"}
        >
          <Box alignItems={"center"} display={"flex"} height={"40%"}>
            <Avatar src={configJSONBase.baseURL + this.state.image} style={{ width: "1.5rem", height: "1.5rem", marginLeft: 10 }} />
            <Box className="darkText nameText">{this.state.fname}</Box>
          </Box>
          <Box height={"40%"} justifyContent={"center"} display={"flex"}>
            <Box className="lightText">{this.forPropValue()}</Box>
          </Box>
        </Box>
      
  }
}


export class DotOnCard extends Component<{color:string}>{
  render(): React.ReactNode {
    const {color} = this.props;
      return <>
          <Box
          borderRadius={"50%"}
          bgcolor={color}
          width={"7"}
          height={"7"}
        />
      </>
  }
}


// Customizable Area End