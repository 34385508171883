import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";

const invoiceIcon = require('../assets/InvoiceIcon.png');
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import InvoiceBillingController, {
  Props,
  configJSON,
} from "./InvoiceBillingController";

export default class InvoiceBilling extends InvoiceBillingController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
      <Container maxWidth={"sm"}>
        <Box sx={webStyle.mainWrapper}>
          <Typography variant="h6">{configJSON.labelTitleText}</Typography>
          <Typography variant="subtitle1" component="div">
            {configJSON.labelBodyText}
          </Typography>
          <Box sx={webStyle.inputStyle}>
            <InputLabel id="service-shop-name">
              This is the reviced value:{this.state.txtSavedValue}{" "}
            </InputLabel>
            <Input
              data-test-id={"txtInput"}
              type={this.state.enableField ? "password" : "text"}
              placeholder={configJSON.txtInputPlaceholder}
              fullWidth={true}
              disableUnderline={true}
              value={this.state.txtInputValue}
              onChange={(e) => this.setInputValue(e.target.value)}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={this.setEnableField}
                    edge="end"
                  >
                    {this.state.enableField ? (
                      <Visibility />
                    ) : (
                      <VisibilityOff />
                    )}
                  </IconButton>
                </InputAdornment>
              }
            />
          </Box>
          <Box
            data-test-id="btnAddExample"
            onClick={() => this.doButtonPressed()}
            component="button"
            sx={webStyle.buttonStyle}
          >
            <Button color={"primary"}>{configJSON.btnExampleTitle}</Button>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>

      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
  invoiceTitle: {
    color: "#2c2c2c",
    fontFamily: "sans-serif",
    fontSize: "16px",
    fontWeight: "700",
  }
};
// Customizable Area End
