//@ts-nocheck
import React from "react";
import MomentUtils from "@date-io/moment";
import { ThemeProvider } from "@material-ui/styles";
import moment from "moment";
import {
  MuiPickersUtilsProvider,
  DatePicker,
} from "material-ui-pickers";

import Avatar from "@material-ui/core/Avatar";
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';

import {
  createMuiTheme,
  Box,
  Button,
  Typography,
  InputLabel,  
  Grid,  
  MenuItem,
  Divider,
  Modal,
  Menu,
  withStyles,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  CircularProgress,
  Tooltip
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import ArrowDownwardOutlinedIcon from '@material-ui/icons/ArrowDownwardOutlined';
import SearchBar from "material-ui-search-bar";
import CloseIcon from "@material-ui/icons/Close";
import CheckIcon from '@material-ui/icons/Check';
import { filter, darkFilter } from "./assets";
// Customizable Area End

import PartiallyPaidInvoicesController, { Props } from "./PartiallyPaidInvoicesController";
import "./PartiallyPaidInvoices.css";
export const configJSONBase = require("../../../framework/src/config");

const materialTheme = createMuiTheme({
  overrides: {
    // @ts-ignore
    MuiPickersToolbar: {
      toolbar: {
          color: "black",
          backgroundColor: "#e8e8e8"
      },
    },
    MuiPickersDay: {
      day: {
      color: "black"
      },
      daySelected: {
          backgroundColor: "#e8e8e8"
      },
      dayDisabled: {
          color: "#e8e8e8"
      },
      current: {
          color: "#e8e8e8"
      },
      isSelected: {
        color: "white",
        backgroundColor: "#e8e8e8"
      },
    },
    MuiPickersToolbarButton: {
      toolbarBtn: {
          color: "black",
      },
      toolbarBtnSelected: {
          color: "black"
      },
   },
    MuiCssBaseline: {
      "@global": {
        "*::-webkit-scrollbar": {
          width: "10px",
        },
        "*::-webkit-scrollbar-track": {
          background: "#E4EFEF",
        },
        "*::-webkit-scrollbar-thumb": {
          background: "#1D388F61",
          borderRadius: "2px",
        },
      },
    },

    palette: {
      primary: "red", // works
    },
    MuiButton: {
      textPrimary: {
        color: "black",
      },
    },

    MuiPickersModal: {
      dialogAction: {
        color: "#8bc34a",
      },
    },
    myComponent: {
      "& .MuiPickersDay-isSelected": {
        backgroundColor: "red",
      },
    },

    MuiPickersCalendarHeader: {
      switchHeader: {
        backgroundColor: "white",
        color: "black"
      //   color: "rgb(171 114 24)",
      },
      dayLabel: {
          color: "black"
      //   color: "rgb(171 114 24)",
      },
    },
  },
});
//istanbul ignore next
const useStyles = ((theme) => ({ 
  menuStyle : {
    "& .MuiPaper-root":{
       maxWidth : "500px",
     }
   },
  selectRoot: {  
    '&:focus':
    { backgroundColor:'yellow' } 
  } 
}));
//istanbul ignore next
const usePlaceholderStyles = makeStyles(theme => ({
  placeholder: {
    color: "#aaa"
  }
}));

export class PartiallyPaidInvoices extends PartiallyPaidInvoicesController{

  constructor(props: Props) {
    super(props);
    
  }

  handleBack = () => {
  this.props.history?.goBack()
  };



  handleClose = () => {
    this.setState({ genericModalOpen: false,isEditMode:false, firstname:'',lastname:'', birthDate:null,
    joiningDate:null,
    email:"",
    contactNo:"",
    password:'',
    role:"",
    desc:"",
    workspace:"",
    designation: "",
    fileData:"", page:true,isErrorEmail:false});
  };
  modalClose = () => {
    this.setState({ isSuccessModal: false ,genericModalOpen:false,modalMsg:"",isEditMode:false,modalVarient:""});
  };
  handleImage = (e: any) => {

    this.setState({
      fileData: e.target.files[0],
    });

  };
  handleDateChange = (date: any) => {
    this.setState({ birthDate: date });
  };
  handleJoingDateChange = (date: any) => {
    this.setState({ joiningDate: date });
  };

  handleAction = () => {
    console.log("Action");

    if (this.state.page) {
      this.setState({ page: false });
    }

      if (
        this.state.firstname &&
        this.state.lastname &&
        this.state.birthDate &&
        this.state.email &&
        this.state.contactNo &&
        this.state.designation&&
        this.state.role &&
        this.state.workspace&&
        this.state.fileData
      ) {

        this.setState({ isErrorEmail: false });
        if(this.state.isEditMode){
          this.updateUser()
        }else{
          this.handleAddAction();

        }
        this.setState({
          page: true,
          firstname:"",
          lastname:"",
          birthDate:null,
          joiningDate:null,
          email:"",
          contactNo:"",
          password:'',
         role:"",
         designation:"",
         desc:"",
         workspace:"",

         fileData:"",

          isErrorFirstName:false,
          isErrorLastName:false,
          isErrorPassword:false,

        })
      }
  };


  handleChangeStatus = (e: any) => {
    this.setState({ status: e?.target?.value },()=>{
      console.log('this.state.status', this.state.status);
      
    });
  };

handleChangePage = (event: unknown, newPage: number) => {
  this.setState({pageNo:newPage})
};
handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({rowsPerPage:+event.target.value})
  this.setState({pageNo:0})
};

requestSearch = (e:any) => {

  this.setState({search:e})


};
 cancelSearch = () => {
  this.setState({search:""})
  this.requestSearch(this.state.search);
  this.setState({search:""})
};

handleApply =() =>{
  let isError = false;
  let shouldApplyFilter = true;
  
  if(this.state.toDate || this.state.fromDate){
    if(!moment(this.state.toDate).isSame(this.state.fromDate) && !moment(this.state.toDate).isAfter(this.state.fromDate)){
      this.setState({ filterToDateError:true})
      isError = true;
    }
  }
 
 if (isError) {
   return;
 }

 if(!this.state.toDate && !this.state.fromDate){
  shouldApplyFilter=false;
}

  this.getApply()
  this.setState({filterOpen:null ,initialTableLoad:true, filterToDateError: false, isFilterApplied:true,search:this.state.search+"",filterApplied: shouldApplyFilter})
}
handleFilterMenuClose =() =>{
  this.setState({isConfirm:false})
}
handleFilterClose =()=>{
  this.setState({isConfirm:false,status:"", toDate:"", fromDate:"",filterOpen:null, filterToDateError:false})
}

handleFilterClick =(e:any) =>(
 this.setState({filterOpen:e.currentTarget})
)

handleDeleteBtn=() =>{
  this.setState({isConfirm:true})
}
handleCancelBtn =() =>{
  this.setState({isConfirm:false})
}
handleYes=() =>{
   this.deleteUser()
   this.setState({isConfirm:false})
}
confirmDelete =() =>(
  <Modal
  className="modal-backdrop"
  open={this.state.isConfirm}
  onClose={this.handleCancelBtn}
  style={{
    overflow: "auto",
  }}
>
  <div
    style={{
      zIndex: 10,
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      background: "white",
      width: "20%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      gap: "20px",
    }}
  >
   <Grid>
    <div
      style={{ textAlign: "center" }}
    >
      <div  className="confirm-heading" style={{padding:"10px"}} >
      <Typography style={{fontWeight:900}}>Delete User</Typography>
    <CloseIcon   onClick={this.handleCancelBtn} />
      </div>
      <Divider/>
    <div style={{ padding:"20px"}} className="confirmbody">
      <Typography>Are you sure,you want to delete selected user ?</Typography>
    </div>
    <Divider/>
    <div style={{padding:"20px"}}>
    <Button
        onClick={this.handleCancelBtn}
        className="cancelbtn"

      >
        Cancel
      </Button>
      <Button
        onClick={this.handleYes}
        className="yesbtn"
        style={{ backgroundColor: "#0096FF "}}
      >
        Yes
      </Button>
    </div>
    </div>
    </Grid>
  </div>
</Modal>
)

  checkStatus =()=> (<CheckIcon/>)
  handleReset=()=>{
    this.setState({loading:true})
    this.getInvoices();
    this.setState({fromDate:"",toDate:"",status:"",filterToDateError: false, isFilterApplied:false, filterApplied:false})
  }

  isTableLoaded=()=>{
    return this?.state?.invoiceData?.length //  || this.state.initialTableLoad
  }

  getFilteredData=(filteredList:any)=>{

    let data = [];

    data = filteredList?.filter((item:any)=>{      
      if(!this.state.search) return true
      if(item?.attributes?.client_data?.client_name?.toLocaleLowerCase()?.startsWith(this.state.search.toLocaleLowerCase()))return true
    }).slice(this.state.pageNo * this.state.rowsPerPage, this.state.pageNo * this.state.rowsPerPage + this.state.rowsPerPage)

    this.searchedRecordsLength = data?.length;
    return data
  }

  getNoDataViewForSearch=()=>{
    if(this.state.search?.length > 0 && this.searchedRecordsLength == 0 || this.state.search?.length > 0 && this.state.invoiceData?.length == 0){
      return <p className="norecord">No invoices found</p>
    }
  }

  getNoDataView=()=>{
    if(this.state.search?.length == 0 && this.state.isFilterApplied && this.state.invoiceData?.length == 0){
      return <p className="norecord">No invoices found</p>
    }
    else if(this.state.search?.length == 0 && this.state.invoiceData?.length == 0){
      return <p className="norecord">No invoices Found</p>
    }
  }

  getCount=()=>{
    if(this.state.search?.length > 0 && this.searchedRecordsLength == 0){
      return 0
    }
    if(this.state.search?.length > 0 && this.searchedRecordsLength > 0){
      return this.searchedRecordsLength
    }
    return this.state.invoiceData?.length
  }
  

  // handleErrorForDate=()=>{
  //   if(this.state.modalToDate && this.state.modalFromDate){
  //     if(!moment(this.state.modalToDate).isAfter(this.state.modalFromDate)){
  //       this.setState({toDateError : true})
  //     }else{
  //       this.setState({toDateError:false})
  //     }
  //   }
  // }

  renderFilterPopup=(classes:any)=>{
    return  <Menu
    id="account-menu"
    anchorEl={this.state.filterOpen}
    open={Boolean(this.state.filterOpen)}
     onClose={this.handleFilterClose}
     getContentAnchorEl={null}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      className={classes?.menuStyle}
     
 >
    <Box style={{ padding: "10px" }}>
          <Box display={"flex"} justifyContent={"space-between"} alignItems={"baseline"}>
          <InputLabel className="inputfilter"> Filter</InputLabel>
            <Box onClick={this.handleReset} style={{ textDecoration: "underline", cursor :"pointer", paddingRight:"16px"}}>Clear Filter</Box>
          </Box>
          <MenuItem>
          <Grid xs={12} container>
            <Grid item xs={6} container style={{paddingRight : "10px"}}>
              <Grid item xs={12}>
                <InputLabel className="inputLabel">From Date</InputLabel>
              </Grid>
              <Grid item xs={12} >                 
                <MuiPickersUtilsProvider
                    utils={MomentUtils}
                  >
                    <ThemeProvider theme={materialTheme}>
                      <DatePicker
                       data-test-id="fromDatePickerID"
                        keyboard
                        variant="outlined"
                        size="small"
                        fullWidth
                        style={webStyle.text}
                        placeholder="DD/MM/YYYY"
                        format={"DD/MM/YYYY"}
                        mask={(value) =>
                          value
                            ? [
                                /\d/,
                                /\d/,
                                "/",
                                /\d/,
                                /\d/,
                                "/",
                                /\d/,
                                /\d/,
                                /\d/,
                                /\d/,
                              ]
                            : []
                        }
                        value={this.state.fromDate}
                        // onChange={(date)=>this.setState({fromDate:date, filterToDateError: false})}
                        onChange={(date)=>{
                          this.setState({fromDate:date},handleError)
                          function handleError(){
                            if(this.state.fromDate && this.state.toDate){
                              if(!moment(this.state.toDate).isSame(this.state.fromDate) && !moment(this.state.toDate).isAfter(this.state.fromDate)){
                                this.setState({filterToDateError: true})
                              }else{
                                this.setState({filterToDateError: false})
                              }
                              
                            }
                          }
                        }}
                        disableOpenOnEnter
                        animateYearScrolling={false}
                        autoOk={true}
                        clearable                           
                      />
                    
                    </ThemeProvider>
                  </MuiPickersUtilsProvider>
              </Grid>
              
            </Grid>
            <Grid item xs={6} container style={{paddingLeft : "10px"}}>
              <Grid item xs={12} >
                <InputLabel className="inputLabel">To Date</InputLabel>
              </Grid>
              <Grid item xs={12}>
              <MuiPickersUtilsProvider
                    utils={MomentUtils}
                  >
                    <ThemeProvider theme={materialTheme}>
                      <DatePicker
                       data-test-id="toDatePickerID"
                        keyboard
                        variant="outlined"
                        size="small"
                        fullWidth
                        style={webStyle.text}
                        placeholder="DD/MM/YYYY"
                        format={"DD/MM/YYYY"}
                        mask={(value) =>
                          value
                            ? [
                                /\d/,
                                /\d/,
                                "/",
                                /\d/,
                                /\d/,
                                "/",
                                /\d/,
                                /\d/,
                                /\d/,
                                /\d/,
                              ]
                            : []
                        }
                        value={this.state.toDate}
                        // onChange={(date)=>this.setState({toDate:date, filterToDateError: false})}
                        onChange={(date)=>{
                          this.setState({toDate:date},()=>{
                            if(this.state.fromDate && this.state.toDate){
                              if(!moment(this.state.toDate).isSame(this.state.fromDate) && !moment(this.state.toDate).isAfter(this.state.fromDate)){
                                this.setState({filterToDateError: true})
                              }else{
                                this.setState({filterToDateError: false})
                              }
                            }
                          })
                        }}
                        disableOpenOnEnter
                        animateYearScrolling={false}
                        autoOk={true}
                        clearable
                      />

                    </ThemeProvider>
                  </MuiPickersUtilsProvider>
              </Grid>
              
            </Grid>
          </Grid>
          

        </MenuItem>
        <Grid item xs={12} >
            
                  <Grid style={{paddingLeft : "20px"}}>
{this.state.filterToDateError && <Typography style={webStyle.errorText}>From Date can not be greater than To Date</Typography>}
                  </Grid>

                 
                
              </Grid>
       
          <div
          style={{
            padding: "10px 15px 0px",
            display: "flex",
            justifyContent: "flex-end",
            gap: "10px",
          }}
        >
          <Button
            style={{ backgroundColor: "grey" }}
            onClick={this.handleFilterClose}
            className="btn-cancelmenu"
          >
            Cancel
          </Button>
          <Button
            className="btn-addmenu"
             onClick={this.handleApply}
          >
            Apply
          </Button>
        </div>
        </Box>
  </Menu>
  }

  getFromToDateError=()=>{
    return this.state.modalToDate !== "" && this.state.toDateError && <Typography style={webStyle.errorText}>From Date can not be greater than To Date</Typography>
  }


  parseImg(img: string | null) {
    if (!img) return undefined;
    const imageLink = configJSONBase.baseURL;
    const imageFragment =
      typeof img === "string" && img.split("/").includes("rails");
    const imgSrc = imageFragment ? `${imageLink}/${img}` : img;
    return imgSrc;
  }

  getClientName=(item:any)=>{
    if(item?.attributes?.client_data?.client_name?.length > 26){
      return <Tooltip title={item?.attributes?.client_data?.client_name}>
      <Typography display="inline">
         {item?.attributes?.client_data?.client_name}
         </Typography>
         </Tooltip>
    }else{
      return item?.attributes?.client_data?.client_name
    }
  }

  getTableCells=(item:any)=>{
    return <>
    <TableCell className="tablecell tabledata" >{item?.slNo}</TableCell>
    <TableCell className="tablecell" style={{textOverflow:'ellipsis', maxWidth:"200", whiteSpace:'nowrap', overflow:'hidden'}}>
    {this.getClientName(item)}
       </TableCell>
    {/* <TableCell className="tablecell"> {item?.attributes?.client_data?.client_name}</TableCell> */}
    <TableCell className="tablecell"> {item?.attributes?.client_data?.client_id}</TableCell>
    <TableCell className="tablecell"> { item?.attributes?.from_date===null ?"NA" :moment(item?.attributes?.from_date).format("DD/MM/YYYY")}</TableCell>
    <TableCell className="tablecell"> { item?.attributes?.to_date===null ?"NA" :moment(item?.attributes?.to_date).format("DD/MM/YYYY")}</TableCell>
    <TableCell className="tablecell"> { item?.attributes?.overdue_date===null ?"NA" :moment(item?.attributes?.overdue_date).format("DD/MM/YYYY")}</TableCell>
   
    <TableCell className="tablecell tabledata">{item?.attributes?.invoice_status}</TableCell>
    <TableCell className="tablecell tabledata">{item?.attributes?.invoice_amount}</TableCell>
    </>
  }

  getFilterIcon=()=>{
    if(this.state.filterApplied){
      return <img src={darkFilter} style={{ width: 22, height: 22,marginRight:"15px", cursor :"pointer"}} onClick={this.handleFilterClick}/>
    }else{
      return <img src={filter} style={{ width: 30, height: 30,marginRight:"15px", cursor :"pointer"}} onClick={this.handleFilterClick}/>
    }
  }
                

  render() {
    const {classes} = this.props
    
    const filteredList = this.state.invoiceData

    return (
      <>
     
     {this.renderFilterPopup(classes)}
      
      
        <Box
          sx={{
            height:"100%",
            width: "85vw",
            top: "50px",
            right:0,
            boxSizing:"border-box",
            padding:"50px 20px 10px 20px",
            position: "absolute",
          }}
          style={{ backgroundColor:"#e8e8e8"}}
          justifyContent="space-between"
          className="maintablehead"
        >
          <Box sx={{ display: "flex" ,justifyContent:"space-between",alignItems:"center"}}>
            
            <div style={{display: "flex", alignItems: "center"}}> 
            <Box style={webStyle.arrowbox}>
                  <ArrowLeftIcon
                    onClick={this.handleBack}
                  />
                </Box>
            <Typography
              style={{
                fontFamily: "sans-serif",
                fontWeight: "bold",
                fontSize: 16,
              }}
            >
           {`${this.state.selectedInvoiceStatus} invoices`}
            </Typography>
            </div>
          
            <div
              style={{
                display: "flex",
                justifyContent: "end",
              }}
            >
              <div style={{display:"flex",alignItems:"center"}}>
                {this.getFilterIcon()}
              </div>

              <SearchBar
                placeholder="Search Invoices"
                  value={this.state.search}
                  onChange={this.requestSearch}
                  onCancelSearch={this.cancelSearch}
              />
              <div>
           
              </div>
            </div>
          </Box>
            { this.state.loading ? <Box className="progres">  <CircularProgress size={50}/> </Box> :
              <>
              <div className="tableuserdata">
               <Table aria-label="sticky table" className="usermanagement-table"  >
              <TableHead className="maintablehead">
                <TableRow>
                 <TableCell className="tableheader">S.no</TableCell>
                 <TableCell className="tableheader">Client Name</TableCell>
                 <TableCell className="tableheader">Client id</TableCell>
                 <TableCell className="tableheader">From Date</TableCell>
                 <TableCell className="tableheader"> To Date</TableCell>
                 <TableCell className="tableheader">Overdue Date</TableCell>
                 <TableCell className="tableheader">Invoice Status</TableCell>
                 <TableCell className="tableheader">Invoice Amount</TableCell>
                 <TableCell className="tableheader">Finulent Admin</TableCell>
                 <TableCell className="tableheader">Actions</TableCell>
              </TableRow>
              </TableHead>


              <TableBody>
                {this.state.invoiceData?.length >0  ?this.getFilteredData(filteredList).map((item:any,id:any) =>
                 {
                  return (
                    <TableRow key={item?.id}>
                      {this.getTableCells(item)}

      <TableCell className="tablecell  name-break1"> 
      
      <Box sx={{display : "flex", alignItems : "center", justifyContent:'center'}} >                     
           <Avatar className="smallSize" alt="Remy Sharp" src={item?.attributes?.finulent_admin[0]?.image ? this.parseImg(item.attributes.finulent_admin[0].image) : "/static/images/avatar/1.jpg"}/> 
            <Typography style={{paddingLeft : "8px"}}>{item?.attributes?.finulent_admin[0]?.first_name}{" "}{item?.attributes?.finulent_admin[0]?.last_name}</Typography>
        </Box>
      </TableCell>


      <TableCell className="tablecell"> 
      
      <Box sx={{display : "flex", gap : "10px", alignItems : "center", justifyContent:'center'}} > 
      <VisibilityOutlinedIcon style={{cursor:'pointer'}} onClick={()=>this.props.history.push(`/genericInvoiceStatus?id=${item?.id}`)}/>
      {/* <EditOutlinedIcon onClick={()=>this.props.history.push("/genricInvoiceClientSuperadmin?id="+item?.id+"&clientId="+this.query.get('clientId')+'&isEdit=true')}/> */}
      <EditOutlinedIcon
       style={{color : this.state.selectedInvoiceStatus !== "Draft" && this.state.selectedInvoiceStatus !== "Sent" && this.state.selectedInvoiceStatus !== "Approved By Client" && "darkgray", cursor:'pointer' }}
      onClick={()=>{
       if(this.state.selectedInvoiceStatus == "Draft" || this.state.selectedInvoiceStatus == "Sent" || this.state.selectedInvoiceStatus =="Approved By Client"){
         this.props.history.push(`/genericInvoiceStatus?id=${item?.id}&isEdit=true`)
          }
      }
        }/>

<ArrowDownwardOutlinedIcon onClick={()=>{
        this.downloadPDF(item?.attributes?.pdf);
      }}
      style={{cursor:'pointer'}}
      />

        </Box>
      </TableCell>
      </TableRow>
                  )
                }):null
                }
                  </TableBody>

            </Table>
            {this.getNoDataViewForSearch()}
            {this.getNoDataView()}
            </div>

        <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={this.getCount()}
        rowsPerPage={this.state.rowsPerPage}
        page={this.state.pageNo}
        onPageChange={this.handleChangePage}
        onRowsPerPageChange={this.handleChangeRowsPerPage}
      />
      </>
            }
        </Box>

      </>
      
    );
  }
}

//istanbul ignore next
const Placeholder:React.FunctionComponent = ({children}) => {
  const classes = usePlaceholderStyles();
  return <div className={classes.placeholder}>{children}</div>;
};

export default withStyles(useStyles)(PartiallyPaidInvoices)

const webStyle = {
  parent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "100%",
    height: "fit-content",
    position: "relative",
    backgroundRepeat: "no-repeat",
  },
  boxes: {
    marginTop: "10px",
  },
  arrowbox: {
    backgroundColor: "#fff",
    width: 35,
    height: 35,
    margin: 10,
    borderRadius: 8,
    alignItems: "center",
    justifyContent: "center",
    alignContent: "center",
    display: "flex",
  },
  modalbackdrop: {
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: "rgba(0, 0, 0, 0.4)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 2,
    alignContent: "center"
  },
  modalcontentsuccess: {
    width: "500px",
    height: "auto",
    fontFamily: "sans-serif",
    backgroundColor: "white",
    alignItems: "left",
    justifyContent: "left",
    margin: 20,
    overflowX: "scroll",
    overflowY: "scroll",
} as React.CSSProperties,
modalcontent: {
  fontSize: "16px",
  fontFamily: "sans-serif",
  fontWeight: 500,
  alignItems: "center",
  justifyContent: "center",
  alignContent: "center",
  alignSelf: "center",
  display: "flex"
},
addworkspacemodaldiv: {
  backgroundColor: "white",
  marginLeft: 0,
  marginright: 0,
},
headerBox: {
  borderBottom: '1px solid #e8e8e8',
  display: "flex",
  justifyContent: "space-between",
  width: 520,
} as React.CSSProperties,
cancelButton: {
  width: "100%",
  border: "1px solid #cecece",
  backgroundColor: "#e8e8e8",
  color: "black",
  fontWeight: 600,
  textTransform: "none",
}as React.CSSProperties,
addEditHeading: {
  marginLeft: 20,
},
closebuttonstyle: {
  marginTop: 20,
  marginRight: 25,
},
addworkspacemaincontentdiv: {
  backgroundColor: "white",
  marginBottom: 10,
},
addworkspacedivcontent: {
  display: "flex",
  flexDirection: "column",
  marginLeft: 20,
  marginRight: 20,
} as React.CSSProperties,
addworkspaceelements: {
  marginBottom: 20
},
modaltextboxheader: {
  color: "#7a7a7a"
},
textfieldDisabled: {
  backgroundColor: "#e8e8e8"
},
errorText: {color:"#f44336", fontSize:15, marginTop:5},

};
