Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.apiContentType = "application/json"
exports.endPoint = "notifications/notifications";
exports.getDataMethod = "GET";
exports.markAsReadMethod = "PUT";
exports.deleteMethod = "DELETE";
exports.markAllasReadMethod = "POST";

exports.notifications = "Notifications";
exports.deleteText = "DELETE"
exports.okText = "OK"
exports.deleteMessage = "Notifications deleted!"

exports.getAllNotficationEndPoint = "bx_block_push_notifications/push_notifications";  
exports.markAllasReadApiEndPoint = "bx_block_push_notifications/push_notifications/:id/mark_as_read_all";
// Customizable Area End