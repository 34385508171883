// Customizable Area Start
import React from "react";
import {
  Button,
  Typography,
  Modal,
  Divider,
} from "@material-ui/core";
import "./style.css";
const redIcon = require("../assets/redicon.png");
import UploadErrorModalController, { Props } from "./UploadErrorModalController";

export default class UploadErrorModal extends UploadErrorModalController {
  constructor(props: Props) {
    super(props);
  }
  render() {
    const { isOpen, handleClose, handleOkClick, errorData } = this.props;
    return (
      <Modal
        open={isOpen}
        onClose={() => handleClose()}
        aria-labelledby="modal-workspace-title"
        aria-describedby="modal-workspace-description"
        style={webStyle.modalRoot}
        BackdropProps={{
          style: webStyle.modalBackdropProps,
        }}
      >
        <div className="error-container">
          <img src={redIcon} alt="Icon" className="error-icon" />
          <br />
          <Typography className="error-param-header">
            Can't perform action!
          </Typography>
          <Typography className="duplicate-header">
            Errors
          </Typography>
          <Divider
            style={{
              marginTop: "10px",
              width: "223px",
              height: "1px",
              border: "1px dashed #dbdbdb",
            }}
          />
          <div className="sequence-bodys-list">
            {errorData?.map((item: any, index: number) => {
              return (
                <div test-id="error-item" key={index} className="duplicate-param">
                  <Typography className="duplicate-param-two">
                    {item}
                  </Typography>
                </div>
              );
            })}
          </div>
          <div>
            <Button
              test-id="ok-button"
              disableElevation
              variant="contained"
              style={localStorage.getItem('user_type') == 'Superadmin' ? webStyle.duplicatebtn : webStyle.duplicateadminbtn}
              onClick={() => handleOkClick()}
            >
              <span>Ok</span>
            </Button>
          </div>
        </div>
      </Modal>
    );
  }
}

const webStyle = {
  modalRoot: {
    display: "flex",
    gap: 1,
    fontSize: "16px",
    justifyContent: "center",
    borderBlockStart: "1px solid #ccc",
    paddingBlock: "2rem", //
    backdropFilter: "blur(5px)",
    overflow: "scroll",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    alignItems: "center",
  } as React.CSSProperties,
  modalBackdropProps: {
    backgroundColor: "rgba(0, 0, 0, .7)",
    backdropFilter: "blur(10px)",
  } as React.CSSProperties,
  modalButton: {
    marginTop: "20px",
    backgroundColor: "#4eabf8",
    color: "white",
    borderRadius: "4px",
    width: "263px",
    textTransform: "none",
    height: "50px",
    fontWeight: 800,
  } as React.CSSProperties,
  duplicatebtn: {
    marginTop: "20px",
    color: "#ffffff",
    fontSize: "16px",
    fontWeight: 600,
    textAlign: "center",
    width: "263px",
    height: "50px",
    borderRadius: "4px",
    background: "#4eabf8",
    textTransform: 'none'
  } as React.CSSProperties,
  duplicateadminbtn: {
    marginTop: "20px",
    color: "black",
    fontSize: "16px",
    fontWeight: 600,
    textAlign: "center",
    backgroundImage: "radial-gradient(rgb(246 168 34), rgb(171 114 24))",
    width: "263px",
    height: "50px",
    borderRadius: "4px",
    textTransform: 'none'
  } as React.CSSProperties,
}
// Customizable Area End
