// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { projectCarouselData, status } from "../../../components/src/types";

export const configJSON = require("../../../framework/src/config");

//sizes for carousel
export const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    lilLargerDesktop: {
      breakpoint: { max: 3000, min: 2000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 2000, min: 1350 },
      items: 3.5,
    },
    inBetweenDesktop: {
      breakpoint: { max: 1350, min: 1250 },
      items: 3.2,
    },
    lilSmallDesktop: {
      breakpoint: { max: 1250, min: 1150 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1150, min: 850 },
      items: 2.2,
    },
    mobile: {
      breakpoint: { max: 850, min: 770 },
      items: 2,
    },
    sm1more: {
      breakpoint: { max: 770, min: 590 },
      items: 1.5,
    },
    small: {
      breakpoint: { max: 590, min: 0 },
      items: 1,
    },
  };
  

export interface PCProps {
    data:projectCarouselData,
    projectStatus:keyof status
}

interface PCS {
    ActiveKeyBoardControl: boolean,
    currentSlide: number, 
    prevSlide: number, 
    deviceType : keyof typeof responsive
}

interface PCSS {
  id: {}
}

export default class KanbanBoardController extends BlockComponent<
  PCProps,
  PCS,
  PCSS
> {
  constructor(props: PCProps) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
        getName(MessageEnum.AccoutLoginSuccess),
    ];

    this.state = {
        ActiveKeyBoardControl: false,
        currentSlide: 0,
        prevSlide: 0,
        deviceType: "desktop"
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  activeKeyBoardControlToggleTrue = () =>{
    this.setState({
        ActiveKeyBoardControl: true
    });
  }
  activeKeyBoardControlToggleFalse = () =>{
    this.setState({
        ActiveKeyBoardControl: false
    });
  }
  beforeChangeHandler = (nextSlide: number,element:string | undefined = "desktop" ) =>{
    this.setState({
        currentSlide: nextSlide,
        deviceType: element as keyof typeof responsive
    });
  }
  afterChangeHandler = (prevSlide: number ) =>{
    this.setState({
        prevSlide: prevSlide
    })
  } 
}
// Customizable Area End