// Customizable Area Start
import React from "react";
import { BlockComponent } from "../../framework/src/BlockComponent";
import {
  Box,
  Divider,
  Link,
  CircularProgress
} from "@material-ui/core";

interface Props {
  drawerList: any;
  isMegaMenuLoading:any;
}

interface S {
  drawerList: []
}

interface SS {
}

export default class MegaMenu extends BlockComponent<Props, S, SS> {

  storeClientBreadCrumbs = (client: any) => {
    const { attributes: { client_management } } = client;
    const { client_id, team_leaders, template, workspace, client_name } = client_management;
    sessionStorage.setItem("client_id", client_id);
    sessionStorage.removeItem("clientFilter")
    if (team_leaders?.length) {
      const breadcrumb_data = {
        workspace: { id: workspace?.id, name: workspace?.name },
        client: { id: client_id, name: client_name },
        template_name: template?.title
      }
      localStorage.setItem('breadcrumb_data', JSON.stringify(breadcrumb_data))
    }
  }

  handleClientClickSidebarRoute = (client: any) => {
    const { attributes: { client_management } } = client;
    const { client_id, team_leaders, template } = client_management;
    if (team_leaders?.length) {
      if (template?.id) return `/reviewChecklist?cid=${client_id}&tid=${template?.id}`
      else return `/template?cid=${client_id}`
    }
     else return localStorage.getItem('role_id') === "Admin" ? `/clientsubfolders?cid=${client_id}` : `/client_subfolder?cid=${client_id}`;
  }

  storeSubfolderBreadCrumbs = (client: any, subFolder: any) => {
    const { attributes: { client_management } } = client;
    const { client_id, client_name } = client_management;
    const { id: subFolderId, team_leaders, team_title, template, workspace } = subFolder
    sessionStorage.setItem("client_id", client_id);
    sessionStorage.removeItem("clientFilter")
    if (team_leaders?.length) {
      const breadcrumb_data = {
        workspace: { id: workspace?.id, name: workspace?.name },
        client: { id: client_id, name: client_name },
        subfolder: { id: subFolderId, name: team_title },
        template_name: template?.title
      }
      localStorage.setItem('breadcrumb_data', JSON.stringify(breadcrumb_data))
    }
  }

  handleSubClientClickSidebarRoute = (client: any, subFolder: any) => {
    const { attributes: { client_management } } = client;
    const { client_id } = client_management;
    const { id: subFolderId, template } = subFolder
    if (template?.id) return `/reviewChecklist?cid=${client_id}&sfid=${subFolderId}&tid=${template?.id}`;
    else return `/template?cid=${client_id}&sfid=${subFolderId}`;
  }

  async componentDidMount() {
    this.setState({ drawerList: this.props.drawerList })
  }

  async componentWillReceiveProps(nextProps: any) {
    if (nextProps?.drawerList !== this.state.drawerList) {
      this.setState({ drawerList: nextProps.drawerList })
    }
  }

  render() {

    return (
      <Box>
        {this.props.drawerList.length ? this.props.drawerList?.map((client: any) => (
          <>
            <p onClick={() => this.storeClientBreadCrumbs(client)} style={{ marginLeft: "10", cursor: 'pointer' }}>
              <Link href={this.handleClientClickSidebarRoute(client)} style={{ color: "black", fontWeight: "bold", fontSize: 15, fontFamily: "sans-serif", textDecoration: "none" }}>
                {client?.attributes?.client_management?.client_name}
              </Link>
            </p>
            {client.attributes.client_subfolders.map((subFolder: any, index: number) => (
              <p onClick={() => this.storeSubfolderBreadCrumbs(client, subFolder)} key={index} style={{ marginLeft: "10" }}>
                <Link href={this.handleSubClientClickSidebarRoute(client, subFolder)} style={{ color: "#969696", fontSize: 14.5, fontFamily: "sans-serif", textDecoration: "none" }}>
                  {subFolder?.team_title}
                </Link>
              </p>
            ))}
            <Box marginY={"15px"}>
              <Divider />
            </Box>
          </>
        )) :  <Box fontSize={"22px"} height={"100%"} display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"}>{!this.props.isMegaMenuLoading ?<Box>No Client Found</Box> : <Box><CircularProgress/></Box>}</Box>}
      </Box>
    );
  }
}

// Customizable Area End