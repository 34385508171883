Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "ProjectTaskTracking";
exports.labelBodyText = "ProjectTaskTracking Body";

exports.btnExampleTitle = "CLICK ME";
exports.allProjectApi  = "/bx_block_taskallocator/kanbanboard";
exports.projectDetailById = "bx_block_taskallocator/kanbanboard/"
exports.projectsForManager = "URL_HERE?"
exports.contentType = "application/json";
exports.typeForGetMethod = "GET";
exports.allProjectColorApi = "/bx_block_taskallocator/get_status_code";
exports.allClientDropDownApi = "/bx_block_taskallocator/client_dropdown";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "KanbanBoard";
exports.labelBodyText = "KanbanBoard Body";
exports.commentEndPoint = "bx_block_comments/comments"
exports.dropDownOptionsEndPoint = "qa_qc_list"
exports.designerDropDownOptionsEndPoint = "account_filter?activation_status=true&role_id[]=Designer/QC/QA&workspace_id="
exports.updateProjectDetailsEndPoint = "bx_block_dashboard/template_field_data"
exports.typeDropDownEndPoint = "bx_block_dashboard/type_of_project"
exports.uploadAttachmentToProjectEndPoint = "bx_block_taskallocator/upload_attachment"
exports.deleteAttachmentEndPoint = "bx_block_taskallocator/delete_attachment"
exports.FinulentStatusChoicesEndPoint = "bx_block_taskallocator/get_status_data/"
exports.getCheckListEndPoint = "bx_block_dashboard/project_checklist?template_field_data_id="
exports.updateCheckListEndPoint = "bx_block_dashboard/project_checklist/"
exports.MentionDataEndPoint = "bx_block_taskallocator/mention_user?search="
exports.getProjectCodeDropDownEndPoint = "bx_block_dashboard/project_code?workspace_id="
exports.updateCodeEndPoint = "bx_block_dashboard/project_code"
exports.uploadFileCommentEndPoint = "bx_block_comments/comments/upload_file"
exports.ahjDropDownEndPoint = "bx_block_download/auto_suggestion/all_suggestion?suggestion_type="
exports.uploadFileCommentEndPoint = "bx_block_comments/comments/upload_file"

exports.btnExampleTitle = "CLICK ME";
// Customizable Area End