import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { date } from "yup";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start

  signUpData: any;
  userData: any;
  token: string;
  errorMsg: string;
  loading: boolean;
  password: any;
  firstname: any;
  lastname: any;
  isErr: boolean;
  isValid: boolean;
  modalOpen: boolean;
  checkedB: boolean;
  isErrorPassword: boolean;
  initialPageLoad: boolean;
  page: boolean;
  birthDate: any;
  joiningDate: any;
  email: string;
  contactNo: string;
  confirmpwd: string;
  // success:boolean,
  isSuccessModal: boolean;
  open: boolean;
  peopleOpen: boolean;
  genericModalOpen: boolean;
  fileData: any;
  genericModalClose: boolean;
  designation: string;
  role: string;
  desc: string;
  imageName: string;
  workspaceData: any;
  usersData: any;
  workspace: string;
  isErrorFirstName: boolean;
  isErrorLastName: boolean;
  isErrorEmail: boolean;
  isMenu: null | HTMLElement;
  isSubMenu: null | HTMLElement;
  pageNo: any;
  rowsPerPage: number;
  roleData: any,
  isEditMode: boolean;
  userId: "";
  modalMsg: string;
  handleAccept: boolean;
  filterBy: string;
  approvedData: any;
  rejectedData: any;
  rejectedId: any;
  isLoader: boolean;
  isConfirm: boolean;
  search: string;
  designationList: any;
  isLoadedTrue: boolean;

  // Customizable Area End
}
interface SS {
  id: any;
}

export default class UserRequestController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiDashboardItemCallId: string = "";
  dashboardApiCallId: string = "";
  apiGetQueryStrinurl: string = "";
  signUpAPICallId: string = "";

  workspaceApiCallId: string = "";
  usersApiCallId: string = "";
  roleApiCallId: string = "";
  updateUserApiCallId: string = "";
  approvedUserApiCallId: string = "";
  rejectedAPICallId: string = "";
  designationApiCallId: string = "";
  userrequestList: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {

      roleData: [],
      userData: [],
      signUpData: [],
      errorMsg: "",
      token: "",
      password: "",
      isErr: false,
      isValid: false,
      firstname: "",
      lastname: "",
      loading: false,
      modalOpen: false,
      checkedB: false,
      isErrorPassword: false,
      initialPageLoad: true,
      page: true,
      birthDate: null,
      email: "",
      contactNo: "",
      confirmpwd: "",
      isSuccessModal: false,
      open: false,
      peopleOpen: false,
      genericModalOpen: false,
      genericModalClose: false,
      fileData: "",
      designation: "",
      role: "",
      desc: "",
      imageName: "",
      workspaceData: [],
      workspace: "",
      usersData: [],
      isErrorFirstName: false,
      isErrorLastName: false,
      isErrorEmail: false,
      isMenu: null,
      isSubMenu: null,
      pageNo: 0,
      rowsPerPage: 10,
      isEditMode: false,
      userId: "",
      modalMsg: "",
      handleAccept: false,
      filterBy: "Pending",
      approvedData: [],
      joiningDate: null,
      rejectedData: [],
      rejectedId: "",
      isLoader: true,
      isConfirm: false,
      search: "",
      designationList: [],
      isLoadedTrue: false,

      // success:true
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.setState({ isLoader: true })
    console.log(this.props, "USER PROPS");
    this.getToken();
    
    this.getUserList()
    this.getWorkspaces();
    this.getRoles();
    this.getDesignations()
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // this.setState({isLoader:false})
    // Customizable Area Start
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };


  getRoles(): boolean {
    // Customizable Area Start
    const header = {
      'token': window.localStorage.getItem('token'),
      // token:
      //   "eyJhbGciOiJIUzUxMiJ9.eyJpZCI6MTA5LCJleHAiOjE2NjAzOTc5MTMsInRva2VuX3R5cGUiOiJsb2dpbiJ9.UVZRsL6ASo-02xh4SQDEsHs64R9suItplATxMRwLWDbueZatJqkQgSAxKpvnJcMYnh_VTrc8jmAXYgA1btLGlQ",
      "Content-Type": 'application/JSON',
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.roleApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "all_role"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
      //   configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
    return true;
  }

  getWorkspaces(): boolean {
    // Customizable Area Start
    const header = {
      'token': window.localStorage.getItem('token'),
      // token:
      //   "eyJhbGciOiJIUzUxMiJ9.eyJpZCI6MjI5LCJleHAiOjE2NjE0MjYwODQsInRva2VuX3R5cGUiOiJsb2dpbiJ9.u7OE-btsPGjMyreXXDbMhjzCdMSMdM0AxjQdZuH3bB2zz3bAQS5_e1iqDVecL5eL28o1eKjXRwVKmREYrZZeGw",
      "Content-Type": "application/JSON",
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.workspaceApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_workspace_management/workspaces"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
      //   configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
    return true;
  }

  handleRejectAction = (id: any) => {

    const attrs = {

      "account_id": id,
      "status": "Rejected"

    }

    const header = {
      'token': window.localStorage.getItem('token'),
      "Content-Type": 'application/JSON',
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.rejectedAPICallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `update_status`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(attrs)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'PUT'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true
  }
  getDesignations(): boolean {
    // Customizable Area Start
    const header = {
      'token': window.localStorage.getItem('token'),
      // token:
      //   "eyJhbGciOiJIUzUxMiJ9.eyJpZCI6MTA5LCJleHAiOjE2NjAzOTc5MTMsInRva2VuX3R5cGUiOiJsb2dpbiJ9.UVZRsL6ASo-02xh4SQDEsHs64R9suItplATxMRwLWDbueZatJqkQgSAxKpvnJcMYnh_VTrc8jmAXYgA1btLGlQ",
      "Content-Type": "application/JSON",
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.designationApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_favourites/designer/all_designation"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
      //   configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
    return true;
  }

  updateUser = () => {
    console.log("api call started *************");


    var formData = new FormData();

    formData.append("designation", this.state.designation);

    formData.append("role_id", this.state.role);
    formData.append("joining_date", this.state.joiningDate)
    formData.append("workspace_id", this.state.workspace)
    formData.append("status", "Approved")


    const header = {
      'token': window.localStorage.getItem('token'),
      // "Content-Type": 'application/JSON',
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateUserApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `update_account/${this.state.userId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  getUserList(): boolean {
    // Customizable Area Start
    const header = {
      'token': window.localStorage.getItem('token'),
      "Content-Type": 'application/JSON',
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.userrequestList = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `get_status_data?status=${this.state.filterBy}&search=${this.state.search}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
    return true;
  }
  workspaceApiCallIdData = (responseJson: any) => {
    console.log(responseJson,"joool")
    if (responseJson.data.length === 0) {
      this.setState({
        errorMsg: "Data Not Found",
        loading: false,
      });
    } else {
      this.setState({
        workspaceData: responseJson.data,
        errorMsg: "",
        loading: false,
      });
    }
  }
  usersApiCallIdData = (responseJson: any) => {
    if (responseJson.data.length === 0 ) {
      this.setState({
        errorMsg: "Data Not Found",
        loading: false,
      });
    } else {
      const apiData = responseJson.data.map((item: any, index: any) => {
        item.slNo = index + 1;
        return item;
      });

      this.setState({
        usersData: apiData,
        isLoader: false,
        isLoadedTrue: true,
        errorMsg: "",
        loading: false,
      });

    }
  }
  roleApiCallIdData = (responseJson: any) => {
    if (responseJson.length === 0) {
      this.setState({
        errorMsg: "Data Not Found",
        loading: false,
      });
    } else {

      this.setState({
        roleData: responseJson,
        errorMsg: "",
        loading: false,
      });
    }
  }
  updateUserApiCallIdData = (responseJson: any) => {
    if (responseJson.data.length === 0) {
      this.setState({
        errorMsg: "Data Not Found",
        loading: false,
      });
    } else {
      const apiData = responseJson.data;
      apiData.slNo = this.state.usersData.length + 1;
      const rowData = this.state.usersData.filter((item: any) => item.id != this.state.userId)
      this.setState(
        {
          userData: responseJson.data.attributes,
          usersData: rowData,
          modalMsg: "Updated Successfully!",
          isSuccessModal: true,
          errorMsg: "",
          loading: false,
        },
      );
    }
  }

  rejectedAPICallIdData = (responseJson: any) => {
    if (responseJson.data.length === 0) {
      this.setState({
        errorMsg: "Data Not Found",
        loading: false,
      });
    } else {
      const rowData = this.state.usersData.filter((item: any) => item.id != this.state.rejectedId)
      this.setState(
        {
          rejectedData: responseJson,
          modalMsg: "Rejected Successfully!",
          isSuccessModal: true,
          usersData: rowData,
          errorMsg: "",
          loading: false,

        },

      );
    }
  }
  designationApiCallIdData = (responseJson: any) => {
    console.log(responseJson,"kllllo")
    if (responseJson.length === 0) {
      this.setState({
        errorMsg: "Data Not Found",
        loading: false,
      });
    } else {
      console.log(responseJson,"kanya")
      this.setState({
        designationList: responseJson,
        errorMsg: "",
        loading: false,
      });
    }
  }
  userrequestListData = (responseJson: any) => {
    if (responseJson?.data?.length === 0 || responseJson.message) {
      console.log("userrequestListData if")
      this.setState({
        errorMsg: "Data Not Found",
        loading: false,
        usersData: [],
        isLoader:false
      });
    } else {
console.log("userrequestListData else")
      const apiData = responseJson?.data?.map((item: any, index: any) => {
        item.slNo = index + 1;
        return item;
      });
      this.setState({
        usersData: apiData,
        errorMsg: "",
        loading: false,
        isLoader:false,
        isLoadedTrue:true,
      });
    }
  }

  workspaceApiCallIdError = (responseJson: any,message:any) => {
    const errorReponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );

    if (errorReponse === undefined) {
      this.setState({
        errorMsg: "Something went wrong",
        loading: false,
      });
    } else {
      this.setState({
        errorMsg: errorReponse,
        loading: false,
      });
    }
  }

  usersApiCallIdError = (responseJson: any,message:any) => {
    const errorReponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );

    if (errorReponse === undefined) {
      this.setState({
        errorMsg: "Something went wrong",
        loading: false,
      });
    } else {
      this.setState({
        errorMsg: errorReponse,
        loading: false,
      });
    }
  }
  roleApiCallIdError = (responseJson: any,message:any) => {
    const errorReponse = message.getData(
            getName(MessageEnum.RestAPIResponceErrorMessage)
          );

      if (errorReponse === undefined) {
        this.setState({
          errorMsg: "Something went wrong",
          loading: false,
        });
      } else {
        this.setState({
          errorMsg: errorReponse,
          loading: false,
        });
      }
  }
  updateUserApiCallIdError = (responseJson: any,message:any) => {
    var errorReponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
    if (errorReponse === undefined) {
      this.setState({
        errorMsg: "Something went wrong",
        loading: false,
      });
    } else {
      this.setState({
        errorMsg: errorReponse,
        loading: false,
      });
    }
  }


  designationApiCallIdError = (responseJson: any,message:any) => {
    const errorReponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );

    if (errorReponse === undefined) {
      this.setState({
        errorMsg: "Something went wrong",
        loading: false,
      });
    } else {
      this.setState({
        errorMsg: errorReponse,
        loading: false,
      });
    }
  }
  rejectedAPICallIdError = (responseJson: any,message:any) => {
    var errorReponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
    if (errorReponse === undefined) {
      this.setState({
        errorMsg: "Something went wrong",
        loading: false,
      });
    } else {
      this.setState({
        errorMsg: errorReponse,
        loading: false,
      });
    }

  }
  userrequestListError = (responseJson: any,message:any) => {
    const errorReponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );

    if (errorReponse === undefined) {
      this.setState({
        errorMsg: "Something went wrong",
        loading: false,
      });
    } else {
      this.setState({
        errorMsg: errorReponse,
        loading: false,
      });
    }
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson && !responseJson.errors ) {
        switch (apiRequestCallId) {

          case this.workspaceApiCallId:
            return this.workspaceApiCallIdData(responseJson)

          case this.usersApiCallId:
            return this.usersApiCallIdData(responseJson)

          case this.roleApiCallId:
            return this.roleApiCallIdData(responseJson)

          case this.updateUserApiCallId:
            return this.updateUserApiCallIdData(responseJson)

          case this.rejectedAPICallId:
            return this.rejectedAPICallIdData(responseJson)

          case this.designationApiCallId:
            return this.designationApiCallIdData(responseJson)

          case this.userrequestList:
            return this.userrequestListData(responseJson)



        }
      } else {
        switch (apiRequestCallId) {
          case this.workspaceApiCallId:
            return this.workspaceApiCallIdError(responseJson,message)

          case this.usersApiCallId:
            return this.usersApiCallIdError(responseJson,message)

          case this.roleApiCallId:
            return this.roleApiCallIdError(responseJson,message)

          case this.updateUserApiCallId:
            return this.updateUserApiCallIdError(responseJson,message)

          case this.rejectedAPICallId:
            return this.rejectedAPICallIdError(responseJson,message)

          case this.designationApiCallId:
            return this.designationApiCallIdError(responseJson,message)

          case this.userrequestList:
            return this.userrequestListError(responseJson,message)

        }
      }

    }
    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End
}
