// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

export const configJSONBase = require("../../../framework/src/config");
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

toast.configure();

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  that?: any;
  history?: any;
  match?: any;
}
interface S {
  breadCrumbs: any;
}
interface SS {
  id: any
}
export default class TemplateBreadcrumbsController extends BlockComponent<Props, S, SS>{
  query: any = new URLSearchParams(this.props.history?.location?.search);

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    this.state = {
      breadCrumbs: {}
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount(): Promise<void> {
    const data = localStorage.getItem('breadcrumb_data');
    if(data && data != "" && typeof data === 'string') this.setState({breadCrumbs: JSON.parse(data)})
  }

  handleBackForProjectListPage = () => {
    if (localStorage.getItem('user_type') == 'TL/Manager') {
      return this.props.history.push('clientmanage')
    }
    else {
      if (this.query.get('tid') && this.query.get('cid') && this.query.get('sfid')) return this.props.history.push(`/reviewChecklist?cid=${this.query.get('cid')}&sfid=${this.query.get('sfid')}&tid=${this.query.get('tid')}`);
      else if (this.query.get('tid') && this.query.get('cid')) return this.props.history.push(`/reviewChecklist?cid=${this.query.get('cid')}&tid=${this.query.get('tid')}`);
    }
  }

  handleBackForReviewChecklistPage = () => {
    if (this.query.get('sfid')) {
      if (localStorage?.getItem('user_type') == 'Superadmin') this.props.history.push(`/client_subfolder?cid=${this.query.get('cid')}`);
      else if (localStorage?.getItem('user_type') == 'Admin') this.props.history.push(`/clientsubfolders?cid=${this.query.get('cid')}`);
    }
    else {
      if (localStorage?.getItem('user_type') == 'Superadmin') return this.props.history.push(`/clients`, { state: "appliedFilter" });
      else if (localStorage?.getItem('user_type') == 'Admin') return this.props.history.push(`/clientmanagement`, { state: "appliedFilter" });
    }
  }

  handleBack = () => {
    if (window?.location?.pathname?.includes('projectlist')) {
      this.handleBackForProjectListPage()
    }
    else if (window?.location?.pathname?.includes('reviewChecklist')) {
      this.handleBackForReviewChecklistPage()
    }
    else return window?.history?.back()
  };

  handleWorkspaceClick = () => {
    if (localStorage?.getItem('user_type') == 'Superadmin') this.props.history.push(`/clients`, { state: "appliedFilter" });
    else if (localStorage?.getItem('user_type') == 'Admin') this.props.history.push(`/clientmanagement`, { state: "appliedFilter" });
    else if (localStorage.getItem('user_type') == 'TL/Manager') return this.props.history.push(`/clientmanage`);
  }

  redirectToReviewChecklist = () => {
    if(!window?.location?.pathname?.includes('templateDetails') && this.query.get('tid') && this.query.get('cid')){
      return this.props.history.push(`/reviewChecklist?cid=${this.query.get('cid')}&tid=${this.query.get('tid')}`);
    }
  }

  redirectToClient = () => {
    if (localStorage?.getItem('user_type') == 'Superadmin') this.props.history.push(`/client_subfolder?cid=${this.query.get('cid')}`);
    else if (localStorage?.getItem('user_type') == 'Admin') this.props.history.push(`/clientsubfolders?cid=${this.query.get('cid')}`);
  }

  handleClientClick = () => {
    if (localStorage.getItem('user_type') == 'TL/Manager') return this.props.history.push(`/clientmanage`);
    else {
      const { breadCrumbs } = this.state;
      sessionStorage.setItem("client_id", this.query.get('cid'));
      if (!breadCrumbs?.subfolder?.name) {
        // ? if no subfolder (only client case).
      return this.redirectToReviewChecklist();
      }
      else {
        // ? if client and subfolder both case.
        return this.redirectToClient();
      }
    }
  }

  handleSFClick = () => {
    if (localStorage.getItem('user_type') == 'TL/Manager') return this.props.history.push(`/clientmanage`);
    else if (!window?.location?.pathname?.includes('templateDetails') && (localStorage?.getItem('user_type') == 'Superadmin' || localStorage?.getItem('user_type') == 'Admin')) {
      if (this.query.get('tid') && this.query.get('cid') && this.query.get('sfid')) return this.props.history.push(`/reviewChecklist?cid=${this.query.get('cid')}&sfid=${this.query.get('sfid')}&tid=${this.query.get('tid')}`);
    }
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));
  }
}
// Customizable Area End