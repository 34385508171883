// Customizable Area Start
import React from "react";
import {
  Box,
  Button,
  Typography,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Modal,
  IconButton,
  Checkbox,
  Grid,
  Divider,
} from "@material-ui/core";
import "./style.css";
import { Close } from "@material-ui/icons";
import CloseIcon from "@material-ui/icons/Close";

import DeleteFieldController, {
  Props,
} from "./DeleteFieldController";
export default class DeleteFieldModal extends DeleteFieldController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <>
        <Button
          data-test-id="delete-field-button"
          disableElevation
          onClick={() => this.setState({ openDeleteField: true })}
          style={webStyle.secondrowbutton}>
          Delete field
        </Button>
        <Modal
            open={this.state.openDeleteField}
            onClose={this.handleButtonCancel}
            aria-labelledby="modal-workspace-title"
            aria-describedby="modal-workspace-description"
            style={webStyle.modalRoot}
            disableScrollLock={true}
            BackdropProps={{
              style: webStyle.modalBackdropProps,
            }}
          >
            <Card variant="outlined" style={webStyle.modalCardRoot}>
              <CardHeader
                className="fdkf"
                style={webStyle.modalCardHeader}
                disableTypography={true}
                action={
                  <IconButton
                    aria-label="close"
                    onClick={this.handleButtonCancel}
                  >
                    <Close />
                  </IconButton>
                }
                title={
                  <Typography style={webStyle.modalCardHeaderText}>
                    Delete field
                  </Typography>
                }
              />

              <CardContent style={webStyle.sequenceModalCardContent}>
                <div className="seq-table">
                  <div className="sequence-header">
                    <div className="sequence-header-row">
                      <p className="seq-title-text">Sequence</p>
                      <p className="field-title-text">Field</p>
                    </div>
                  </div>

                  <div className="sequence-body-list">
                    {this.state?.fieldsData?.map((data: any) => {

                      if (data.custom_field === false) {
                        return (
                          <div
                            className="sequence-item-freeze"
                            key={data.sequence_no}
                          >
                            <div className="seq-left-con">
                              <p className="seq-title-text1">
                                {data.sequence_no}
                              </p>
                            </div>
                            <div className="seq-right-con">
                              <p className="field-title-text1">
                                {data.field_name}
                              </p>
                            </div>
                          </div>
                        );
                      }
                      return (
                        <div
                          className={`sequence-item ${
                            this.state.selectedDelField ==
                            data.field_name
                              ? "active-border"
                              : ""
                          }`}
                          key={data.sequence_no}
                        >
                          <div className="seq-left-con">
                            <div className="seq-title-text1">
                              <Checkbox
                              data-test-id="custom-field"
                              style={{marginLeft: '-40'}}
                                checked={this.isChecked(
                                  this.state.selectedDelField,
                                  data.field_name
                                )}
                                value={data.field_name}
                                onChange={(e) => this.handleDeleteCheckboxChange(e, data) }
                                inputProps={{ "aria-label": "controlled" }}

                              />
                                    {data.sequence_no}
                            </div>
                          </div>
                          <div className="seq-right-con">
                            <p className="field-title-text1">
                              {data.field_name}
                            </p>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </CardContent>

              <CardActions style={webStyle.modalCardActions}>
                <Box style={webStyle.modalCardActionsContainer}>
                <Button
                    test-id="cancel-button"
                    disableElevation
                    variant="contained"
                    style={webStyle.modalCardCancelButton}
                    onClick={
                        this.handleButtonCancel
                    }
                >
                    Cancel
                </Button>
                
                <Button
                  test-id="delete-button"
                  disableElevation
                  disabled={!this.state.selectedDelField?.length}
                  variant="contained"
                  style={webStyle.modalCardActionButton}
                  onClick={() => this.setState({ deleteConfirmationModal: true })}
                  >
                    Delete
                  </Button>
                </Box>
              </CardActions>
            </Card>
          </Modal>
        <Modal
          className="modal-backdrop"
          open={this.state.deleteConfirmationModal}
          style={{
            overflow: "auto",
          }}
        >
          <div
            style={{
              zIndex: 10,
              position: "absolute",
              fontFamily: 'sans-serif',
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              background: "white",
              width: "25%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: "20px",
              borderRadius: 5
            }}
          >
            <Grid>
              <div style={{ textAlign: "center" }}>
                <div className="confirm-heading" style={{ margin: "20 30" }} >
                  <Typography style={{ fontWeight: 600 }}>Do you want to continue?</Typography>
                  <CloseIcon style={{cursor: 'pointer'}} onClick={() => this.handleCloseConfirmation()} />
                </div>
                <Divider style={{width: '100%'}}/>
                <div style={{ margin: "20 30", color: 'grey' }} className="confirmbody">
                  <Typography>All selected fields will be deleted permanently!</Typography>
                </div>
                <Divider style={{width: '100%'}}/>
                <div style={{display: "flex", justifyContent: "end", margin: "20 30"}}>
                  <Button
                    test-id="cancel-confirm-button"
                    onClick={() => this.handleCloseConfirmation()}
                    style={webStyle.cancelCalenderBtn}
                  >
                    Cancel
                  </Button>
                  <Button
                    test-id="confirm-button"
                    component="span"
                    onClick={() => this.handleConfirm()}
                    disabled={!this.state.selectedDelField?.length}
                    style={localStorage.getItem('user_type') == 'Superadmin' ? webStyle.addpopbtn : webStyle.adminaddpopbtn}
                    >
                    <span>Confirm</span>
                  </Button>
                </div>
              </div>
            </Grid>
          </div>
        </Modal>
      </>
    );
  }
}
const webStyle = {
  modalRoot: {
    display: "flex",
    gap: 1,
    fontSize: "16px",
    justifyContent: "center",
    borderBlockStart: "1px solid #ccc",
    paddingBlock: "2rem", //
    backdropFilter: "blur(5px)",
    overflow: "scroll",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    alignItems: "center",
  },
  cancelCalenderBtn: {
    color: "black",
    marginLeft: "15px",
    padding: "10px 45px 10px 45px ",
    fontFamily: "sans-serif",
    fontSize: 16,
    textTransform: "none",
    width: "40%",
    fontWeight: 600,
    /* height: 30%; */
    backgroundColor: "#e8e8e8",
  } as React.CSSProperties,
  addpopbtn: {
    color: "white",
    marginLeft: "15px",
    padding: "10px 45px 10px 45px ",
    fontFamily: "sans-serif",
    fontSize: 16,
    textTransform: "none",
    width: "40%",
    fontWeight: 600,
    backgroundColor: "#0096FF "
    /* height: 30%; */
    //   backgroundImage: "radial-gradient(rgb(246 168 34), rgb(171 114 24))",
  } as React.CSSProperties,
  adminaddpopbtn: {
    color: "black",
    marginLeft: "15px",
    padding: "10px 45px 10px 45px ",
    fontFamily: "sans-serif",
    fontSize: 16,
    textTransform: "none",
    width: "40%",
    fontWeight: 600,
    // backgroundColor: "#0096FF "
    /* height: 30%; */
    backgroundImage: "radial-gradient(rgb(246 168 34), rgb(171 114 24))",
  } as React.CSSProperties,
  secondrowbutton: {
    color: "rgb(0, 150, 255)",
    textTransform: "none",
    marginRight: "20px",
    boxShadow:"none",
    outline:0
  } as React.CSSProperties,
  modalBackdropProps: {
    backgroundColor: "rgba(0, 0, 0, .7)",
    backdropFilter: "blur(10px)",
  },
  modalCardRoot: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    minHeight: "50%",
    maxHeight: "80%",
    fontSize: "16px",
    overflowY: "scroll",
  } as React.CSSProperties,
  modalCardHeader: {
    // borderBottom: "1px solid #ccc",
    paddingInline: "1rem",
  },
  modalCardHeaderText: {
    fontWeight: 600,
  },
  modalCardContent: {
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    padding: "1rem",
    fontSize: "16px",
  } as React.CSSProperties,
  sequenceModalCardContent: {
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: "1rem",
    paddingRight: "1rem",
    fontSize: "16px",
  } as React.CSSProperties,
  modalCardActionButton: {
    backgroundColor: "#42a5f5",
    color: "white",
    borderRadius: "4px",
    width: "100px",
    textTransform: "none",
    fontFamily:"sans-serif",
    fontWeight:"bold"
  } as React.CSSProperties,
  modalCardActionsContainer: { display: "flex", gap: "0.5rem" },
  modalCardActions: {
    display: "flex",
    justifyContent: "flex-end",
    paddingInline: "1rem",
  },
  modalCardCancelButton: {
    backgroundColor: "rgba(230, 230, 230, 1)",
    borderRadius: "4px",
    textTransform: "none",
    width: "100px",
    fontWeight: "bold",
    fontFamily:"sans-serif"
  } as React.CSSProperties,
  modalCardAddActionButton: {
    backgroundImage: "radial-gradient(rgb(246 168 34), rgb(171 114 24))",
    color: "black",
    borderRadius: "4px",
    width: "100px",
    textTransform: "none",
    fontWeight: 600,
    marginLeft:"5px"
  } as React.CSSProperties,
};
// Customizable Area End
