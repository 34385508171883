import React from "react";

// Customizable Area Start
import {
    Box,
    Button,
    Link,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Typography,
    Checkbox,
    CircularProgress,
    Modal,
    Menu,
    MenuItem,
    TextField,
    InputLabel,
    Grid,
    createMuiTheme,
    Divider,
    Select,
    Tooltip,
} from "@material-ui/core";
import "./style.css";
import IndeterminateCheckBoxOutlinedIcon from '@material-ui/icons/IndeterminateCheckBoxOutlined';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import GetAppIcon from '@material-ui/icons/GetApp';
import PublishIcon from '@material-ui/icons/Publish';
import ListAltIcon from '@material-ui/icons/ListAlt';
const rightIcon = require("../assets/righticon.png");
const {baseURL} = require("../../../framework/src/config");
// Customizable Area End
import {
    MuiPickersUtilsProvider,
    DatePicker,
} from "material-ui-pickers";
const filterApplied = require("../assets/filterApplied.png");
const filterEmpty = require("../assets/filterEmpty.png");

// Customizable Area Start
import AddFieldModal from "./AddFieldModal.web";
import SearchBar from "material-ui-search-bar";
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import Pagination from '@material-ui/lab/Pagination';
import CloseIcon from "@material-ui/icons/Close";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { ThemeProvider } from "@material-ui/styles";
import MomentUtils from "@date-io/moment";

const materialTheme = createMuiTheme({
    overrides: {
      // @ts-ignore
      MuiPickersToolbar: {
        toolbar: {
          backgroundColor: localStorage.getItem('user_type')=='Superadmin'?"#e8e8e8":"rgb(171 114 24)",
          color: "black",
          backgroundImage:   localStorage.getItem('user_type')=='Superadmin'?"#e8e8e8":"radial-gradient(rgb(246 168 34), rgb(171 114 24))",
        },
      },
      MuiPickersDay: {
        day: {
          color: localStorage.getItem('user_type')=='Superadmin'?"black":"rgb(171 114 24)",
        },
        daySelected: {
          backgroundColor:localStorage.getItem('user_type')=='Superadmin'?"#e8e8e8": "rgb(171 114 24)",
          backgroundImage: "radial-gradient(rgb(246 168 34), rgb(171 114 24))",
        },
        dayDisabled: {
          color:localStorage.getItem('user_type')=='Superadmin'?"#e8e8e8": "rgb(171 114 24)",
        },
        current: {
          color:localStorage.getItem('user_type')=='Superadmin'?"#e8e8e8": "rgb(171 114 24)",
        },
        isSelected: {
          color: localStorage.getItem('user_type')=='Superadmin'?"black":"white",
          backgroundColor: localStorage.getItem('user_type')=='Superadmin'?"#e8e8e8":"rgb(171 114 24)",
          backgroundImage: localStorage.getItem('user_type')=='Superadmin'?"#e8e8e8": "radial-gradient(rgb(246 168 34), rgb(171 114 24))",
        },
      },
      MuiCssBaseline: {
        "@global": {
          "*::-webkit-scrollbar": {
            width: "10px",
          },
          "*::-webkit-scrollbar-track": {
            background: "#E4EFEF",
          },
          "*::-webkit-scrollbar-thumb": {
            background: "#1D388F61",
            borderRadius: "2px",
          },
        },
      },
      MuiPickersToolbarButton: {
        toolbarBtn: {
            color:  localStorage.getItem('user_type')=='Superadmin'?"black":"white",
        },
        toolbarBtnSelected: {
            color:  localStorage.getItem('user_type')=='Superadmin'?"black":"white"
        },
     },
      palette: {
        primary: "red", // works
      },
      MuiButton: {
        textPrimary: {
          color: localStorage.getItem('user_type')=='Superadmin'?"black":"rgb(171 114 24)",
        },
      },

      MuiPickersModal: {
        dialogAction: {
          color: "#8bc34a",
        },
      },
      myComponent: {
        "& .MuiPickersDay-isSelected": {
          backgroundColor: "red",
        },
      },

      MuiPickersCalendarHeader: {
        switchHeader: {
          backgroundColor: "white",
          color: localStorage.getItem('user_type')=='Superadmin'?"black":"rgb(171 114 24)",
        },
        dayLabel: {
          color: localStorage.getItem('user_type')=='Superadmin'?"black":"rgb(171 114 24)",
        },
      },
    },
  });


// Customizable Area End

import ProjectTemplateTableController, {
    Props,
} from "./ProjectTemplateTableController";
import UpdateSequenceModal from "./UpdateSequenceModal.web";
import AddProjectModal from "./AddProjectModal.web";
import DeleteFieldModal from "./DeleteFieldModal.web";
import TemplateBreadcrumbs from "./TemplateBreadcrumbs.web";
import UploadErrorModal from "./UploadErrorModal";
export default class ProjectTemplateTable extends ProjectTemplateTableController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    handleBack = () => {
        if (this.query.get('sfid')) {
            this.props.history.push(`/definechecktable?cid=${this.query.get('cid')}&sfid=${this.query.get('sfid')}&tid=${this.query.get('tid')}`);
        } else {
            this.props.history.push(`/definechecktable?cid=${this.query.get('cid')}&tid=${this.query.get('tid')}`);
        }
    };

    handleSave = () => {
        this.props.history.push("/userprofile");
    };

    handleNext = () => {
        this.props.history.push({
            pathname: "/definechecklist",
            state: { header: "Solar Template", parent: "Solar", child: "Tata Power" },
        });
    };

    replaceUndersocotWithSpace = /_/g;
    replaceCaseFormation = /(^\w{1})|(\s+\w{1})/g;

    handleFilters = (htmlElement: EventTarget, headerItem: any) => {
        console.log('handleFilters', htmlElement, headerItem)
        if (headerItem?.attributes?.quick_filter) {
            this.setState({ selectedFilterId: headerItem?.id });
            switch (headerItem?.attributes?.field_type) {
                case 'Number':
                    this.setState({ isOpenNumberFilterModal: true, errorText: headerItem.attributes?.field_name, fromNumberFilter: "", toNumberFilter: "" })
                    break;
                case 'Dropdown': {
                    this.handleFilteTypeDropDown(htmlElement, headerItem);
                    break;
                }
                case 'Calendar': {
                    this.setState({ isOpenDateFilterModal: 'DATE_FILTER', fromDate: "", toDate: "" })
                    break;
                }
            }
        }
    }

    renderHeader = () => (
        <div className="heading" style={webStyle.pageheader}>
            <TemplateBreadcrumbs
                navigation={""}
                id={""}
                that={this}
                history={this.props.history}
            />
            {this.state.isProjectsUploading && (<div className='uploadingLoaderContainer'>
                <div>
                    <div style={{ marginBottom: 5 }} >
                        <b>Uploading...</b>
                    </div>
                    <div className={`loaderbar ${localStorage.getItem('user_type') !== "Superadmin" ? "admin" : ""}`} />
                </div>
            </div>)}
        </div>
    )

    closeCSVSuccess = () => {
        this.setState({ csvUploaded: false })
    }

    handleCSVScceussModal = () => (
        <Modal
            open={this.state.csvUploaded}
            onClose={this.closeCSVSuccess}
            aria-labelledby="modal-workspace-title"
            aria-describedby="modal-workspace-description"
            style={webStyle.modalRoot}
            BackdropProps={{
                style: webStyle.modalBackdropProps,
            }}
        >
            <div style={webStyle.successSaveModal}>
                <img style={webStyle.rightIcon} src={rightIcon} />
                <p style={webStyle.TemplateCreatedText}>All the projects are appended to the existing list of projects.</p>
                <div>
                    <Button
                        disableElevation
                        variant="contained"
                        style={localStorage.getItem('user_type') == 'Superadmin' ? webStyle.modalButton : webStyle.modalButtonAdmin}
                        onClick={() => this.closeCSVSuccess()}
                    >
                        <span style={localStorage.getItem('user_type') == 'Superadmin' ? webStyle.duplicatebtn : webStyle.duplicateadminbtn}>Ok</span>
                    </Button>
                </div>
            </div>
        </Modal>
    )


    renderActionButtons = () => (
        <div style={webStyle.cardContentOption}>
            <Button onClick={() => this.getProjectList('sample')} className="btnshadow" disableElevation style={localStorage.getItem('user_type') == "Superadmin" ? webStyle.btn : webStyle.adminbtn}> {localStorage.getItem('user_type') == "Superadmin" ? "" : <GetAppIcon style={{ marginRight: 3 }} />}Download sample .CSV file</Button>
            <input
                onClick={(event: any) => {
                    event.target.value = ''
                }}
                type="file"
                style={{ display: "none" }}
                id="project-import"
                onChange={this.handleFileSelect}
                accept={".csv"}
            // disabled={localStorage.getItem('user_type') === 'TL/Manager'}
            />
            <label htmlFor="project-import">
                <Button component="span" style={localStorage.getItem('user_type') == "Superadmin" ? webStyle.btn : webStyle.adminbtn}>{localStorage.getItem('user_type') == "Superadmin" ? "" : <PublishIcon style={{ marginRight: 3 }} />}Upload .CSV file</Button>
            </label>
            <Button onClick={() => this.handleDownloadCompleteData()} style={localStorage.getItem('user_type') == "Superadmin" ? webStyle.btn : webStyle.adminbtn}>{localStorage.getItem('user_type') == "Superadmin" ? "" : <ListAltIcon style={{ marginRight: 3 }} />}Download complete data</Button>
            <AddFieldModal
                isChooseTemplateFlow={false}
                navigation={""}
                id={""}
                history={this.props.history}
                fieldsData={this.state.fieldsData}
                newFieldAddedCallback={() => { this.getFields(); this.getProjectList() }}
            />
            {localStorage.getItem('user_type') == 'Superadmin' &&
                <DeleteFieldModal
                    isChooseTemplateFlow={false}
                    navigation={""}
                    id={""}
                    that={this}
                    history={this.props.history}
                    fieldsData={this.state.fieldsData}
                    fieldDeleteCallback={() => { this.getFields(); this.getProjectList() }}
                />
            }
            <UpdateSequenceModal
                isChooseTemplateFlow={false}
                navigation={""}
                id={""}
                history={this.props.history}
                fieldsData={this.state.fieldsData}
                sequenceUpdatedCallback={() => { this.getFields(); this.getProjectList() }}
            />
            {!this.state.loading && (
                <AddProjectModal
                    navigation={""}
                    id={""}
                    that={this}
                    history={this.props.history}
                    fieldsData={this.filterAutoFilled(this.state?.fieldsData)}
                    newProjectAddedCallback={() => this.getProjectList(undefined, true)}
                    projectTypes={this.state.projectTypes}
                    updateProjectData={this.state.updateProjectData}
                    isEditProject={this.state.isEditProject}
                    editProjectId={this.state.editProjectId}
                    QAandQc={this.state.QAandQc}
                    projectCodes={this.state.projectCodes}
                />
            )}
            <Button onClick={() => this.getProjectList("current")} style={localStorage.getItem('user_type') == "Superadmin" ? webStyle.btn : webStyle.adminbtn}> {localStorage.getItem('user_type') == "Superadmin" ? "" : <GetAppIcon style={{ marginRight: 3 }} />}Download current Data</Button>
        </div>
    )

    returnTestIdByFildType = (headerItem: any) => {
        switch (headerItem?.attributes?.field_type) {
            case 'Number':
                return 'numberTypeField'
            case 'Dropdown':
                return 'dropdownTypeField'
            case 'Calendar':
                return 'calenderTypeField'
        }
    }

    renderFrozenHeaders = (headerItem: any, headerindex: any) => {
        return (<TableCell  className={localStorage.getItem('user_type') == 'Superadmin' ? "tablehead" : "admintablehead"}
            align="center"
            style={{ minWidth: headerindex == 0 ? 50 : 101.5, maxWidth: headerindex == 0 ? 50 : 101.5, border: 'none', padding: 7, overflow: 'hidden', overflowWrap: 'break-word' }} key={headerindex}>
            <Tooltip title={headerItem?.attributes?.field_name}>
                <Typography id="renderFrozenHeaders" test-id={this.returnTestIdByFildType(headerItem)} style={{ display: 'flex', alignItems: 'center', justifyContent: "center", fontWeight: 'bold', cursor: headerItem?.attributes?.quick_filter ? 'pointer' : 'text' }} onClick={(e) => this.handleFilters(e.target, headerItem)}>
                    <span>{headerItem?.attributes?.field_name}{headerItem?.attributes?.mandatory && '*'}</span>
                    {headerItem?.attributes?.quick_filter && (<span>
                        <img
                            style={{ marginLeft: 3, height: 15, width: 15 }}
                            src={this.removeExtraFilters()?.some((i: any) => i.template_field_id == headerItem.id) ?
                                filterApplied :
                                filterEmpty} />
                    </span>
                    )}
                </Typography>
            </Tooltip>
        </TableCell>)
    }

    renderNonFrozenHeaders = (headerItem: any, headerindex: any) => {
        return (<TableCell align="center" key={headerindex} className={localStorage.getItem('user_type') == 'Superadmin' ? "tablehead" : "admintablehead"}>
            <Tooltip title={headerItem?.attributes?.field_name}>
                <Typography test-id={this.returnTestIdByFildType(headerItem)} style={{
                    display: 'block',
                    width: 'auto',
                    minWidth: headerItem?.attributes?.field_type == 'Calendar' ? 150 : 100,
                    maxWidth: 200,
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    fontWeight: 'bold',
                    cursor: headerItem?.attributes?.quick_filter ? 'pointer' : 'text'
                }} onClick={(e) => this.handleFilters(e.target, headerItem)}>
                    <span>{headerItem?.attributes?.field_name}{headerItem?.attributes?.mandatory && '*'}</span>
                    {headerItem?.attributes?.quick_filter && (<span>
                        <img
                            style={{ marginLeft: 10, height: 15, width: 15 }}
                            src={this.removeExtraFilters()?.some((i: any) => i.template_field_id == headerItem.id) ?
                                filterApplied :
                                filterEmpty} />
                    </span>
                    )}
                </Typography>
            </Tooltip>
        </TableCell>)
    }

    renderTabelHead = () => {
        return (
            <TableHead>
                <TableRow className="template-table-header" style={{ backgroundColor: "darkgrey" }}>
                    <TableCell style={{
                        left: 0,
                        position: "sticky",
                        zIndex: 1001,
                        padding: 0,
                        background: localStorage.getItem('user_type') == 'Superadmin' ? '#bdbdbd' : "#f5a823"
                    }}>{this.state?.projectListAllData?.fields?.map((headerItem: any, headerindex: any) =>
                        headerItem?.attributes?.is_frozen &&
                        (this.renderFrozenHeaders(headerItem, headerindex))
                    )}
                    </TableCell>
                    {this.state?.projectListAllData?.fields?.map((headerItem: any, headerindex: any) =>
                        !headerItem?.attributes?.is_frozen &&
                        (this.renderNonFrozenHeaders(headerItem, headerindex))
                    )}
                    <TableCell align="left" style={{ width: '100' }} className={localStorage.getItem('user_type') == 'Superadmin' ? "tablehead" : "admintablehead"}>
                        <div style={{ width: '100', display: 'flex', justifyContent: 'space-evenly' }}>
                            {this.state?.selectedProjects?.length ?
                                (<Tooltip title="Remove all selection"><span style={{ cursor: 'pointer' }}><IndeterminateCheckBoxOutlinedIcon onClick={() => this.setState({ selectedProjects: [] })} /></span></Tooltip>) : ""}
                            <span>
                                <Typography style={{ fontWeight: 'bold', textAlign: 'center' }}>
                                    Actions
                                </Typography>
                            </span>
                        </div>
                    </TableCell>
                </TableRow>
            </TableHead>
        )
    }

    renderTabelBody = () => {
        return (
            <TableBody style={{ background: 'white' }}>
                {this.state?.projectListAllData?.projects?.map((projectItem: any, projectindex: number) =>
                (<TableRow key={projectindex}>
                    {/* ? FROZEN cells */}
                    <TableCell style={{
                        left: 0,
                        position: "sticky",
                        zIndex: 1000,
                        padding: 0,
                        background: 'darkgrey'
                    }}>
                        {this.state?.projectListAllData?.fields?.map((headerItem: any, headerindex: any) =>
                            headerItem?.attributes?.is_frozen &&
                            this.renderTabelCell(projectItem, headerItem, headerindex, true)
                        )}
                    </TableCell>
                    {/* ? NON-FROZEN cells */}
                    {this.state?.projectListAllData?.fields?.map((headerItem: any, headerindex: any) =>
                        !headerItem?.attributes?.is_frozen &&
                        this.renderTabelCell(projectItem, headerItem, headerindex, false)
                    )}
                    <TableCell align="center" style={localStorage.getItem('user_type') == 'Superadmin' ? webStyle.tableCell : webStyle.tableAdminCell}  >
                        <div style={{ height: 10, width: '100', display: 'inline-flex', alignItems: 'center', justifyContent: 'space-evenly' }}>
                            <Tooltip title="Edit project">
                                <EditOutlinedIcon style={{ cursor: 'pointer' }} onClick={() => this.handleEdit(projectItem)} />
                            </Tooltip>
                            {(localStorage.getItem('user_type') == 'Superadmin' || localStorage.getItem('user_type') == 'Admin') &&
                                <Checkbox checked={this.returnChecked(projectItem?.id)} onChange={(e) => this.handleCheckboxClick(e.target.checked, projectItem?.id)} />
                            }
                        </div>
                    </TableCell>
                </TableRow>)
                )}
            </TableBody>
        )
    }


    renderNonStickyTable = () => (
        <div className="template-table">
            <div style={{ maxHeight: '65vh', minHeight: '30vh' }}>
                <Table
                    stickyHeader
                    aria-label="sticky table"
                    className="template-tab"
                    style={{
                        position: 'sticky',
                        top: 0,
                    }}
                >
                    {this.renderTabelHead()}
                    {!this.state?.isProjectsFetching && this.state?.projectListAllData?.projects?.length ? (this.renderTabelBody()) : (
                        <TableRow style={{
                            left: '20vw',
                            position: "sticky",
                            zIndex: 1001, display: 'flex', justifyContent: 'center', alignItems: 'center', height: '40vh'
                        }}>{this.state?.isProjectsFetching ? "Loading..." : "No Records Found"}</TableRow>)}
                </Table>
            </div>
        </div>
    )

    returnInitials = (name: string) => {
        return name?.split(' ')?.map(name => name[0])?.join('')?.toUpperCase()
    }

    handleSelectTabelCellAcc = (value: string) => {
        this.handleEditProject(value, this.state.selectedFieldId, this.state.selectedProjectId)
        this.setState({ tabelCellSelectAccAnchorEl: null, tabelCellSelectAccSearchText: "", tabelCellSelectAccOptions: [], tabelCellSelectAccOptionsBySearch: [] })
    }

    searchableMenuForAccounts = () => {
        return (
            <Menu
                className="searchable-menu-for-accs"
                style={webStyle.dropDownFilterMenu}
                keepMounted={true}
                // @ts-ignore
                anchorEl={this.state.tabelCellSelectAccAnchorEl}
                id="account-menu"
                open={this.state.tabelCellSelectAccAnchorEl != null ? true : false}
                onClose={() => this.setState({ tabelCellSelectAccAnchorEl: null, tabelCellSelectAccSearchText: "", tabelCellSelectAccOptions: [], tabelCellSelectAccOptionsBySearch: [] })}
                transformOrigin={{ horizontal: "center", vertical: "top" }}
                anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
            >
                <MenuItem disableTouchRipple={true} style={{ background: 'white' }}>
                    <SearchBar
                        placeholder="Search"
                        style={{ width: '200' }}
                        onChange={(e) => this.filterMenuOptions(e, 'cell')}
                        value={this.state.tabelCellSelectAccSearchText}
                        // @ts-ignore
                        onKeyDown={(e: any): void => {
                            if (e.key !== "Escape") {
                                e.stopPropagation();
                            }
                        }}
                        onCancelSearch={() => this.setState({ tabelCellSelectAccOptionsBySearch: this.state.tabelCellSelectAccOptions })}
                    />
                </MenuItem>
                <MenuItem value="NA" onClick={() => this.handleSelectTabelCellAcc("NA")}>NA</MenuItem>
                {this.state?.tabelCellSelectAccOptionsBySearch?.map((i: any, key: number) =>
                    <MenuItem onClick={() => this.handleSelectTabelCellAcc(i.value)} disableTouchRipple={true} key={key} style={{ display: "flex" }} >
                        <span style={{ ...webStyle.accNameInitials, marginRight: 3 }}>
                            {i?.image ? (<img style={{ borderRadius: '50%' }} height={35} width={35} src={`${baseURL + i.image}`} />) : this.returnInitials(i.label)}
                        </span>&nbsp;
                        {i.label}
                        {i?.onLeave && <span style={{ marginLeft: '10px', width: '8px', height: '8px', background: '#AA0000', borderRadius: '50%' }} />}
                    </MenuItem>)}
            </Menu>)
    }

    handleDeleteConfirm = () => {
        this.deleteProjects()
        this.setState({ deleteSuccess: false })
    }

    confirmDelete = () => (
        <Modal
            className="modal-backdrop"
            open={this.state.deleteSuccess}
            onClose={this.handleCancelBtn}
            style={{
                overflow: "auto",
            }}
        >
            <div
                style={{
                    zIndex: 10,
                    position: "absolute",
                    fontFamily: 'sans-serif',
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    background: "white",
                    width: "25%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "20px",
                    borderRadius: 5
                }}
            >
                <div>
                    <div className="confirm-heading" style={{ margin: "20 30" }} >
                        <Typography style={{ fontWeight: 600 }}>Delete Projects?</Typography>
                        <CloseIcon style={{ cursor: 'pointer' }} onClick={this.handleCancelBtn} />
                    </div>
                    <Divider style={{ width: '100%' }} />
                    <div style={{ margin: "20 30", color: 'grey' }} className="confirmbody">
                        <Typography>Are you sure, you want to delete the selected project?</Typography>
                    </div>
                    <Divider style={{ width: '100%' }} />
                    <div style={{ display: "flex", justifyContent: "end", margin: "20 30" }}>
                        <Button
                            onClick={this.handleCancelBtn}
                            style={webStyle.cancelCalenderBtn}
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={this.handleDeleteConfirm}
                            style={localStorage.getItem('user_type') == 'Superadmin' ? webStyle.addpopbtn : webStyle.adminaddpopbtn}
                        >
                            Yes
                        </Button>
                    </div>
                </div>
            </div>
        </Modal>
    )

    handleOpenTabelCellAcc = (element: EventTarget, field_values: any, associated_class_name: any, projectItem: any, headerId: any, fieldName?: string) => {
        const options = this.createOptions(field_values, associated_class_name, fieldName);
        this.setState({ tabelCellSelectAccAnchorEl: element, tabelCellSelectAccOptions: options, tabelCellSelectAccOptionsBySearch: options, selectedProjectId: projectItem?.id, selectedFieldId: headerId })
    }

    returnTabelCellDynamicStyles = (isFrozen: boolean, fieldItem?: any, isSRNO?: boolean): {minWidth: string|number, maxWidth: string|number, background: string} => {
        return {
            minWidth: isSRNO ? 50 : isFrozen ? 100 : 'auto',
            maxWidth: isSRNO ? 50 : isFrozen ? 100 : 'auto',
            background: localStorage.getItem('user_type') !== 'Superadmin' ? fieldItem?.color_code : ''
        }
    }

    renderAccSelectInTabelCell = (projectItem: any, headerItem: any, headerindex: any, isFrozen: boolean, fieldItem: any) => {
        return (
            <TableCell
                align="center"
                size="small"
                key={headerindex}
                style={{ ...webStyle.commonTabelCellStyle, ...this.returnTabelCellDynamicStyles(isFrozen, fieldItem, headerItem?.attributes?.field_name?.toLowerCase() === 's no') }}
            >
                <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    value={fieldItem?.data}
                    disableUnderline
                    style={{ background: fieldItem?.color_code, maxWidth: "auto", cursor: 'pointer', color: 'black', display: "flex" }}
                    disabled
                    onClick={(e) => this.handleOpenTabelCellAcc(e.target, headerItem?.attributes?.field_values, headerItem?.attributes?.associated_class_name, projectItem, headerItem?.id, headerItem?.attributes?.field_name)}
                >
                    <MenuItem value="NA" className="designerselect">NA</MenuItem>
                    {this.createOptions(headerItem?.attributes?.field_values, headerItem?.attributes?.associated_class_name, headerItem?.attributes?.field_name)?.map((i: any, index: number) => {
                        return (
                            <MenuItem style={{ maxWidth: "auto", cursor: 'pointer', color: 'black', display: "flex" }}
                                key={index}
                                value={i.value}>
                                <div className="designerselect">
                                    <span style={{ ...webStyle.accNameInitials, marginRight: 3 }}>
                                        {i?.image ? (<img style={{ borderRadius: '50%' }} height={35} width={35} src={`${baseURL + i.image}`} />) : this.returnInitials(i.label)}
                                    </span>&nbsp;
                                    <span>{i.label}</span>
                                </div>
                            </MenuItem>)
                    }
                    )}
                </Select>
            </TableCell>)
    }

    renderDDSelectInTabelCell = (projectItem: any, headerItem: any, headerindex: any, isFrozen: boolean, fieldItem: any) => {
        return (
            <TableCell
                align="center"
                size="small"
                key={headerindex}
                style={{ ...webStyle.commonTabelCellStyle, width: 'auto', ...this.returnTabelCellDynamicStyles(isFrozen, fieldItem, headerItem?.attributes?.field_name?.toLowerCase() === 's no') }}
            >
                <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    value={fieldItem?.data}
                    disableUnderline
                    onChange={(e: any) => this.handleEditProject(e.target.value, headerItem?.id, projectItem?.id)}
                >
                    {this.createOptions(headerItem?.attributes?.field_values, headerItem?.attributes?.associated_class_name, headerItem?.attributes?.field_name)?.map((i: any, index: number) => {
                        return (<MenuItem key={index} value={i.value}>{i.label}</MenuItem>)
                    }
                    )}
                </Select></TableCell>)
    }

    renderDateInTabelCell = (projectItem: any, headerItem: any, headerindex: any, isFrozen: boolean, fieldItem: any) => {
        return (
            <TableCell
                align="center"
                size="small"
                key={headerindex}
                style={{ ...webStyle.commonTabelCellStyle, ...this.returnTabelCellDynamicStyles(isFrozen, fieldItem, headerItem?.attributes?.field_name?.toLowerCase() === 's no') }}
            >
                <MuiPickersUtilsProvider
                    utils={MomentUtils}
                    style={{ background: "orange", color: 'black', display: 'flex', justifyContent: 'center', }}
                >
                    <ThemeProvider theme={materialTheme}>
                        <span style={{ width: '200 !important' }}>
                            <DatePicker
                                style={{ width: 100 }}
                                size="small"
                                fullWidth
                                placeholder="DD MMM YY"
                                format={"DD MMM YY"}
                                name={headerindex}
                                value={fieldItem?.data || null}
                                helperText={null}
                                onChange={(e) => this.handleFieldDateUpdate(e.format('YYYY-MM-DD'), fieldItem, projectItem)}
                                disableOpenOnEnter
                                autoOk={true}
                                disabled={!fieldItem.is_editable}
                                className="datefixer"
                                emptyLabel="NA"
                                InputProps={{ disableUnderline: true, style: { color: 'black' } }}
                            />
                            {!isFrozen && (<CalendarTodayIcon />)}
                        </span>
                    </ThemeProvider>
                </MuiPickersUtilsProvider>
            </TableCell>
        );
    }

    renderTextInTabelCell = (projectItem: any, headerItem: any, headerindex: any, isFrozen: boolean, fieldItem: any) => {
        return (<TableCell
            align="center"
            size="small"
            key={headerindex}
            style={{ ...webStyle.commonTabelCellStyle, ...this.returnTabelCellDynamicStyles(isFrozen, fieldItem, headerItem?.attributes?.field_name?.toLowerCase() === 's no') }}
        >
            {headerItem?.attributes?.field_name?.toLowerCase() === 's no' ?
                <Link
                    style={{ cursor: 'pointer', fontSize: 15, color: 'black' }}
                    onClick={() => this.handleEdit(projectItem)}
                    className="tabeldataoverflow">
                    {fieldItem?.display_value || fieldItem?.data || 'NA'}
                </Link>
                :
                <Tooltip
                    title={<p style={{ fontSize: '15px' }} >{fieldItem?.display_value || fieldItem?.data || 'NA'}</p>}>
                    <Typography style={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        display: '-webkit-box',
                        WebkitLineClamp: 2,
                        WebkitBoxOrient: 'vertical',
                        maxWidth: 220,
                    }} className="tabeldataoverflow">
                        {fieldItem?.display_value || fieldItem?.data || 'NA'}
                    </Typography>
                </Tooltip>
            }
        </TableCell>)
    }

    renderFilledCell = (projectItem: any, headerItem: any, headerindex: any, isFrozen: boolean, fieldItem: any) => {
        if (fieldItem?.template_field_id == headerItem?.id) {
            if (headerItem?.attributes?.associated_class_name === 'AccountBlock::Account') {
                return this.renderAccSelectInTabelCell(projectItem, headerItem, headerindex, isFrozen, fieldItem);
            }
            if (headerItem?.attributes?.field_type == 'Dropdown' && !isFrozen) {
                return this.renderDDSelectInTabelCell(projectItem, headerItem, headerindex, isFrozen, fieldItem);
            }
            else if (headerItem?.attributes?.field_type == 'Calendar') {
                return this.renderDateInTabelCell(projectItem, headerItem, headerindex, isFrozen, fieldItem);
            }
            else return this.renderTextInTabelCell(projectItem, headerItem, headerindex, isFrozen, fieldItem);
        }
    }

    renderTabelCell = (projectItem: any, headerItem: any, headerindex: any, isFrozen: boolean) => {
        return projectItem?.attributes?.project_data?.map((fieldItem: any) => {
            return this.renderFilledCell(projectItem, headerItem, headerindex, isFrozen, fieldItem)
        })
    }

    renderProjectListTabel = () => {
        if (this.state.loading) return (<Box style={webStyle.loader}><CircularProgress size={50} /></Box>)
        else if (this.state.projectListAllData?.fields?.length) return (<Box style={{ minHeight: '55vh' }} sx={{ bgcolor: "#e8e8e8", p: 2 }}>
            <div style={webStyle.secondbtn}>
                <SearchBar
                    placeholder="Search"
                    value={this.state.searchTerm}
                    onChange={(e) => this.handleSearch(e)}
                    onCancelSearch={() => this.cancelSearch()}
                />
                <div style={{ justifyContent: "end" }}>
                    <Button disabled={!this.removeExtraFilters()?.length}>
                        <Link onClick={() => this.handleClearAllFilters()} style={localStorage.getItem('user_type') == 'Superadmin' ? webStyle.quickLink : webStyle.adminquickLink} underline="none">Clear Quick Filter</Link>
                    </Button>
                    {localStorage.getItem('user_type') == 'TL/Manager' ? <></> :
                        <Button disabled={!this?.state?.selectedProjects?.length} onClick={() => this.setState({ deleteSuccess: true })} variant="contained" style={localStorage.getItem('user_type') == "Superadmin" ? webStyle.deletebtn : webStyle.admindeletebtn}>
                            Delete Selected
                        </Button>
                    }
                </div>
            </div>
            <Box>
                <div style={webStyle.cardContent}>
                    <div style={{ display: "flex", flexDirection: "row", }}>
                        {this.renderNonStickyTable()}
                    </div>
                    {this.state.projectListAllData?.projects?.length ?
                        (<Pagination style={{ alignSelf: 'center', marginTop: 40 }} page={this.state?.paginationPage} onChange={(e, value) => this.handlePagination(value)} count={this.state?.projectListAllData?.meta?.total_pages} />)
                        : null
                    }
                </div>
            </Box>
        </Box>)
        else return <div style={{ ...webStyle?.noData, background: 'lightgrey' }}>
            No Data
        </div>
    }


    returnDateRangeFilterModal = () => {
        return (<Modal
            className="modal-backdrop"
            open={this.state.isOpenDateFilterModal !== 'CLOSE'}
            onClose={this.handleCancelBtn}
            style={{
                overflow: "auto",
            }}
        >
            <div
                style={webStyle.dateFilterStyle}
            >
                <div style={webStyle.mainModalStyle}>
                    <h1 style={webStyle.modalHeadStyle}>{this.state.isOpenDateFilterModal === 'DATE_FILTER' ? 'Date Filter' : 'Download Complete Data'}</h1>
                    <CloseIcon onClick={this.handleCancelBtn} style={{ marginRight: "8px", cursor: 'pointer' }} />
                </div>
                <Divider />
                <Grid container style={{ padding: "10px 15px", backgroundColor: localStorage.getItem('user_type') == "Superadmin" ? "" : "rgb(245, 245, 245)" }}>
                    <h3 style={webStyle.modalSecondHeadStyle}>Please select the date range {this.state.isOpenDateFilterModal === 'DATE_FILTER' ? '' : 'to download'}</h3>
                    <div style={{ display: "flex", gap: "10px", margin: "0px 20px 0px 20px" }}>
                        <Grid item sm={6}>
                            <InputLabel style={webStyle.dateHeader}>Start Date</InputLabel>
                            <MuiPickersUtilsProvider
                                utils={MomentUtils}
                                style={{ background: "orange" }}
                            >
                                <ThemeProvider theme={materialTheme}>
                                    <DatePicker
                                        keyboard
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        placeholder="DD/MM/YYYY"
                                        format={"DD/MM/YYYY"}
                                        mask={(value) =>
                                            value
                                                ? [
                                                    /\d/,
                                                    /\d/,
                                                    "/",
                                                    /\d/,
                                                    /\d/,
                                                    "/",
                                                    /\d/,
                                                    /\d/,
                                                    /\d/,
                                                    /\d/,
                                                ]
                                                : []
                                        }
                                        value={this.returnDateValue('start') || null}
                                        onChange={(e) => this.handleDateChange(e, 'start')}
                                        disableOpenOnEnter
                                        animateYearScrolling={false}
                                        autoOk={true}
                                    />
                                </ThemeProvider>
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item sm={6}>
                            <InputLabel style={webStyle.dateHeader}>End Date</InputLabel>
                            <MuiPickersUtilsProvider
                                utils={MomentUtils}
                                style={{ background: "orange" }}
                            >
                                <ThemeProvider theme={materialTheme}>
                                    <DatePicker
                                        keyboard
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        minDate={this.state.fromDate}
                                        placeholder="DD/MM/YYYY"
                                        format={"DD/MM/YYYY"}
                                        mask={(value) =>
                                            value
                                                ? [
                                                    /\d/,
                                                    /\d/,
                                                    "/",
                                                    /\d/,
                                                    /\d/,
                                                    "/",
                                                    /\d/,
                                                    /\d/,
                                                    /\d/,
                                                    /\d/,
                                                ]
                                                : []
                                        }
                                        value={this.returnDateValue('end') || null}
                                        onChange={(e) => this.handleDateChange(e, 'end')}
                                        disableOpenOnEnter
                                        animateYearScrolling={false}
                                        autoOk={true}
                                    />
                                </ThemeProvider>
                            </MuiPickersUtilsProvider>
                        </Grid>
                    </div>
                    <br />
                </Grid>
                <Divider />
                <div style={webStyle.dateFilterButtons}>
                    {this.renderCancelOrRemoveButton(() => this.handleRemoveFilter(), () => this.handleCancelBtn())}
                    <Button
                        disabled={!this.state.fromDate || !this.state.toDate || this.state.isDateError}
                        onClick={() => this.handleDateFilterAdd()}
                        style={localStorage.getItem('user_type') == 'Superadmin' ? webStyle.addpopbtn : webStyle.adminaddpopbtn}
                    >
                        {this.state.isOpenDateFilterModal === 'DATE_FILTER' ? 'Add' : 'Download'}
                    </Button>
                </div>
            </div>
        </Modal>)
    }

    renderCancelOrRemoveButton = (removeCallBack: any, cancelCallBack: any) => {
        const foundFilter = this.state.filterData.find((i: any) => i.template_field_id == this.state.selectedFilterId);
        if (foundFilter && (this.state.isOpenDateFilterModal !== 'DOWNLOAD_COMPLETE_DATA')) return (
            <Button onClick={() => removeCallBack()} style={webStyle.cancelCalenderBtn}>
                Remove
            </Button>
        )
        else return (
            <Button onClick={() => cancelCallBack()} style={webStyle.cancelCalenderBtn}>
                Cancel
            </Button>
        )
    }

    returnNumberFilterModal = () => {
        return (<Modal
            className="modal-backdrop"
            open={this.state.isOpenNumberFilterModal}
            onClose={this.handleCancelBtn}
            style={{
                overflow: "auto",
            }}
        >
            <div style={webStyle.dateFilterStyle}>
                <Grid>
                    <div style={{ textAlign: "center" }}>
                        <div style={webStyle.mainModalStyle}>
                            <h1 style={webStyle.modalHeadStyle}>{(this.state.errorText)} filter</h1>
                            <CloseIcon onClick={this.handleCancelBtn} style={{ marginRight: "8px", cursor: 'pointer' }} />
                        </div>
                        <Divider />
                        <div style={{ padding: "10 0", backgroundColor: localStorage.getItem('user_type') == "Superadmin" ? "" : "rgb(245, 245, 245)" }}>
                            <Typography style={webStyle.range}>Enter range</Typography>
                            <div style={{ padding: "15px 20px ", display: "flex", gap: "10px", alignItems: "left", justifyContent: "left" }} className="confirmbody">
                                <div>
                                    <InputLabel style={webStyle.modalhead}>From</InputLabel>
                                    <TextField
                                        variant="outlined"
                                        size="small"
                                        required
                                        fullWidth
                                        placeholder="0"
                                        type="number"
                                        name="From"
                                        style={{ marginTop: "10px" }}
                                        value={this.returnNumValue('start')}
                                        onChange={(e) => this.handleNumChange(e.target.value, 'start')}
                                    />
                                </div>
                                <div>
                                    <InputLabel style={webStyle.modalhead}>To</InputLabel>
                                    <TextField
                                        variant="outlined"
                                        size="small"
                                        required
                                        fullWidth
                                        placeholder="0"
                                        type="number"
                                        name="To"
                                        style={{ marginTop: "10px" }}
                                        value={this.returnNumValue('end')}
                                        onChange={(e) => this.handleNumChange(e.target.value, 'end')}
                                        helperText={this.state.isNumberFilterError && 'Please enter valid input.'}
                                        error={this.state.isNumberFilterError}
                                    />
                                </div>
                            </div>
                        </div>
                        <Divider />
                        <div style={webStyle.dateFilterButtons}>
                            {this.renderCancelOrRemoveButton(() => this.handleRemoveFilter(), () => this.handleCancelBtn())}
                            <Button
                                disabled={this.state.fromNumberFilter === "" || this.state.toNumberFilter === "" || this.state.toNumberFilter === 0 || this.state.isNumberFilterError}
                                onClick={() => this.handleNumberFilterAdd()}
                                style={localStorage.getItem('user_type') == 'Superadmin' ? webStyle.addpopbtn : webStyle.adminaddpopbtn}
                            >
                                Apply
                            </Button>
                        </div>
                    </div>
                </Grid>
            </div>
        </Modal>)
    }

    returnSelectFilterModal = () => {
        return (
            <Menu
                style={webStyle.dropDownFilterMenu}
                keepMounted={true}
                // @ts-ignore
                anchorEl={this.state.menuAnchorEl}
                id="account-menu"
                open={this.state.menuAnchorEl != null ? true : false}
                onClose={() => this.setState({ menuAnchorEl: null, filterTypeDDSearchText: "", filterTypeDDOptionsBySearch: [], filterTypeDDOptions: [] })}
                transformOrigin={{ horizontal: "center", vertical: "top" }}
                anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
            >
                <MenuItem style={{ background: 'white', width: '100%', display: 'flex', justifyContent: 'center' }} disableTouchRipple={true}>
                    <SearchBar
                        style={{ width: '200' }}
                        placeholder="Search"
                        onChange={(e) => this.filterMenuOptions(e, 'header')}
                        value={this.state.filterTypeDDSearchText}
                        // @ts-ignore
                        onKeyDown={(e: any): void => {
                            if (e.key !== "Escape") {
                                e.stopPropagation();
                            }
                        }}
                        onCancelSearch={() => this.setState({ filterTypeDDOptionsBySearch: this.state.filterTypeDDOptions })}
                    />
                </MenuItem>
                <>
                    {this.state.selectFilterType === 'Account' && <MenuItem disableTouchRipple={true} key={"NA"}><Checkbox checked={this.returnIfFilterIsApplied({ label: "NA", value: "NA" })} onChange={() => this.handleSelectFilterValueChange({ label: "NA", value: "NA" })} />NA</MenuItem>}
                    {this.returnIfSelectedFieldMendatory() && <MenuItem disableTouchRipple={true} key={"Blank"}><Checkbox checked={this.returnIfFilterIsApplied({ label: "Blank", value: "" })} onChange={() => this.handleSelectFilterValueChange({ label: "Blank", value: "" })} />(Blank)</MenuItem>}
                    {this.state?.filterTypeDDOptionsBySearch?.map((i: any, key: number) =>
                        <MenuItem disableTouchRipple={true} key={key}><Checkbox checked={this.returnIfFilterIsApplied(i)} onChange={() => this.handleSelectFilterValueChange(i)} />
                            <span style={{
                                maxWidth: 300, whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis"
                            }}>{i.label}</span>
                        </MenuItem>)}
                </>
            </Menu>
        )
    }

    returnTextFilterModal = () => {
        return (<Modal
            className="modal-backdrop"
            //   open={false}
            open={this.state.codeModal}
            onClose={this.handleCancelBtn}
            style={{
                overflow: "auto",
            }}
        >
            <div
                // className="modal-successcontent"
                style={{
                    zIndex: 10,
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    background: "white",
                    width: "25%",
                    // height: "20%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "20px",
                    overflow: "auto"
                    // padding:"20px"
                }}
            >
                <Grid>
                    <div
                        style={{ textAlign: "center" }}
                    >
                        <div className="confirm-heading" style={{ padding: "10px" }} >
                            <Typography style={{ fontWeight: 900 }}>{(this.state.errorText)?.replace(this.replaceUndersocotWithSpace, " ")?.replace(this.replaceCaseFormation, letter => letter?.toUpperCase())} Enter Code</Typography>
                            <CloseIcon onClick={this.handleCancelBtn} />
                        </div>
                        <Divider />
                        <div style={{ padding: "20px", display: "flex", gap: "10px", alignItems: "left", justifyContent: "left" }} className="confirmbody">
                            <div>
                                <InputLabel
                                    style={webStyle.modalhead}

                                >
                                    Code
                                </InputLabel>
                                <TextField
                                    variant="outlined"
                                    size="small"
                                    required
                                    fullWidth
                                    placeholder="Enter Code"
                                    type="text"
                                    name="lastname"
                                //   value={this.state.lastname}
                                //   onChange={this.handleChange}
                                />
                            </div>
                        </div>
                        <Divider />
                        <div style={{ padding: "20px" }}>
                            <Button
                                onClick={this.handleCancelBtn}
                                style={webStyle.cancelbtn}
                            // className="cancelbtn"
                            >
                                Cancel
                            </Button>
                            <Button
                                // onClick={() => this.handleYes()}
                                style={localStorage.getItem('user_type') == 'Superadmin' ? webStyle.addpopbtn : webStyle.adminaddpopbtn}
                            // style={{ backgroundColor: "#0096FF " }}
                            >
                                Add
                            </Button>
                        </div>
                        {/* </div> */}
                    </div>
                </Grid>
            </div>
        </Modal>)
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        // Customizable Area End
        return (
            // Customizable Area Start
            <>
             {this.state.deleteSuccess && this.confirmDelete()}
             {/* {this.state.csvUploaded && this.handleCSVScceussModal} */}
                <Box style={webStyle.background}>
                    <Box style={webStyle.content} className="background-content">
                        {this.renderHeader()}
                        <br />
                        <Divider />
                        {this.renderActionButtons()}
                        {this.renderProjectListTabel()}
                    </Box>
                </Box>
                {this.returnDateRangeFilterModal()}
                {this.returnSelectFilterModal()}
                {this.returnNumberFilterModal()}
                {this.returnTextFilterModal()}
                {this.searchableMenuForAccounts()}
                {this.handleCSVScceussModal()}
                <UploadErrorModal
                    isOpen={this.state.displayUploadErrors}
                    handleClose={() => this.closeUploadErrorModal()}
                    handleOkClick={
                       () =>  this.setState({ displayUploadErrors: false, duplicateUploadValue: [] })
                    }
                    errorData={this.state.duplicateUploadValue} />
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {
    modalRoot: {
        display: "flex",
        gap: 1,
        fontSize: "16px",
        justifyContent: "center",
        borderBlockStart: "1px solid #ccc",
        paddingBlock: "2rem", //
        backdropFilter: "blur(5px)",
        overflow: "scroll",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        alignItems: "center",
    },
    cancelbtn: {
        color: "black",
        marginLeft: "15px",
        padding: "10px 35px 10px 35px ",
        fontFamily: "sans-serif",
        fontSize: 16,
        textTransform: "none",
        width: "40%",
        fontWeight: "bold",
        /* height: 30%; */
        backgroundColor: "#e8e8e8",
    } as React.CSSProperties,
    rightIcon: {
        marginTop: '10px',
        width: '30px',
        height: '30px'
      } as React.CSSProperties,
    secondbtn: {
        display: "flex",
        justifyContent: "space-between",
        margin: "20px 0px 20px 0px",
    } as React.CSSProperties,

    background: {
        backgroundColor: "white",
        marginTop: 35,
        // height: "100vh",
        fontFamily: "sans-serif",
    },
    content: {
        top: "50px",
        right: "0px",
        width: "85vw",
        height: "fit-content",
        padding: "50px 20px 10px",
        justifyContent: "space-between",
    },

    pageheader: {
        display: "flex",
        justifyContent: "space-between",
    },
    btn: {
        color: "rgb(0, 150, 255)",
        textTransform: "none",
        marginRight: "20px",
        boxShadow:"none",
        outline:0
    } as React.CSSProperties,
    adminbtn: {
        color: "orange",
        textTransform: "none",
        marginRight: "20px"
    } as React.CSSProperties,
    deletebtn: {
        backgroundColor: "rgb(0, 150, 255)",
        color: "white",
        textTransform:"none",
        fontFamily: "sans-serif",
        fontWeight:"bold",
        fontSize:14
    } as React.CSSProperties,
    admindeletebtn: {
        backgroundImage: "radial-gradient(rgb(246 168 34), rgb(171 114 24))",
        // backgroundColor: "rgb(0, 150, 255)",
        color: "black",
        textTransform:"none",
        fontFamily: "sans-serif",
        fontWeight:"bold",
        fontSize:15
    } as React.CSSProperties,
    successSaveModal: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        alignContent: 'center',
        width: '436px',
        height: '300px',
        borderRadius: '4px',
        background: '#ffffff',
      } as React.CSSProperties,
      TemplateCreatedText: {
        fontFamily: "sans-serif",
        fontSize: "26px",
        width: '282px',
        textAlign: 'center',
        marginBottom: '-10px'
      } as React.CSSProperties,
    quickLink: {
        marginRight: "10px",
        color: "grey",
        cursor: 'pointer',
        textTransform: "capitalize",
        textDecoration: "underline",
        } as React.CSSProperties,
    modalHeadStyle:{
        fontFamily: "sans-serif",
        fontWeight:"bold",
        fontSize: 16,
        alignItems:"left",
        marginLeft:"10px"
    } as React.CSSProperties,
    modalSecondHeadStyle:{
        fontFamily: "sans-serif",
        fontSize: 14,
        color:"grey",
        marginLeft: "15px",
        fontWeight: 500
    } as React.CSSProperties,


    pagename: {
        display: "flex",
        // flexDirection: row,
    },
    modalhead: {
        fontFamily: "sans-serif",
        color: "#6a6f7a",
        marginBottom: 7,
        fontWeight: 600,
        fontSize: "14px",
        textAlign: "left",
        marginTop: "15px",
        alignItems: "left"
    } as React.CSSProperties,

    arrowbox: {
        backgroundColor: "#eeeeee",
        width: 35,
        height: 35,
        margin: 10,
        borderRadius: 8,
        alignItems: "center",
        justifyContent: "center",
        alignContent: "center",
        display: "flex",
    },
    addpopbtn: {
        color: "white",
        marginLeft: "15px",
        padding: "10px 45px 10px 45px ",
        fontFamily: "sans-serif",
        fontSize: 16,
        textTransform: "none",
        width: "40%",
        fontWeight: 600,
        backgroundColor: "#0096FF "
        /* height: 30%; */
        //   backgroundImage: "radial-gradient(rgb(246 168 34), rgb(171 114 24))",
    } as React.CSSProperties,
    adminaddpopbtn: {
        color: "black",
        marginLeft: "15px",
        padding: "10px 45px 10px 45px ",
        fontFamily: "sans-serif",
        fontSize: 16,
        textTransform: "none",
        width: "40%",
        fontWeight: 600,
        // backgroundColor: "#0096FF "
        /* height: 30%; */
        backgroundImage: "radial-gradient(rgb(246 168 34), rgb(171 114 24))",
    } as React.CSSProperties,
    arrow: {
        // backgroundColor: "#eeeeee",
        width: 20,
        height: 20,
        // marginLeft: 30,
        // borderRadius: 8,
    },

    title: {
        margin: 10,
        marginTop: 2,
        color: "#42454e",
        // fontFamily: "Open Sans",
        fontSize: "20px",
        fontWeight: 100,
    },

    breadcrumb: {
        marginLeft: 10,
        marginTop: -10,
    },

    savebutton: {
        marginRight: 10,
        width: "193px",
        height: "50px",
        borderradius: "8px",
        background: "#e8e8e8",
    },

    nextbutton: {
        width: "193px",
        height: "50px",
        borderradius: "4px",
        background: "#4eabf8",
    },
    range:{
        fontFamily: "sans-serif",
        fontSize: 14,
        textAlign:"left",
        marginLeft:"20px",
        marginTop: 5
    } as React.CSSProperties,

    cardContent: {
        margin: "20px 0px",
        display: 'flex',
        flexDirection: 'column',
    } as React.CSSProperties,

    cardContentOption: {
        margin: "20px 0px",
    },

    secondrowbutton: {
        color: "#0096ff",
    },

    tablescroll: {
        overflowX: "scroll",
    },
    gridMargin: { marginBlockEnd: "0" },
    gridLess: { paddingInline: 0 },
    gridMore: { paddingInline: "1rem" },
    uploadImage: {
        padding: "5rem",
        border: "2px dashed rgba(155, 155, 155, .2)",
        borderRadius: "5px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    filterContainer: {
        // height: "5rem",
        paddingBlock: ".5rem",
        overflow: "scroll",
    },
    formTypography: {
        paddingBlockEnd: 1,
        marginBottom: "5px",
        fontSize: "14px",
        fontWeight: 600,
        color: "#424242",
    },
    modalCardCancelButton: {
        backgroundColor: "rgba(230, 230, 230, 1)",
        borderRadius: "4px",
        textTransform: "none",
        width: "100px",
        fontWeight: 500,
    } as React.CSSProperties,
    modalCardCancelButtonSuccess: {
        backgroundColor: "rgba(230, 230, 230, 1)",
        borderRadius: "4px",
        textTransform: "none",
        fontWeight: 500,
    } as React.CSSProperties,
    modalCardActionButton: {
        backgroundColor: "#42a5f5",
        color: "white",
        borderRadius: "4px",
        width: "100px",
        textTransform: "none",
    } as React.CSSProperties,
    modalCardActionsContainer: { display: "flex", gap: "0.5rem" },
    modalCardActions: {
        display: "flex",
        justifyContent: "flex-end",
        paddingInline: "1rem",
    },
    modalCardContent: {
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        padding: "1rem",
        fontSize: "16px",
    } as React.CSSProperties,
    sequenceModalCardContent: {
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: "1rem",
        paddingRight: "1rem",
        fontSize: "16px",
    } as React.CSSProperties,

    modalCardHeader: {
        // borderBottom: "1px solid #ccc",
        paddingInline: "1rem",
    },
    modalCardHeaderText: {
        fontWeight: 600,
    },
    modalCardRoot: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 500,
        minHeight: "50%",
        maxHeight: "80%",
        fontSize: "16px",
        overflowY: "scroll",
    } as React.CSSProperties,
    modalBackdropProps: {
        backgroundColor: "rgba(0, 0, 0, .7)",
        backdropFilter: "blur(10px)",
    },
    titleText: {
        fontSize: "medium",
        fontWeight: 600,
        textAlign: "center",
    } as React.CSSProperties,
    titleButtonStyle: {
        textTransform: "none",
        backgroundColor: "#42a5f5",
        color: "white",
    } as React.CSSProperties,
    alignRoot: {
        display: "grid",
        gridTemplateColumns: "15vw minmax(85vw, auto)",
    } as React.CSSProperties,
    secondaryGrid: {
        border: "none",
        gap: "1rem",
    },
    rootContainer: {
        display: "flex",
        flexDirection: "column",
        padding: "1rem",
        gap: "1rem",
    } as React.CSSProperties,
    mainGrid: {
        flexGrow: 1,
        backgroundColor: "rgba(200, 200, 200, .5)",
        zIndex: -1,
    },
    root: {
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        padding: "1rem", //
        height: 450,
    },
    cardActions: {
        display: "flex",
        gap: 1,
        alignItems: "center",
        justifyContent: "center",
        borderBlockStart: "1px solid #ccc",
        minHeight: "60px",
    },
    alert: {
        display: "flex",
        position: "fixed",
        top: 80,
        right: 1,
        zIndex: 4000,
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
    } as React.CSSProperties,
    gutter: {
        marginBlockEnd: "0.4rem",
    },

    loadingRoot: {
        display: "flex",
        flexDirection: "column",
    } as React.CSSProperties,
    loadingGutter: {
        marginBlockEnd: 4,
    },
    skeletonStart: { paddingTop: "40%", marginBlockEnd: 6 },
    skeletonEnd: { paddingTop: "10%", marginBlockStart: 6 },
    circularRoot: {
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    progressCircle: {
        color: "#6e6e6e",
    },
    badgeButton: {
        borderRadius: "6px",
        textTransform: "none",
        backgroundColor: "rgba(222, 227, 230, 1)",
    } as React.CSSProperties,
    badgeTypography: {
        paddingBlock: ".2rem",
        paddingInline: ".3rem",
    },

    badgeCardRoot: {
        height: "200px",
        width: "100%",
    },

    workspaceAvatar: { width: 32, height: 32 },
    workspaceCardRoot: {
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        textAlign: "center",
        padding: "1rem",
        height: 450,
    } as React.CSSProperties,
    workspaceIconButton: { padding: "1rem" },
    workspaceStyledCard: { height: "175px" },
    workspaceId: {
        fontSize: "small",
    },
    workspaceName: { fontWeight: 600, fontSize: "medium" },
    descriptionContainer: {
        height: "100px",
        overflow: "scroll",
    },

    formRoot: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        border: "1px solid rgba(155, 155, 155, .5)",
        gap: "0.1rem",
        borderRadius: "4px",
        paddingInline: "8px",
        paddingBlock: "0.2rem",
        color: "rgba(155, 155, 155, 1)",
    },
    formActivePick: { backgroundColor: "#42a5f5", color: "#fff" },
    formAvatar: { width: 32, height: 32, backgroundColor: "#5f5f5f" },
    formTextContainer: { cursor: "pointer" },
    formToolTypography: {
        display: "flex",
        flexWrap: "nowrap",
        fontSize: "small",
    } as React.CSSProperties,

    hide: {
        display: "none",
    },
    secondaryModalRoot: {
        display: "flex",
        gap: 1,
        fontSize: "16px",
        justifyContent: "center",
        borderBlockStart: "1px solid #ccc",
        backdropFilter: "blur(5px)",
        overflow: "scroll",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        alignItems: "center",
    },
    modalCardRootSuccess: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 300,
        height: "37%",
        maxHeight: 300,
        fontSize: "16px",
        overflow: "scroll",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        borderRadius: "0",
    } as React.CSSProperties,
    secondaryModalCardContent: {
        padding: 0,
    },

    secondaryModalContentContainer: {
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        fontSize: "16px",
        alignItems: "center",
    } as React.CSSProperties,

    modalImageBox: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    modalImage: {
        width: "200px",
        height: "100px",
        aspectRatio: "1/1",
        marginBlockEnd: "25px",
    },
    modalSucessTextContainer: {
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        fontSize: "16px",
    } as React.CSSProperties,
    descriptionText: {
        fontSize: "16px",
    },
    modalFormControl: {
        // border: "none",
        // textDecorationColor: "purple",
        // textDecoration: "none",
    } as React.CSSProperties,
    modalTextField: {
        borderRadius: "4px",
        paddingBlock: "18px",
        paddingInline: "14.5px",
        fontSize: "16px",
        border: "1px solid rgba(0, 0, 0, .3)",
    },
    modalTextArea: {
        paddingBlock: "18px",
        paddingInline: "14.5px",
        boxSizing: "border-box",
        border: "1px solid rgba(0, 0, 0, .3)",
        borderRadius: "4px",
        fontSize: "16px",
        resize: "none",
        fontFamily: "sans-serif",
    } as React.CSSProperties,

    modalTextAreaError: {
        paddingBlock: "18px",
        paddingInline: "14.5px",
        boxSizing: "border-box",
        borderRadius: "4px",
        fontSize: "16px",
        resize: "none",
        fontFamily: "sans-serif",
        backgroundColor: "#fdf5f5",
        border: "1px solid red",
    } as React.CSSProperties,

    modalTextFieldError: {
        borderRadius: "4px",
        paddingBlock: "18px",
        paddingInline: "14.5px",
        fontSize: "16px",
        backgroundColor: "#fdf5f5",
        border: "1px solid red",
    },
    modalFormHelper: {
        paddingBlock: "0",
        paddingInline: "0",
    },
    modalFormHelperImage: {
        alignSelf: "center",
        paddingBlock: "0",
        paddingInline: "0",
    },
    excessIcon: {
        color: "white",
    },
    adminquickLink: {
        marginRight: "10px",
        color: "grey",
        cursor: 'pointer',
        textTransform: "capitalize",
        textDecoration: "underline",
    } as React.CSSProperties,
    noData: {
        height: '55vh',
        display: 'grid',
        placeItems: 'center'
    } as React.CSSProperties,
    loader: {
        minHeight: '55vh',
        display: "flex",
        justifyContent: "center",
        alignItems: 'center'
    } as React.CSSProperties,
    tableHead: {
        width:"3px",
        height:"100px",
        // maxWidth:"3vw",
        backgroundColor: localStorage.getItem('user_type') == "Superadmin" ? "darkgrey" : "orange",
        // borderRadius:"2px solid black"
    } as React.CSSProperties,
    tableNonHead: {
        // width:"3px",
        // minHeight:"50vh",
        // maxWidth:"3vw",
        width: '100%',
        height:"100px",
        backgroundColor: localStorage.getItem('user_type') == "Superadmin" ? "darkgrey" : "orange",
        // borderRadius:"2px solid black"
    } as React.CSSProperties,

    tableHeadSticky: {
        background: 'white',
        padding: 0,
        width: 200,
    } as React.CSSProperties,
    tableCellSticky: {
        background: 'white',
        width: 200,
        padding: 0,
        border: 'none'
    } as React.CSSProperties,
    tableCell: {
        background: 'white',
        border: "1px solid #BDBDBD",
        height:"10vh",
        textAlign:"center",


    } as React.CSSProperties,
    tableAdminCell: {
        background: '#e8e8e8',
        border: "1px solid #BDBDBD",
        height:"10vh",
        textAlign:"center"

    } as React.CSSProperties,
    dateHeader:{
        fontFamily: "sans-serif",
        fontSize: 14,
        marginTop: "10px",
        paddingBottom: "20px",
        fontWeight: 600
    } as React.CSSProperties,
    cancelCalenderBtn:{
        color: "black",
        marginLeft: "15px",
        padding: "10px 45px 10px 45px ",
        fontFamily: "sans-serif",
        fontSize: 16,
        textTransform: "none",
        width: "40%",
        fontWeight: 600,
        /* height: 30%; */
        backgroundColor: "#e8e8e8",
    } as React.CSSProperties,
    dropDownFilterMenu:{
        boxSizing: 'border-box',
        maxHeight: 400,
        width: 'auto',
        overFlowY: 'auto',
    } as React.CSSProperties,
    dateFilterStyle:{
        zIndex: 10,
        top: "50%",
        left: "50%",
        background: "white",
        width: "30%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-evenly",
        fontFamily: "RubikRoman-Medium",
        overflow: "auto",
        borderRadius: 5
    } as React.CSSProperties,
    accNameInitials:{
        background: "darkgrey",
        width: "35px",
        height: "35px",
        // fontSize: 13,
        borderRadius: "50%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        marginRight: 10
    } as React.CSSProperties,
    mainModalStyle:{
        display:"flex",
        justifyContent:"space-between",
        alignItems:"center",
        margin:"15px 0px 15px 0px",
        padding: "0px 10px"
    } as React.CSSProperties,
    duplicatebtn: {
        color: "#ffffff",
        fontFamily: "sans-serif",
        fontSize: "18px",
        fontWeight: 600,
        textAlign: "center",
    } as React.CSSProperties,
    duplicateadminbtn: {
        color: "black",
        fontSize: "18px",
        fontWeight: "bold",
        textAlign: "center",
        fontFamily: "sans-serif",
      } as React.CSSProperties,
    modalButton: {
        marginTop: "20px",
        backgroundColor: "#4eabf8",
        color: "white",
        borderRadius: "4px",
        width: "263px",
        textTransform: "none",
        height: "50px",
        fontWeight: 800,
    } as React.CSSProperties,
    modalButtonAdmin: {
        marginTop: "20px",
        backgroundImage: "radial-gradient(rgb(246 168 34), rgb(171 114 24))",
        color: "white",
        borderRadius: "4px",
        width: "263px",
        textTransform: "none",
        height: "50px",
        fontWeight: 600,
        fontFamily: "sans-serif",
        // marginLeft:"5px"
      } as React.CSSProperties,
    commonTabelCellStyle: {
        height: 70,
        padding: 7,
        overflow: 'hidden',
        border: '1px solid #BDBDBD'
    } as React.CSSProperties,
    dateFilterButtons: {
        margin: '12px 20px',
        display: 'flex',
        justifyContent: 'right'
    } as React.CSSProperties,
};
// Customizable Area End
