import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import actionCable from 'actioncable'
const configJSONBase = require("../../../framework/src/config");
import { checkListData, projectDataType, projectStatusType } from "../../../components/src/types";
import moment from 'moment';
toast.configure();
import {History} from 'history'
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  history: History<any>
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  toggler: boolean;
  projectData: projectDataType["project"];
  projectStatus: projectStatusType;
  projectId: number | string;
  loader: boolean;
  comment: string;
  displayComments: projectDataType["additional_attr"]["project_comments"];
  projectName: string;
  fieldData: projectDataType["fields"]
  projectPriority: boolean
  client: string
  projectDate: string
  dropDownOptions: {value: string,
    id: number,
    image: string,
    on_leave: boolean}[]
  typeDropDownOptions: {id:number, value: string}[]
  codeDropDownOptions: {
    id: string;
    value: string;
    image: string;
  }[]
  designerDropDownOptions: {value: string,
    id: number,
    image: string,
    on_leave: boolean}[]
  showCustomBox: boolean
  isUploading: boolean
  Attachments: projectDataType["additional_attr"]["attachments"];
  bottomStatusValue: string
  deletedAttachmentId: string | null
  projectAttachmentToBeUploaded: Blob[]
  activityData: projectDataType["additional_attr"]["activity"]
  showCommentTab: boolean
  openSnackBar: boolean
  snackBarMsg: string
  projectDisplayId: string | null
  currentFinulentStatus: string 
  finulentStatusChoices: string[]
  openCheckListModal: boolean
  checkListData: checkListData[]
  mentions: string[]
  mentionsData: string[]
  disableDesginerChecklist: boolean
  buttonClicked: string
  commentInProgress: boolean
  commentFileID : string[]
  lastValue: string
  ahjDropDown: {id:string,value:string, image:string}[]
  utilityDropDown: {id:string,value:string, image:string}[]
  openAlert: boolean
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ProjectTaskTrackingController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  handleData = this.props.history.location.state.data;
  submitCommentId = "";
  getProjectDetailsCallId = "";
  getDropDownOptionId = "";
  profileUpdateCallId = "";
  editCommentCallId = "";
  deleteCommentId = "";
  getTypeDropDownOptionCallId = "";
  uploadAttachmentToProjectCallId = "";
  deleteAttachmentCallId = "";
  FinulentStatusChoicesCallId = "";
  getCheckListCallId = "";
  updateCheckListCallId = "";
  getMentionsDataCallId = "";
  timer: string = "";
  previousMention: string[] = [];
  focusedValue= ""
  projectStatusChange = false
  getProjectCodeDropDownCallId = ""
  updateCodeCallId = ""
  getdesignerDropDownOptionCallId = ""
  uploadCommentFileCallId = ""
  getUtilityDropDownCallId = ""
  getAHJDropDownCallId  = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      comment: "",
      loader: true,
      toggler: true,
      projectId: this.props.history.location?.state?.data?.id || null,
      projectDisplayId: this.props.history.location?.state?.data?.project_id || null,
      projectStatus: {
        "0": "Project in Queue",
        "1": "Production Assigned",
        "2": "Production Intiated",
        "3": "Production Sent for QC",
        "4": "QC Initiated",
        "5": "File Sent for Corrections",
        "6": "Design Corrections Initiated",
        "7": "Revised File sent for QC",
        "8": "Production Sent for QA",
        "9": "QA Initiated",
        "10": "File Completed",
        "11": "Query",
        "12": "In Progress",
        "13": "Query - File Completed"
      },
      displayComments: [],
      projectName: '',
      projectData: [],
      fieldData: [],
      projectPriority: this.props.history.location?.state?.data?.priority == ("Yes"),
      client: this.props.history.location?.state?.data.client_name || "NA",
      projectDate: this.props.history.location?.state?.data?.created_at,
      dropDownOptions: [],
      typeDropDownOptions: [],
      codeDropDownOptions: [],
      showCustomBox: false,
      isUploading: false,
      Attachments: [],
      deletedAttachmentId: null,
      bottomStatusValue: "Select",
      projectAttachmentToBeUploaded: [],
      activityData: [],
      showCommentTab: true,
      openSnackBar: false,
      snackBarMsg: "",
      currentFinulentStatus: this.props.history.location?.state?.data.finulent_status,
      finulentStatusChoices: [],
      openCheckListModal: false,
      checkListData: [],
      mentions: [],
      mentionsData: [],
      disableDesginerChecklist: false,
      buttonClicked: "",
      commentInProgress: false,
      designerDropDownOptions: [],
      commentFileID: [],
      lastValue: "",
      ahjDropDown: [],
      utilityDropDown: [],
      openAlert: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.uploadAttachmentToProjectCallId) {
        if (responseJson && !responseJson.errors) {
          this.addAttachment(responseJson.data)
          this.getProjectDetailsById()
        }

      }

      if (apiRequestCallId === this.uploadCommentFileCallId) {
        if (responseJson && !responseJson.errors) {
         this.setState({
          commentFileID: [...this.state.commentFileID , ...responseJson.files],
          commentInProgress: false
         })
        }
      }

      if (apiRequestCallId === this.getCheckListCallId) {
        if (responseJson && !responseJson.errors) {
          this.setState({
            checkListData: responseJson.data
          })
        }

      }
      if (apiRequestCallId === this.getMentionsDataCallId) {
        if (responseJson && !responseJson.errors) {
          responseJson.data.sort((a:{display:string}, b:{display:string}) => a.display.localeCompare(b.display))
          this.setState({
            mentionsData: responseJson.data
          })
        }

      }
      if (apiRequestCallId === this.getUtilityDropDownCallId || apiRequestCallId === this.getAHJDropDownCallId) {
        if (responseJson && !responseJson.errors) {
          responseJson.sort((a:{name:string}, b:{name:string}) => a.name.localeCompare(b.name))
          let tempArr: {id:string, value:string, image:string}[] = []
          responseJson?.map((el: {name:string, image:string})=>{
            tempArr.push({id: el.name, value:el.name, image: 'NA'})
          })
          if(apiRequestCallId === this.getUtilityDropDownCallId){
            this.setState({
              utilityDropDown: tempArr
            })
          }else{
            this.setState({
              ahjDropDown: tempArr
            })
          }
        }
      }
      
      if (apiRequestCallId === this.updateCheckListCallId) {
        if (responseJson && !responseJson.errors) {
          return
        }

      }

      if (apiRequestCallId === this.FinulentStatusChoicesCallId) {
        if (responseJson && !responseJson.errors) {
          this.setState({
            finulentStatusChoices: Object.values(responseJson)
          })
        }

      }

      if (apiRequestCallId === this.deleteAttachmentCallId) {
        if (responseJson && !responseJson.errors) {
          this.updateAttachmentArray()
          this.getProjectDetailsById()
        }

      }

      if (apiRequestCallId === this.getProjectCodeDropDownCallId) {
        if (responseJson && !responseJson.errors) {

          let tempArr:{id: string, value:string, image: string}[] = []
          
          responseJson?.data.map((el: {id: string, attributes:{code:string}, image: string})=>{
            tempArr.push({id: el.id, value:el.attributes.code, image: 'NA'})
          })
          tempArr.sort(function (a: {id: string}, b: {id: string}) { return a.id as unknown as number - Number(b.id) })
          this.setState({
            codeDropDownOptions: tempArr
          })
        }

      }

      if (apiRequestCallId === this.editCommentCallId) {
        if (responseJson && !responseJson.errors) {
          this.getProjectDetailsById()
        }

      }
      if (apiRequestCallId === this.deleteCommentId) {
        if (responseJson && !responseJson.errors ) {
          this.getProjectDetailsById()
          toast.success("Comment Deleted Successfully", {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            });
        }

      }

      if (apiRequestCallId === this.submitCommentId) {
        this.setState({
          commentInProgress: false
        })
        if (responseJson && !responseJson.errors && responseJson?.data) {
          this.updateComments(responseJson)
          this.getProjectDetailsById()
        }

      }
      if (apiRequestCallId === this.profileUpdateCallId) {
        if (responseJson && !responseJson.errors) {
          if(this.projectStatusChange){
            window.location.replace(this.props.history.location.state.prevPath.toLowerCase())
            this.projectStatusChange = false
            return
          }
          this.projectStatusChange = false
          toast.success(responseJson.message, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            });
          this.getProjectDetailsById()
          this.updateFinulentStatus()
          this.getCheckList()
        }else{
          this.projectStatusChange = false
          if(responseJson.errors == "Please fill mandatory checklist" ){
            this.setState({
              openCheckListModal: true
            },()=>{this.displayErrorToast("Please complete the checklist first")})
            return
          }else if( responseJson.errors == "Please fill mandatory designer checklist"){
            this.setState({
              disableDesginerChecklist: true,
              openCheckListModal: true
            },()=>{this.displayErrorToast("Please complete the checklist first")})
            return
          }
          this.getProjectDetailsById()
          responseJson.errors.map((el:string)=>{
            return toast.error(el, {
              position: "bottom-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              });
          })
        }

      }
      if (apiRequestCallId === this.getTypeDropDownOptionCallId) {
        if (responseJson && !responseJson.errors) {
          let typeDropDownArr = [...responseJson.data]
          
          typeDropDownArr.sort(function (a: {id:number}, b: {id:number}) { return a.id - b.id })
          
          let tempArr: {id:number, value: string}[] = []
          typeDropDownArr.forEach((item: {id:number, attributes:{project_type:string}}) => {
            tempArr.push({
              value: item.attributes.project_type,
              id: Number(item.id)
            })
          });
          this.setState({
            typeDropDownOptions: tempArr
          })

        }

      }
      if (apiRequestCallId === this.getProjectDetailsCallId) {
        if (responseJson && !responseJson.errors) {

          localStorage.setItem("template_id", responseJson.additional_attr.template.id)
          localStorage.setItem("client_id", responseJson.additional_attr.client_id)
          localStorage.setItem("client_subfolder_id", responseJson.additional_attr.client_subfolder_id)
          localStorage.setItem("workspace_id", responseJson.additional_attr.workspace_id)

          let project = [...responseJson.project]
          let field = [...responseJson.fields]
          
          project.sort(function (a: {template_field_id: number}, b: {template_field_id: number}) { return a.template_field_id - b.template_field_id })
          field.sort(function (a: {sequence_no: number}, b: {sequence_no: number}) { return a.sequence_no - b.sequence_no })
          this.setState({
            projectData: project as projectDataType["project"],
            fieldData: field,
            displayComments: responseJson.additional_attr.project_comments,
            loader: false,
            Attachments: responseJson.additional_attr?.attachments,
            activityData: responseJson.additional_attr?.activity,
          }, ()=> {
            this.checkForCustomBox()
            this.getFinulentStatusChoices()
            this.setState({
              currentFinulentStatus:this.findValueByFieldName(Number(localStorage.getItem("tempValue")), project)
            })
            
          })
        }else{
          this.setState({
            loader: false
          })
        }

      }
      if (apiRequestCallId === this.getDropDownOptionId) {
        if (responseJson && !responseJson.errors) {

          let tempArr: {value:string,id:number,image:string,on_leave:boolean}[] = []
          responseJson.data.sort(function (a: {id:number}, b: {id:number}) { return a.id - b.id })

          responseJson.data.forEach((item: {attributes:{first_name: string, last_name: string, image: string, on_leave: boolean}, id: number}) => {
            tempArr.push({
              value: item.attributes.first_name + " " + item.attributes.last_name,
              id: Number(item.id),
              image: item.attributes.image,
              on_leave: item.attributes.on_leave
            })
          });
          this.setState({
            dropDownOptions: tempArr
          },()=>{
            this.getProjectDetailsById()
          })
        }

      }

      if (apiRequestCallId === this.getdesignerDropDownOptionCallId) {
        if (responseJson && !responseJson.errors) {

          let tempArr: {value:string,id:number,image:string,on_leave:boolean}[] = []
          responseJson.data.sort(function (a: {id:number}, b: {id:number}) { return a.id - b.id })

          responseJson.data.forEach((item:  {attributes:{first_name: string, last_name: string, image: string, on_leave: boolean}, id: number}) => {
            tempArr.push({
              value: item.attributes.first_name + " " + item.attributes.last_name,
              id: Number(item.id),
              image: item.attributes.image,
              on_leave: item.attributes.on_leave
            })
          });
          this.setState({
            designerDropDownOptions: tempArr
          })
        }

      }
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  async componentDidMount() {
    if (!this.state.projectId) {
      this.props.history.goBack();
      return;
    }
    setTimeout(() => {
      let elem = document.getElementById("TaskBoardCommentParent")
      elem!.addEventListener("keyup", (e) => {
      if (e.key === "ArrowUp" || e.key === "ArrowDown") {
        this.scrollTo(this.focusedValue)
      }
      
    });
    }, 6000);
    this.getDesignerDropDownOption(this.props.history.location?.state?.data?.workspace_id)
    this.getDropDownOption(this.props.history.location?.state?.data?.workspace_id)
    this.getTypeDropDown()
    this.getProjectCodeDropDown()
    this.getFinulentStatusChoices()
    this.getCheckList()
    this.getMentionsData("")
    this.getAHJUtilityDropDown("AHJ")
    this.getAHJUtilityDropDown("Utilities")
    this.projectWebSocket()
  }

  scrollTo = (id:null | undefined |string ) => {
    if (id !== null && id !== undefined) {
      let elem = document.getElementById(id);
      if (elem != null)
        elem.scrollIntoView({ behavior: "smooth", block: "nearest" });
    }
  };

  displayErrorToast = (msg:string) =>{
    toast.error(msg, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      });
  }

  cable!: actionCable.Cable;

  projectWebSocket = () => {
    this.cable = actionCable.createConsumer(
      `${configJSONBase.baseURL}/cable`
    );
    this.cable.subscriptions.create(
      {
        channel: "ProjectChannel",
        project_id: this.props.history.location?.state?.data?.id,
        user_id: localStorage.getItem("id")
      },
      {
        connected: () => {
        },
        disconnected: () => {
        },
        received: (data: {secreen: string}) => {
          if(data.secreen == "show"){
            toast.success("Data has been changed. Updating...", {
              position: "bottom-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              });
            this.getProjectDetailsById()
            this.getCheckList()
          }
        }
      }
    );
  }

  async componentWillUnmount(){
    this.cable.disconnect()
  }

  updateAttachmentArray = () => {

    let tempArr: {id:string}[] = []

    this.state.Attachments.forEach((e: {id:string}) => {
      if(e.id != this.state.deletedAttachmentId)
        tempArr.push(e)
    })
    this.setState({
      Attachments: tempArr,
      deletedAttachmentId: null
    })
    
  }

  handleCommentTextChange = (text: string) => {

    setTimeout(() => {
      let divElement = document.getElementById("customSuggestionsContainer");
      let elemHeight;
      if(divElement !== null){
        elemHeight = divElement.offsetHeight;
        
      let finalElement = document.querySelector<HTMLElement>("[id^=TaskBoardCommentParent] > div >div >div>div:nth-child(even) ") 

      if(finalElement != null)
        finalElement.style.cssText = `top:${-elemHeight}px !important`
      }
    }, 120);

    this.setState({ comment: text })
  }

  getProjectDetailsById = () => {

    const header = {
      token: localStorage.getItem("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getProjectDetailsCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.projectDetailById + `${this.state.projectId}${this.props.history.location.state.prevPath.toLowerCase() == "/taskboard" ? "?task_board=true" : "?task_board=false"}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true
  }
  
  getMentionsData = (str: string) => {

    const header = {
      token: localStorage.getItem("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getMentionsDataCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.MentionDataEndPoint + str.slice(1)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true
  }

  getCheckList = () => {

    const header = {
      token: localStorage.getItem("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCheckListCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCheckListEndPoint + `${this.state.projectId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true
  }

  getAHJUtilityDropDown = (name: "AHJ" | "Utilities") => {
    const header = {
      token: localStorage.getItem("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    
    if(name == "AHJ")
      this.getAHJDropDownCallId = requestMessage.messageId;
    else
      this.getUtilityDropDownCallId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.ahjDropDownEndPoint + name
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true
  }

  getFinulentStatusChoices = () => {

    const header = {
      token: localStorage.getItem("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.FinulentStatusChoicesCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.FinulentStatusChoicesEndPoint + `${this.state.projectId}` + `${this.props.history.location.state.prevPath.toLowerCase() == "/taskboard" ? "?task_board=true" : "?task_board=false"}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true
  }

  getDropDownOption = (workspace_id: string | number) => {

    const header = {
      token: localStorage.getItem("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getDropDownOptionId = requestMessage.messageId;

    const {client_id, client_subfolder_id } = this.props.history.location?.state?.data

    let path = `?workspace_id=${workspace_id}&client_id=${client_id}`

    if(client_subfolder_id != null)
      path += `&sub_client_id=${client_subfolder_id}`

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.dropDownOptionsEndPoint + path
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true
  }

  getDesignerDropDownOption = (workspace_id: string | number) => {

    const header = {
      token: localStorage.getItem("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getdesignerDropDownOptionCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.designerDropDownOptionsEndPoint + workspace_id
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true
  }


  getTypeDropDown = () => {

    const header = {
      token: localStorage.getItem("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getTypeDropDownOptionCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.typeDropDownEndPoint + `?template_id${localStorage.getItem("template_id")}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true
  }


  getProjectCodeDropDown = () => {

    const header = {
      token: localStorage.getItem("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getProjectCodeDropDownCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getProjectCodeDropDownEndPoint + this.props.history.location?.state?.data?.workspace_id
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true
  }

  submitComment = () => {

    this.setState({
      projectAttachmentToBeUploaded: []
    })
    const header = {
      token: localStorage.getItem("token")
    };

    let formdata = new FormData();
    formdata.append("comments", this.state.comment);
    formdata.append("commentable_type", "BxBlockDashboard::TemplateFieldData");
    formdata.append("commentable_id", this.state.projectId as string);

    if(this.state.mentions.length > 0){
      let uniq = [...new Set(this.state.mentions)];
      uniq.forEach((el)=>{
        formdata.append("mentioned_ids[]", el);
      })
    }
    
    if (this.state.projectAttachmentToBeUploaded.length >0){
      this.state.commentFileID.forEach((el)=>{
        formdata.append("files[]", el);
      })
    }
      
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.submitCommentId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.commentEndPoint
    );
    

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata

    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'POST'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true
  }


  uploadCommentFile = () => {

    this.setState({
      commentInProgress: true,
    })
    const header = {
      token: localStorage.getItem("token")
    };

    let formdata = new FormData();
    formdata.append("files[]", this.state.projectAttachmentToBeUploaded[this.state.projectAttachmentToBeUploaded.length - 1] as unknown as string);

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.uploadCommentFileCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.uploadFileCommentEndPoint
    );
    

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata

    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'POST'
    );
      
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true
  }

  editComment = (newComment: string, id: string | number) => {

    const header = {
      token: localStorage.getItem("token")
    };

    let formdata = new FormData();
    formdata.append("comments", newComment);
    formdata.append("commentable_type", "BxBlockDashboard::TemplateFieldData");
    formdata.append("commentable_id", this.state.projectId as string);

    if(this.state.mentions.length > 0){
      let uniq = [...new Set(this.state.mentions)];
      uniq.forEach((el)=>{
        formdata.append("mentioned_ids[]", el);
      })
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.editCommentCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.commentEndPoint + `/${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata

    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'PUT'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true
  }

  uploadAttachmentToProject = (file:Blob) => {

    const header = {
      token: localStorage.getItem("token")
    };

    let formdata = new FormData();
    formdata.append("attachment", file);


    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.uploadAttachmentToProjectCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.uploadAttachmentToProjectEndPoint + `/${this.state.projectId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'POST'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true
  }


  deleteComment = (id: string | number) => {

    const header = {
      token: localStorage.getItem("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteCommentId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.commentEndPoint + `/${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'DELETE'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true
  }


  deleteAttachment = (id: string | number) => {
    this.setState({deletedAttachmentId: id as string},()=>{
      this.updateAttachmentArray()
    })
    
    const header = {
      token: localStorage.getItem("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteAttachmentCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteAttachmentEndPoint + `/${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'DELETE'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true
  }

  updateComments = (comment: {data:string}) => {

    let obj = comment.data
    let tempArr = [...this.state.displayComments]
    tempArr.unshift(obj)
    this.setState({
      displayComments: tempArr,
      comment: ""
    })


  }

  findValueByFieldName = (searchKey: number, tempArr: {template_field_id: number, data: string}[]) => {
    
    let start = 0;
    let end = tempArr.length - 1;
    
    while (start <= end) {
      let middle = Math.floor((start + end) / 2);
      
      if (tempArr[middle].template_field_id === searchKey) {
        return tempArr[middle].data;
      } else if (tempArr[middle].template_field_id < searchKey) {
        start = middle + 1;
      } else {
        end = middle - 1;
      }
    }
    return "-1";
  }

  binarySearchforDropDown = (searchKey: number | string, tempArr: {id: number | string}[]) => {
    
    let start = 0;
    let end = tempArr.length - 1;
    searchKey = Number(searchKey)
    while (start <= end) {
      let middle = Math.floor((start + end) / 2);

      if (tempArr[middle].id == searchKey) {
        return tempArr[middle].id;
      } else if (tempArr[middle].id as number < searchKey) {
        start = middle + 1;
      } else {
        end = middle - 1;
      }
    }
    return -1;
  }


  updateProjectDetails = (value: string, item: {id:string | number}) => {
    this.setState({
      buttonClicked: value
    })
    let client_subfolder_id = localStorage.getItem("client_subfolder_id")
    let obj = {
      "project": {
        "template_id": localStorage.getItem("template_id"),
        "client_id": localStorage.getItem("client_id"),
        "client_subfolder_id": client_subfolder_id == "null" ? null : client_subfolder_id,
        "project_data": [
          {
            "template_field_id": item.id,
            "data": value
          }
        ]
      }
    }
    if(item.id == localStorage.getItem("tempValue"))
      this.projectStatusChange = true

    // return
    const header = {
      token: localStorage.getItem("token"),
      "Content-Type" : "application/json"
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.profileUpdateCallId = requestMessage.messageId;


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(obj)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'PUT'
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateProjectDetailsEndPoint + `/${this.state.projectId}`
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  checkForCustomBox = () => {
    try {
      this.state.fieldData.forEach((item) => {
        if(item.custom_field){
          this.setState({
            showCustomBox: true
          })
          throw Error
        }
      })
    } catch (error) {
      
    }
  }

  addAttachment = (response: string) => {
    let tempArr = [...this.state.Attachments]
      tempArr.push(response as unknown as {id:string})
      this.setState({
        Attachments: tempArr,
        isUploading: false
      })
  }

  handleUpload = (event:React.ChangeEvent<HTMLInputElement>) => {
    if(event?.target?.files){
      if(event?.target?.files[0]){
        this.uploadAttachmentToProject(event.target.files[0])
      }
    }

    // uncomment below code if you wish to allow same file to be uploaded multiple times continuously

    // const file = document.getElementById("uploadfileinput") as HTMLInputElement;
    // if(file != null){
    //   file.value = ''
    // }
 }

 changeFinulentStatusValue = (e: string) => {
  this.setState({
    bottomStatusValue: e
  })
 }

 handleProjectAttachment = (event: React.ChangeEvent<HTMLInputElement>) => {
   let tempArr = [...this.state.projectAttachmentToBeUploaded]
   if(event?.target?.files){
    if(event?.target?.files[0]){
      tempArr.push(event.target.files[0])
      this.setState({
        projectAttachmentToBeUploaded: tempArr,
       },()=>{
         this.uploadCommentFile()
       })
     }else{
       this.setState({
         projectAttachmentToBeUploaded: []
       })
     }
   }
    const file = document.getElementById("commentfileupload") as HTMLInputElement;
    if(file != null){
      file.value = ''
    }
 }

  closeSnackBar = () => {
    this.setState({
      openSnackBar: false
    })
  }

 
  updateFinulentStatus = () =>{
    this.setState({
      currentFinulentStatus: localStorage.getItem("finulentStatusValue") as string
    })
  }

  debouncerHandler = (value:string, data: {id:number,field_type:string}, what: string) =>{

    if(what == "onFocus"){
      this.setState({
        lastValue: value
      })
    }else{
      if(this.state.lastValue != value){
        if(data.field_type == "Calendar")
          this.updateProjectDetails(moment(value, "DD-MM-YYYY").format("YYYY-MM-DD"), data)
        else
          this.updateProjectDetails(value, data)
      }
    }
  }

  handleMention = (newPlainTextValue: string, newValue: string, mentions: {id:string}[])=>{
    let regex = /@[a-zA-Z]+/gm
    let temp:string[]|null = newPlainTextValue.match(regex)
    let tempArr: string[] = []
    
    if(this.state.mentions.length != mentions.length){
      mentions.forEach((el)=>{
          tempArr.push(el.id)
      })
      this.setState({
        mentions: tempArr
      })
    }

    if(temp == null)
      this.previousMention = []
    else{
      
      this.previousMention = temp
    }
  }

  setfocusedValue = (str: string) =>{
   this.focusedValue = str
  }

  handleCheckListModalClose = () => {
    this.setState({
      openCheckListModal: false,
      disableDesginerChecklist: false
    })
    this.getCheckList()
  }

  handleCheckListModalSave = () => {
    this.setState({
      openCheckListModal: false,
      disableDesginerChecklist: false
    })
    this.updateProjectDetails(this.state.buttonClicked, {id: localStorage.getItem("tempValue") as string} )
    this.getCheckList()
  }

  updateCheckList = (id: number, designer: boolean | string, qc: 0 | 1 | string ) => {
    
    let obj = {
          "project_checklist" :{
            "designer" : designer,
            "qc" : qc
          }
    }


    const header = {
      token: localStorage.getItem("token"),
      "Content-Type" : "application/json"
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateCheckListCallId = requestMessage.messageId;


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(obj)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'PUT'
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateCheckListEndPoint + id
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  deleteCommentAttachment = (index: number) => {
    
    if(index == this.state.projectAttachmentToBeUploaded.length - 1){
      this.setState({
        commentInProgress: false
      })
    }
    let tempArr: Blob[] = this.state.projectAttachmentToBeUploaded
    tempArr.splice(index, 1);
    
    let fileIdArr: string[] = this.state.commentFileID
    fileIdArr.splice(index, 1)

    this.setState({
      projectAttachmentToBeUploaded: tempArr,
      commentFileID: fileIdArr
    })
    
  }

  commentStateToggler = () =>{
    this.setState((prevState) => ({
      toggler: !prevState.toggler
    }));
  }

  closeAlert = () => {
    this.setState({
      openAlert: false
    })
  }
  // Customizable Area End
}
