import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
export const configJSONBase = require("../../../framework/src/config");
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const Solar = require("../assets/Slar.png");
const Telecom = require("../assets/Telecom_template.png");
const Basic = require("../assets/Basic_template.png");
const FireSafety = require("../assets/firesafety_template.png");
toast.configure();

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  that?: any;
  history?: any;
}

interface S {
  templates: any;
  loading: boolean;
  error: string;
}
interface SS {
  id: any;
}

export default class ReportingTemplateSelectionController extends BlockComponent<
  Props,
  S,
  SS
> {
  getTemplatesForReportAPIId: string = "";
  getClientsAPIId: string = "";

  query: any = new URLSearchParams(this.props.history?.location?.search);

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.getTemplatesForReport = this.getTemplatesForReport.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    this.state = {
      templates: [],
      loading: true,
      error: ""
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.getTemplatesForReport();
  }

  loadStaticTemplateImgs = (key: string) => {
    switch (key) {
      case 'SOLAR_TEMPLATE_FIELDS':
        return Solar;
      case 'TELECOM_TEMPLATE_FIELDS':
        return Telecom;
      case 'FIRESAFTEY_TEMPLATE_FIELDS':
        return FireSafety;
      case 'BASIC_TEMPLATE_FIELDS':
        return Basic;
      default:
        return Basic;
    }
  }

  getTemplatesForReport = () => {
    const workspaceId = this.query.get('wid') || localStorage.getItem("workspace_id");
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getTemplatesForReportAPIId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_dashboard/templates?reporting=true&workspace_id=${workspaceId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  handleGetTemplatesSucc = (responseJson: any) => {
    this.setState({ templates: responseJson.data, loading: false });
  }

  handleGetTemplatesFail = (responseJson: any) => {
    this.setState({ loading: false });
    if (responseJson?.errors?.length) {
      this.setState({ error: responseJson?.errors?.join(". ") });
    }
    else if (responseJson?.error) {
      this.setState({ error: responseJson?.error });
    }
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson?.errors && !responseJson?.error) {
        if (this.getTemplatesForReportAPIId === apiRequestCallId) {
          this.handleGetTemplatesSucc(responseJson);
        }
      } else {
        if (this.getTemplatesForReportAPIId === apiRequestCallId) {
          this.handleGetTemplatesFail(responseJson);
        }
      }
    }
  }
}
// Customizable Area End
