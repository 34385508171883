// Customizable Area Start
import React from "react";
import {
  Box,
  Breadcrumbs,
  Link,
} from "@material-ui/core";
import "./style.css";
const iconArrow = require("../assets/iconArrow.png");
import TemplateBreadcrumbsController, { Props } from "./TemplateBreadcrumbsController.web";

export default class TemplateBreadcrumbs extends TemplateBreadcrumbsController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { breadCrumbs } = this.state;
    return (
      <div style={webStyle.pagename}>
        <Box test-id="back-button" onClick={() => this.handleBack()} style={{...webStyle.arrowbox, background: window?.location?.pathname?.includes('reviewChecklist') ? "white" : "#f1f1f1"}}>
          <img src={iconArrow} />
        </Box>
        <div>
          <h4 style={webStyle.title}>{breadCrumbs?.template_name || 'Template Name'}</h4>
          <Breadcrumbs
            separator=">"
            aria-label="breadcrumb"
            style={webStyle.breadcrumb}
          >
            <Link test-id="workspace-breadcrumb" style={webStyle.link} color="inherit" onClick={() => this.handleWorkspaceClick()}>
              {breadCrumbs?.workspace?.name || 'Workspace'}
            </Link>
            <Link test-id="client-breadcrumb" style={webStyle.link} color="inherit" onClick={() => this.handleClientClick()}>
              {breadCrumbs?.client?.name || 'Client'}
            </Link>
            {breadCrumbs?.subfolder?.name && <Link test-id="sf-breadcrumb" style={webStyle.link} color="inherit" onClick={() => this.handleSFClick()}>
              {breadCrumbs?.subfolder?.name}
            </Link>}
          </Breadcrumbs>
        </div>
      </div>
    );
  }
}

const webStyle = {
  modalRoot: {
    display: "flex",
    gap: 1,
    fontSize: "16px",
    justifyContent: "center",
    borderBlockStart: "1px solid #ccc",
    paddingBlock: "2rem", //
    backdropFilter: "blur(5px)",
    overflow: "scroll",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    alignItems: "center",
  },
  link: {
    cursor: 'pointer'
  },
  pagename: {
    display: "flex",
  },
  breadcrumb: {
    marginLeft: 10,
    marginTop: -10,
  },
  arrowbox: {
    width: 36,
    height: 36,
    marginTop: 7,
    borderRadius: 6,
    cursor: 'pointer',
    backgroundBlendMode: "normal",
    alignItems: "center",
    justifyContent: "center",
    alignContent: "center",
    display: "flex",
  },
  title: {
    margin: 10,
    marginTop: 2,
    color: "#42454e",
    fontSize: "20px",
    fontWeight: 100,
  },
  arrow: {
    borderRadius: 8,
    cursor: 'pointer'
  },
}
// Customizable Area End
