// Customizable Area Start
import React from "react";
import {
  Box,
  Typography,
  Divider,
} from "@material-ui/core";
import "./style.css";
const redIcon = require("../assets/redicon.png");
import TemplateStepsFooterController, { Props } from "./TemplateStepsFooterController";

export default class TemplateStepsFooter extends TemplateStepsFooterController {
  constructor(props: Props) {
    super(props);
  }

  stepContainerClassname = (step: number, tab: number) => {
    if (localStorage.getItem('user_type') == 'Superadmin') {
      if (step === tab) return "active-box";
      else return "non-active-box"
    }
    else if (step === tab) return "admin-active-box";
    else return "admin-non-active-box"
  }

  stepWrapperClassname = (step: number, tab: number) => {
    if (localStorage.getItem('user_type') == 'Superadmin') {
      if (step === tab) return "active-oval";
      else return "non-active-oval"
    }
    else if (step === tab) return "admin-active-oval";
    else return "non-active-admin-oval"
  }

  stepNumberClassname = (step: number, tab: number) => {
    if (step === tab) return "active-oval-content";
    else return "non-active-oval-content"
  }
  render() {
    const { step } = this.props;
    return (
      <div className="template-footer">
        <Box test-id="step-one" className={this.stepContainerClassname(step, 1)}>
          <div className={this.stepWrapperClassname(step, 1)}>
            <div className={this.stepNumberClassname(step, 1)}>
              1
            </div>
          </div>
          <Typography className="template-box-content">
            Template Details
          </Typography>
        </Box>
        <Divider className="template-footer-devider" />
        <Box test-id="step-two" className={this.stepContainerClassname(step, 2)}>
          <div className={this.stepWrapperClassname(step, 2)}>
            <div className={this.stepNumberClassname(step, 2)}>
              2
            </div>
          </div>
          <Typography className="template-box-content">
            Checklist
          </Typography>
        </Box>
      </div>
    );
  }
}
// Customizable Area End
