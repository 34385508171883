import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import actionCable from 'actioncable'
const configJSONBase = require("../../../framework/src/config");
import { RouteComponentProps } from 'react-router-dom';
// Customizable Area End

export const configJSON = require("../../ProjectTaskTracking/src/config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  history: RouteComponentProps["history"];
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  projects: object;
  history: object;
  projectStatusColors: object;
  loader: boolean;
  clientDropDown: {client_type: string, id: string, name:string}[];
  selectedClient: string;
  selectedProjectData: object;
  dateRange: string | null | string[] | Date[];
  client_type: string;
  client_id: number | string;
  from: string | null | string[] | Date[];
  to: string | null | string[] | Date[];
  statusFlow: string[]
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ProjecttaskTracking2Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  allProjectApiCallId: string ="";
  allColorApiCallId: string = "";
  allClientDropDownId: string = "";
  selectedProjectApiId: string = "";
  localStorageName = window.location.pathname == "/taskboard" ? "taskboardData" : "managerTaskboardData"

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      loader: true,
      projects: {},
      projectStatusColors: {},
      history: this.props.history,
      clientDropDown: [],
      selectedClient: JSON.parse(sessionStorage.getItem(this.localStorageName) || '{}').client_id ? JSON.stringify({
        "client_type":JSON.parse(sessionStorage.getItem(this.localStorageName) || '{}').client_type,
        "client_id":JSON.parse(sessionStorage.getItem(this.localStorageName) || '{}').client_id 
      }) : "All",
      selectedProjectData: {},
      dateRange: "",
      client_type: JSON.parse(sessionStorage.getItem(this.localStorageName) || '{}').client_type ||  "",
      client_id: JSON.parse(sessionStorage.getItem(this.localStorageName) || '{}').client_id ||  "",
      from: JSON.parse(sessionStorage.getItem(this.localStorageName) || '{}').from || "",
      to: JSON.parse(sessionStorage.getItem(this.localStorageName) || '{}').to || "",
      statusFlow: []
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (!responseJson?.error && responseJson) {
        
        switch (apiRequestCallId) {
          case this.allProjectApiCallId:
            this.setState({ projects: { ...responseJson } });
            this.setState({ loader: false });
            break;

          case this.allColorApiCallId:
            this.setState({ projectStatusColors: { ...responseJson } });
            break;

          case this.allClientDropDownId:
            let tempArr = responseJson.sort((a:{name:string}, b:{name:string}) => a.name.localeCompare(b.name))
            return this.setState({ clientDropDown: [...tempArr] });

          case this.selectedProjectApiId:
            // Attemp to sort starts
            let temp: string[] = []
            let Ineed = ["Project in Queue", "Production Assigned", "Production Initiated", "Production Sent for QC",
            "QC Initiated", "File Sent for Corrections", "Design Corrections Initiated", "Revised File sent for QC", "Production Sent for QA",
            "QA Initiated", "File Completed", "Query", "In Progress", "Query - File Completed"]
            Ineed.forEach((el)=>{
              if(JSON.stringify(Object.keys(responseJson).includes(el)) == "true")
                temp.push(el)
            })
            //Attempt to sort ends, statusFlow has been added below
            this.setState({ projects: { ...responseJson }, statusFlow: temp,  loader: false });
            break;
          default:
            break;
        }
      } else{
        this.setState({ loader: false,projects:{}});
      }
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let tempMsg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    tempMsg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(tempMsg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start

  cable!:actionCable.Cable;
  projectWebSocket = () => {
    this.cable = actionCable.createConsumer(
      `${configJSONBase.baseURL}/cable`
    );
    this.cable.subscriptions.create(
      {
        channel: "KanbanChannel",
        user_id: localStorage.getItem("id")
      },
      {
        connected: () => {
        },
        disconnected: () => {
        },
        received: (data: {secreen: string}) => {
          if(data.secreen == "index"){
            toast.success("Status has been changed. Updating...", {
              position: "bottom-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              });
              if(this.state.dateRange)
                this.getSelectedProjects("date",new Date(this.state.dateRange[0]).toLocaleDateString("en-GB"),new Date(this.state.dateRange[1]).toLocaleDateString("en-GB"))
              else
                this.getSelectedProjects("date",null,null)
          }
        }
      }
    );
  }

  async componentWillUnmount() {
    this.cable.disconnect();
  }


  getAllStatusColor = () => {
    const header = {
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.allColorApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.allProjectColorApi
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.typeForGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getAllClientDropDown = () => {
    const header = {
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.allClientDropDownId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.allClientDropDownApi + `${window.location.pathname.toLowerCase() == "/managertaskboard" ? "?task_board=false" : "?task_board=true"}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.typeForGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };


  handleDatePicker = (a: string| Date[] ) => {
    if (a == null){
      this.setState({
        dateRange: null,
        from: "",
        to: "",
      })
      return
    }

    this.setState({
      dateRange:a as unknown as string,
      from: new Date(a[0]).toLocaleDateString("en-GB"),
      to: new Date(a[1]).toLocaleDateString("en-GB"),
    })
  }

  getSelectedProjects = (reqType: string, data1: Date | string | null, data2: Date | string | null) => {
    let clientDropDownOption:string = "";
    let dateRangePart:string = "";
    let client_id = JSON.parse(sessionStorage.getItem("taskboardData") || '{}').client_id || this.state.client_id
    let client_type = JSON.parse(sessionStorage.getItem("taskboardData") || '{}').client_type || this.state.client_type

    if(reqType == "dropDown"){
      clientDropDownOption = !data1 ? `` : `client_id=${data1}&client_type=${data2}`;
      dateRangePart = !this.state.from ? `` : `from=${this.state.from}&to=${this.state.to}&`;

    }else if(reqType == "date"){
      dateRangePart = !this.state.dateRange ? `` : `from=${data1}&to=${data2}&`;
      clientDropDownOption = !this.state.client_id ? `` : `client_id=${client_id}&client_type=${client_type}`
    }


    const selectedProjectsApi = window.location.pathname == "/managertaskboard" ? configJSON.allProjectApi + "?" + dateRangePart + clientDropDownOption : `bx_block_taskallocator/kanbanboard?task_board=true&${dateRangePart+clientDropDownOption}`;
    const header = {
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.selectedProjectApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      selectedProjectsApi
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.typeForGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  async componentDidMount() {
    this.previousDataExists()
    this.setState({loader:true});
    this.getAllStatusColor();
    this.getAllClientDropDown();
    this.projectWebSocket()
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<S>,
  ): void {
    if (this.state.dateRange && this.state.dateRange !== prevState.dateRange) {
      this.setState({ loader: true });
      const fromDate = new Date(this.state.dateRange[0]).toLocaleDateString("en-GB");
      const toDate = new Date(this.state.dateRange[1]).toLocaleDateString("en-GB");
      this.getSelectedProjects("date",fromDate,toDate);
    }else if(!this.state.dateRange && this.state.dateRange !== prevState.dateRange){
      this.setState({ loader: true });
      // this.setState({from:null,to:null})
      this.getSelectedProjects("date",null,null);
    }

    if((this.state.dateRange !== prevState.dateRange || this.state.client_id != prevState.client_id || this.state.client_type != prevState.client_type) ){
      console.log("prevState.dateRange",prevState.dateRange)
      sessionStorage.setItem(this.localStorageName, JSON.stringify({
        to: this.state.to,
        from: this.state.from,
        client_id: this.state.client_id,
        client_type: this.state.client_type
      }))
    }
  }

  previousDataExists = () => {
    let tempTo;
    let from;
   
    tempTo = JSON.parse(sessionStorage.getItem(this.localStorageName) || '{}').to
    from = JSON.parse(sessionStorage.getItem(this.localStorageName) || '{}').from

    if(tempTo == undefined || from == undefined){
      const today = new Date();
      const oneWeeksAgo = new Date();
      oneWeeksAgo.setDate(today.getDate() - 7);
      const tempArr = [oneWeeksAgo,today];
      this.setState({
        dateRange:tempArr,
        from: oneWeeksAgo.toLocaleDateString("en-GB"),
        to: today.toLocaleDateString("en-GB")
      });
    }else if(tempTo == "" || from == "" ){
      this.setState({
        dateRange:null,
        from: "",
        to: ""
      });
    }else{
      let toArr = tempTo.toString().split("/")
      let fromArr = from.toString().split("/")
      const tempArr = [fromArr[2]+ "/" + fromArr[1]+ "/" + fromArr[0],toArr[2]+ "/" + toArr[1]+ "/" + toArr[0]];
      this.setState({
        dateRange:tempArr,
        from: from,
        to: tempTo
      });
    }
  }

  handleClientChange = (e:React.ChangeEvent<{ value: unknown }>) => {
      this.setState({ 
        selectedClient: e.target.value as string,
       });
      this.setState({ loader: true });
      if (e.target.value !== "All") {
        const tempObj = JSON.parse(e.target.value as string);
        this.setState({ client_id: tempObj.client_id, client_type: tempObj.client_type },()=>{
          this.getSelectedProjects("dropDown", tempObj.client_id, tempObj.client_type);
        });
      } else {
        this.getSelectedProjects("dropDown",null,null);
        this.setState({ client_id: "", client_type: "" });
      }
    }

  // Customizable Area End
}
//validation test