import {Tooltip,withStyles,Theme} from "@material-ui/core";
import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";

export const LightTooltip = withStyles((theme: Theme) => ({
    tooltip: {
      backgroundColor: "white",
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      position:"absolute",
      top:"-80",
      boxSizing:"border-box",
      overflowY:"scroll",
      height:"70vh",
      width:"25vw",
      marginLeft:"-10px",
      '&::-webkit-scrollbar': {
        width: '0.6em',
      },
      '&::-webkit-scrollbar-track': {
        boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
        webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#E1E0E5',
        borderRadius:"5px",
        marginRight:"10px"
      }
    },
  }))(Tooltip);