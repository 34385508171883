import React from 'react'
import {
  Grid,
  Box,
  Button, Typography,
  // Customizable Area Start
  Divider,
  Table,
  TableContainer,
  TextField,
  TableRow,
  TableCell, TableBody,
  Modal,
  Select,
  InputLabel,
  MenuItem, createMuiTheme,
  CircularProgress,Tooltip
} from "@material-ui/core";

// Customizable Area Start
import Close from '@material-ui/icons/Close';
import {
  MuiPickersUtilsProvider, DatePicker
} from "material-ui-pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import { ThemeProvider } from "@material-ui/styles";
import { makeStyles } from "@material-ui/core/styles";
import { AiFillCaretLeft, AiOutlineDelete } from "react-icons/ai";
import "./Generic.css";
import { finulentImageInvoice, uploadimage } from "./assets";
export const configJSONBase = require("../../../framework/src/config");

// Customizable Area End
import GenericInvoiceController, { Props } from "./GenericInvoiceController";

export default class GenericInvoiceStatus extends GenericInvoiceController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area Start
  console.log("props.....generic invoie",this.props)
    // Customizable Area End
  }

getView=(status:any)=>{
  if(status == "Full Paid"){
    return;
  }
  else{
    return (
      <Box
        display={"flex"} justifyContent={"flex-end"}
        margin={"30px 0px"}
        bgcolor={"#f3f4f6"}
        border={"1px solid #d4d3d4"}
        borderRadius={"4px"}
         width={"95%"}
         >
      <Box
        style={{display: "flex", padding : "10px", gap : "20px"}}
        >
         {this.getButtonsToChangeStatus(status)}
        </Box>
        </Box>
      
    )
  }
}
getButton=(label:any, status:any)=>{
    return (
    
    <Button
     data-test-id='buttonBlueTestID'
    variant={"contained"}
    style={webStyle.buttonBlue}
    onClick={()=>{
      //istanbul ignore next
      if(label == "Move to Approved by client"){
        this.setState({movetoApprovedByClientModal: true})
      }
      else{
        this.UpdateStatus(status)
      }
    }}
    // onClick={()=>this.UpdateStatus(status)}
    >
     {label}
   </Button>
    )
  }

  getButtonsToChangeStatus = (status:any) => {
    switch (status) {
      case "Draft":
        return <>
        {this.getButton("Move to Write off", "Write Off")}
        {this.getButton("Move to Sent", "Sent")}
       </>
        case "Sent":
          return <>
          {this.getButton("Move to Write off", "Write Off")}
          {this.getButton("Move to Approved by client","Approved By Client")}
          </>
          case "Approved By Client":
            return <>
            {this.getButton("Move to Partially Paid", "Partially Paid")}
            {this.getButton("Move to Full Paid", "Full Paid")}
            {this.getButton("Move to Write off", "Write Off")}
            </>
            case "Partially Paid":
              return <>
              {this.getButton("Move to Full Paid", "Full Paid")}
              {this.getButton("Move to Write off", "Write Off")}
              </>
              case "Write Off":
                return <>
               {this.getButton("Move to Full Paid", "Full Paid")}
               </>
                case "Full Paid" :
                  return null;

                  case "Overdue" : 
                  return <>
                    <InputLabel className="inputLabel">Move to</InputLabel>
                 {this.getStatusDropdown()}
                  <InputLabel className="inputLabel">Status</InputLabel>
                  <Button
                    onClick={this.getConfirm}
                   variant={"contained"}
                   style={webStyle.buttonBlue}
                   >
                   Confirm
                  </Button>
                  </>
    
      default:
        break;
    }
  }

  createDataForAddress = (name: string, calories: number) => {
    return { name, calories };
  };
  createDataForTerms = (name: string, calories: string) => {
    return { name, calories };
  };
  createBillTableData = (name: string, calories: number) => {
    return { name, calories };
  };

  getConfirm = () => {
    if(!this.state.status){
      return
    }
    else{
       this.UpdateStatus()  
    }
  }

  handleDeleteBtn = () => {
    this.setState({ isConfirm: true })

  }
  handleCancelBtn = () => {
    this.setState({ isConfirm: false })
  }
  handleYes = () => {
    this.deleteInvoice()
    this.setState({ isConfirm: false })
  }
  confirmDelete = () => (
    <Modal
      className="modal-backdrop"
      //   open={false}
      open={this.state.isConfirm}
      onClose={this.handleCancelBtn}
      style={{
        overflow: "auto",
      }}
    >
      <div
        // className="modal-successcontent"
        style={{
          zIndex: 10,
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          background: "white",
          width: "20%",
          // height: "20%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "20px",
          // padding:"20px"
        }}
      >
        <Grid>
          <div
            style={{ textAlign: "center" }}
          //    className="usercontent"
          >
            <div className="confirm-heading" style={{ padding: "10px" }} >
              <Typography style={{ fontWeight: 900 }}>Delete Invoice</Typography>
              <Close onClick={this.handleCancelBtn} />
            </div>
            <Divider />
            <div style={{ padding: "20px" }} className="confirmbody">
              <Typography>Are you sure you want to delete this invoice ?</Typography>
            </div>
            <Divider />
            <div style={{ padding: "20px" }}>
              <Button
                onClick={this.handleCancelBtn}
                className="cancelbtn"

              >
                Cancel
              </Button>
              <Button
                onClick={this.handleYes}
                className="yesbtn"
                style={{ backgroundColor: "#0096FF " }}
              >
                Yes
              </Button>
            </div>

            {/* </div> */}
          </div>
        </Grid>
      </div>
    </Modal>
  )

 
  handleColor=(status:any)=>{
    // console.warn("status",status)
    switch(status){
      case "Draft":
        return "#fac12c"
        case "Sent":
          return "#f66799"
          case "Approved By Client":
            return "#4057c9"
            case "Full Paid" :
            return "#298200"
            case "Overdue" :
              return "#a252cf"
              case "Partially Paid" :
                return "#f7860d"
                case "Write Off":
                return "#f51b13"
    }
    
  } 

  BillDetailsSection = () => {
    return (
      <>
        {/* Total Amount Section Start*/}
        <Box display={"flex"} justifyContent={"center"}>
          <Box className={"totalAmount"} width={"95%"}>
            <Box fontWeight={"bolder"} component={"div"}>
              <Box>Total Amount</Box>
              <Box>{this.state.invoiceData?.attributes?.currency_symbol}{this.state.invoiceData?.attributes?.billing_amount}</Box>
            </Box>
            <Box marginTop={"15px"} component={"div"}>
              <Box color={"#868686"} fontSize={"10px"}>
                Tax Details
              </Box>
              <Box />
            </Box>
            {this?.state?.invoiceData?.attributes?.tax_details[0]?.cgst != 0 && <Box margin={"10px 0px"} component={"div"}>
              <Box>CGST</Box>
              <Box>{this?.state?.invoiceData?.attributes?.currency_symbol}{this?.state?.invoiceData?.attributes?.tax_details[0]?.cgst}</Box>
            </Box>}
            {this?.state?.invoiceData?.attributes?.tax_details[0]?.igst != 0 && <Box margin={"10px 0px"} component={"div"}>
              <Box>IGST</Box>
              <Box>{this?.state?.invoiceData?.attributes?.currency_symbol}{this?.state?.invoiceData?.attributes?.tax_details[0]?.igst}</Box>
            </Box>}
           { this?.state?.invoiceData?.attributes?.tax_details[0]?.sgst != 0 && <Box margin={"10px 0px"} component={"div"}>
              <Box>SGST</Box>
              <Box>{this?.state?.invoiceData?.attributes?.currency_symbol}{this?.state?.invoiceData?.attributes?.tax_details[0]?.sgst}</Box>
            </Box>}
            {this?.state?.invoiceData?.attributes?.tax_details[0]?.other_tax != 0 && <Box margin={"10px 0px"} component={"div"}>
              <Box>Other Tax</Box>
              <Box>{this?.state?.invoiceData?.attributes?.currency_symbol}{this?.state?.invoiceData?.attributes?.tax_details[0]?.other_tax}</Box>
            </Box>}
            <Box component={"hr"} />
            <Box fontWeight={"bolder"} margin={"15px 0px"} component={"div"}>
              <Box>Gross Total</Box>
              <Box>{this?.state?.invoiceData?.attributes?.currency_symbol}{this?.state?.invoiceData?.attributes?.gross_total}</Box>
            </Box>
          </Box>
        </Box>
        {/* Total Amount Section End*/}
      </>
    );
  };

  parseImg(img: string | null) {
    if (!img) return undefined;
    const imageLink = configJSONBase.baseURL;
    const imageFragment =
      typeof img === "string" && img.split("/").includes("rails");
    const imgSrc = imageFragment ? `${imageLink}/${img}` : img;
    return imgSrc;
  }

  AccountDetails = () => {
    return (
      <>
        <Box display={"flex"} justifyContent={"center"}>
          <Box width={"95%"}>
            <Box marginBottom={"2.5vh"}>
              I have no objection if the amount is credited to my bank directly.
              Bank details are given below:
            </Box>

            <Box>
              <Box marginY={"2vh"} display={"flex"} alignItems={"center"}>
                <Box width={"18%"}>Account Name</Box>
                <Box marginX={"5px"}>:</Box>
                <Box width={"45%"} fontWeight={"bolder"}>
                  <Box>{this.state.invoiceData?.attributes?.account_details?.account_name}</Box>
                  {/* {this.state.pageType ? (
                  ) : null
                  //  (
                  //   <TextField
                  //     fullWidth
                  //     size={"small"}
                  //     variant={"outlined"}
                  //     value={""}
                  //   />
                  // )
                } */}
                </Box>
              </Box>
              <Box marginY={"2vh"} display={"flex"} alignItems={"center"}>
                <Box width={"18%"}>A/C Number</Box>
                <Box marginX={"5px"}>:</Box>
                <Box width={"45%"} fontWeight={"bolder"}>
                  <Box>{this.state.invoiceData?.attributes?.account_details?.account_number}</Box>
                  {/* {this.state.pageType ? (
                    ) : null
                    // (
                    //   <TextField
                    //     fullWidth
                    //     size={"small"}
                    //     variant={"outlined"}
                    //     value={""}
                    //   />
                    // )
                    } */}
                </Box>
              </Box>
              <Box marginY={"2vh"} display={"flex"} alignItems={"center"}>
                <Box width={"18%"}>MICR Code</Box>
                <Box marginX={"5px"}>:</Box>
                <Box width={"45%"} fontWeight={"bolder"}>
                  <Box>{this.state.invoiceData?.attributes?.account_details?.micr_code}</Box>
                  {/* {this.state.pageType ? (
                    ) : null
                  //    (
                  //   <TextField
                  //     fullWidth
                  //     size={"small"}
                  //     variant={"outlined"}
                  //     value={""}
                  //   />
                  // )
                  } */}
                </Box>
              </Box>
              <Box marginY={"2vh"} display={"flex"} alignItems={"center"}>
                <Box width={"18%"}>Branch IFSC Code</Box>
                <Box marginX={"5px"}>:</Box>
                <Box width={"45%"} fontWeight={"bolder"}>
                  <Box>{this.state.invoiceData?.attributes?.account_details?.ifsc_code}</Box>
                  {/* {this.state.pageType ? (
                    ) : null
                  //   (
                  //   <TextField
                  //     fullWidth
                  //     size={"small"}
                  //     variant={"outlined"}
                  //     value={""}
                  //   />
                  // )
                } */}
                </Box>
              </Box>
              <Box marginY={"2vh"} display={"flex"} alignItems={"center"}>
                <Box width={"18%"}>Swift Code</Box>
                <Box marginX={"5px"}>:</Box>
                <Box width={"45%"} fontWeight={"bolder"}>
                  <Box>{this.state.invoiceData?.attributes?.account_details?.swift_code}</Box>
                  {/* {this.state.pageType ? (
                    ) : null
                  //   (
                  //   <TextField
                  //     fullWidth
                  //     size={"small"}
                  //     variant={"outlined"}
                  //     value={""}
                  //   />
                  // )
                } */}
                </Box>
              </Box>
              <Box marginY={"2vh"} display={"flex"} alignItems={"center"}>
                <Box width={"18%"}>Branch Address</Box>
                <Box marginX={"5px"}>:</Box>
                <Box width={"45%"} fontWeight={"bolder"}>
                  <Box>{this.state.invoiceData?.attributes?.account_details?.branch_address}</Box>
                  {/* {this.state.pageType ? (

                  ) : null
                  //  (
                  //   <TextField
                  //     multiline
                  //     fullWidth
                  //     size={"small"}
                  //     variant={"outlined"}
                  //     value={``}
                  //   />
                  // )
                  } */}
                </Box>
              </Box>
              <Box marginY={"2vh"} display={"flex"} alignItems={"center"}>
                <Box width={"18%"}>
                  Whether the tax is payable under the Reverse charge
                </Box>
                <Box marginX={"5px"}>:</Box>
                <Box width={"45%"} fontWeight={"bolder"}>
                  <Box>{this.state.invoiceData?.attributes?.account_details?.tax_payable?"Yes":"No"}</Box>
                  {/* {this.state.pageType ? (
                    ) : null
                  //   (
                  //   <TextField
                  //     fullWidth
                  //     size={"small"}
                  //     variant={"outlined"}
                  //     value={""}
                  //   />
                  // )
                  } */}
                </Box>
              </Box>
              <Box marginY={"4vh"} display={"flex"} alignItems={"center"}>
                <Box width={"18%"}>Signature</Box>
                <Box marginX={"5px"}>:</Box>
                { this.state.invoiceData?.attributes?.signature &&
                  <Box width={"27%"} fontWeight={"bolder"}>
                  <img
                    src={this.parseImg(this.state.invoiceData.attributes.signature)}
                      width={"100%"}
                      height={"100px"}
                      className={"signature"}
                      />
                </Box> }
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          marginBottom={"2vh"}
          color={"#9A9A9A"}
          display={"flex"}
          justifyContent={"flex-end"}
        >
          <Box>India : +91 97694 92539</Box>
          <Box marginX={"0.5vh"}>|</Box>
          <Box>info@finulent.com</Box>
          <Box marginX={"0.5vh"}>|</Box>
          <Box>US: +1 97831 055 45</Box>
        </Box>
      </>
    );
  };

  getSideElevationView=()=>{
    return <Grid >
      <Typography style={{fontWeight:"bold", fontSize:"0.875rem"}}>Side Elevation</Typography>
      <Typography  style={{fontSize:"0.775rem"}}>cost</Typography>
    </Grid>
  }


  getTableCellForTypeSideOrKwData=(row:any)=>{
    if(this.state.isAdditionalCost && this.state.invoiceData?.attributes?.define_invoices?.invoice_generation_method == "type_side_elevation_structure"){
      return <TableCell align="center">{row?.type_side_additional_amount ? row?.type_side_additional_amount : "0"}</TableCell>
    }
    if(this.state.invoiceData?.attributes?.define_invoices?.invoice_generation_method == "kw_structure"){
      return <TableCell align="center">{row?.kw_amount ? row?.kw_amount : "0"}</TableCell>
    }
  }

  getTableCellForTypeSideOrKw=()=>{
    if(this.state.isAdditionalCost && this.state.invoiceData?.attributes?.define_invoices?.invoice_generation_method == "type_side_elevation_structure"){
      return <TableCell align="center">{this.getSideElevationView()}</TableCell>
    }
    if(this.state.invoiceData?.attributes?.define_invoices?.invoice_generation_method == "kw_structure"){
      return <TableCell align="center">Kw</TableCell>
    }    
  }

  getColSpan=()=>{
    if(this.state.isAdditionalCost && this.state.invoiceData?.attributes?.define_invoices?.invoice_generation_method == "type_side_elevation_structure"){
      return 2
    }
    if(this.state.invoiceData?.attributes?.define_invoices?.invoice_generation_method == "kw_structure"){
      return 2
    }    
    return 1
  }

  BillingTable = () => {
    return (
      <>
        <Box marginY={"3vh"}>
          <TableContainer>
            <Table aria-label="simple table">
              <TableRow
                className="tHead billLastRow"
                style={{
                  backgroundColor: "#F6A724",
                  border: "1px solid black",
                }}
              >
                <TableCell align="center">S no.</TableCell>
                <TableCell align="center">Project Date</TableCell>
                <TableCell align="center">Project Id</TableCell>
                <TableCell align="center">Project Name</TableCell>
                <TableCell align="center">Project Type</TableCell>
                {this.getTableCellForTypeSideOrKw()}
                <TableCell align="center">Amount</TableCell>
              </TableRow>
              <TableBody>
                {this?.state?.invoiceData?.attributes?.all_projects?.length > 0 && this.state?.invoiceData?.attributes?.all_projects.map((row: any, i: any) => (
                  <TableRow key={i} className={"billTable"}>
                    <TableCell align="center">{i + 1}</TableCell>
                    <TableCell align="center">{row?.project_date}</TableCell>
                    <TableCell align="center">{row?.project_id}</TableCell>
                    <TableCell align="center">
                    {row?.project_name}
                    </TableCell>
                    <TableCell align="center">{row?.project_type}</TableCell>
                    {this.getTableCellForTypeSideOrKwData(row)}
                    <TableCell align="center">
                      <Box display={"flex"} alignItems={"center"} justifyContent="center">
                      {/* <Box display={"flex"} alignItems={"center"} justifyContent="space-between"> */}
                        <Box style={{flexGrow:1}} >{this.state.invoiceData?.attributes?.currency_symbol}{row?.amount ? row?.amount : 0}</Box>
                        {!this.state.pageType && (
                          <Box
                            className={"icon"}
                            style={{
                              border: "none",
                              marginRight : "1.1rem"
                              // transform: "translate(30px, 0px)",
                            }}
                            onClick={()=>this.deleteProject(row?.id)}
                          >
                            <AiOutlineDelete />
                          </Box>
                        )}
                        {/* </Box> */}
                      </Box>{" "}
                    </TableCell>
                  </TableRow>
                ))}
                <TableRow className={"billLastRow"}>
                  <TableCell
                    style={{ borderLeft: "1px solid black" }}
                    colSpan={3}
                    // align="center"
                  >
                    {!this.state.pageType && <Button
                      onClick={this.handleOpen}
                      size={"small"}
                      variant={"contained"}
                      style={{
                        backgroundColor: "#4FABF6",
                        textTransform: "none",
                        color: "#fff",
                        padding: "10px 30px",
                        fontWeight: 600,
                      }}
                    >
                      Add Project
                    </Button>}
                    {this.getAddProjectModal()}
                  </TableCell>
                  <TableCell colSpan={this.getColSpan()} align="center" />
                  <TableCell style={{ fontWeight: "bolder", width:"15%" }} align="center">
                    Billing Amount
                  </TableCell>
                  <TableCell
                    style={{
                      fontWeight: "bolder",
                      borderRight: "1px solid black",
                    }}
                    align="center"
                  >
                    {this.state.invoiceData?.attributes?.currency_symbol}{this.state.invoiceData?.attributes?.billing_amount}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </>
    );
  };

  getKwOrSideElevationFields=()=>{
    if(this.state.template == "Telecom Template"){
      return(
        <Box marginY={"2.5vh"}>
        <Box className={"modalLabel"} fontSize={"13px"}>
          KW
        </Box>
        <Box>
          <TextField
            variant={"outlined"}
            fullWidth
            type="number"
            size={"small"}
            placeholder="Enter KW here"
            onChange={(e)=>this.setState({kwAmount:e.target.value
              // , isProjectAmountError:false,
            })}
            value={this.state.kwAmount}
          />
        </Box>
      </Box>
      );
    }

    if(this.state.template == "Solar Template"){
      return(
        <Box marginY={"2.5vh"}>
        <Box className={"modalLabel"} fontSize={"13px"}>
          Additional cost per file{" (Side Elevation)"}
        </Box>
        <Box>
          <TextField
            variant={"outlined"}
            fullWidth
            type="number"
            size={"small"}
            placeholder="Enter project amount here"
            onChange={(e)=>this.setState({sideElevationAmount:e.target.value
              // , isProjectAmountError:false,
            })}
            value={this.state.sideElevationAmount}
          />
        </Box>
      </Box>
      );
    }
  }

  getPopupHeight=()=>{
    if(this.state.template == "Telecom Template" || this.state.template == "Solar Template"){
      return "78%"
    }else{
      return "68%"
    }
  }

  getAddProjectModal=()=>{
    const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
    id:"customSuggestionsContainer1"
  },
};
    return(
      <Modal
            open={this.state.open}
            onClose={this.handleClose}
            className="modalbackdrop"
            style={{
          //     maxHeight :'95%',
          // overflowY : "scroll",

  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  backgroundColor: "rgba(0, 0, 0, 0.4)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  zIndex: 2,
  alignContent: "center"
            }}
          >
         <div 
     style={{
      height:this.getPopupHeight(),
      width: "500px",
    fontFamily: "sans-serif",
    backgroundColor: "white",
    alignItems: "left",
    justifyContent: "left",
    overflowY: "scroll",
    }}
    >
      <div style={webStyle.modalContent}>
        <Grid 
        container
        style={{
        borderBottom : "1px solid rgb(232, 232, 232)", 
        padding: "1.3rem 1.4rem",
      }}
         justifyContent="space-between">
                <Box fontSize={"20px"} fontWeight={"bold"}>
           Add Project
          </Box>
            <Close
             onClick={this.handleClose}
            //  style={webStyle.closebuttonstyle}
             />
        </Grid>
        {/* <Box display={"flex"} justifyContent={"space-between"} 
        style={{borderBottom : "1px solid rgb(232, 232, 232)"}}>
          <Box fontSize={"20px"} fontWeight={"bold"}>
           Add Project
          </Box>
          <Box
            onClick={this.handleClose}
            className={"icon"}
            fontSize={"20px"}
            fontWeight={"bold"}
          >
            <IoCloseSharp />
          </Box>  
        </Box> */}

        {/* <Box component={"hr"} /> */}
        <Box display={"flex"} justifyContent={"center"}>
          <Box width={"90%"}>
            <Box marginY={"2.5vh"}>
              <Box className={"modalLabel"} fontSize={"13px"}>
                Project Date*
              </Box>
              <Box>
                 <MuiPickersUtilsProvider
                        utils={MomentUtils}
                        // style={{ background: "orange" }}
                      >
                        <ThemeProvider theme={materialTheme}>
                          <DatePicker
                            data-test-id="projectDatePickerID"
                            keyboard
                            variant="outlined"
                            size="small"
                            fullWidth
                            style={webStyle.text}
                            // maxDate={new Date().setDate(
                            //   new Date().getDate() - 1
                            // )}
                            // placeholder="MM/DD/YYYY"
                            // format={"MM/DD/YYYY"}
                            placeholder="DD/MM/YYYY"
                            format={"DD/MM/YYYY"}
                            mask={(value) =>
                              value
                                ? [
                                    /\d/,
                                    /\d/,
                                    "/",
                                    /\d/,
                                    /\d/,
                                    "/",
                                    /\d/,
                                    /\d/,
                                    /\d/,
                                    /\d/,
                                  ]
                                : []
                            }
                            value={this.state.projectDate}
                            onChange={(date:any)=>this.setState({projectDate:date, isProjectDateError:false})}
                            disableOpenOnEnter
                            animateYearScrolling={false}
                            autoOk={true}
                            clearable
                            // onInputChange={(e: any) => {
                            //   if(e.target.value == ""){
                            //    this.setState({projectDate:""});
                            //   }}
                            // }
                          />
                        </ThemeProvider>
                      </MuiPickersUtilsProvider>
                      {this.state.isProjectDateError && <Typography style={webStyle.errorText}>Project date is required</Typography>}
                      
                      
                      
              </Box>
            </Box>
            <Box marginY={"2.5vh"}>
              <Box className={"modalLabel"} fontSize={"13px"}>
                Project ID*
              </Box>
              <Box>
                <TextField
                  data-test-id='projectID'
                  variant={"outlined"}
                  fullWidth
                  size={"small"}
                  placeholder="Project id goes here"
                  onChange={(e)=>this.setState({projectId:e.target.value, isProjectIdError:false,})}
                  value={this.state.projectId}
                />
                {this.state.isProjectIdError && <Typography style={webStyle.errorText}>Project id is required</Typography>}
              </Box>
            </Box>
            <Box marginY={"2.5vh"}>
              <Box className={"modalLabel"} fontSize={"13px"}>
                Project Name*
              </Box>
              <Box>
                <TextField
                  data-test-id='projectNameID'
                  variant={"outlined"}
                  fullWidth
                  size={"small"}
                  placeholder="Project name goes here"
                  onChange={(e)=>this.setState({projectName:e.target.value, isProjectNameError:false,})}
                  value={this.state.projectName}
                />
                {this.state.isProjectNameError && <Typography style={webStyle.errorText}>Project name is required</Typography>}
              </Box>
            </Box>
            <Box marginY={"2.5vh"}>
              <Box className={"modalLabel"} fontSize={"13px"}>
                Project Type*
              </Box>
              <Box>
              <Select
                      variant="outlined"
                      name="taxPay"
                      renderValue={
                        this.state.selectedProjectType !== "" ? undefined : () => <Placeholder>Select</Placeholder>
                      }
                      value={this.state.selectedProjectType}
                      MenuProps={MenuProps}
                      required
                      fullWidth
                      displayEmpty
                      style={{ height: "40px", textAlign: "left", paddingLeft:"15px"}}
                      onChange={this.handleChange}
                    >
                      {this.state.projectTypes.map((item:any)=><MenuItem value={item}>{item}</MenuItem>)}
                    </Select>
                    {this.state.isSelectedProjectTypeError && <Typography style={webStyle.errorText}>Project type is required</Typography>}
              </Box>
            </Box>
            
            {this.getKwOrSideElevationFields()}
            <Box marginY={"2.5vh"}>
              <Box className={"modalLabel"} fontSize={"13px"}>
                Amount*
              </Box>
              <Box>
                <TextField
                  data-test-id='projectAmountID'
                  variant={"outlined"}
                  fullWidth
                  type="number"
                  size={"small"}
                  placeholder="Enter project amount here"
                  onChange={(e)=>this.setState({projectAmount:e.target.value, isProjectAmountError:false,})}
                  value={this.state.projectAmount}
                />
                {this.state.isProjectAmountError && <Typography style={webStyle.errorText}>Project amount is required</Typography>}
              </Box>
            </Box>
          </Box>
        </Box>
        {/* <Box component={"hr"} /> */}

        <Grid 
        container 
        style={{
          padding : "1rem",
          borderTop: "1px solid rgb(232, 232, 232)"
        }}
        justifyContent="flex-end"
        >
        <Button
            onClick={this.handleClose}
            variant={"contained"}
            color={"default"}
            style={webStyle.cancelButton}
          >
            Cancel
          </Button>
          <Button
            variant={"contained"}
            color={"default"}
            style={{
              marginLeft: "15px",
              marginRight: "15px",
              backgroundColor: "#4EABF8",
              textTransform: "none",
              color: "#fff",
              boxShadow : "none",
              fontWeight: 600,
              padding : "10px 40px"
             }}
            onClick={this.addNewProject}
            disabled={this.state.isProcessing}
          >
            Add
          </Button>

        </Grid>
        {/* <Box display={"flex"} justifyContent={"end"}>
         
        </Box> */} 
      </div>
      </div>
          </Modal>
    )
  }

  handleChange = (e: any) => {
    this.setState({ selectedProjectType: e?.target?.value, isSelectedProjectTypeError: false });
  };
  
  handleChangeStatus = (e: any) => {
    if(e.target.value == "Approved By Client"){
      this.setState({movetoApprovedByClientModal: true})
    }
    else{
    this.setState({ status: e.target.value },()=>{
        console.log('this.state.status', this.state.status);
        
      });
    }
  };

  getStatusDropdown=()=>{
    return(
      <Box width={"300px"}>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        renderValue={
          this.state.status !== "" ? undefined : () => <Placeholder>Select</Placeholder>
        }
        value={this.state.status}
        name="role"
        onChange={this.handleChangeStatus}
        variant="outlined"
        required
        fullWidth
        displayEmpty
        style={{ height: "40px",textAlign:"left", background :"#fff"}}
      >
        {this.state.statusData && this.state.statusData.map((item:any) =>{
          if(item!="Draft" && item != "Overdue"){
            return  <MenuItem value={item}
             >{item}</MenuItem>
          }
        
        })}

      </Select>
    </Box>
    )
  }

  getEditView=()=>{

    let status = this.state.invoiceData?.attributes?.generate_invoices?.invoice_status

    let statusFromPreviousScreen = window?.localStorage?.getItem('selectedInvoiceStatus');

    if(status == "Draft" || status == "Sent" || status == "Approved By Client"){

      return this.state.pageType ? (
        <Box display={"flex"} style={{gap: "10px"}}>
        <Button
          disabled={statusFromPreviousScreen == "Approved By Client"}
          color={"default"}
          variant={"contained"}
          style={{ textTransform: "none", backgroundColor : "#4EABF8", color :"#fff",padding : "10px 30px" }}
          onClick={this.switchToEdit}
        >
          Edit
        </Button>
        <Button
        style={{ textTransform: "none", fontWeight: "bold", padding : "10px 40px" }}
        color={"default"}
        variant={"contained"}
        onClick={this.handleDeleteBtn}
      >
        Delete
      </Button>
      </Box>
      ) : (
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Button
            style={{ textTransform: "none", fontWeight: "bold", padding : "10px 35px" }}
            color={"default"}
            variant={"contained"}
            onClick={this.switchToViewForCancel}
          >
            Cancel
          </Button>
          <Button
            style={{
              backgroundColor: "#4EABF8",
              color : "#fff",
              textTransform: "none",
              fontWeight: "bold",
              marginLeft: "10px",
              padding : "10px 40px"
            }}
            variant={"contained"}
            onClick={this.switchToViewForSave}
          >
            Save
          </Button>
        </Box>
      )
    }
  }

  getCommentEditView=()=>{
    let status = this.state.invoiceData?.attributes?.generate_invoices?.invoice_status
    if(status == "Draft" || status == "Sent" || status == "Approved By Client"){
      return(
        !this.state.pageType && (
          <TextField
            inputProps={{
              style: {
                fontSize: "13px",
                padding: "0px 10px",
                color: "#58585B",
              },
            }}
            multiline
            variant="outlined"
            value={this.state.invoiceComment}
            onChange={(e)=>{this.setState({invoiceComment:e.target.value})}}
            maxRows={4}
            fullWidth
          />
        )
      )
    }
  }

  handleCloseMovetoApprovedModal = () => {
    this.setState({movetoApprovedByClientModal: false, uploadProofImage: ""})
  }

  handleContinueofMoveToApporvedModal = () => {
    if(this.state.uploadProofImage != ""){
      this.attachProof()
    }
    this.UpdateStatus("Approved By Client")
  }
  
  handleSkipofMoveToApporvedModal = () => {
    this.UpdateStatus("Approved By Client")
   }

   handleImage = (e: any) => {
    console.log("Files",e.target.files[0])
  this.setState({uploadProofImage: e.target.files[0],})
  }

  renderMovetoApprovedByClientModal = () => {
    return (
      <Modal 
      style={webStyle.modalbackdrop}
          open={this.state.movetoApprovedByClientModal}
          onClose={this.handleCloseMovetoApprovedModal}
      >
            <div style={webStyle.modalcontentsuccess}>
                 
                      <div style={webStyle.addworkspacemodaldiv}>
                          <div style={webStyle.addworkspaceseconddiv}>
                              <Box style={webStyle.headerBox}>
                                  <h4>Move to Approved By Client</h4>
                                  <Close onClick={this.handleCloseMovetoApprovedModal} style={{marginTop: "20px", cursor: "pointer"}} />
                              </Box>
                          </div>
                      </div>
                      <div style={webStyle.addworkspacemaincontentdiv}>
                          <div style={webStyle.addworkspacedivcontent} className="addclientdivcontent">
                              <div style={webStyle.addworkspacecontentdiv}>
                                  <Box style={webStyle.saveimage}>
                                  <label className="input-label" htmlFor="input-label">
                                            {this.state.uploadProofImage ?
                                                <>
                                                {this.state.uploadProofImage.type == "image/png" ? <img src={URL.createObjectURL(this.state.uploadProofImage)} style={webStyle.addimagesize}/>
                                                 : 
                                                 <Typography align='center'>{this.state.uploadProofImage.name}</Typography>
                                              }
                                                </> :
                                                <div style={{display: "flex", alignItems:"center"}}>
                                                <img src={uploadimage}
                                                style={{width: 30, height: 30}}
                                                />
                                                <div>
                                                <span style={webStyle.uploadimage}>Upload Proof</span>
                                                <span style={webStyle.optionalText}>Optional</span>
                                                 </div>
                                                </div>}
                                                <input
                                                    id="input-label"
                                                    type="file"
                                                    accept="image/*, .pdf"
                                                    hidden onChange={(e) => this.handleImage(e)} />
                                                    <br/>
                                            </label>

                                  </Box>
                              </div>
                             
                          </div>
                      </div>
                      <div style={webStyle.addworkspacebuttonstyle}>
                          <Button style={webStyle.skipButton} onClick={this.handleSkipofMoveToApporvedModal}>Skip</Button>
                          <Button style={webStyle.addworkspaceButton} onClick={this.handleContinueofMoveToApporvedModal}>Continue</Button>
                      </div>
                  
              </div>
          </Modal>
  )
  }

  getClientName=(client_management_name:any)=>{
    if(client_management_name?.length > 26){
      return <Tooltip title={client_management_name}>
      <Typography display="inline">
         {client_management_name}
         </Typography>
         </Tooltip>
    }else{
      return <Typography>{client_management_name}</Typography>
    }
  }
  // Customizable Area End

  render() {
    const addressTabelRow = [this.createDataForAddress("Frozen yoghurt", 159)];
    const termsTableData = [
      this.createDataForTerms(
        this.state.invoiceData?.attributes?.define_invoices?.client_name,
        this.state.invoiceData?.attributes?.account_details?.terms,
      ),
      this.createDataForTerms(`Address - ${this.state.invoiceData?.attributes?.client?.client_address ? this.state.invoiceData?.attributes?.client?.client_address : ""}`, "(ARN: AD2702220272320)"),
    ];
    
    return (
      // Customizable Area Start
      <>
      {this.state.isConfirm && this.confirmDelete()}
      {this.state.movetoApprovedByClientModal&& this.renderMovetoApprovedByClientModal()}
        <Box bgcolor={"#EEEEEE"} width={"85vw"} marginLeft={"15vw"} marginTop={this.state.showLoader ? "0px" : "65px"}>
        {this.state.showLoader ? <Box display={"flex"} minWidth={"80vw"} justifyContent={"center"} alignItems={"center"} minHeight={"100vh"} position="absolute">
            <CircularProgress size={50} />
          </Box> : <Box display={"flex"} justifyContent={"center"}>
            <Box width={"95%"}>
              <Box
                // margin={"20px 0px"}
                marginTop={"20px"}
                alignItems={"center"}
                display={"flex"}
                justifyContent={"space-between"}
                bgcolor={"#fff"}
                style={{padding :"10px"}}
              >
                <Box display={"flex"} alignItems={"center"}>
                  <Box
                    className={"hoverPointer"}
                    marginRight={"10px"}
                    borderRadius={"5px"}
                    padding={"10px 10px"}
                    bgcolor={"#f1f1f1"}
                    color={"black"}
                    onClick={this.handleBack}
                    >
                    <AiFillCaretLeft />
                  </Box>
                  <Box
                  display={"flex"}
                  alignItems={"center"}
                  marginLeft={"10px"}

                   >
                    <Box className="lightText" >
                     <Typography>
                      Invoice#
                     </Typography>
                </Box>
                 <Box className="darkText" margin={"1.2vh"}><Typography>{this.state.invoiceData?.attributes?.generate_invoices?.invoice_id}</Typography></Box>
                </Box>
                  {/* <Box className="buttonText">
                    {this.state.pageType ? "Invoice" : "Edit Invoice"}
                  </Box> */}
                  <Box
                  display={"flex"}
                  alignItems={"center"}
                  marginLeft={"20px"}
                     >
                    <Box className="lightText" >
                     <Typography>
                      Workspace
                     </Typography>
                </Box>
                 <Box className="darkText" margin={"1.2vh"}><Typography>Solar</Typography></Box>
                </Box>
                <Box
                 display={"flex"}
                  alignItems={"center"}
                  marginLeft={"20px"}
                   >
                    <Box className="lightText" >
                     <Typography>
                      Client
                     </Typography>
                </Box>
                 <Box className="darkText" margin={"1.2vh"} style={{textOverflow:'ellipsis', maxWidth:"200", whiteSpace:'nowrap', overflow:'hidden'}}>
                  {this.getClientName(this.state.invoiceData?.attributes?.client?.client_management_name)}
                  </Box>
                </Box>
                <Box
                 style={webStyle.statusBoxColored}
                 marginLeft={"10px"}
                 bgcolor={this.handleColor(this.state.invoiceData?.attributes?.generate_invoices?.invoice_status)}
                 >
                  {this.state.invoiceData?.attributes?.generate_invoices?.invoice_status}
                </Box>
                </Box>

                {this.getEditView()}
              </Box>

              <Box bgcolor={"white"}>
                {/* Image */}
                <Box margin={"20px 0px 10px 0px"} height={"25vh"} width={"100%"}>
                  <img src={finulentImageInvoice} className={"finImage"} />
                </Box>
                <Box />

                <Box display={"flex"} justifyContent={"center"}>
                  <Box className="genText" width={"88%"}>
                    <Box
                      alignItems={"center"}
                      display={"flex"}
                      justifyContent={"space-between"}
                    >
                      <Box width={"25%"}>
                        <Typography>{this.state.invoiceData?.attributes?.account_details?.company_name}</Typography>
                        <Typography>
                          {this.state.invoiceData?.attributes?.account_details?.address}
                        </Typography>
                        {/* <Typography>Mumbai - 400 064, India</Typography> */}
                      </Box>

                      <Box width={"55%"}>
                        <>
                          <Box margin={"10px 0px"} border={"1px solid gray"}>
                            <TableContainer>
                              <Table aria-label="simple table">
                                <TableRow
                                  className="tHead"
                                  style={{ backgroundColor: "#F6A724" }}
                                >
                                  <TableCell align="center">Invoice#</TableCell>
                                  <TableCell align="center">
                                    From Date
                                  </TableCell>
                                  <TableCell align="center">To Date</TableCell>
                                </TableRow>
                                <TableBody>
                                  {addressTabelRow.map((row) => (
                                    <TableRow
                                      key={row.name}
                                      className={"tCell"}
                                    >
                                      <TableCell align="center">
                                        {this.state.invoiceData?.attributes?.generate_invoices?.invoice_id}
                                      </TableCell>
                                      <TableCell align="center">
                                      {moment(this.state.invoiceData?.attributes?.generate_invoices?.from_date).format('DD MMM YYYY')}
                                      </TableCell>
                                      <TableCell align="center">
                                      {moment(this.state.invoiceData?.attributes?.generate_invoices?.to_date).format('DD MMM YYYY')}
                                      </TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Box>
                        </>
                      </Box>
                    </Box>

                    <>
                      <Box
                        border={"1px solid gray"}
                        marginTop={"5vh"}
                        marginBottom={"2vh"}
                      >
                        <TableContainer>
                          <Table aria-label="simple table">
                            <TableRow
                              className="tHead"
                              style={{ backgroundColor: "#F6A724" }}
                            >
                              <TableCell align="center">BILL TO</TableCell>
                              <TableCell align="center">Terms</TableCell>
                            </TableRow>
                            <TableBody>
                              {termsTableData.map((row) => (
                                <TableRow key={row.name} className={"tCell"}>
                                  <TableCell align="center">
                                    {row.name}
                                  </TableCell>
                                  <TableCell align="center" style={{width:"50%"}}>
                                    {row.calories}
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Box>
                    </>
                    {this.BillingTable()}
                    {/* <Box marginY={"5vh"} component={"hr"} /> */}
                    {/* <OtherChargesTable data={this.state} /> */}
                    {this.BillDetailsSection()}
                    <>
                      <Box marginTop={"3.5vh"}>
                        <Box
                          fontWeight={"bold"}
                          fontSize={"14px"}
                          marginBottom={"1.5vh"}
                        >
                          Comment
                        </Box>

                        {this.getCommentEditView()}
                        {this.state.pageType && (
                          <Box
                            marginTop={"15px"}
                            color={"#58585B"}
                            fontSize={"13px"}
                          >
                            {this.state.invoiceComment}
                          </Box>
                        )}
                      </Box>
                    </>
                    <Box marginY={"3.5vh"} component={"hr"} />
                    {this.AccountDetails()}
                  </Box>
                </Box>
              </Box>
            </Box>
            </Box>}
          {!this.state.showLoader && <Box display={"flex"} justifyContent={"center"}>
          {this.getView(this.state.invoiceData?.attributes?.generate_invoices?.invoice_status)}
                 </Box>}
          
        </Box>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const usePlaceholderStyles = makeStyles(theme => ({
  placeholder: {
    color: "#aaa"
  }
}));


const Placeholder:React.FunctionComponent = ({children}) => {
  const classes = usePlaceholderStyles();
  return <div className={classes.placeholder}>{children}</div>;
};

const materialTheme = createMuiTheme({
  overrides: {
    // @ts-ignore
    MuiPickersToolbar: {
      toolbar: {
          color: "black",
          backgroundColor: "#e8e8e8"
      },
    },
    MuiPickersDay: {
      day: {
      color: "black"
      },
      daySelected: {
          backgroundColor: "#e8e8e8"
      },
      dayDisabled: {
          color: "#e8e8e8"
      },
      current: {
          color: "#e8e8e8"
      },
      isSelected: {
        color: "white",
        backgroundColor: "#e8e8e8"
      },
    },
    MuiPickersToolbarButton: {
      toolbarBtn: {
          color: "black",
      },
      toolbarBtnSelected: {
          color: "black"
      },
   },
    MuiCssBaseline: {
      "@global": {
        "*::-webkit-scrollbar": {
          width: "10px",
        },
        "*::-webkit-scrollbar-track": {
          background: "#E4EFEF",
        },
        "*::-webkit-scrollbar-thumb": {
          background: "#1D388F61",
          borderRadius: "2px",
        },
      },
    },

    palette: {
      primary: "red", // works
    },
    MuiButton: {
      textPrimary: {
        color: "black",
      },
    },

    MuiPickersModal: {
      dialogAction: {
        color: "#8bc34a",
      },
    },
    myComponent: {
      "& .MuiPickersDay-isSelected": {
        backgroundColor: "red",
      },
    },

    MuiPickersCalendarHeader: {
      switchHeader: {
        backgroundColor: "white",
        color: "black"
      //   color: "rgb(171 114 24)",
      },
      dayLabel: {
          color: "black"
      //   color: "rgb(171 114 24)",
      },
    },
  },
});

const webStyle:any={
  modalStyle:{
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
    position: "absolute",
    width: "35%",
    border: "1px solid #000",
    fontFamily: "sans-serif",
    backgroundColor: "white",
    // boxShadow: theme.shadows[5],
    padding: "25px 10px",
  },

  modalcontentsuccess1: {
    // width: "30%",
    width: "500px",
    height: "78%",
    fontFamily: "sans-serif",
    backgroundColor: "white",
    alignItems: "left",
    justifyContent: "left",
    // margin: 20,
    // overflowX: "scroll",
    overflowY: "scroll",
  } as React.CSSProperties,
  
  modalContent: {
  //  margin: 20,
  //  marginTop: -10,
  },
  modalbackdrop: {
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: "rgba(0, 0, 0, 0.4)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 2,
    alignContent: "center"
  
  },
  modalcontentsuccess: {
    // width: "30%",
    width: "400px",
    fontFamily: "sans-serif",
    backgroundColor: "white",
    alignItems: "left",
    justifyContent: "left",
    // margin: 20,
    overflowX: "scroll",
    overflowY: "scroll",
  } as React.CSSProperties,
 
   imagesizeedit: {
    width: 250, 
    height: 200,
  },
  headerBox: {
    borderBottom: '1px solid #e8e8e8',
    display: "flex",
    justifyContent: "space-between",
    padding: "0px 20px"
   } as React.CSSProperties,
   closebuttonstyle: {
    marginTop: 20,
    marginRight: 20,
    cursor: 'pointer',
    },

   addworkspacebuttonstyle: {
    backgroundColor: "white",
    padding: "0px 20px",
    marginTop: 20,
    display: "flex",
   },
addworkspaceButton: {
  // display: "flex",
  // width: "100px",
  border: "1px solid #cecece",
  backgroundColor: "#42a5f5",
  marginLeft: 10,
  color: "white",
  fontWeight: 600,
  textTransform: "none",
  padding : "10px 70px",
  flexGrow : 1,
} as React.CSSProperties,
skipButton: {
  background: "#e8e8e8",
  color: "black",
  fontWeight: 600,
  textTransform: "none",
  boxShadow : "none",
  padding : "10px 70px"
}as React.CSSProperties,
addworkspacecontentdiv: {
  margin : "20px 0px",
  display:"flex",
  justifyContent :"center",
  // marginTop: 20,
},
saveimage: {
    width: 350, 
    height: 200,
    border: "2px dashed #e8e8e8",
    borderRadius: "4px",
    alignContent: "center",
    justifyContent: "center",
    display: "flex",
    alignItems: "center"
},
addimagesize: {
  width: 350, 
    height: 200,
  marginTop: 20,
},

uploadimage: {
  marginTop: 6, 
  // fontSize: 14, 
  marginLeft: 7
},
optionalText:{
  marginTop: 6, 
  color: "#bababa", 
  fontSize: 14, 
  marginLeft: 7,
  fontStyle:"italic"
},
errorText:{
    color:"#f44336",
     fontSize:15, 
      marginTop:5
    },
    cancelButton: {
      background: "#e8e8e8",
      color: "black",
      fontWeight: 600,
      textTransform: "none",
      boxShadow : "none",
      padding : "10px 40px"
    },
   buttonBlue:{
    backgroundColor: "#4FABF6",
    textTransform: "none",
    color: "#fff",
    padding: "10px 30px",
    fontWeight: 600,
  },
  statusBoxColored:{
    // background :'#fac12c',
    color : "#fff",
    padding : "5px 20px",
    borderRadius : "50px",
    fontWeight :"bold", 
   }
}
// Customizable Area End