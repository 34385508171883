// Customizable Area Start
import React from "react";
import {
    Box,
    Button,
    Link,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Typography,
    Checkbox,
    CircularProgress,
    Modal,
    Menu,
    MenuItem,
    TextField,
    InputLabel,
    Grid,
    createMuiTheme,
    Divider,
    Tooltip,
} from "@material-ui/core";
import "./style.css";
import GetAppIcon from '@material-ui/icons/GetApp';
import ListAltIcon from '@material-ui/icons/ListAlt';
const iconArrow = require("../assets/iconArrow.png");

import {
    MuiPickersUtilsProvider,
    DatePicker,
} from "material-ui-pickers";
const filterApplied = require("../assets/filterApplied.png");
const filterEmpty = require("../assets/filterEmpty.png");

import AddFieldModal from "./AddFieldModal.web";
import SearchBar from "material-ui-search-bar";
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import Pagination from '@material-ui/lab/Pagination';
import CloseIcon from "@material-ui/icons/Close";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { ThemeProvider } from "@material-ui/styles";
import MomentUtils from "@date-io/moment";

const materialTheme = createMuiTheme({
    overrides: {
      // @ts-ignore
      MuiPickersToolbar: {
        toolbar: {
          backgroundColor: localStorage.getItem('user_type')=='Superadmin'?"#e8e8e8":"rgb(171 114 24)",
          color: "black",
          backgroundImage:   localStorage.getItem('user_type')=='Superadmin'?"#e8e8e8":"radial-gradient(rgb(246 168 34), rgb(171 114 24))",
        },
      },
      MuiPickersDay: {
        day: {
          color: localStorage.getItem('user_type')=='Superadmin'?"black":"rgb(171 114 24)",
        },
        daySelected: {
          backgroundColor:localStorage.getItem('user_type')=='Superadmin'?"#e8e8e8": "rgb(171 114 24)",
          backgroundImage: "radial-gradient(rgb(246 168 34), rgb(171 114 24))",
        },
        dayDisabled: {
          color:localStorage.getItem('user_type')=='Superadmin'?"#e8e8e8": "rgb(171 114 24)",
        },
        current: {
          color:localStorage.getItem('user_type')=='Superadmin'?"#e8e8e8": "rgb(171 114 24)",
        },
        isSelected: {
          color: localStorage.getItem('user_type')=='Superadmin'?"black":"white",
          backgroundColor: localStorage.getItem('user_type')=='Superadmin'?"#e8e8e8":"rgb(171 114 24)",
          backgroundImage: localStorage.getItem('user_type')=='Superadmin'?"#e8e8e8": "radial-gradient(rgb(246 168 34), rgb(171 114 24))",
        },
      },
      MuiCssBaseline: {
        "@global": {
          "*::-webkit-scrollbar": {
            width: "10px",
          },
          "*::-webkit-scrollbar-track": {
            background: "#E4EFEF",
          },
          "*::-webkit-scrollbar-thumb": {
            background: "#1D388F61",
            borderRadius: "2px",
          },
        },
      },
      MuiPickersToolbarButton: {
        toolbarBtn: {
            color:  localStorage.getItem('user_type')=='Superadmin'?"black":"white",
        },
        toolbarBtnSelected: {
            color:  localStorage.getItem('user_type')=='Superadmin'?"black":"white"
        },
     },
      palette: {
        primary: "red", // works
      },
      MuiButton: {
        textPrimary: {
          color: localStorage.getItem('user_type')=='Superadmin'?"black":"rgb(171 114 24)",
        },
      },

      MuiPickersModal: {
        dialogAction: {
          color: "#8bc34a",
        },
      },
      myComponent: {
        "& .MuiPickersDay-isSelected": {
          backgroundColor: "red",
        },
      },

      MuiPickersCalendarHeader: {
        switchHeader: {
          backgroundColor: "white",
          color: localStorage.getItem('user_type')=='Superadmin'?"black":"rgb(171 114 24)",
        },
        dayLabel: {
          color: localStorage.getItem('user_type')=='Superadmin'?"black":"rgb(171 114 24)",
        },
      },
    },
  });

import TemplateReportingController, {
    Props,
} from "./TemplateReportingController";

export default class TemplateReporting extends TemplateReportingController {
    constructor(props: Props) {
        super(props);
    }

    handleBack = () => {
        return window.history.back();
    };

    replaceUndersocotWithSpace = /_/g;
    replaceCaseFormation = /(^\w{1})|(\s+\w{1})/g;

    handleFilters = (htmlElement: EventTarget, headerItem: any) => {
        if (headerItem?.quick_filter) {
            this.setState({selectedFilterId: headerItem?.field_name});
            switch (headerItem?.field_type) {
                case 'Number':
                    this.setState({ isOpenNumberFilterModal: true, errorText: headerItem?.field_name,  fromNumberFilter: "", toNumberFilter: "" })
                    break;
                case 'Dropdown': {
                    this.handleFilteTypeDropDown(htmlElement, headerItem);
                    break;
                }
                case 'Calendar': {
                    this.setState({ isOpenDateFilterModal: 'DATE_FILTER', fromDate: "", toDate: "" })
                    break;
                }
            }
        }
    }

  renderHeader = () => (
      <div style={webStyle.pageheader}>
          <div style={webStyle.pagename}>
              <Box onClick={() => this.handleBack()} style={webStyle.arrowbox}>
                  <img src={iconArrow} />
              </Box>
              <div style={webStyle.headerNameWrapper}>
                  <h4 style={webStyle.title}>{this.state.projectListAllData?.template?.attributes?.report_title || 'Basic Reporting'}</h4>
              </div>
          </div>
      </div>
  )

    renderActionButtons = () => (
        <div style={webStyle.cardContentOption}>
            <Button onClick={() => this.handleDownloadCompleteData()} style={localStorage.getItem('user_type') == "Superadmin" ? webStyle.btn : webStyle.adminbtn}>{localStorage.getItem('user_type') == "Superadmin"?"": <ListAltIcon/>}Download complete data</Button>
            <Button onClick={() => this.getProjectList("current")} style={localStorage.getItem('user_type') == "Superadmin" ? webStyle.btn : webStyle.adminbtn}> {localStorage.getItem('user_type') == "Superadmin"?"": <GetAppIcon/>}Download current Data</Button>
        </div>
    )

    renderFrozenHeaders = (headerItem: any, headerindex: any) => {
        return (<TableCell className={localStorage.getItem('user_type') == 'Superadmin' ? "tablehead" : "admintablehead"}
            align="center"
            style={{ minWidth: headerindex == 0 ? 50 : 101.5, maxWidth:  headerindex == 0 ? 50 : 101.5, border: 'none', padding: 7, overflow: 'hidden', overflowWrap: 'break-word' }} key={headerindex}>            <Tooltip title={headerItem?.field_name}>
                <Typography style={{ display: 'flex', alignItems: 'center', justifyContent: "center", fontWeight: 'bold', cursor: headerItem?.quick_filter ? 'pointer' : 'text' }} onClick={(e) => this.handleFilters(e.target, headerItem)}>
                    <span>{headerItem?.field_name}{headerItem?.mandatory && '*'}</span>
                    {headerItem?.quick_filter && (<span>
                        <img
                            style={{ marginLeft: 3, height: 15, width: 15 }}
                            src={this.removeExtraFilters()?.some((i: any) => i?.template_field_name == headerItem?.field_name) ?
                                filterApplied :
                                filterEmpty} />
                    </span>
                    )}
                </Typography>
            </Tooltip>
        </TableCell>)
    }

    renderNonFrozenHeaders = (headerItem: any, headerindex: any) => {
        return (<TableCell align="center" key={headerindex} className={localStorage.getItem('user_type') == 'Superadmin' ? "tablehead" : "admintablehead"}>
            <Tooltip title={headerItem?.field_name}>
                <Typography style={{
                    width: 'auto',
                    minWidth: headerItem?.field_type == 'Calendar' ? 150 : 100,
                    maxWidth: 200,
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    fontWeight: 'bold',
                    cursor: headerItem?.quick_filter ? 'pointer' : 'text'
                }} onClick={(e) => this.handleFilters(e.target, headerItem)}>
                    <span>{headerItem?.field_name}{headerItem?.mandatory && '*'}</span>
                    {headerItem?.quick_filter && (<span>
                        <img
                            style={{ marginLeft: 10, height: 15, width: 15 }}
                            src={this.removeExtraFilters()?.some((i: any) => i?.template_field_name == headerItem?.field_name) ?
                                filterApplied :
                                filterEmpty} />
                    </span>
                    )}
                </Typography>
            </Tooltip>
        </TableCell>)
    }

    renderTabelHead = () => {
        return (
            <TableHead>
                <TableRow className="template-table-header" style={{ backgroundColor: "darkgrey" }}>
                    <TableCell style={{
                        left: 0,
                        position: "sticky",
                        zIndex: 1001,
                        padding: 0,
                        background: localStorage.getItem('user_type') == 'Superadmin' ? '#bdbdbd' : "#f5a823"
                    }}>{this.state?.projectListAllData?.fields?.map((headerItem: any, headerindex: any) =>
                        headerItem?.is_frozen &&
                        (this.renderFrozenHeaders(headerItem, headerindex))
                    )}
                    </TableCell>
                    {this.state?.projectListAllData?.fields?.map((headerItem: any, headerindex: any) =>
                        !headerItem?.is_frozen &&
                        (this.renderNonFrozenHeaders(headerItem, headerindex))
                    )}
                </TableRow>
            </TableHead>
        )
    }

    renderTabelBody = () => {
        return (
            <TableBody style={{ background: 'white' }}>
                {this.state?.projectListAllData?.projects?.map((projectItem: any, projectindex: number) =>
                (<TableRow key={projectindex}>
                    {/* ? FROZEN cells */}
                    <TableCell style={{
                        left: 0,
                        position: "sticky",
                        zIndex: 1000,
                        padding: 0,
                        background: 'darkgrey'
                        // border: 'none'
                    }}>
                        {this.state?.projectListAllData?.fields?.map((headerItem: any, headerindex: any) =>
                            headerItem?.is_frozen &&
                                this.renderTabelCell(projectItem, headerItem, headerindex, true)
                        )}
                    </TableCell>
                    {/* ? NON-FROZEN cells */}
                    {this.state?.projectListAllData?.fields?.map((headerItem: any, headerindex: any) =>
                        !headerItem?.is_frozen &&
                            this.renderTabelCell(projectItem, headerItem, headerindex, false)
                    )}
                </TableRow>)
                )}
            </TableBody>
        )
    }


    renderNonStickyTable = () => (
        <div className="template-table">
            <div style={{maxHeight: '65vh', minHeight: '30vh'}}>
            <Table
                stickyHeader
                aria-label="sticky table"
                    className="template-tab"
                    style={{
                        position: 'sticky',
                        top: 0,
                    }}
                >
                    {this.renderTabelHead()}
                    {!this.state.isProjectsFetching && this.state?.projectListAllData?.projects?.length ? (this.renderTabelBody()) : (
                    <TableRow style={{
                        left: '20vw',
                        position: "sticky",
                        zIndex: 1001, display: 'flex', justifyContent: 'center', alignItems: 'center', height: '40vh'
                    }}>{this.state.isProjectsFetching ? 'Loading...' : 'No Records Found'}</TableRow>)}
                </Table>
            </div>
        </div>
    )

    returnInitials = (name: string) => {
        return name?.split(' ')?.map(name => name[0])?.join('')?.toUpperCase()
    }

    returnDynamicWidthForCells = (isFrozen: boolean, isSRNO?: boolean) => {
        if (isSRNO) return 50;
        else if (isFrozen) return 100;
        else return 'auto';
    }

    returnTabelCellDynamicStyles = (isFrozen: boolean, fieldItem?: any, isSRNO?: boolean) => {
        return {
            minWidth: this.returnDynamicWidthForCells(isFrozen, isSRNO),
            maxWidth: this.returnDynamicWidthForCells(isFrozen, isSRNO),
            background: localStorage.getItem('user_type') !== 'Superadmin' ? fieldItem?.color_code : ''
        }
    }

    returnTextValueForCell = (headerItem: any, fieldItem: any) => {
        if (headerItem?.associated_class_name == "AccountBlock::Account") {
            if (fieldItem?.data == "") return ""
            else return fieldItem?.display_value || fieldItem?.data;
        }
        else return fieldItem?.display_value || fieldItem?.data;
    }

    renderTextInTabelCell = (projectItem: any, headerItem: any, headerindex: any, isFrozen: boolean, fieldItem: any) => {
        return (<TableCell
            align="center"
            size="small"
            key={headerindex}
            style={{ ...webStyle.commonTabelCellStyle, ...this.returnTabelCellDynamicStyles(isFrozen, fieldItem, headerItem?.field_name?.toLowerCase() === 's no') }}
        >
            <Tooltip
                title={<p style={{ fontSize: '15px' }} >{fieldItem?.display_value || fieldItem?.data}</p>}>
                <Typography style={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: '-webkit-box',
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: 'vertical',
                    maxWidth: 220,
                }} className="tabeldataoverflow">{this.returnTextValueForCell(headerItem, fieldItem)}</Typography></Tooltip >
        </TableCell>)
    }

    renderFilledCell = (projectItem: any, headerItem: any, headerindex: any, isFrozen: boolean, fieldItem: any) => {
        if (fieldItem?.field_name === headerItem?.field_name) {
             return this.renderTextInTabelCell(projectItem, headerItem, headerindex, isFrozen, fieldItem);
        }
    }

    renderEmptyCell = (projectItem: any, headerItem: any, headerindex: any, isFrozen: any) => {
        return ( <TableCell
            align="center"
            size="small"
            key={headerindex}
            style={{ minWidth: isFrozen ? 90 : 'auto', maxWidth: isFrozen ? 90 : 'auto', padding: 7,border: '1px solid #BDBDBD', overflow: 'hidden'}}
        ><Typography></Typography>
        </TableCell>)
    }

    renderTabelCell = (projectItem: any, headerItem: any, headerindex: any, isFrozen: boolean) => {
        return projectItem?.attributes?.project_data?.map((fieldItem: any) => {
            return this.renderFilledCell(projectItem, headerItem, headerindex, isFrozen, fieldItem)
        })
    }

    renderProjectListTabel = () => {
        if (this.state.loading) return (<Box style={webStyle.loader}><CircularProgress size={50} /></Box>)
        else if (this.state.projectListAllData?.fields?.length) return (<Box style={{ minHeight: '55vh' }} sx={{ bgcolor: "#e8e8e8", p: 2 }}>
            <div style={webStyle.secondbtn}>
                <SearchBar
                    placeholder="Search"
                    value={this.state.searchTerm}
                    onChange={(e) => this.handleSearch(e)}
                    onCancelSearch={() => this.cancelSearch()}
                />
                <div style={{ justifyContent: "end" }}>
                    <Button disabled={!this.removeExtraFilters()?.length}>
                        <Link onClick={() => this.handleClearAllFilters()} style={localStorage.getItem('user_type') == 'Superadmin' ? webStyle.quickLink : webStyle.adminquickLink} underline="none">Clear Quick Filter</Link>
                    </Button>
                </div>
            </div>
            <Box>
                <div style={webStyle.cardContent}>
                    <div style={{ display: "flex", flexDirection: "row", }}>
                        {this.renderNonStickyTable()}
                    </div>
                    {this.state.projectListAllData?.projects?.length ?
                        (<Pagination style={{ alignSelf: 'center', marginTop: 40 }} page={this.state?.paginationPage} onChange={(e, value) => this.handlePagination(value)} count={this.state?.projectListAllData?.meta?.total_pages} />)
                        : null
                    }
                </div>
            </Box>
        </Box>)
        else return <div style={{ ...webStyle?.noData, background: 'lightgrey' }}>
            No Data
        </div>
    }

    returnDateRangeFilterModal = () => {
        return (<Modal
            className="modal-backdrop"
            open={this.state.isOpenDateFilterModal !== 'CLOSE'}
            onClose={this.handleCancelBtn}
            style={{
                overflow: "auto",
            }}
        >
            <div style={webStyle.dateFilterStyle}>
                <div style={webStyle.mainModalStyle}>
                    <h1 style={webStyle.modalHeadStyle}>{this.state.isOpenDateFilterModal === 'DATE_FILTER' ? 'Date Filter' : 'Download Complete Data'}</h1>
                    <CloseIcon onClick={this.handleCancelBtn} style={{ marginRight: "8px", cursor: 'pointer' }} />
                </div>
                <Divider />
                <Grid container style={{ padding: "10px 15px", backgroundColor: localStorage.getItem('user_type') == "Superadmin" ? "" : "rgb(245, 245, 245)" }}>
                    <h3 style={webStyle.modalSecondHeadStyle}>Please select the date range {this.state.isOpenDateFilterModal === 'DATE_FILTER' ? '' : 'to download'}</h3>
                    <div style={{ display: "flex", gap: "10px", margin: "0px 20px 0px 20px" }}>
                        <Grid item sm={6}>
                            <InputLabel style={webStyle.dateHeader}>Start Date</InputLabel>
                            <MuiPickersUtilsProvider
                                utils={MomentUtils}
                                style={{ background: "orange" }}
                            >
                                <ThemeProvider theme={materialTheme}>
                                    <DatePicker
                                        keyboard
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        placeholder="DD/MM/YYYY"
                                        format={"DD/MM/YYYY"}
                                        mask={(value) =>
                                            value
                                                ? [
                                                    /\d/,
                                                    /\d/,
                                                    "/",
                                                    /\d/,
                                                    /\d/,
                                                    "/",
                                                    /\d/,
                                                    /\d/,
                                                    /\d/,
                                                    /\d/,
                                                ]
                                                : []
                                        }
                                        value={this.returnDateValue('start') || null}
                                        onChange={(e) => this.handleDateChange(e, 'start')}
                                        disableOpenOnEnter
                                        animateYearScrolling={false}
                                        autoOk={true}
                                    />
                                </ThemeProvider>
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item sm={6}>
                            <InputLabel style={webStyle.dateHeader}>End Date</InputLabel>
                            <MuiPickersUtilsProvider
                                utils={MomentUtils}
                                style={{ background: "orange" }}
                            >
                                <ThemeProvider theme={materialTheme}>
                                    <DatePicker
                                        keyboard
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        minDate={this.state.fromDate}
                                        placeholder="DD/MM/YYYY"
                                        format={"DD/MM/YYYY"}
                                        mask={(value) =>
                                            value
                                                ? [
                                                    /\d/,
                                                    /\d/,
                                                    "/",
                                                    /\d/,
                                                    /\d/,
                                                    "/",
                                                    /\d/,
                                                    /\d/,
                                                    /\d/,
                                                    /\d/,
                                                ]
                                                : []
                                        }
                                        value={this.returnDateValue('end') || null}
                                        onChange={(e) => this.handleDateChange(e, 'end')}
                                        disableOpenOnEnter
                                        disabled={!this.state.fromDate}
                                        animateYearScrolling={false}
                                        autoOk={true}
                                    />
                                </ThemeProvider>
                            </MuiPickersUtilsProvider>
                        </Grid>
                    </div>
                    <br />
                </Grid>
                <Divider />
                <div style={webStyle.dateFilterButtons}>
                    {this.renderCancelOrRemoveButton(() => this.handleRemoveFilter(), () => this.handleCancelBtn())}
                    <Button
                        disabled={!this.state.fromDate || !this.state.toDate || this.state.isDateError}
                        onClick={() => this.handleDateFilterAdd()}
                        style={localStorage.getItem('user_type') == 'Superadmin' ? webStyle.addpopbtn : webStyle.adminaddpopbtn}
                    >
                        {this.state.isOpenDateFilterModal === 'DATE_FILTER' ? 'Add' : 'Download'}
                    </Button>
                </div>
            </div>
        </Modal>)
    }

    renderCancelOrRemoveButton = (removeCallBack: any, cancelCallBack: any) => {
        const foundFilter = this.state.filterData?.find((i: any) => i?.template_field_name == this.state.selectedFilterId);
        if (foundFilter && (this.state.isOpenDateFilterModal !== 'DOWNLOAD_COMPLETE_DATA')) return (
            <Button onClick={() => removeCallBack()} style={webStyle.cancelCalenderBtn}>
                Remove
            </Button>
        )
        else return (
            <Button onClick={() => cancelCallBack()} style={webStyle.cancelCalenderBtn}>
                Cancel
            </Button>
        )
    }

    returnNumberFilterModal = () => {
        return (<Modal
            className="modal-backdrop"
            open={this.state.isOpenNumberFilterModal}
            onClose={this.handleCancelBtn}
            style={{
                overflow: "auto",
            }}
        >
            <div style={webStyle.dateFilterStyle}>
                <Grid>
                    <div style={{ textAlign: "center" }}>
                        <div style={webStyle.mainModalStyle}>
                            <h1 style={webStyle.modalHeadStyle}>{(this.state.errorText)} filter</h1>
                            <CloseIcon onClick={this.handleCancelBtn} style={{ marginRight: "8px", cursor: 'pointer' }} />
                        </div>
                        <Divider />
                        <div style={{ padding: "10 0", backgroundColor: localStorage.getItem('user_type') == "Superadmin" ? "" : "rgb(245, 245, 245)" }}>
                            <Typography style={webStyle.range}>Enter range</Typography>
                            <div style={{ padding: "15px 20px ", display: "flex", gap: "10px", alignItems: "left", justifyContent: "left" }} className="confirmbody">
                                <div>
                                    <InputLabel style={webStyle.modalhead}>From</InputLabel>
                                    <TextField
                                        variant="outlined"
                                        size="small"
                                        required
                                        fullWidth
                                        placeholder="0"
                                        type="number"
                                        name="From"
                                        style={{ marginTop: "10px" }}
                                        value={this.returnNumValue('start')}
                                        onChange={(e) => this.handleNumChange(e.target.value, 'start')}
                                    />
                                </div>
                                <div>
                                    <InputLabel style={webStyle.modalhead}>To</InputLabel>
                                    <TextField
                                        variant="outlined"
                                        size="small"
                                        required
                                        fullWidth
                                        placeholder="0"
                                        type="number"
                                        name="To"
                                        style={{ marginTop: "10px" }}
                                        value={this.returnNumValue('end')}
                                        onChange={(e) => this.handleNumChange(e.target.value, 'end')}
                                        helperText={this.state.isNumberFilterError &&  'Please enter valid input.'}
                                        error={this.state.isNumberFilterError}
                                    />
                                </div>
                            </div>
                        </div>
                        <Divider />
                        <div style={webStyle.dateFilterButtons}>
                            {this.renderCancelOrRemoveButton(() => this.handleRemoveFilter(), () => this.handleCancelBtn())}
                            <Button
                                disabled={this.state.fromNumberFilter === "" || this.state.toNumberFilter === "" || this.state.toNumberFilter === 0 || this.state.isNumberFilterError}
                                onClick={() => this.handleNumberFilterAdd()}
                                style={localStorage.getItem('user_type') == 'Superadmin' ? webStyle.addpopbtn : webStyle.adminaddpopbtn}
                            >
                                Apply
                            </Button>
                        </div>
                    </div>
                </Grid>
            </div>
        </Modal>)
    }

    returnSelectFilterModal = () => {
        return (
            <Menu
                style={webStyle.dropDownFilterMenu}
                keepMounted={true}
                // @ts-ignore
                anchorEl={this.state.menuAnchorEl}
                id="account-menu"
                open={this.state.menuAnchorEl != null ? true : false}
                onClose={() => this.setState({ menuAnchorEl: null, filterTypeDDSearchText: "", filterTypeDDOptionsBySearch: [], filterTypeDDOptions: [] })}
                transformOrigin={{ horizontal: "center", vertical: "top" }}
                anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
            >
                <MenuItem style={{ background: 'white', width: '100%', display: 'flex', justifyContent: 'center' }} disableTouchRipple={true}>
                    <SearchBar
                        style={{ width: '200' }}
                        placeholder="Search"
                        onChange={(e) => this.filterMenuOptions(e, 'header')}
                        value={this.state.filterTypeDDSearchText}
                        // @ts-ignore
                        onKeyDown={(e: any): void => {
                            if (e.key !== "Escape") {
                                e.stopPropagation();
                            }
                        }}
                        onCancelSearch={() => this.setState({ filterTypeDDOptionsBySearch: this.state.filterTypeDDOptions })}
                    />
                </MenuItem>
                <>
                    {this.state.selectFilterType === 'Account' && <MenuItem disableTouchRipple={true} key={"NA"}><Checkbox checked={this.returnIfFilterIsApplied({ label: "NA", value: "NA" })} onChange={() => this.handleSelectFilterValueChange({ label: "NA", value: "NA" })} />NA</MenuItem>}
                    {this.returnIfSelectedFieldMendatory() && <MenuItem disableTouchRipple={true} key={"Blank"}><Checkbox checked={this.returnIfFilterIsApplied({ label: "Blank", value: "" })} onChange={() => this.handleSelectFilterValueChange({ label: "Blank", value: "" })} />(Blank)</MenuItem>}
                    {this.state?.filterTypeDDOptionsBySearch?.map((i: any, key: number) =>
                        <MenuItem disableTouchRipple={true} key={key}><Checkbox checked={this.returnIfFilterIsApplied(i)} onChange={() => this.handleSelectFilterValueChange(i)} />
                            <span style={{
                                maxWidth: 300, whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis"
                            }}>{i.label}</span>
                        </MenuItem>)}
                </>
            </Menu>
        )
    }

    returnTextFilterModal = () => {
        return (<Modal
            className="modal-backdrop"
            //   open={false}
            open={this.state.codeModal}
            onClose={this.handleCancelBtn}
            style={{
                overflow: "auto",
            }}
        >
            <div
                // className="modal-successcontent"
                style={{
                    zIndex: 10,
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    background: "white",
                    width: "25%",
                    // height: "20%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "20px",
                    overflow: "auto"
                    // padding:"20px"
                }}
            >
                <Grid>
                    <div style={{ textAlign: "center" }}>
                        <div className="confirm-heading" >
                            <Typography style={{ fontWeight: 900 }}>{(this.state.errorText)?.replace(this.replaceUndersocotWithSpace, " ")?.replace(this.replaceCaseFormation, letter => letter?.toUpperCase())} Enter Code</Typography>
                            <CloseIcon onClick={this.handleCancelBtn} />
                        </div>
                        <Divider />
                        <div style={{ padding: "20px", display: "flex", gap: "10px", alignItems: "left", justifyContent: "left" }} className="confirmbody">
                            <div>
                                <InputLabel style={webStyle.modalhead}>
                                    Code
                                </InputLabel>
                                <TextField
                                    variant="outlined"
                                    size="small"
                                    required
                                    fullWidth
                                    placeholder="Enter Code"
                                    type="text"
                                    name="lastname"
                                //   value={this.state.lastname}
                                //   onChange={this.handleChange}
                                />
                            </div>
                        </div>
                        <Divider />
                        <div style={{ padding: "20px" }}>
                            <Button
                                onClick={this.handleCancelBtn}
                                style={webStyle.cancelbtn}
                            // className="cancelbtn"
                            >
                                Cancel
                            </Button>
                            <Button
                                // onClick={() => this.handleYes()}
                                style={localStorage.getItem('user_type') == 'Superadmin' ? webStyle.addpopbtn : webStyle.adminaddpopbtn}
                            // style={{ backgroundColor: "#0096FF " }}
                            >
                                Add
                            </Button>
                        </div>
                        {/* </div> */}
                    </div>
                </Grid>
            </div>
        </Modal>)
    }

    render() {
        return (
            <>
                <Box style={webStyle.background}>
                    <Box style={webStyle.content} className="background-content">
                        {this.state.loading ? (<Box style={webStyle.loader}><CircularProgress size={50} /></Box>) : (
                            <>
                                {this.renderHeader()}
                                <Divider />
                                {this.renderActionButtons()}
                                {this.renderProjectListTabel()}
                            </>
                        )}
                    </Box>
                </Box>
                {this.returnDateRangeFilterModal()}
                {this.returnSelectFilterModal()}
                {this.returnNumberFilterModal()}
                {this.returnTextFilterModal()}
            </>
        );
    }
}

const webStyle = {
    modalRoot: {
        display: "flex",
        gap: 1,
        fontSize: "16px",
        justifyContent: "center",
        borderBlockStart: "1px solid #ccc",
        paddingBlock: "2rem", //
        backdropFilter: "blur(5px)",
        overflow: "scroll",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        alignItems: "center",
    },
    cancelbtn: {
        color: "black",
        marginLeft: "15px",
        padding: "10px 35px 10px 35px ",
        fontFamily: "sans-serif",
        fontSize: 16,
        textTransform: "none",
        width: "40%",
        fontWeight: "bold",
        /* height: 30%; */
        backgroundColor: "#e8e8e8",
    } as React.CSSProperties,
    secondbtn: {
        display: "flex",
        justifyContent: "space-between",
        margin: "20px 0px 20px 0px",
    } as React.CSSProperties,
    background: {
        backgroundColor: "white",
        // height: "100vh",
        fontFamily: "sans-serif",
        marginTop: 50

    },
    content: {
        top: "50px",
        right: "0px",
        width: "85vw",
        height: "fit-content",
        padding: "20px 20px 10px",
        justifyContent: "space-between",
    },

    pageheader: {
        display: "flex",
        justifyContent: 'left'
    },
    btn: {
        color: "rgb(0, 150, 255)",
        textTransform: "none",
        marginRight: "20px",
        boxShadow:"none",
        outline:0
    } as React.CSSProperties,
    adminbtn: {
        color: "orange",
        textTransform: "none",
        marginRight: "20px"
    } as React.CSSProperties,
    deletebtn: {
        backgroundColor: "rgb(0, 150, 255)",
        color: "white",
        textTransform:"none",
        fontFamily: "sans-serif",
        fontWeight:"bold",
        fontSize:14
    } as React.CSSProperties,
    admindeletebtn: {
        backgroundImage: "radial-gradient(rgb(246 168 34), rgb(171 114 24))",
        // backgroundColor: "rgb(0, 150, 255)",
        color: "black",
        textTransform:"none",
        fontFamily: "sans-serif",
        fontWeight:"bold",
        fontSize:15
    } as React.CSSProperties,
    quickLink: {
        marginRight: "10px",
        color: "grey",
        cursor: 'pointer',
        textTransform: "capitalize",
        textDecoration: "underline",
    } as React.CSSProperties,
    modalHeadStyle:{
        fontFamily: "sans-serif",
        fontWeight:"bold",
        fontSize:16,
        alignItems:"left",
        marginLeft:"10px"
    } as React.CSSProperties,
    modalSecondHeadStyle:{
        fontFamily: "sans-serif",
        fontWeight:"bold",
        fontSize:14,
        color:"grey",
        marginLeft:"15px"
    } as React.CSSProperties,


    pagename: {
        height: '100%',
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        alignContent: "center",
        // flexDirection: row,
    },
    modalhead: {
        fontFamily: "sans-serif",
        color: "#6a6f7a",
        marginBottom: 7,
        fontWeight: 600,
        fontSize: "14px",
        textAlign: "left",
        marginTop: "15px",
        alignItems: "left"
    } as React.CSSProperties,

    arrowbox: {
        backgroundColor: "#eeeeee",
        width: 35,
        height: 35,
        borderRadius: 8,
        alignItems: "center",
        justifyContent: "center",
        alignContent: "center",
        display: "flex",
        cursor: 'pointer',
    },
    addpopbtn: {
        color: "white",
        marginLeft: "15px",
        padding: "10px 45px 10px 45px ",
        fontFamily: "sans-serif",
        fontSize: 16,
        textTransform: "none",
        width: "40%",
        fontWeight: "bold",
        backgroundColor: "#0096FF "
        /* height: 30%; */
        //   backgroundImage: "radial-gradient(rgb(246 168 34), rgb(171 114 24))",
    } as React.CSSProperties,
    adminaddpopbtn: {
        color: "black",
        marginLeft: "15px",
        padding: "10px 45px 10px 45px ",
        fontFamily: "sans-serif",
        fontSize: 16,
        textTransform: "none",
        width: "40%",
        fontWeight: "bold",
        // backgroundColor: "#0096FF "
        /* height: 30%; */
        backgroundImage: "radial-gradient(rgb(246 168 34), rgb(171 114 24))",
    } as React.CSSProperties,
    arrow: {
        // backgroundColor: "#eeeeee",
        width: 20,
        height: 20,
        // marginLeft: 30,
        // borderRadius: 8,
    },

    title: {
        color: "#42454e",
        fontSize: "20px",
        fontWeight: 100,
    },

    breadcrumb: {
        marginLeft: 10,
        marginTop: -10,
    },

    savebutton: {
        marginRight: 10,
        width: "193px",
        height: "50px",
        borderradius: "8px",
        background: "#e8e8e8",
    },

    nextbutton: {
        width: "193px",
        height: "50px",
        borderradius: "4px",
        background: "#4eabf8",
    },
    range:{
        fontFamily: "sans-serif",
        fontSize: 14,
        textAlign:"left",
        marginLeft:"20px",
        marginTop: 5
    } as React.CSSProperties,

    cardContent: {
        margin: "20px 0px",
        display: 'flex',
        flexDirection: 'column',
    } as React.CSSProperties,

    cardContentOption: {
        margin: "20px 0px",
    },

    secondrowbutton: {
        color: "#0096ff",
    },

    tablescroll: {
        overflowX: "scroll",
    },
    gridMargin: { marginBlockEnd: "0" },
    gridLess: { paddingInline: 0 },
    gridMore: { paddingInline: "1rem" },
    uploadImage: {
        padding: "5rem",
        border: "2px dashed rgba(155, 155, 155, .2)",
        borderRadius: "5px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    filterContainer: {
        // height: "5rem",
        paddingBlock: ".5rem",
        overflow: "scroll",
    },
    formTypography: {
        paddingBlockEnd: 1,
        marginBottom: "5px",
        fontSize: "14px",
        fontWeight: 600,
        color: "#424242",
    },
    modalCardCancelButton: {
        backgroundColor: "rgba(230, 230, 230, 1)",
        borderRadius: "4px",
        textTransform: "none",
        width: "100px",
        fontWeight: 500,
    } as React.CSSProperties,
    modalCardCancelButtonSuccess: {
        backgroundColor: "rgba(230, 230, 230, 1)",
        borderRadius: "4px",
        textTransform: "none",
        fontWeight: 500,
    } as React.CSSProperties,
    modalCardActionButton: {
        backgroundColor: "#42a5f5",
        color: "white",
        borderRadius: "4px",
        width: "100px",
        textTransform: "none",
    } as React.CSSProperties,
    modalCardActionsContainer: { display: "flex", gap: "0.5rem" },
    modalCardActions: {
        display: "flex",
        justifyContent: "flex-end",
        paddingInline: "1rem",
    },
    modalCardContent: {
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        padding: "1rem",
        fontSize: "16px",
    } as React.CSSProperties,
    sequenceModalCardContent: {
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: "1rem",
        paddingRight: "1rem",
        fontSize: "16px",
    } as React.CSSProperties,

    modalCardHeader: {
        // borderBottom: "1px solid #ccc",
        paddingInline: "1rem",
    },
    modalCardHeaderText: {
        fontWeight: 600,
    },
    modalCardRoot: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 500,
        minHeight: "50%",
        maxHeight: "80%",
        fontSize: "16px",
        overflowY: "scroll",
    } as React.CSSProperties,
    modalBackdropProps: {
        backgroundColor: "rgba(0, 0, 0, .7)",
        backdropFilter: "blur(10px)",
    },
    titleText: {
        fontSize: "medium",
        fontWeight: 600,
        textAlign: "center",
    } as React.CSSProperties,
    titleButtonStyle: {
        textTransform: "none",
        backgroundColor: "#42a5f5",
        color: "white",
    } as React.CSSProperties,
    alignRoot: {
        display: "grid",
        gridTemplateColumns: "15vw minmax(85vw, auto)",
    } as React.CSSProperties,
    secondaryGrid: {
        border: "none",
        gap: "1rem",
    },
    rootContainer: {
        display: "flex",
        flexDirection: "column",
        padding: "1rem",
        gap: "1rem",
    } as React.CSSProperties,
    mainGrid: {
        flexGrow: 1,
        backgroundColor: "rgba(200, 200, 200, .5)",
        zIndex: -1,
    },
    root: {
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        padding: "1rem", //
        height: 450,
    },
    cardActions: {
        display: "flex",
        gap: 1,
        alignItems: "center",
        justifyContent: "center",
        borderBlockStart: "1px solid #ccc",
        minHeight: "60px",
    },
    alert: {
        display: "flex",
        position: "fixed",
        top: 80,
        right: 1,
        zIndex: 4000,
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
    } as React.CSSProperties,
    gutter: {
        marginBlockEnd: "0.4rem",
    },

    loadingRoot: {
        display: "flex",
        flexDirection: "column",
    } as React.CSSProperties,
    loadingGutter: {
        marginBlockEnd: 4,
    },
    skeletonStart: { paddingTop: "40%", marginBlockEnd: 6 },
    skeletonEnd: { paddingTop: "10%", marginBlockStart: 6 },
    circularRoot: {
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    progressCircle: {
        color: "#6e6e6e",
    },
    badgeButton: {
        borderRadius: "6px",
        textTransform: "none",
        backgroundColor: "rgba(222, 227, 230, 1)",
    } as React.CSSProperties,
    badgeTypography: {
        paddingBlock: ".2rem",
        paddingInline: ".3rem",
    },

    badgeCardRoot: {
        height: "200px",
        width: "100%",
    },

    workspaceAvatar: { width: 32, height: 32 },
    workspaceCardRoot: {
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        textAlign: "center",
        padding: "1rem",
        height: 450,
    } as React.CSSProperties,
    workspaceIconButton: { padding: "1rem" },
    workspaceStyledCard: { height: "175px" },
    workspaceId: {
        fontSize: "small",
    },
    workspaceName: { fontWeight: 600, fontSize: "medium" },
    descriptionContainer: {
        height: "100px",
        overflow: "scroll",
    },

    formRoot: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        border: "1px solid rgba(155, 155, 155, .5)",
        gap: "0.1rem",
        borderRadius: "4px",
        paddingInline: "8px",
        paddingBlock: "0.2rem",
        color: "rgba(155, 155, 155, 1)",
    },
    formActivePick: { backgroundColor: "#42a5f5", color: "#fff" },
    formAvatar: { width: 32, height: 32, backgroundColor: "#5f5f5f" },
    formTextContainer: { cursor: "pointer" },
    formToolTypography: {
        display: "flex",
        flexWrap: "nowrap",
        fontSize: "small",
    } as React.CSSProperties,

    hide: {
        display: "none",
    },
    secondaryModalRoot: {
        display: "flex",
        gap: 1,
        fontSize: "16px",
        justifyContent: "center",
        borderBlockStart: "1px solid #ccc",
        backdropFilter: "blur(5px)",
        overflow: "scroll",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        alignItems: "center",
    },
    modalCardRootSuccess: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 300,
        height: "37%",
        maxHeight: 300,
        fontSize: "16px",
        overflow: "scroll",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        borderRadius: "0",
    } as React.CSSProperties,
    secondaryModalCardContent: {
        padding: 0,
    },

    secondaryModalContentContainer: {
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        fontSize: "16px",
        alignItems: "center",
    } as React.CSSProperties,

    modalImageBox: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    modalImage: {
        width: "200px",
        height: "100px",
        aspectRatio: "1/1",
        marginBlockEnd: "25px",
    },
    modalSucessTextContainer: {
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        fontSize: "16px",
    } as React.CSSProperties,
    descriptionText: {
        fontSize: "16px",
    },
    modalFormControl: {
        // border: "none",
        // textDecorationColor: "purple",
        // textDecoration: "none",
    } as React.CSSProperties,
    modalTextField: {
        borderRadius: "4px",
        paddingBlock: "18px",
        paddingInline: "14.5px",
        fontSize: "16px",
        border: "1px solid rgba(0, 0, 0, .3)",
    },
    modalTextArea: {
        paddingBlock: "18px",
        paddingInline: "14.5px",
        boxSizing: "border-box",
        border: "1px solid rgba(0, 0, 0, .3)",
        borderRadius: "4px",
        fontSize: "16px",
        resize: "none",
        fontFamily: "sans-serif",
    } as React.CSSProperties,

    modalTextAreaError: {
        paddingBlock: "18px",
        paddingInline: "14.5px",
        boxSizing: "border-box",
        borderRadius: "4px",
        fontSize: "16px",
        resize: "none",
        fontFamily: "sans-serif",
        backgroundColor: "#fdf5f5",
        border: "1px solid red",
    } as React.CSSProperties,

    modalTextFieldError: {
        borderRadius: "4px",
        paddingBlock: "18px",
        paddingInline: "14.5px",
        fontSize: "16px",
        backgroundColor: "#fdf5f5",
        border: "1px solid red",
    },
    modalFormHelper: {
        paddingBlock: "0",
        paddingInline: "0",
    },
    modalFormHelperImage: {
        alignSelf: "center",
        paddingBlock: "0",
        paddingInline: "0",
    },
    excessIcon: {
        color: "white",
    },
    adminquickLink: {
        marginRight: "10px",
        color: "grey",
        cursor: 'pointer',
        textTransform: "capitalize",
        textDecoration: "underline",
    } as React.CSSProperties,
    noData: {
        height: '55vh',
        display: 'grid',
        placeItems: 'center'
    } as React.CSSProperties,
    loader: {
        minHeight: '75vh',
        display: "flex",
        justifyContent: "center",
        alignItems: 'center'
    } as React.CSSProperties,
    tableHead: {
        width:"3px",
        height:"100px",
        // maxWidth:"3vw",
        backgroundColor: localStorage.getItem('user_type') == "Superadmin" ? "darkgrey" : "orange",
        // borderRadius:"2px solid black"
    } as React.CSSProperties,
    tableNonHead: {
        // width:"3px",
        // minHeight:"50vh",
        // maxWidth:"3vw",
        height:"100px",
        backgroundColor: localStorage.getItem('user_type') == "Superadmin" ? "darkgrey" : "orange",
        // borderRadius:"2px solid black"
    } as React.CSSProperties,

    tableHeadSticky: {
        background: 'white',
        padding: 0,
        width: 200,
    } as React.CSSProperties,
    tableCellSticky: {
        background: 'white',
        width: 200,
        padding: 0,
        border: 'none'
    } as React.CSSProperties,
    tableCell: {
        background: 'white',
        border: "1px solid #BDBDBD",
        height:"10vh",
        textAlign:"center",


    } as React.CSSProperties,
    tableAdminCell: {
        background: '#e8e8e8',
        border: "1px solid #BDBDBD",
        height:"10vh",
        textAlign:"center"

    } as React.CSSProperties,
    dateHeader:{
        fontFamily: "sans-serif",
        fontSize:16,
        fontWeight:600,
        marginTop:"10px",
        paddingBottom:"20px"
    } as React.CSSProperties,
    cancelCalenderBtn:{
        color: "black",
        marginLeft: "15px",
        padding: "10px 45px 10px 45px ",
        fontFamily: "sans-serif",
        fontSize: 16,
        textTransform: "none",
        width: "40%",
        fontWeight: "bold",
        /* height: 30%; */
        backgroundColor: "#e8e8e8",
    } as React.CSSProperties,
    dropDownFilterMenu:{
        maxWidth: 320,
        boxSizing: 'border-box',
        maxHeight: 400,
        width: 'auto',
        overFlowY: 'auto',
    } as React.CSSProperties,
    dateFilterStyle:{
        zIndex: 10,
        top: "50%",
        left: "50%",
        background: "white",
        width: "30%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-evenly",
        overflow: "auto",
        borderRadius: 5
    } as React.CSSProperties,
    accNameInitials:{
        background: "darkgrey",
        width: "35px",
        height: "35px",
        // fontSize: 13,
        borderRadius: "50%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        marginRight: 10
    } as React.CSSProperties,
    mainModalStyle:{
        display:"flex",
        justifyContent:"space-between",
        alignItems:"center",
        margin:"15px 0px 15px 0px",
        padding: "0px 10px"
    } as React.CSSProperties,
    duplicatebtn: {
        color: "#ffffff",
        fontFamily: "Open Sans",
        fontSize: "18px",
        fontWeight: 600,
        textAlign: "center",
    } as React.CSSProperties,
    modalButton: {
        marginTop: "20px",
        backgroundColor: "#4eabf8",
        color: "white",
        borderRadius: "4px",
        width: "263px",
        textTransform: "none",
        height: "50px",
        fontWeight: 800,
    } as React.CSSProperties,
    headerNameWrapper: {
        marginLeft: 15 
    } as React.CSSProperties,
    commonTabelCellStyle: {
        height: 70,
        padding: 7,
        overflow: 'hidden',
        border: '1px solid #BDBDBD'
    } as React.CSSProperties,
    dateFilterButtons: {
        margin: '12px 20px',
        display: 'flex',
        justifyContent: 'right'
    } as React.CSSProperties,
};
// Customizable Area End
