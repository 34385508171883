import React from "react";
// Customizable Area Start
import {
  Box,
  Button,
} from "@material-ui/core";
import "./style.css";
// Customizable Area End

// Customizable Area Start
// Customizable Area End
import ChecklistController, { Props } from "./ChecklistController";
import TemplateBreadcrumbs from "./TemplateBreadcrumbs.web";
import UploadErrorModal from "./UploadErrorModal";
import TemplateStepsFooter from "./TemplateStepsFooter";

export default class DefineChecklistDesign extends ChecklistController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  handleSave = () => {
    if (this.query.get('tid') && this.query.get('cid') && this.query.get('sfid')) return this.props.history.push(`/reviewChecklist?cid=${this.query.get('cid')}&sfid=${this.query.get('sfid')}&tid=${this.query.get('tid')}`);
    else if (this.query.get('tid') && this.query.get('cid')) return this.props.history.push(`/reviewChecklist?cid=${this.query.get('cid')}&tid=${this.query.get('tid')}`);
  };

  handleNext = () => {
    if (this.query.get('sfid')) {
      this.props.history.push(`/definechecktable?cid=${this.query.get('cid')}&sfid=${this.query.get('sfid')}&tid=${this.query.get('tid')}`)
    } else {
      this.props.history.push(`/definechecktable?cid=${this.query.get('cid')}&tid=${this.query.get('tid')}`)
    }
  };

  handleFileSelect = (event: any) => {
    this.uploadCSV(event.target.files[0]);
  };

  renderSaveAndNextButtons = () => (
    <div>
      <Button test-id="save-and-go-home-btn" style={webStyle.savebutton} onClick={this.handleSave}>
        <span className="save-button-content">Save & go to home</span>
      </Button>
      <Button test-id="next-btn" style={localStorage.getItem('user_type') == 'Superadmin' ? webStyle.nextbutton : webStyle.adminnextbutton} onClick={this.handleNext}>
        <span className={localStorage.getItem('user_type') == 'Superadmin' ? "next-button-content" : "admin-next-button-content"} >Next</span>
      </Button>
    </div>)

  // Customizable Area End
  render() {
    // Customizable Area Start

    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        <Box style={webStyle.background}>
          <Box style={webStyle.content} className="background-content">
            <div className="heading" style={webStyle.pageheader}>
            <TemplateBreadcrumbs
                navigation={""}
                id={""}
                that={this}
                history={this.props.history} />
                {this.state.isProjectsUploading && (<div className='uploadingLoaderContainer'>
                <div>
                  <div style={{ marginBottom: 5 }} >
                    <b>Uploading...</b>
                  </div>
                  <div className={`loaderbar ${localStorage.getItem('user_type') !== "Superadmin" ? "admin" : ""}`} />
                </div>
              </div>)}
              {this.renderSaveAndNextButtons()}
            </div>
            <Box
              sx={{
                p: 2,
                bgcolor: "#EEEEEE",
              }}
            >
              <div style={webStyle.cardContent}>
                <Button
                  test-id="download-csv-btn"
                  className="csv-button"
                  style={localStorage.getItem('user_type')=='Superadmin'?webStyle.fileButton:webStyle.adminfileButton}
                  onClick={() => this.downloadSampleChecklistCSV()}
                  disableRipple={true}
                >
                  <span className="button-content">
                    Download sample .CSV file
                  </span>
                </Button>
                <input
                  type="file"
                  style={{ display: "none" }}
                  id="icon-button-file"
                  onClick={(event: any) => {
                    event.target.value = ''
                  }}
                  onChange={this.handleFileSelect}
                  accept={".csv"}
                />
                <label htmlFor="icon-button-file">
                  <Button
                    test-id="upload-csv-btn"
                    component="span"
                    className="csv-button"
                    style={localStorage.getItem('user_type')=='Superadmin'?webStyle.fileButton:webStyle.adminfileButton}
                    // onClick={this.uploadCSV}
                    disableRipple={true}
                  >
                    Upload .CSV file
                  </Button>
                </label>
              </div>
            </Box>
            <TemplateStepsFooter step={2}/>
          </Box>
        </Box>
        <UploadErrorModal
          isOpen={this.state.duplicateParam}
          handleClose={() => this.closeChecklistModal()}
          handleOkClick={
            () => this.setState({ duplicateParam: false, parameterModal: [] })
          }
          errorData={this.state.parameterModal} />
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  modalRoot: {
    display: "flex",
    gap: 1,
    fontSize: "16px",
    justifyContent: "center",
    borderBlockStart: "1px solid #ccc",
    paddingBlock: "2rem", //
    backdropFilter: "blur(5px)",
    overflow: "scroll",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    alignItems: "center",
  },
  background: {
    backgroundColor: "white",
    height: "100vh",
    fontFamily: "sans-serif",
    marginTop: 35
  },
  modalBackdropProps: {
    backgroundColor: "rgba(0, 0, 0, .7)",
    backdropFilter: "blur(10px)",
  },
  adminnextbutton: {
    width: "193px",
    height: "50px",
    borderradius: "4px",
    backgroundImage: "radial-gradient(rgb(246 168 34), rgb(171 114 24))",
    color: 'black'
  },
  adminfileButton: {
    backgroundColor: "white",
    margin: "10px",
    fontSize: "12px",
    color: "#f5a823",
    borderRadius: "5%",
    textTransform: "capitalize",
  } as React.CSSProperties,


  modalButton: {
    marginTop: "20px",
    backgroundColor: "#4eabf8",
    color: "white",
    borderRadius: "4px",
    width: "263px",
    textTransform: "none",
    height: "50px",
    fontWeight: 800,
  } as React.CSSProperties,
  duplicatebtn: {
    color: "#ffffff",
    fontFamily: "Open Sans",
    fontSize: "18px",
    fontWeight: 600,
    textAlign: "center",
  } as React.CSSProperties,
  content: {
    top: "50px",
    right: "0px",
    width: "85vw",
    height: "fit-content",
    padding: "50px 20px 10px",
    justifyContent: "space-between",
    backgroundColor:"white"
  },
  pageheader: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: '15px'
  },
  pagename: {
    display: "flex",
    // flexDirection: row,
  },

  arrowbox: {
    backgroundColor: "#eeeeee",
    width: 35,
    height: 35,
    margin: 10,
    borderRadius: 8,
    alignItems: "center",
    justifyContent: "center",
    alignContent: "center",
    display: "flex",
  },

  arrow: {
    // backgroundColor: "#eeeeee",
    width: 20,
    height: 20,
    // marginLeft: 30,
    // borderRadius: 8,
  },

  title: {
    margin: 10,
    marginTop: 2,
    color: "#42454e",
    // fontFamily: "Open Sans",
    fontSize: "20px",
    fontWeight: 300,
  },

  breadcrumb: {
    marginLeft: 10,
    marginTop: -10,
  },

  savebutton: {
    marginRight: 10,
    width: "193px",
    height: "50px",
    borderradius: "8px",
    background: "#e8e8e8",
  },

  nextbutton: {
    width: "193px",
    height: "50px",
    borderradius: "4px",
    background: "#4eabf8",
    color: 'white'
  },
  cardContent: {
    margin: 20,
    height: "500px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  fileButton: {
    backgroundColor: "white",
    margin: "10px",
    fontSize: "12px",
    color: "#0096ff",
    borderRadius: "5%",
    textTransform: "capitalize",
  } as React.CSSProperties,
};
// Customizable Area End
