import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  Select,
  MenuItem,
  FormControl,
  CircularProgress
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import "../../KanbanBoard/src/ProjectCard.css";
import { ToastContainer } from 'react-toastify';
import { CalendarIcon } from "./assets";
// Customizable Area End

import ProjecttaskTracking2Controller, {
  Props,
  configJSON,
} from "./ProjecttaskTracking2Controller";
import KanbanBoard from "../../KanbanBoard/src/KanbanBoard.web";

export default class ProjecttaskTracking2 extends ProjecttaskTracking2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const {loader,clientDropDown,selectedClient,dateRange} = this.state
    return (
      // Customizable Area Start
      <Box
        bgcolor={loader ? "white" : "#F7F8FA"}
        marginLeft={"15vw"}
        width={"85vw"}
        fontFamily={"sans-serif"}
        height={"inherit"}
        marginTop={"65px"}
      >

        {loader ? <Box marginTop={"25vh"} display={"flex"} justifyContent={"center"}><CircularProgress size={50}/></Box> : <Centered>
          <Box margin={"20"} width={"95%"}>
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
              className={"SelectRangeContainer"}
            >
              <Box display={"flex"} alignItems={"center"}>
                <Box marginRight={"3vh"}>Select Date Range</Box>
                <Box>
                  <DateRangePicker
                    value={dateRange as string}
                    className={"calender"}
                    onChange = {(a)=>{this.handleDatePicker(a as unknown as Date[])}}
                    calendarIcon={
                      <img src={CalendarIcon} style={{height: '19', paddingBottom: '1'}} />
                    }
                    format={"dd/MM/y"}
                  />
                </Box>
              </Box>
              <Box />
              <Box display={"flex"} alignItems={"center"} className={'SelectContainerTaskBoard'}>
                <Box marginRight={"20"}>Client</Box>
                <Box bgcolor={"white"} height={"50%"} sx={{ minWidth: "15vw" }}>
                  <FormControl size={"small"} variant={"outlined"} fullWidth>
                    <InputLabel id="demo-simple-select-label"></InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={selectedClient}
                      onChange={this.handleClientChange}
                      MenuProps={{ keepMounted: true, disablePortal: true, classes: { paper: "hangover" } }}
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {clientDropDown.map((el:{client_type: string, id: string, name:string},i:number)=>{
                        return <MenuItem key={i} value={JSON.stringify({
                          "client_type":el.client_type,
                          "client_id":el.id
                        })}
                        style={{
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          display: 'block',
                        }}    
                        >{el.name}</MenuItem>
                      })}
                    </Select>
                  </FormControl>
                </Box>
              </Box>
            </Box>
            {/* Object.keys(projects) has been removed from map in order to sort. Kindly revert if errors are shown */}
             <KanbanBoard
              projects={this.state.projects as any}
              statusFlow={this.state.statusFlow}
              data={this.state}
             />
           
          </Box>
        </Centered>}

        <ToastContainer
          position="bottom-right"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
          />
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export class Centered extends React.Component{
  render(): React.ReactNode {
      return <>
          <Box display={"flex"} justifyContent={"center"} bgcolor="#F7F8FA">
          {this.props.children}
        </Box>
      </>
  }
}
// Customizable Area End
