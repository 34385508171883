import React from 'react';
// Customizable Area Start
import {Paper, Divider, Grid, Box, Link, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Typography, CircularProgress} from '@material-ui/core';
import TooltipIcon from '@material-ui/core/Tooltip';
import { Chart as ChartJS, ArcElement, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, } from 'chart.js';
import { Doughnut, Bar,} from 'react-chartjs-2';
ChartJS.register(ArcElement, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend,);
import {ZoomOutMap, Loop} from '@material-ui/icons';

import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import { CalendarIcon, DownloadIcon } from "./assets";
import './Graphs.css';

import TLGraphsController, { Props} from "./TLGraphsController";
import { CSVLink } from 'react-csv';
// Customizable Area End

export default class TLAnalytics extends TLGraphsController {
// Customizable Area Start
    constructor(props: Props) {
        super(props);
        this.handleDateChange = this.handleDateChange.bind(this)
        
    }
    componentDidMount(): any {
        let token = localStorage.getItem("token")
        this.handleDateChange = this.handleDateChange.bind(this)
        if(token) {
            this.analyticsGraphDetails()
        }
    }

    handleDateChange(date: any) {
        this.setState({ selecteddate: date });
      }

      handleRefresh = () => {
        this.setState({loader: true})
        this.setState({from: null})
        this.setState({to: null})
        this.handleDatePicker(null)
        // this.analyticsGraphDetails()
    }

    displaydescription = (data: any) => {
        let client_name = data[0]
        if(client_name) {
          const charLength = true ? 6 : 10;
          if (client_name.length > 8) {
            return client_name.substring(0, charLength).concat("....");
          }
          return client_name;
        }
      }
      handleClose = () => {
        this.setState({isSuccessModal: false})
        this.setState({tokenError: false})
        this.props.history.push("/EmailAccountLoginsBlock")
    }

    renderTLData = () => {
        return (
            <>
            {!this.state.tokenError && 
            <div className="content"style={webStyle.contents}>
            <Grid container>
                <Grid item lg={6} sm={6}>
                </Grid>
                <Grid item lg={6} sm={6}>
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-end", height: 60}}>
                        <DateRangePicker
                                value={this.state.dateRange}
                                className={"calender"}
                                onChange = {(a)=>{this.handleDatePicker(a)}}
                                calendarIcon={
                                <img src={CalendarIcon} style={{height: '19', paddingBottom: '1'}} />
                                }
                                format={"dd/MM/y"}
                                dayPlaceholder={"DD"} monthPlaceholder={"MM"} yearPlaceholder={"YYYY"}
                                />  
                        <p style={{display: "flex", justifyContent: 'flex-end', marginRight: 10, textDecoration: "underline", marginTop: 24, cursor: "pointer"}} className="handleTLRefresh" onClick={this.handleRefresh}><Loop style={{marginTop: -4}}/> Refresh</p>
                    </div>
                </Grid>
            </Grid>
            <div>
            <Paper style={{borderRadius: 10, flexGrow: 1}}>
                <Grid container>
                    <Grid item sm={12}>
                        <Box style={{height: 25, width: 140, backgroundColor: "#f5a823",marginBottom: 10, borderTopRightRadius: 20, borderBottomRightRadius: 20, marginTop: -5, flexGrow: 1}}>
                            <h4 style={{display: "flex", justifyContent: "center", textAlign: "center",}}><strong style={{margin: 5, fontSize: 14}}>As Team Leader</strong></h4>
                        </Box>
                        <Paper style={{minHeight: 400, margin: 5, marginBottom: 20,  border: '1px solid #e8e8e8', flexGrow: 1, display: "flex", flexDirection: "column", textAlign: "center", maxHeight: "auto",}}>
                            <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", height: 60}}>
                                <Grid container>
                                    <Grid item sm={6}>
                                        <div style={{display: "flex", flexDirection: "row",}}>
                                            <h4 style={{paddingLeft: 20}}>Client Projects (Typewise)</h4>
                                            <TooltipIcon title={this.state.totalProjectclientname}>
                                            <Box style={{height: 30, width: 110, backgroundColor: "#e5e3e3",margin: 15,borderRadius: 20}}>
                                                <p style={{textAlign: "center", margin: 8}} className='handleDesTL'><span style={{margin: 5, fontSize: 14, color: "#9fa0a2"}}>{this.displaydescription(this.state.totalProjectclientname)}</span></p>
                                            </Box>
                                            </TooltipIcon>
                                        </div>
                                    </Grid>
                                    <Grid item sm={6}>
                                        <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-end", alignContent: "flex-end", marginTop: -4}}>
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                            <Divider />
                                <Grid container>
                                    <Grid item lg={4} sm={6} style={{height: 300, marginLeft: -10}}>
                                        <Doughnut 
                                        options={this.state.TLDoughnutChartOptions} 
                                        data={this.state.TLdoughnutChart}
                                        width={'100px'} height={'100px'}
                                        />
                                    </Grid>
                                    <Grid item lg={8} sm={6}>
                                        <div style={{maxWidth: 850, minHeight: 230, maxHeight: "auto", marginTop: 40, marginLeft: -40, border: '2px solid #e8e8e8', backgroundColor: "#f9f9f9",
                                        display: 'flex', flexDirection: 'row', flexGrow: 1}}>
                                            <div style={{display: "flex", flexDirection: "column", width: '250px', justifyContent: "center", 
                                            alignItems: "center", borderRight: '2px solid #e8e8e8', marginTop: 20, marginBottom: 20}}>
                                                <h4 style={{fontSize: 24, marginLeft: -50, marginTop: -10,}}>{this.state.totalNoOfProjectsTypewise}</h4>
                                                <div>
                                                    <p style={{marginTop: -25, color: "#9fa0a2"}}>Total projects</p>
                                                </div>
                                            </div> 
                                            <Grid container>
                                                <div style={{marginTop: 40, marginLeft: 40}}>
                                                    {/* <Grid container>
                                                        {this.state.totalProjectsTypewise.map((item: any) => {
                                                            if(item.count > 0) {
                                                            return (
                                                                <Grid item lg={6} sm={12}>
                                                                    <div style={{display: "flex",}}>
                                                                        <Grid container>
                                                                            <Grid item sm={1}></Grid>
                                                                            <Grid item sm={1}>
                                                                                <p style={{display: "inline-block", marginTop: 14, marginBottom: 10,  height: "10px", width: "10px", borderRadius: "50%", backgroundColor:`${item.color_code}`,marginLeft: -10,}}></p>
                                                                            </Grid>
                                                                            <Grid item sm={5}>
                                                                                <p style={{color: "#9fa0a2", marginTop: 10, marginBottom: 10, marginLeft: 4,display: "flex", textAlign: "start"}}>{item.type}</p>
                                                                            </Grid>
                                                                            <Grid item sm={5}>
                                                                                <p style={{marginLeft: 35, color: "#000000", fontWeight: 600, marginTop: 10, marginBottom: 10,display: "flex", textAlign: "start"}}>{item.count}</p>
                                                                            </Grid>
                                                                            <Grid item sm={1}></Grid>
                                                                        </Grid>
                                                                    </div>
                                                                </Grid>
                                                            )
                                                            }
                                                        })}
                                                    </Grid> */}
                                                    <Grid container>
                                                        {this.state.totalProjectsTypewise.map((item: any) => {
                                                            if(item.count > 0) {
                                                                return (
                                                                    <Grid item lg={6} sm={12}>
                                                                        <div style={{display: "flex",}}>
                                                                            <Grid container spacing={1} style={{marginRight: 20}}>
                                                                                <Grid item sm={1}>
                                                                                    <Box><p style={{display: "inline-block", marginTop: 14, marginBottom: 10,  height: "10px", width: "10px", borderRadius: "50%", backgroundColor:`${item.color_code}`,marginLeft: -10,}}></p></Box>
                                                                                </Grid>
                                                                                <Grid item sm={5}>
                                                                                    <Box style={{ marginTop: 10, marginBottom: 10, marginLeft: 4, }}><p style={{color: "#9fa0a2", marginTop: 10, marginBottom: 10, marginLeft: 4, textAlign: "start"}}>{item.type}</p></Box>
                                                                                </Grid>
                                                                                <Grid item sm={5}>
                                                                                    <Box style={{marginLeft: 50,marginTop: 10, marginBottom: 10,}}><p style={{marginLeft: 20, color: "#000000", fontWeight: 600, marginTop: 10, marginBottom: 10,textAlign: "start"}}>{item.count}</p></Box>
                                                                                </Grid>
                                                                                <Grid item sm={1}></Grid>                        
                                                                            </Grid>
                                                                        </div> 
                                                                    </Grid>
                                                                )
                                                            }
                                                        })}
                                                    </Grid>
                                                </div>
                                            </Grid>
                                        </div>
                                    </Grid>
                                </Grid>
                        </Paper>

                        <Paper style={{minHeight: 400, margin: 5, marginBottom: 20,  border: '1px solid #e8e8e8', flexGrow: 1, display: "flex", flexDirection: "column", textAlign: "center", maxHeight: "auto",}}>
                            <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", height: 60}}>
                                <Grid container>
                                    <Grid item sm={6}>
                                        <div style={{display: "flex", flexDirection: "row",}}>
                                            <h4 style={{paddingLeft: 20}}>Client Projects (Statuswise)</h4>
                                            <TooltipIcon title={this.state.totalProjectclientname}>
                                            <Box style={{height: 30, width: 110, backgroundColor: "#e5e3e3",margin: 15,borderRadius: 20}}>
                                                <p style={{textAlign: "center", margin: 8}}><span style={{margin: 5, fontSize: 14, color: "#9fa0a2"}}>{this.displaydescription(this.state.totalProjectclientname)}</span></p>
                                            </Box>
                                            </TooltipIcon>
                                        </div>
                                    </Grid>
                                    <Grid item sm={6}>
                                        <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-end", alignContent: "flex-end", marginTop: -4}}>
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                            <Divider />
                            <Grid container>
                                <Grid item lg={4} sm={6} style={{height: 300, marginLeft: -10}}>
                                    <Doughnut 
                                    options={this.state.TLDoughnutChartStatusOptoins} 
                                    data={this.state.TLDoughnutChartstatus}
                                    width={'100px'} height={'100px'}
                                    />
                                </Grid>
                                <Grid item lg={8} sm={6}>
                                    <Grid container>
                                        <div style={{maxWidth: 850, minHeight: 255, maxHeight: "auto", marginTop: 40, marginLeft: -40, border: '2px solid #e8e8e8', backgroundColor: "#f9f9f9",
                                        display: 'flex', flexDirection: 'row', flexGrow: 1}}>
                                            <Grid container>
                                                {this.state.totalProjectsStatuswiseanalytics.map((item: any) => {
                                                    if(item.count > 0) {
                                                    return (
                                                        <Grid item lg={3} md={6} sm={12}>
                                                            <div style={{display: "flex",}}>
                                                                <Grid container>
                                                                    <Grid item sm={1}></Grid>
                                                                    <Grid item sm={1}>
                                                                        <p style={{display: "inline-block", marginTop: 14, marginBottom: 10,  height: "10px", width: "10px", borderRadius: "50%", backgroundColor:`${item.color_code}`,marginLeft: -10,}}></p>
                                                                    </Grid>
                                                                    <Grid item sm={5}>
                                                                        <p style={{color: "#9fa0a2", marginTop: 10, marginBottom: 10, marginLeft: 4, textAlign: "start"}}>{item.status}</p>
                                                                    </Grid>
                                                                    <Grid item sm={5}>
                                                                        <p style={{marginLeft: 5, color: "#000000", fontWeight: 600, marginTop: 10, marginBottom: 10, textAlign: "start"}}>{item.count}</p>
                                                                    </Grid>
                                                                    <Grid item sm={1}></Grid>
                                                                </Grid>
                                                            </div>
                                                        </Grid>
                                                    )
                                                    }
                                                })}
                                            </Grid>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Paper>

                        <Grid container spacing={3}>

                            <Grid item lg={6} sm={12}>
                                <Paper  style={{height: 430, margin: 5, marginBottom: 20,  border: '1px solid #e8e8e8', flexGrow: 1, display: "flex", flexDirection: "column"}}>
                                    <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", height: 60}}>
                                        <Grid container>
                                            <Grid item sm={6}>
                                                <div style={{display: "flex", flexDirection: "row",}}>
                                                    <h4 style={{paddingLeft: 20, marginTop: 10}}>Overdue Files<br/>(Clientwise)</h4>
                                                    <TooltipIcon title={this.state.totalProjectclientname}>
                                                    <Box style={{height: 30, width: 110, backgroundColor: "#e5e3e3",margin: 15,borderRadius: 20}}>
                                                        <p style={{textAlign: "center", margin: 8}}><span style={{margin: 5, fontSize: 14, color: "#9fa0a2"}}>{this.displaydescription(this.state.totalProjectclientname)}</span></p>
                                                    </Box>
                                                    </TooltipIcon>
                                                </div>
                                            </Grid>
                                            <Grid item sm={6}>
                                                <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-end", alignContent: "flex-end", marginTop: -4}}>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </div>
                                    <Divider />
                                    <div style={{height: 340}}>
                                    <Grid container>
                                        <Grid item sm={10} style={{marginTop: 30, marginLeft: 20}}>
                                            <Bar 
                                            data={this.state.TLOverdueChart} 
                                            options={this.state.TLOverdueChartOptions} 
                                            height={'180px'}  style={webStyle.barchartstyle}
                                            />
                                        </Grid>
                                        <Grid item sm={1}></Grid>
                                    </Grid>
                                    </div>
                                </Paper>
                            </Grid>

                            <Grid item lg={6} sm={12}>
                                <Paper style={{height: 430, margin: 5, marginBottom: 20,  border: '1px solid #e8e8e8', flexGrow: 1, display: "flex", flexDirection: "column"}}>
                                    <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", height: 60}}>
                                        <Grid container>
                                            <Grid item sm={6}>
                                        <h4 style={{paddingLeft: 20, marginTop: 10}}>No. of Errors<br/>(Designerwise)</h4>
                                        </Grid>
                                        <Grid item sm={6} style={{display: "flex", justifyContent: "flex-end"}}>
                                            <CSVLink {...this.state.exportcsvReport} style={{textDecoration: "none", cursor:"pointer"}}>
                                                <div style={{ display: "flex", paddingLeft: 10, color: "#f5a823", alignItems: "center"}}>
                                                    <img src={DownloadIcon} style={{color: "#f5a823", height: 25, width: 25, marginTop: -1, marginRight: -4}}/>
                                                    <h4 style={{paddingLeft: 10, fontSize: 14, fontWeight: 600, color: "#f5a823", marginTop:20, marginRight: 5}}>Export as .csv</h4>
                                                </div>
                                            </CSVLink>
                                        </Grid>
                                        </Grid>
                                    </div>
                                    <Divider />
                                    <Grid container>
                                        <Grid item sm={12}>
                                            <div style={{height: 365,}}>
                                                <Paper>
                                                <TableContainer style={{maxHeight: 370,}} className='scrollbar'>
                                                    <Table>
                                                        <TableHead style={{height: 5}}>
                                                            <TableRow style={{height: 5}}>
                                                                <TableCell style={{height: 5, backgroundColor: "#f5a823", textAlign: "center",}}>
                                                                    <Typography style={{fontWeight: 600}}>S no.</Typography>
                                                                </TableCell>
                                                                <TableCell style={{height: 5, backgroundColor: "#f5a823", textAlign: "center",}}>
                                                                    <Typography style={{fontWeight: 600}}>Designer ID</Typography>
                                                                </TableCell>
                                                                <TableCell style={{height: 5, backgroundColor: "#f5a823", textAlign: "center",}}>
                                                                    <Typography style={{fontWeight: 600}}>Designer Name</Typography>
                                                                </TableCell>
                                                                <TableCell style={{height: 5, backgroundColor: "#f5a823", textAlign: "center",}}>
                                                                    <Typography style={{fontWeight: 600}}>No of errors</Typography>
                                                                </TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        {this.state.TLDataErrorDesigneerWise?.map((userdata: any) => {
                                                            return (
                                                                <TableBody>
                                                                    <TableRow>
                                                                        <TableCell align='center' style={{borderRight: "1px solid #d0d0d0", borderTop: "1px solid #d0d0d0", backgroundColor: "white",}}>{userdata.s_no}</TableCell>
                                                                        <TableCell align='center' style={{borderRight: "1px solid #d0d0d0", borderTop: "1px solid #d0d0d0", backgroundColor: "white",}}>{userdata.email_id}</TableCell>
                                                                        <TableCell align='center' style={{borderRight: "1px solid #d0d0d0", borderTop: "1px solid #d0d0d0", backgroundColor: "white",}}>{userdata.name} </TableCell>
                                                                        <TableCell align='center' style={{borderTop: "1px solid #d0d0d0", backgroundColor: "white",}}>{userdata.errors}</TableCell>
                                                                    </TableRow>
                                                                </TableBody>
                                                            )
                                                        }) }
                                                    </Table>
                                                </TableContainer>
                                                </Paper>
                                            </div>
                                        </Grid>
                                    </Grid>
                                    <div style={{display: 'flex', justifyContent: 'flex-end', padding: 10,}}>
                                        <Box style={{backgroundColor: "#e8e8e8", height: 30, width: 30, border: '1px solid #e8e8e8', display: 'flex', justifyContent: "center", alignItems: "center", marginLeft: 10, marginTop: -30, marginRight: -20, borderRadius: "50%"}}>
                                            <Link onClick={() => {this.props.history.push("/detailedtable", {state: this.state.TLDataErrorDesigneerWise, title: "No. of Errors (Designerwise)"})}}  underline='none' style={{color: 'black'}}>
                                                <ZoomOutMap style={{cursor: "pointer"}} />
                                            </Link>
                                        </Box>
                                    </div>
                                </Paper>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
            <br/>
            <Paper style={{borderRadius: 10}}>
                <Grid container>
                    <Grid item sm={12}>
                        <Box style={{height: 25, width: 155, backgroundColor: "#f5a823",marginBottom: 10, borderTopRightRadius: 20, borderBottomRightRadius: 20, marginTop: -5}}>
                            <h4 style={{display: "flex", justifyContent: "center", textAlign: "center",}}><strong style={{margin: 5, fontSize: 14}}>As Designer/QC/QA</strong></h4>
                        </Box>
                        <Grid container>
                            <Grid item lg={6} sm={12}>
                                <Paper style={{height: 430, margin: 5, marginBottom: 40,  border: '1px solid #e8e8e8'}}>
                                    <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", height: 60}}>
                                        <Grid container>
                                            <Grid item sm={6}>
                                                <div style={{display: "flex", flexDirection: "row",}}>
                                                    <h4 style={{paddingLeft: 20}}>All Clients' Projects (Rolewise)</h4>
                                                </div>
                                            </Grid>
                                            <Grid item sm={6}>
                                                <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-end", alignContent: "flex-end", marginTop: -4}}>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </div>
                                    <Divider />
                                    <div style={{height: 340}}>
                                    <Grid container>
                                        <Grid item sm={10} style={{marginTop: 30, marginLeft: 20}}>
                                            <Bar data={this.state.TLChartRolewise} 
                                            options={this.state.TLChartRolewise.labels.length > 0 ? this.state.TLOptionRolewise : this.state.TLOptionRolewiseNodata} 
                                            height={'180px'} style={webStyle.barchartstyle}
                                            />
                                        </Grid>
                                        <Grid item sm={1}>
                                            <div>
                                                <div style={{display: "flex", flexDirection: "row", rotate: "270deg", width: 75, height: 154, marginLeft: 68, marginRight: 20, marginTop: -16}}>
                                                    <p style={{ height: "10px", width: "10px", borderRadius: "50%", backgroundColor: "#660066",margin: '3px 3px 7px 14px',}}></p>
                                                    <p style={{height: 50, marginLeft: 10, marginTop: 0, fontSize: 13}}>QA</p>
                                                </div>
                                                <div style={{display: "flex", flexDirection: "row", rotate: "270deg", width: 75, height: 62, marginLeft: 23, marginRight: 20, marginTop: -16}}>
                                                    <p style={{ height: "10px", width: "10px", borderRadius: "50%", backgroundColor: "#FFBF00",margin: '3px 3px 7px 14px',}}></p>
                                                    <p style={{height: 50, marginLeft: 10, marginTop: 0, fontSize: 13}}>QC</p>
                                                </div>
                                                <div style={{display: "flex", flexDirection: "row", rotate: "270deg", width: 126, height: 50, marginLeft: 1}}>
                                                    <p style={{margin: "-8px 6px 7px -13px", height: "10px", width: "10px", borderRadius: "50%", backgroundColor: "#1F51FF",}}></p>
                                                    <p style={{marginLeft: 3, marginTop: -11, height: 50, fontSize: 13}}>Designer</p>
                                                </div>
                                            </div>
                                        </Grid>
                                    </Grid>
                                    </div>
                                    {/* <div style={{display: 'flex', justifyContent: 'flex-end', padding: 10,}}>
                                        <Box style={{backgroundColor: "white", height: 20, width: 20, border: '1px solid #e8e8e8', display: 'flex', justifyContent: "center", alignItems: "center", marginLeft: 5, marginTop: -30}}>
                                            <ArrowLeft/>
                                        </Box>
                                        <Box style={{backgroundColor: "white", height: 20, width: 20, border: '1px solid #e8e8e8', display: 'flex', justifyContent: "center", alignItems: "center", marginLeft: 5, marginTop: -30}}>
                                            <ArrowRight />
                                        </Box>
                                        <Box style={{backgroundColor: "#e8e8e8", height: 30, width: 30, border: '1px solid #e8e8e8', display: 'flex', justifyContent: "center", alignItems: "center", marginLeft: 10, marginTop: 1, marginRight: -20, borderRadius: "50%"}}>
                                            <Link onClick={() => {this.props.history.push("/detailedgraph", {state: this.state.TLChartRolewise, title: "All Clients' Projects (Rolewise)"})}} underline='none' style={{color: 'black'}}>
                                                <ZoomOutMap/>
                                            </Link>
                                        </Box>
                                    </div> */}
                                </Paper>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
            </div>
            
        </div>
        }
        </>
        )
    }

// Customizable Area End
    render() {
        // Customizable Area Start
        // Customizable Area End
        return (
            // Customizable Area Start
            <>
            {/* <Box style={webStyle.sign} className="headnavbar">
                <h3 style={webStyle.headnavAnalytics}>
                     Analytics
                </h3>
            </Box> */}
            <div style={webStyle.container} className="boxcontainer">
            <div style={webStyle.divnav}>
                <h3 style={webStyle.headnavAna}  className="headnavbaranalytics">
                    Analytics
                </h3>
            </div>
            {this.state.loader ? <Grid item sm={12}><div style={webStyle.circularprogress}><CircularProgress size={50} /></div></Grid>
                  :
                  <div>
                    {this.state.chartError ?  
                        <div style={webStyle.errormessageContent}><p>{this.state.errorStatement}</p></div> 
                    :
                        <>
                        {this.renderTLData()}
                        </>
                    
                }
                </div>
    }
            </div>
                                                            
            </>
        )
    }
}
 // Customizable Area End
 // Customizable Area Start
const webStyle = {

    container: {
        backgroundColor: "#eeeeee",
        height: "100%",
        color: "#5f5f5f",
        fontFamily: "sans-serif",
        width: "85vw",
        top: "50px",
        right:0,
        padding:"30px 20px 10px 0px",
        marginLeft: '15vw',
        boxSizing: "border-box",
        // position: "fixed",
        overflow: "scroll"
    }as React.CSSProperties,

    innerGridcontainer: {
        // marginLeft: 0,
    },

    content: {
        margin: 0
    },
    
    sign: {
        display: "flex",
        justifyContent: "flex-start",
        paddingLeft: "20px",
        fontFamily: "sans-serif",
        zIndex: 1300,
    },

    
    headnav: {
        marginLeft: 10,
        marginTop: "-55px",
        zIndex: 1300,
    },

    contents: {
        marginLeft: 30
    },

    twocards: {
        display: "flex",
        height: 100,
        width: 350,
        marginRight: 30,
        marginTop: 20,
        padding: 20,
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
    },

    iconimages: {
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
    },

    icons: {
        width: 120,
        height: 120,
    },

    iconname: {
        marginLeft: 28,
        marginRight: 20,
    },

    iconnamecheck: {
        marginLeft: 32,
        marginRight: 20,
    },

    text: {
        backgroundColor: "white", 
        color: "#000000", 
        margin: 10,
        marginRight: 20,
    },

    circularprogress: {
        height: "100%",
        // margin: "100",
        marginTop: -50,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        alignContent: "center",
        // color: "#6e6e6e"
    } as React.CSSProperties,

    barchartstyle: {
        maxHeight: "300px",
        maxWidth: "650px"
    },

    errormessageContent: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        fontSize: 16,
        fontWeight: 600,
        color: "#6a6f7a",
        fontFamily: "sans-serif",
        margin: 300
    }as React.CSSProperties,

    headnavAnalytics: {
        marginLeft: 250,
        marginTop: "22px",
        color: "#6a6f7a",
        fontWeight: "bold",
        fontFamily: "sans-serif",
        zIndex: 1300,
    }as React.CSSProperties,

    divnav: {
        display: "flex",
        justifyContent: "flex-start",
    },

    headnavAna: {
        marginLeft: 35,
        marginTop: -55,
        zIndex: 1300
    },
  

}

// Customizable Area End