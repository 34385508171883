//@ts-nocheck
import React from "react";
import MomentUtils from "@date-io/moment";
import { ThemeProvider } from "@material-ui/styles";
import moment from "moment";
import {
  MuiPickersUtilsProvider,
  DatePicker,
} from "material-ui-pickers";

import Avatar from "@material-ui/core/Avatar";
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';

import {
  createMuiTheme,
  Box,
  Button,
  Typography,
  InputLabel,
  Grid,
  Select,
  MenuItem,
  Divider,
  Modal,
  Menu,
  withStyles,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  TablePagination,
  CircularProgress,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import SearchBar from "material-ui-search-bar";
import CloseIcon from "@material-ui/icons/Close";
import CheckIcon from '@material-ui/icons/Check';
import  {filter, darkFilter } from "./assets"
// Customizable Area End

import LeaveReportController, { Props } from "./LeaveReportController";
import "./LeaveReport.css";
export const configJSONBase = require("../../../framework/src/config");


const materialThemeTwo = createMuiTheme({
  overrides: {
    // @ts-ignore
    MuiPickersToolbar: {
      toolbar: {
          color: "black",
          backgroundColor: "#e8e8e8"
      },
    },
    MuiPickersDay: {
      day: {
      color: "black"
      },
      daySelected: {
          backgroundColor: "#e8e8e8"
      },
      dayDisabled: {
          color: "#e8e8e8"
      },
      current: {
          color: "#e8e8e8"
      },
      isSelected: {
        color: "white",
        backgroundColor: "#e8e8e8"
      },
    },
    MuiPickersToolbarButton: {
      toolbarBtn: {
          color: "black",
      },
      toolbarBtnSelected: {
          color: "black"
      },
   },
    MuiCssBaseline: {
      "@global": {
        "*::-webkit-scrollbar": {
          width: "10px",
        },
        "*::-webkit-scrollbar-track": {
          background: "#E4EFEF",
        },
        "*::-webkit-scrollbar-thumb": {
          background: "#1D388F61",
          borderRadius: "2px",
        },
      },
    },

    palette: {
      primary: "red"
    },
    MuiButton: {
      textPrimary: {
        color: "rgb(171 114 24)",
      },
    },

    MuiPickersModal: {
      dialogAction: {
        color: "#8bc34a",
      },
    },
    myComponent: {
      "& .MuiPickersDay-isSelected": {
        backgroundColor: "red",
      },
    },

    MuiPickersCalendarHeader: {
      switchHeader: {
        backgroundColor: "white",
        color: "black"
      },
      dayLabel: {
          color: "black"
      },
    },
  },
});
//istanbul ignore next
const useStyles = ((theme) => ({ 
  menuStyle : {
    "& .MuiPaper-root":{
       maxWidth : "400px",
     }
   },
  selectRoot: {  
    '&:focus':
    { backgroundColor:'yellow' } 
  } 
}));


//istanbul ignore next
const usePlaceholderStyles = makeStyles(theme => ({
  placeholder: {
    color: "#aaa"
  }
}));

export class LeaveReport extends LeaveReportController {
  constructor(props: Props) {
    super(props);
    console.log(props, 'Props')
  }

  handleBack = () => {
    console.log("moving back");
    this.props.history.push("/reporting");
  };

  handleClose = () => {
    this.setState({ genericModalOpen: false,isEditMode:false, firstname:'',lastname:'', birthDate:null,
    joiningDate:null,
    email:"",
    contactNo:"",
    password:'',
    role:"",
    desc:"",
    workspace:"",
    designation: "",
    fileData:"", page:true,isErrorEmail:false});
  };
  modalClose = () => {
    this.setState({ isSuccessModal: false ,genericModalOpen:false,modalMsg:"",isEditMode:false,modalVarient:""});
  };
  handleImage = (e: any) => {

    this.setState({
      fileData: e.target.files[0],
    });

    console.log(this.state.fileData, "FileData");
  };
  handleDateChange = (date: any) => {
    this.setState({ toDate: date });
    };
    handleDateChangeFrom = (date: any) => {
      this.setState({ fromDate: date });
      };
  handleJoingDateChange = (date: any) => {
    this.setState({ joiningDate: date });
  };

  handleAction = () => {
    console.log("Action");

    if (this.state.page) {
      this.setState({ page: false });
    }

      if (
        this.state.firstname &&
        this.state.lastname &&
        this.state.birthDate &&
        this.state.email &&
        this.state.contactNo &&
        this.state.designation&&
        this.state.role &&
        this.state.workspace&&
        this.state.fileData
      ) {

        this.setState({ isErrorEmail: false });
        if(this.state.isEditMode){
          this.updateUser()
        }else{
        }
        this.setState({
          page: true,
          firstname:"",
          lastname:"",
          birthDate:null,
          joiningDate:null,
          email:"",
          contactNo:"",
          password:'',
         role:"",
         designation:"",
         desc:"",
         workspace:"",

         fileData:"",

          isErrorFirstName:false,
          isErrorLastName:false,
          isErrorPassword:false,

        })
      }
  };
  handleChange = (e: any) => {
    let char, email;
    e?.persist();
    if (e?.target?.name == "password") {

      let password = e?.currentTarget?.value;

      const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])(?=.{8,})/;
      !regex.test(password) || password.length < 5
        ? this.setState({ isErrorPassword: true })
        : this.setState({ isErrorPassword: false });
      this.setState({ password: e?.currentTarget?.value });
    } else if (e?.target?.name == "firstname") {
      char = /^[a-zA-Z ]*$/.test(e?.currentTarget?.value);
      char
        ? this.setState({
            isErrorFirstName: false,
            firstname: e?.currentTarget?.value,
          })
        : this.setState({ isErrorFirstName: true });
    } else if (e?.target?.name == "lastname") {
      char = /^[a-zA-Z ]*$/.test(e?.currentTarget?.value);
      char
        ? this.setState({
            isErrorLastName: false,
            lastname: e?.currentTarget?.value,
          })
        : this.setState({ isErrorLastName: true });
    } else {
      const val = {
        ...this.state,
        [e.target.name]: e.target.value,
      };
      this.setState(val);
    }
  };

  successErrModalBody = () => (
    <>
      <div>
        <Typography
          style={{
            fontSize: "16px",
            fontFamily: "sans-serif",
            fontWeight: "bold",
          }}
        >
          {this.state.modalMsg }

        </Typography>


      </div>
    </>
  );

handleChangePage = (event: unknown, newPage: number) => {
  this.setState({pageNo:newPage})
};
handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({rowsPerPage:+event.target.value})
  this.setState({pageNo:0})
};

requestSearch = (e:any) => {

  this.setState({search:e})


};
 cancelSearch = () => {
  this.setState({search:""})
  this.requestSearch(this.state.search);
  this.setState({search:""})
};

handleApply =()=>{
  let isError = false;
  let shouldApplyFilter = true;
  
    if(this.state.toDate || this.state.fromDate){
      if(!moment(this.state.toDate).isSame(this.state.fromDate) && !moment(this.state.toDate).isAfter(this.state.fromDate)){
        this.setState({ filterToDateError:true})
        isError = true;
    
      }
    }

  if(isError){
    return;
  }

  if(!this.state.toDate && !this.state.fromDate && this.state.role == "" && this.state.selectedWorkspace == ""){
    shouldApplyFilter=false;
  }
  
    this.getApply()
  this.setState({filterOpen:null,initialTableLoad:true, isFilterApplied:shouldApplyFilter })
}
handleFilterClose =()=>{
  this.setState({isConfirm:false,isStatus:"", role:"",filterOpen:null,selectedWorkspace:"",toDate: "", fromDate: "", filterToDateError: false})
}
handleFilterClick =(e:any) =>(
 this.setState({filterOpen:e.currentTarget})
)

handleCancelBtn =() =>{
  this.setState({isConfirm:false})
}
handleYes=() =>{
   this.deleteUser()
   this.setState({isConfirm:false})
}
confirmDelete =() =>(
  <Modal
  className="modal-backdrop"
  open={this.state.isConfirm}
  onClose={this.handleCancelBtn}
  style={{
    overflow: "auto",
  }}
>
  <div
    style={{
      zIndex: 10,
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      background: "white",
      width: "20%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      gap: "20px",
    }}
  >
   <Grid>
    <div
      style={{ textAlign: "center" }}
    >
      <div  className="confirm-heading" style={{padding:"10px"}} >
      <Typography style={{fontWeight:900}}>Delete User</Typography>
    <CloseIcon   onClick={this.handleCancelBtn} />
      </div>
      <Divider/>
    <div style={{ padding:"20px"}} className="confirmbody">
      <Typography>Are you sure,you want to delete selected user ?</Typography>
    </div>
    <Divider/>
    <div style={{padding:"20px"}}>
    <Button
        onClick={this.handleCancelBtn}
        className="cancelbtn"

      >
        Cancel
      </Button>
      <Button
        onClick={this.handleYes}
        className="yesbtn"
        style={{ backgroundColor: "#0096FF "}}
      >
        Yes
      </Button>
    </div>
    </div>
    </Grid>
  </div>
</Modal>
)

  checkStatus =()=> (<CheckIcon/>)
  handleReset=()=>{
   this.getUsers()
    this.setState({selectedWorkspace:"",role:"", toDate : "", fromDate: "", filterToDateError: false,isFilterApplied:false,})
  }

  renderNoData=()=>{
    if(this.state.search?.length > 0 && this.searchedRecordsLength == 0 || this.state.search?.length > 0 && this.state.usersData?.length == 0){
      return <p className="norecord">No Data found</p>
    }
    return this.state.usersData?.length >0 ? null:<p className="norecord">No Data Found</p>
  }   

  getCount=()=>{
    if(this.state.search?.length > 0 && this.searchedRecordsLength == 0){
      return 0
    }
    if(this.state.search?.length > 0 && this.searchedRecordsLength > 0){
      return this.searchedRecordsLength
    }
    return this.state.usersData?.length
  }

  getUserData=(filteredList:any)=>{
    let data = [];

    data = filteredList.filter((item:any)=>{
                  if(!this.state.search) return true
                  if(item?.attributes?.first_name?.toLocaleLowerCase()?.startsWith(this.state?.search.toLocaleLowerCase()))return true
                }).slice(this.state.pageNo * this.state.rowsPerPage, this.state.pageNo * this.state.rowsPerPage + this.state.rowsPerPage)

                this.searchedRecordsLength = data?.length;
                return data
  }

  parseImg(img: string | null) {
    if (!img) return undefined;
    const imageLink = configJSONBase.baseURL;
    const imageFragment =
      typeof img === "string" && img.split("/").includes("rails");
    const imgSrc = imageFragment ? `${imageLink}/${img}` : img;
    return imgSrc;
  }

  handleError=()=>{
    if(this.state.fromDate && this.state.toDate){
      if(!moment(this.state.toDate).isSame(this.state.fromDate) && !moment(this.state.toDate).isAfter(this.state.fromDate)){
        this.setState({filterToDateError: true})
      }else{
        this.setState({filterToDateError: false})
      }
      
    }
  }

  getFilterIcon=()=>{
    if(this.state.isFilterApplied){
      return <img src={darkFilter} style={{ width: 22, height: 22,marginRight:"15px",
      cursor: "pointer"}} onClick={this.handleFilterClick}/>
    }else{
      return <img src={filter} style={{ width: 30, height: 30,marginRight:"15px",
      cursor: "pointer"}} onClick={this.handleFilterClick}/>
    }
  }

  render() {
    const {classes} = this.props   
    const filteredList = this.state.usersData

    return (
      <>
      {this.state.isConfirm && this.confirmDelete()}
     
      <Menu
          anchorEl={this.state.filterOpen}
          id="account-menu"
          open={Boolean(this.state.filterOpen)}
          onClose={this.handleFilterClose}
          getContentAnchorEl={null}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          className={classes?.menuStyle}
         
        >
          
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "baseline",
              }}
            >
              <InputLabel className="inputfilter"> Filter</InputLabel>
              <InputLabel onClick={this.handleReset} className="inputfilter clearfilter">Clear Filter</InputLabel>
              </div>

            <MenuItem>
              <Grid sm={12}>
                <InputLabel className="inputLabel">Workspace</InputLabel>
                <Select
                  fullWidth
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  placeholder="Select"
                  value={this.state.selectedWorkspace}
                  name="selectedWorkspace"
                  displayEmpty
                  renderValue={this.state.selectedWorkspace !== "" ? undefined : () => <span style={{color : "#aaa"}}>Select</span>}
                  onChange={this.handleChange}
                  variant="outlined"
                  required
                  style={{ height: "40px", textAlign: "left" }}
                >

              <MenuItem value="">
                <em>None</em>
              </MenuItem>
                  {this.state.workspaceData?.map( (item:any) => (
                    <MenuItem key={item.attributes.name} value={item.attributes.name}>{item.attributes.name}</MenuItem>
                  ))}
              
                </Select>
              </Grid>
            </MenuItem>
            <MenuItem>
              <Grid item sm={12}>
                <InputLabel className="inputLabel">Role</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  placeholder="Select"
                  value={this.state.role}
                  name="role"
                  displayEmpty
                  renderValue={this.state.role !== "" ? undefined : () => <span style={{color : "#aaa"}}>Select</span>}
                onChange={this.handleChange}
                  variant="outlined"
                  required
                  fullWidth
                  style={{ height: "40px", textAlign: "left" }}
                >

              <MenuItem value="">
                <em>None</em>
              </MenuItem>
                   {this.state.roleData?.map((item:any) =>(
                    <MenuItem key={item} value={item}>{item}</MenuItem>
                  ))}
                </Select>
              </Grid>
            </MenuItem>
            
            <MenuItem>
              <Grid xs={12} container>
                <Grid item xs={6} container style={{paddingRight : "10px"}}>
                  <Grid item xs={12}>
                    <InputLabel className="inputLabel">Leave from Date</InputLabel>
                  </Grid>
                  <Grid item xs={12}>
                  <MuiPickersUtilsProvider
                        utils={MomentUtils}
                      >
                        <ThemeProvider theme={materialThemeTwo}>
                          <DatePicker
                            data-test-id="fromDatePickerID"
                            keyboard
                            variant="outlined"
                            size="small"
                            fullWidth
                            style={webStyle.text}
                            placeholder="DD/MM/YYYY"
                            format={"DD/MM/YYYY"}
                            mask={(value) =>
                              value
                                ? [
                                    /\d/,
                                    /\d/,
                                    "/",
                                    /\d/,
                                    /\d/,
                                    "/",
                                    /\d/,
                                    /\d/,
                                    /\d/,
                                    /\d/,
                                  ]
                                : []
                            }
                            value={this.state.fromDate}
                            onChange={(date)=>{
                              this.setState({fromDate:date},this.handleError)
                            }}
                            disableOpenOnEnter
                            animateYearScrolling={false}
                            autoOk={true}
                            clearable
                            onInputChange={(e: any) => {
                              if(e.target.value == ""){
                               this.setState({fromDate:"",filterToDateError:false});
                              }}
                            }    
                          />
                        </ThemeProvider>
                      </MuiPickersUtilsProvider>
                  </Grid>
                </Grid>
                <Grid item xs={6} container style={{paddingLeft : "10px"}}>
                  <Grid item xs={12} >
                    <InputLabel className="inputLabel">Leave to Date</InputLabel>
                  </Grid>
                  <Grid item xs={12}>
                  <MuiPickersUtilsProvider
                        utils={MomentUtils}
                      >
                        <ThemeProvider theme={materialThemeTwo}>
                          <DatePicker
                            data-test-id="toDatePickerID"
                            keyboard
                            variant="outlined"
                            size="small"
                            fullWidth
                            style={webStyle.text}
                            placeholder="DD/MM/YYYY"
                            format={"DD/MM/YYYY"}
                            mask={(value) =>
                              value
                                ? [
                                    /\d/,
                                    /\d/,
                                    "/",
                                    /\d/,
                                    /\d/,
                                    "/",
                                    /\d/,
                                    /\d/,
                                    /\d/,
                                    /\d/,
                                  ]
                                : []
                            }
                            value={this.state.toDate}
                            onChange={(date)=>{
                              this.setState({toDate:date},this.handleError)
                            }}
                            disableOpenOnEnter
                            animateYearScrolling={false}
                            autoOk={true}
                            clearable
                            onInputChange={(e: any) => {
                              if(e.target.value == ""){
                               this.setState({toDate:"",filterToDateError:false});
                              }}
                            }  
                          />
                        </ThemeProvider>
                      </MuiPickersUtilsProvider>
                  </Grid>
                </Grid>
              </Grid>
          
            </MenuItem>
            <Grid item xs={12} >
            
            <Grid style={{paddingLeft : "20px"}}>
{this.state.filterToDateError && <Typography style={webStyle.errorText}>From Date can not be greater than To Date</Typography>}
            </Grid>

           
          
        </Grid>
            <div
              style={{
                padding: "10px 16px",
                display: "flex",
                justifyContent: "flex-end",
                gap: "10px",
              }}
            >
              <Button
                style={{ backgroundColor: "grey" }}
                onClick={this.handleFilterClose}
                className="btn-cancelmenu"
              >
                Cancel
              </Button>
              <Button
                className="btn-addmenu"
                onClick={this.handleApply}
              >
                Apply
              </Button>
            </div>
          
        </Menu>
        <Box
          sx={{
            height:"100%",
            width: "85vw",
            top: "45px",
            right:0,
            boxSizing:"border-box",
            padding:"50px 20px 10px 20px",
            position: "absolute",
          }}
          style={{ backgroundColor:"#e8e8e8"}}
          justifyContent="space-between"
          className="maintablehead"
        >
          <Box sx={{ display: "flex" ,justifyContent:"space-between",alignItems:"center"}}>
            
            <div style={{display: "flex", alignItems: "center"}}> 
            <Box style={webStyle.arrowbox}>
                  <ArrowLeftIcon
                    onClick={this.handleBack}
                  />
                </Box>
            <Typography
              style={{
                fontFamily: "sans-serif",
                fontWeight: "bold",
                fontSize: 16,
              }}
            >
           Leave Report
            </Typography>
            </div>
          
            <div
              style={{
                display: "flex",
                justifyContent: "end",
              }}
            >
              <div style={{display:"flex",alignItems:"center"}}>
                {this.getFilterIcon()}
              </div>

              <SearchBar
                placeholder="Search User"
                  value={this.state.search}
                  onChange={this.requestSearch}
                  onCancelSearch={this.cancelSearch}
              />
            </div>
          </Box>
            {
              this.state.loading ? <Box className="progres">  <CircularProgress size={50}/> </Box> :
              <>
              <div className="tableuserdata">
               <Table aria-label="sticky table" className="usermanagement-table"  >
              <TableHead className="maintablehead">
                <TableRow>
                 <TableCell className="tableheader">S.no</TableCell>
                 <TableCell className="tableheader">{" "}</TableCell>
                 <TableCell className="tableheader">First Name</TableCell>
                 <TableCell className="tableheader">Last Name</TableCell>
                 <TableCell className="tableheader">Official email id</TableCell>
                 <TableCell className="tableheader"> Contact number</TableCell>
                 <TableCell className="tableheader">Designation</TableCell>
                 <TableCell className="tableheader">Role</TableCell>
                 <TableCell className="tableheader">Workspace</TableCell>
                 <TableCell className="tableheader">Leave from Date</TableCell>
                 <TableCell className="tableheader">Leave to date</TableCell>
                </TableRow>
              </TableHead>


              <TableBody>
                {this.state.usersData?.length >0  ? this.getUserData(filteredList).map((item:any) =>
                 {
                  return (
                    <TableRow key={item.slNo}>
                     <TableCell className="tablecell tabledata" >{item?.slNo}</TableCell>
                     <TableCell className="tablecellAvatar">
                     <Avatar className="smallSize" alt="" src={item?.attributes?.image}/>
                    </TableCell>
                    <TableCell className="tablecell tabledata">{item?.attributes?.first_name}</TableCell>
                    <TableCell className="tablecell tabledata"> {item?.attributes?.last_name}</TableCell>
                    <TableCell className="tablecell"> {item?.attributes?.email}</TableCell>
                    <TableCell className="tablecell tabledata"> {"+" + item?.attributes?.contact_number}</TableCell>
                    <TableCell className="tablecell tabledata"> {item?.attributes?.designation}</TableCell>
                    <TableCell className="tablecell  name-break1"> {item?.attributes?.role}</TableCell>
                   
                    <TableCell className="tablecell tabledata"> {item?.attributes?.workspace}</TableCell>
                    <TableCell className="tablecell"> {moment(item?.attributes?.leave_from).format("DD/MM/YYYY")}</TableCell>
                    <TableCell className="tablecell tabledata"> {moment(item?.attributes?.leave_to).format("DD/MM/YYYY")}</TableCell>

                  </TableRow>
                    )
                }):null
                }
                  </TableBody>

            </Table>
            { this.renderNoData() }
            </div>

        <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={this.getCount()}
        rowsPerPage={this.state.rowsPerPage}
        page={this.state.pageNo}
        onPageChange={this.handleChangePage}
        onRowsPerPageChange={this.handleChangeRowsPerPage}
      />
      </>
            }
        </Box>

      </>
    );
  }
}

export default withStyles(useStyles)(LeaveReport)

const webStyle = {
  parent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "100%",
    height: "fit-content",
    position: "relative",
    backgroundRepeat: "no-repeat",
  },
  boxes: {
    marginTop: "10px",
  },
  arrowbox: {
    backgroundColor: "#fff",
    width: 35,
    height: 35,
    margin: 10,
    borderRadius: 8,
    alignItems: "center",
    justifyContent: "center",
    alignContent: "center",
    display: "flex",
    cursor: "pointer"
  },
  errorText: {color:"#f44336", fontSize:15, marginTop:5},


};
