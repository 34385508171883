// Customizable Area Start
import React from "react";
import {
  Box,
  Grid,
  Card,
  Typography,
  CircularProgress
} from "@material-ui/core";
export const configJSONBase = require("../../../framework/src/config");
import ReportingController, {
  Props,
} from "./ReportingTemplateSelectionController";
const iconArrow = require("../assets/iconArrow.png");

export default class ReportingTemplateSelection extends ReportingController {
  constructor(props: Props) {
    super(props);
  }
  handleBack = () => {
    return this.props.history.push('/reporting');
  };
  render() {
    if (this.state.loading) return (<Box style={webStyle.background} className="background-content">
      <Box style={webStyle.content}>
        <Box style={webStyle.loader}>
          <CircularProgress size={50} />
        </Box>
      </Box>
    </Box>)
    else if (!this.state.loading && this.state.error) {
      return (<Box style={webStyle.content}><div style={webStyle.errormessageContent}><p>{this.state.error}</p></div></Box>)
    }
    else if(!this.state.loading && !this.state.error) return (
      <>
        <Box style={webStyle.background} className="background-content">
          <Box style={webStyle.content}>
            {localStorage.getItem('user_type') == 'Superadmin' && (<div style={webStyle.pageheader}>
              <div style={webStyle.pagename}>
                <Box onClick={() => this.handleBack()} style={webStyle.arrowbox}>
                  <img src={iconArrow} />
                </Box>
                &nbsp;
                <div style={webStyle.headerNameWrapper}>
                  <h4 style={webStyle.title}>{this.state.templates?.[0]?.attributes?.report_title || 'Reporting'}</h4>
                </div>
              </div>
            </div>)}
            <div style={webStyle.cardContent}>
              <Grid container>
                {this.state.templates?.length ? this.state.templates?.map((item: any, index: number) => {
                  return (<Grid item xs={12} sm={9} md={6} lg={4} xl={3} key={index}>
                    <Card onClick={() => this.props.history?.push(`/template-reporting?tid=${item?.id}&wid=${this.query.get('wid')}`)} style={webStyle.cards}>
                      <div style={webStyle.maincard2}>
                        {item?.attributes?.image ? <img src={`${item?.attributes?.image}`} style={webStyle.tempImg} /> : <img src={this.loadStaticTemplateImgs(item?.attributes?.key)} style={webStyle.tempImg} />}
                        <br />
                        <br />
                        <Typography style={webStyle.header}>
                          {item?.attributes?.report_title}
                        </Typography>
                      </div>
                    </Card>
                  </Grid>
                  )
                }) :
                  (<Box style={{ minHeight: '70vh', width: '100%' }}>
                  <div style={webStyle.noData}>No Data Found</div>
                </Box>)
                }
              </Grid>
            </div>
          </Box>
        </Box>
      </>
    );
  }
}

const webStyle = {
  errormessageContent: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    fontSize: 16,
    fontWeight: 600,
    color: "#6a6f7a",
    background: '#eeeeee',
    fontFamily: "sans-serif",
    alignItems: "center",
    height: "95vh"
}as React.CSSProperties,
  background: {
    backgroundColor: "#eeeeee",
    height: "100%",
    color: "#5f5f5f",
    fontFamily: "sans-serif",
    width: "85vw",
    right: 0,
    overflow: 'scroll',
    marginTop: 35
  },
  content: {
    right: "0px",
    background: "#eeeeee",
    marginTop: "50px",
    justifyContent: "space-between",
    width: '85vw',
    position: 'absolute'
  } as React.CSSProperties,
  tempImg: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    maxWidth: "320px",
    height: "146px",
    borderRadius: "4px",
    border: "1px dashed #dbdbdb",
  },
  arrow: {
    backgroundColor: "white",
    width: 35,
    height: 35,
    marginLeft: 30,
    borderRadius: 8,
  },
  cards: {
    height: "auto",
    margin: 10,
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    padding: '30 0'
  } as React.CSSProperties,

  maincard: {
    cursor: "pointer",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "4rem 0",
    flexDirection: 'column'
  } as React.CSSProperties,
  maincard2: {
    cursor: "pointer",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "10",
    flexDirection: 'column'
  } as React.CSSProperties,
  header: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#2c2c2c",
    fontSize: "18px",
    fontWeight: 700,
    textAlign: "center",
  } as React.CSSProperties,

  paragraph: {
    color: "#8f8e8e",
    padding: '0px 20px',
    height: "70",
    overflowX: 'auto',
    align: "justify",
    marginBottom: 20
  } as React.CSSProperties,

  title: {
    margin: '0px 30px',
    color: "#252631",
    fontSize: "20px",
    fontWeight: 600,
  },

  cardContent: {
    marginLeft: 20,
    marginTop: 10,
    display: 'flex',
    flexWrap: 'wrap',
    listStyle: 'none'
  } as React.CSSProperties,

  modalCardRoot: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    // width: 350,
    minHeight: "50%",
    maxHeight: "80%",
    fontSize: "16px",
    overflowY: "scroll",
  } as React.CSSProperties,
  modalRoot: {
    display: "flex",
    gap: 1,
    fontSize: "16px",
    justifyContent: "space-between",
    borderBlockStart: "1px solid #ccc",
    paddingBlock: "2rem",
    overflow: "scroll",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    alignItems: "center",
  },
  modalCardHeader: {
    paddingInline: "1rem",
  },
  modalCardHeaderText: {
    fontWeight: 600,
  },
  modalCardContent: {
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    paddingRight: 0,
    fontSize: "16px",
  } as React.CSSProperties,
  modalFieldName: {
    fontSize: "14px",
    fontWeight: 600,
    margin: "10px 0px",
  } as React.CSSProperties,
  modalForm: {
    display: "flex",
    flexDirection: "column",
    paddingLeft: "1rem",
    paddingRight: "1rem",
    margin: "10px",
  } as React.CSSProperties,
  modalHeader: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  } as React.CSSProperties,
  modalUpdateImage: {
    backgroundColor: "white",
    margin: "10px",
    fontSize: "15px",
    color: "#0096ff",
    borderRadius: "5%",
    textTransform: "capitalize",
    fontWeight: 600
  } as React.CSSProperties,
  modalCardActionsContainer: { display: "flex", gap: "1.5rem" },
  modalCardActions: {
    display: "flex",
    justifyContent: "flex-end",
    paddingInline: "1rem",
  },
  modalCardCancelButton: {
    backgroundColor: "#e8e8e8",
    borderRadius: "8px",
    textTransform: "none",
    width: "140px",
    fontWeight: 800,
    height: "50px",
  } as React.CSSProperties,
  modalCardActionButton: {
    backgroundColor: "#4eabf8",
    color: "white",
    borderRadius: "4px",
    width: "140px",
    textTransform: "none",
    height: "50px",
    fontWeight: 800,
  } as React.CSSProperties,
  modalCardAdminActionButton: {
    backgroundImage: "radial-gradient(rgb(246 168 34), rgb(171 114 24))",

    color: "black",
    borderRadius: "4px",
    width: "140px",
    textTransform: "none",
    height: "50px",
    fontWeight: 800,
  } as React.CSSProperties,
  editIconWrapper: {
    position: 'absolute',
    right: 0,
    background: '#F5F5F5',
    width: 40,
    height: 40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer'
  } as React.CSSProperties,
  Textareastyle: {
    fontFamily: "sans-serif",
    fontSize: '16px',
    fontWeight: '0px',
    padding: 13,
    border: '1px solid #d1d5da',
    borderRadius: '4px'
  } as unknown as React.CSSProperties,
  pageheader: {
    display: "flex",
    justifyContent: 'left',
    marginLeft: 30
  },
  pagename: {
    height: '100%',
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    alignContent: "center",
    // flexDirection: row,
  },
  arrowbox: {
    backgroundColor: "white",
    width: 35,
    height: 35,
    borderRadius: 8,
    alignItems: "center",
    justifyContent: "center",
    alignContent: "center",
    display: "flex",
    cursor: 'pointer',
  },
  headerNameWrapper: {
    // marginLeft: 15 
  } as React.CSSProperties,
  loader: {
    minHeight: '75vh',
    display: "flex",
    justifyContent: "center",
    alignItems: 'center'
  } as React.CSSProperties,
  noData: {
    height: '70vh',
    display: 'grid',
    placeItems: 'center'
  } as React.CSSProperties,
};
// Customizable Area End
