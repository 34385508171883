import React from "react";
import "./autosuggestion.css"
import {

    Box,
    Button,
    Typography,
    InputAdornment,
    Modal,
    Grid,
    Divider,
    TextField,
    Input
} from "@material-ui/core";

import SearchBar from "material-ui-search-bar";
import ClearIcon from '@material-ui/icons/Clear';
import GenericSuccessModal from "../../ContentManagement/src/GenericSuccessModal.web";
import * as XLSX from 'xlsx';
import { redicon } from "./assets"

// import "./invoice.css";
// Customizable Area End

import AutoSuggestionController, { Props } from "./AutoSuggestionController";


export default class AutoSuggestion extends AutoSuggestionController {
    // static get isLoggedIn(){
    //     return {isLoggedIn:localStorage.getItem('token')}
    // }

    constructor(props: Props) {

        super(props);
    }

    handleEdit = () => {
        this.setState({ isEdit: true });
    };
    handleCancelbtn = () => {

        if (this.state.removeData.length > 0) {
            this.setState({ newResult: [...this.state.newResult, ...this.state.removeData] })
        }
        if (this.state.newNames.length > 0) {
            const filteredData = this.state.newResult.filter(((item: any) => {
                return this.state.newNames.indexOf(item) == -1
            }))

            this.setState({ newResult: filteredData, newNames: [] })

        }
        this.setState({ isEdit: false, page: true, removeData: [], name: "", sameValue: false });
    };

    handleChange = (e: any) => {
        const val = {
            ...this.state,
            [e.target.name]: e.target.value,
        };
        this.setState(val, () => {
            this.setState({
                sameValue: this.state.newResult.filter((item: any) => (
                    item.toLowerCase().trim() == this.state.name.toLowerCase().trim()
                )).length > 0 ? true : false
            })


        });

    }


    handleAddProjectCode = () => {
        if (this.state.page) {
            this.setState({ page: false });
        }

        if (this.state.name.trim().length !=0) {
            this.setState(({
                newResult: [...this.state.newResult, this.state.name], name: "", page: true,
                newNames: [...this.state.newNames, this.state.name]
            }))
        }else{
            this.setState({sameValue:true})
        }


    }
    modalClose = () => {
        this.setState({ isSuccessModal: false, modalMsg: "" });
    };
    successErrModalBody = () => (
        <>
            <div>
                <Typography
                    className="success-message-modal"
                >

                    {this.state.modalMsg}

                </Typography>
            </div>
        </>
    );
   
    handleCodeDelete = (code: any) => {
        const result = this.state.newResult.filter((item: any) => item != code)
        this.setState({ newResult: result, removeData: [...this.state.removeData, code] })

    }
    requestSearch = (e: any) => {
        this.setState({ search: e }, () => this.getSuggestionList());
    };
    cancelSearch = () => {
        this.setState({ search: "" })
        this.requestSearch(this.state.search);
        this.setState({ search: "" })
    };
    handleSelectedType = (e: any) => {

        this.setState({ selectedType: e})
        this.getAll(e)
    }
    handleCloseModal = () => {
        this.setState({ checkValue: false, modalMessage: "" })
    }
    handleSameAutoSuggestion = () => (
        <Modal
            className="modal-backdrop"
            //   open={false}
            open={this.state.checkValue}
            onClose={this.handleCloseModal}
            style={{
                overflow: "auto",
            }}
        >
            <div

                style={{
                    zIndex: 10,
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    background: "white",
                    width: "20%",
                    // height: "30%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "20px",
                }}
            >

                <img
                    width="40px"
                    style={{ paddingTop: "40px" }}
                    src={redicon}
                // style={{ width: "200px", height: "150px" }}
                />
                <div

                    style={webStyle.modalname}
                >
                    {this.state.modalMessage}

                </div>
                <Button
                    onClick={this.handleCloseModal}

                    style={webStyle.okBtn}

                >
                    Ok
                </Button>
            </div>
        </Modal>
    )
    handleAutoSuggestionDelete = (code: any) => {
        let autosuggest = this.state.removeData.includes(code) ?
            (
                this.setState({ removeData: this.state.removeData.filter((item: any) => item !== code) })
            )
            : (this.state.removeData.push(code),
                this.setState({ selectedCode: code }))
        return autosuggest
    }
    handleFileSelect = (event: any) => {


        let name = event?.target?.files[0].name;
        const reader = new FileReader();

        reader.onload = (evt: any) => { // evt = on_file_select event

            const bstr = evt.target.result;
            const wb = XLSX.read(bstr, { type: 'binary' });

            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];

            const data = XLSX.utils.sheet_to_csv(ws);

            let test = data.split("\n")

            if (test[0] == "name") {

                if(test.length ==1){

                        this.setState({ checkValue: true, modalMessage: "Empty file uploaded!", page: true })

                }else{
                test?.filter((item: any, index: any) => {
                const lowercaseResult=this.state.newResult?.map((item:any)=>(
                    item.toLowerCase()
                ))

                
                    if (index > 0) {

                        if (!lowercaseResult?.includes(item.toLowerCase())) {
                            this.state?.newNames?.push(item)
                            this.state?.newResult?.push(item)
                        } else {
                            this.setState({ checkValue: true, modalMessage: "Duplicate  entries found!", page: true })
                        }
                    }
                })}
            }
            else {
                this.setState({ checkValue: true, modalMessage: "Please upload a correct CSV file!", page: true })
            }
            this.setState(({
                newResult: [...this.state.newResult], name: "", page: true,
                newNames: [...this.state.newNames]
            }))
        };
        reader.readAsBinaryString(event.target.files[0]);

    };

    handleSaveBtnCode = () => {
        if (this.state.removeData.length > 0) {
            this.deleteAuto()
            this.setState({ removeData: [] })

        } else {
            if (this.state.selectedType.length > 0 && this.state.newNames.length > 0) {
                this.handleAddSuggestion()
            }

        }
    }


    editAutoSuggestion = () => (
        <>
            <Grid className="edit-maingrid" xs={12}>
                <Box className="box-maingrid">
                    <Grid xs={12}>
                        <div className="subgrid" >
                            {this.state.newResult && this.state.newResult.filter((item: any) => {
                                if (!this.state.search) return true
                                if (item.toLocaleLowerCase()?.includes(this.state.search.toLocaleLowerCase())) return true
                            }).map((code: any) => {

                                return (

                                    <div style={{ marginRight: "10px" }}>

                                        <Button   id="clear-auto" onClick={() => this.handleCodeDelete(code)} variant="outlined" style={{ textTransform: "none" }} >
                                            {code}
                                            <ClearIcon />
                                        </Button>
                                    </div>

                                )
                            })}
                            <div>
                            </div>
                        </div>
                    </Grid>
                    <div>
                        <Typography className="add-auto-heading">{`Add ${this.state.selectedType}  Auto Suggestion`}</Typography>
                        <div className="input-add-code">
                            <TextField
                                variant="outlined"
                                size="small"
                                required
                                type="text"
                                name="name"
                                value={this.state.name}
                                onChange={this.handleChange}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start" />,
                                }}
                                id="name-auto-text"
                            />

                            <div className="add-auto-btn-code">
                                <input
                                    onClick={(event: any) => {
                                        event.target.value = ''
                                    }}
                                    type="file"
                                    style={{ display: "none" }}
                                    id="project-import"
                                    onChange={this.handleFileSelect}
                                    accept={".csv"}

                                />


                                <Button  id="add-auto" className="add-auto-code" onClick={this.handleAddProjectCode} disabled={(this.state.name.trim().length==0||this.state.sameValue) } >Add |</Button>

                                <label htmlFor="project-import" >
                                    <Button   id="upload-csv"  component="span" className="add-auto-code" >Import from .CSV |</Button>
                                </label>

                                <Button id="download-id" className="add-auto-code" onClick={() => this.getDownloadSampleList()}>Download Sample .CSV</Button>
                            </div>

                        </div>
                        {this.state.name === "" && !this.state.page && (
                            <>
                                <p
                                    className="messageErr"

                                >

                                    Name is required
                                </p>
                            </>
                        )}
                        {this.state.sameValue && (
                            <>
                                <p
                                    className="messageErr"

                                >

                                    Same  Name is  there
                                </p>
                            </>
                        )}

                    </div>
                </Box>
            </Grid>

        </>
    )
    render() {

        return (
            <>
                {this.handleSameAutoSuggestion()}

                {this.state.isSuccessModal &&
                    <GenericSuccessModal
                        isOpen={this.state.isSuccessModal}
                        succesModalBody={this.successErrModalBody}

                        close={this.modalClose}
                    />
                }
                <Box
                    sx={{
                        height: "100%",
                        overflow: "scroll",
                        width: "85vw",
                        top: "50px",

                        right: 0,
                        boxSizing: "border-box",
                        padding: "50px 20px 10px 20px",
                        position: "absolute",

                        // spacing:2
                    }}
                    style={{ backgroundColor: "#e8e8e8" }}
                >
                    <Box

                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            width: "100%",
                        }}
                    >
                        <Typography className="terms-heading">
                            {this.state.isEdit
                                ? `Edit Auto Suggestions`
                                : `Auto Suggestions`}
                        </Typography>

                        <div className="maincodediv">
                            <Box>
                                {this.state.isEdit ? (
                                    <div className="subdiv-code-btn">
                                        <Button
                                            className="cancel-code-btn"
                                            onClick={this.handleCancelbtn}
                                        >
                                            Back
                                        </Button>
                                        <Button  id="save-auto" className="save-code-btn" onClick={this.handleSaveBtnCode}>
                                            Save
                                        </Button>
                                    </div>
                                ) : (
                                    <Button className="editcodebtn" onClick={this.handleEdit}  id="edit-auto">
                                        Edit
                                    </Button>
                                )}
                            </Box>
                        </div>
                    </Box>
                    <div style={{ display: "flex", }}>
                        <Button onClick={() => this.handleSelectedType("AHJ")}  className="btn-ahj" style={{ backgroundColor: this.state.selectedType == "AHJ" ? "white" : "#D3D3D3" }} value="AHJ" >AHJ</Button>
                        <Button onClick={() => this.handleSelectedType("Utilities")}  className="btn-ahj" style={{ backgroundColor: this.state.selectedType == "Utilities" ? "white" : "#D3D3D3" }} value="Utilities">Utilities</Button>
                    </div>

                    <Grid style={{ height: "100%", backgroundColor: "white", overflow: "scroll", marginTop: "20px" }}>
                        <Box style={{ marginTop: "20px" }} className="table-data1">

                            <SearchBar
                                placeholder={`Search ${this.state.selectedType}`}
                                value={this.state.search}
                                onChange={this.requestSearch}
                                onCancelSearch={this.cancelSearch}
                                className="search-auto-id"
                                style={{ width: "25vw" }}
                            />
                        </Box>
                        <p className="subhead-heading">{this.state.isEdit ? " " : `${this.state.selectedType} Auto Suggestions`}</p>
                        {this.state.isEdit ? this.editAutoSuggestion() :
                            <Box className="table-data1">
                                {this.state.newResult && this.state.newResult.filter((item: any) => {
                                    if (!this.state.search) return true
                                    if (item.toLocaleLowerCase()?.includes(this.state.search.toLocaleLowerCase())) return true
                                }).map((item: any) => (
                                    < div className="textdata"><p>{item}</p><Divider /></div>
                                ))}
                            </Box>
                        }

                    </Grid>
                </Box>
            </>
        );
    }
}

const webStyle = {
    boxes: {
        marginTop: "10px",
    },
    modalname: {
        fontFamily: "sans-serif",
        fontWeight: "bold",
        fontSize: 16,
        marginTop: "25px"


    } as React.CSSProperties,
    okBtn: {
        marginTop: "2% ",
        marginLeft: "15px",
        backgroundColor: "#e8e8e8",
        color: "black",
        width: "30%",
        padding: "10px 35px 10px 35px",
        fontFamily: "sans-serif ",
        fontSize: 16,
        marginBottom: "20px"


    } as React.CSSProperties
};
