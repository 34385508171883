import React from "react";
// Customizable Area Start
import {
  Box,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Divider,
  Modal,
  Checkbox,
  Select,
  MenuItem,
  InputLabel,
  CircularProgress,
  Tooltip
} from "@material-ui/core";
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import CloseIcon from "@material-ui/icons/Close";
import ListAltIcon from '@material-ui/icons/ListAlt';
import PublishIcon from '@material-ui/icons/Publish';

import "./style.css";
// Customizable Area End

// Customizable Area Start
import GetAppIcon from '@material-ui/icons/GetApp';
// Customizable Area End
import DefineChecklistTableController, {
  Props,
} from "./DefineChecklistTableController";
import AddChecklistModal from "./AddCheckListModal.web";
import TemplateBreadcrumbs from "./TemplateBreadcrumbs.web";
import UploadErrorModal from "./UploadErrorModal";
import TemplateStepsFooter from "./TemplateStepsFooter";


export default class DefineChecklistTable extends DefineChecklistTableController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  //Customizable Area Start

  handleUploadConfirmModal = () => (
    <Modal
      className="modal-backdrop"
      open={this.state.isUploadConfirm}
      onClose={() => this.setState({isUploadConfirm: false})}
      style={{
        overflow: "auto",
      }}
    >
      <div
        style={{
          zIndex: 10,
          position: "absolute",
          fontFamily: 'sans-serif',
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          background: "white",
          width: "25%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "20px",
          borderRadius: 5
        }}
      >
          <div>
            <div className="confirm-heading" style={{ margin: "20 30" }}>
              <Typography style={{ fontSize: 16, fontWeight: 600 }}>Do you want to continue?</Typography>
              <CloseIcon style={{cursor: 'pointer'}} onClick={this.handleUploadCancelBtn} />
            </div>
            <Divider style={{width: '100%'}}/>
            <div style={{ margin: "20 30", color: 'grey' }} className="confirmbody">
              <Typography>All old parameters will be removed and new parameter will be added.</Typography>
            </div>
            <Divider style={{width: '100%'}}/>
            <div style={{display: "flex", justifyContent: "end", margin: "20 30"}}>
              <Button
                onClick={this.handleUploadCancelBtn}
                style={webStyle.modalCardCancelButton}
              >
                Cancel
              </Button>
              <input
                type="file"
                style={{ display: "none" }}
                id="icon-button-file"
                onClick={(event: any) => {
                  event.target.value = ''
                }}
                accept={".csv"}
                onChange={this.handleFileSelect}
              />
              &nbsp;
              <label htmlFor="icon-button-file">
                <Button
                  component="span"
                  style={(localStorage.getItem('user_type') == 'Superadmin'? webStyle.modalCardActionButton : webStyle.modalCardAddActionButton)}
                >
                  <span>Confirm</span>
                </Button>
              </label>
            </div>
          </div>
      </div>
    </Modal>
  )



  renderChecklistButtons = () => (<div style={webStyle.checklistbuttons}>
    <Button
      test-id="download-sample-btn"
      style={webStyle.fileButton}
      onClick={() => this.downloadChecklistCSV('sample')}
      disableRipple={true}
    >
      <span className={localStorage.getItem('user_type') == 'Superadmin' ? "button-checklist" : "admin-button-checklist "} >
        {localStorage.getItem('user_type') == "Superadmin" ? "" : <GetAppIcon style={{ marginRight: 3 }} />} Download sample .CSV file
      </span>
    </Button>

    <Button
      test-id="download-complete-btn"
      style={webStyle.fileButton}
      onClick={() => this.downloadChecklistCSV('data')}
      disableRipple={true}
    >
      <span className={localStorage.getItem('user_type') == 'Superadmin' ? "button-checklist" : "admin-button-checklist "} >
        {localStorage.getItem('user_type') == "Superadmin" ? "" : <ListAltIcon style={{ marginRight: 3 }} />} Download  Complete data
      </span>
    </Button>

    <Button
      test-id="upload-CSV-btn"
      component="span"
      style={webStyle.fileButton}
      disableRipple={true}
    >
      <span className={localStorage.getItem('user_type') == 'Superadmin' ? "button-checklist" : "admin-button-checklist "}
        onClick={() => this.handleUpload()}>
        {localStorage.getItem('user_type') == "Superadmin" ? "" : <PublishIcon style={{ marginRight: 3 }} />} Upload .CSV file
      </span>
    </Button>

    <AddChecklistModal
      navigation={""}
      id={""}
      that={this}
      history={""}
    />
  </div>)

  renderCheckListTable = () => {
    if (this.state.loading) return (<Box style={webStyle.loader}><CircularProgress size={50} /></Box>)
    else if (this.state?.errParameter?.length) return (<Box
      sx={{
        p: 3,
        bgcolor: "#EEEEEE",
      }}
    >
      <div className="template-table">
        <Table>
          <TableHead className={localStorage.getItem('user_type') == 'Superadmin' ? "checklist-tablehead" : "admin-checklist-tablehead"}>
            <TableRow>
              <TableCell style={{ width: "50px", textAlign: "center", fontWeight: 'bold' }}>
                S No
              </TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>Error Parameter</TableCell>
              <TableCell
                style={{ width: "150px", textAlign: "center", fontWeight: 'bold' }}
              >
                Designer
              </TableCell>
              <TableCell
                style={{ width: "150px", textAlign: "center", fontWeight: 'bold' }}
              >
                QC
              </TableCell>
              <TableCell
                style={{ width: "70px", textAlign: "center", fontWeight: 'bold' }}
              >
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.state?.errParameter?.map((data: any, index: any) => {
              return (
                <TableRow key={index}>
                  <TableCell
                    style={{
                      border: "1px solid #BDBDBD",
                      backgroundColor: "white",
                      textAlign: 'center'
                    }}
                  >
                    {data?.attributes?.s_no}
                  </TableCell>
                  <TableCell
                    style={{
                      border: "1px solid #BDBDBD",
                      backgroundColor: "white",
                    }}
                  >
                    {data?.attributes?.error_parameter}
                  </TableCell>
                  <TableCell
                    style={{
                      border: "1px solid #BDBDBD",
                      backgroundColor: "white",
                      textAlign: 'center'
                    }}
                  >
                    <Checkbox disabled />
                  </TableCell>
                  <TableCell
                    style={{
                      border: "1px solid #BDBDBD",
                      backgroundColor: "white",
                      
                    }}
                  >
                      <div style={{ width: "150px", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <InputLabel disabled id="demo-simple-select-disabled-label">
                          Select
                        </InputLabel>
                        <Select
                          disabled
                          labelId="demo-simple-select-disabled-label"
                          id="demo-simple-select-disabled"
                          label="Age"
                          disableUnderline
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          <MenuItem value={10}>Ten</MenuItem>
                          <MenuItem value={20}>Twenty</MenuItem>
                          <MenuItem value={30}>Thirty</MenuItem>
                        </Select>
                      </div>
                  </TableCell>
                  <TableCell style={{
                    border: "1px solid #BDBDBD",
                    backgroundColor: "white",
                  }}>
                    <div style={{ height: 10, display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                      <Tooltip title={"Edit error parameter"}>
                      <Typography display="inline">
                        <EditOutlinedIcon test-id="edit-parameter" style={{cursor: 'pointer'}} onClick={() => this.setState({ openAddChecklistParam: true, isEdit: true, checklistField: data?.attributes?.error_parameter, editId: data?.id })} />
                      </Typography>
                      </Tooltip>
                      
                      <Tooltip title={"Delete error parameter"}>
                      <Typography display="inline">
                        <DeleteOutlineIcon test-id="delete-parameter" style={{cursor: 'pointer'}} onClick={() => this.deleteChecklistParameter(data?.id)} />
                      </Typography>
                      </Tooltip>
                    </div>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
    </Box>)
    else return (<Box style={{ minHeight: '55vh' }} sx={{ bgcolor: "#e8e8e8", p: 2 }}>
      <div style={webStyle.noData}>No Error Parameter Found</div>
    </Box>)
  }
  // Customizable Area End
  render() {
    //Customizable Area Start
    //Customizable Area End
    return (
      //Customizable Area Start
      <>
        {this.state.isUploadConfirm && this.handleUploadConfirmModal()}
        <Box style={webStyle.background}>
          <Box style={webStyle.content} className="background-content">
            <div className="heading" style={webStyle.pageheader}>
              <TemplateBreadcrumbs
                navigation={""}
                id={""}
                that={this}
                history={this.props.history} />
              
              {this.state.isProjectsUploading && (<div className='uploadingLoaderContainer'>
                <div>
                  <div style={{ marginBottom: 5 }} >
                    <b>Uploading...</b>
                  </div>
                  <div className={`loaderbar ${localStorage.getItem('user_type') !== "Superadmin" ? "admin" : ""}`} />
                </div>
              </div>)}
              <div>
                <Button test-id="save-and-goto-btn" style={webStyle.savebutton} onClick={this.handleSave}>
                  <span className="save-button-content">Save & go to home</span>
                </Button>
                <Button test-id="save-btn" style={localStorage.getItem('user_type') == 'Superadmin' ? webStyle.nextbutton : webStyle.adminnextbutton} onClick={this.handleNext}>
                  <span className={localStorage.getItem('user_type') == 'Superadmin' ? "next-button-content" : "admin-next-button-content"}>Save</span>
                </Button>
              </div>
            </div>
            <br />
              <Divider />
            {this.renderChecklistButtons()}
            {this.renderCheckListTable()}
           <TemplateStepsFooter step={2}/>
          </Box>
        </Box>
        <UploadErrorModal
          isOpen={this.state.displayParam}
          handleClose={() => this.closeChecklistModal()}
          handleOkClick={
            () => this.setState({ displayParam: false, isUploadConfirm: false })
          }
          errorData={this.state.duplicateValue} />
      </>
    );
    //Customizable Area End
  }
}
//Customizable Area Start
const webStyle = {
  modalRoot: {
    display: "flex",
    gap: 1,
    fontSize: "16px",
    justifyContent: "center",
    borderBlockStart: "1px solid #ccc",
    paddingBlock: "2rem", //
    backdropFilter: "blur(5px)",
    overflow: "scroll",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    alignItems: "center",
  },
  modalBackdropProps: {
    backgroundColor: "rgba(0, 0, 0, .7)",
    backdropFilter: "blur(10px)",
  },
  modalButton: {
    marginTop: "20px",
    backgroundColor: "#4eabf8",
    color: "white",
    borderRadius: "4px",
    width: "263px",
    textTransform: "none",
    height: "50px",
    fontWeight: 600,
    fontFamily: "sans-serif",
  } as React.CSSProperties,
  modalButtonAdmin: {
    marginTop: "20px",
    backgroundImage: "radial-gradient(rgb(246 168 34), rgb(171 114 24))",
    color: "white",
    borderRadius: "4px",
    width: "263px",
    textTransform: "none",
    height: "50px",
    fontWeight: 600,
    fontFamily: "sans-serif",
    // marginLeft:"5px"
  } as React.CSSProperties,
  adminnextbutton: {
    width: "193px",
    height: "50px",
    borderradius: "4px",
    backgroundImage: "radial-gradient(rgb(246 168 34), rgb(171 114 24))",
  },
  modalCardActionButton: {
    backgroundColor: "#42a5f5",
    color: "white",
    borderRadius: "4px",
    width: "120px",
    textTransform: "none",
    fontWeight: 600,
    marginLeft:"5px",
    fontSize: 16,
  } as React.CSSProperties,
  modalCardAddActionButton: {
    backgroundImage: "radial-gradient(rgb(246 168 34), rgb(171 114 24))",
    color: "black",
    borderRadius: "4px",
    width: "120px",
    textTransform: "none",
    fontWeight: 600,
    marginLeft:"5px",
    fontSize: 16,
  } as React.CSSProperties,

  duplicatebtn: {
    color: "#ffffff",
    fontSize: "18px",
    fontWeight: "bold",
    textAlign: "center",
    fontFamily: "sans-serif",
  } as React.CSSProperties,
  duplicateadminbtn: {
    color: "black",
    fontSize: "18px",
    fontWeight: "bold",
    textAlign: "center",
    fontFamily: "sans-serif",
  } as React.CSSProperties,
  background: {
    backgroundColor: "white",
    height: "100vh",
    fontFamily: "sans-serif",
    marginTop: 35
  },
  content: {
    top: "50px",
    right: "0px",
    width: "85vw",
    height: "fit-content",
    padding: "50px 20px 10px",
    justifyContent: "space-between",
    backgroundColor: "white"
  },
  pageheader: {
    display: "flex",
    justifyContent: "space-between",
  },
  pagename: {
    display: "flex",
  },
  arrowbox: {
    backgroundColor: "#eeeeee",
    width: 35,
    height: 35,
    margin: 10,
    borderRadius: 8,
    alignItems: "center",
    justifyContent: "center",
    alignContent: "center",
    display: "flex",
  },
  confirmbtn:{
    backgroundColor: "#0096FF"
  },
  adminconfirmbtn:{
    backgroundImage: "radial-gradient(rgb(246 168 34), rgb(171 114 24))",
    color: "black",
  },

  arrow: {
    width: 20,
    height: 20,
  },

  title: {
    margin: 10,
    marginTop: 2,
    fontWeight: 500,
    fontSize: 18,
  },

  breadcrumb: {
    marginLeft: 10,
    marginTop: -10,
  },

  savebutton: {
    marginRight: 10,
    width: "193px",
    height: "50px",
    borderradius: "8px",
    background: "#e8e8e8",
  },

  nextbutton: {
    width: "193px",
    height: "50px",
    borderradius: "4px",
    background: "#4eabf8",
  },

  checklistbuttons: {
    display: "flex",
    height: "50px",
  },
  fileButton: {
    color: "rgb(0, 150, 255)",
    textTransform: "none",
    marginRight: "20px",
    boxShadow:"none",
    outline: 0
  } as React.CSSProperties,
  underline: {
    borderBottom: "0px solid red !important",
    border: "0px solid white !important",
  },
  successSaveModal: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignContent: 'center',
    width: '436px',
    height: '300px',
    borderRadius: '4px',
    background: '#ffffff',
  } as React.CSSProperties,
  rightIcon: {
    marginTop: '10px',
    width: '30px',
    height: '30px'
  } as React.CSSProperties,
  TemplateCreatedText: {
    fontFamily: "sans-serif",
    fontSize: "26px",
    width: '282px',
    textAlign: 'center',
    marginBottom: '-10px'
  } as React.CSSProperties,
  modalCardCancelButton: {
    backgroundColor: "rgba(230, 230, 230, 1)",
    borderRadius: "4px",
    textTransform: "none",
    fontSize: 16,
    width: "120px",
    fontWeight: 600,
  } as React.CSSProperties,
  loader: {
    minHeight: '55vh',
    display: "flex",
    justifyContent: "center",
    alignItems: 'center'
  } as React.CSSProperties,
  noData: {
    height: '55vh',
    display: 'grid',
    placeItems: 'center'
  } as React.CSSProperties,
};
//Customizable Area End
